import React, { useState } from "react";
import "./StartupSeeMorePage.scss";
import linkedinicon from "../../../assets/images/linkedinicon.png";
import membericon from "../../../assets/images/FounderPhoto.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import prependHttp from "prepend-http";
import { fetchUserDashboard } from "../../../redux/investorFlowControl/investorFlowControlActions";

function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="cofounders-carousel">
      <Carousel
        interval={null}
        activeIndex={index}
        onSelect={handleSelect}
        className={`mb-0 ${
          props.cFou && props.cFou.length < 4 ? "str-arrows" : ""
        }`}
      >
        <Carousel.Item>
          <div className="founding-team-member-see-more d-flex justify-content-center">
            {props.cFou
              ? props.cFou.map((cf, idx) => (
                  <>
                    {idx > 2 ? null : (
                      <div className="member-see-more mx-2">
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            display: "block",
                            margin: "30px auto 5px",
                          }}
                          src={cf.profile_image ? cf.profile_image : membericon}
                          alt=""
                        />
                        <div className="member-fullname">
                          <span className="member-name-see-more">
                            {cf.name}
                          </span>
                        </div>
                        <div
                          className="member-position-see-more"
                          style={{
                            textTransform: (
                              cf.position[0] === "CMO" ||
                              cf.position[0] === "cmo" ||
                              cf.position[0] === "COO" ||
                              cf.position[0] === "coo" ||
                              cf.position[0] === "CFO" ||
                              cf.position[0] === "cfo" ||
                              cf.position[0] === "CEO" ||
                              cf.position[0] === "ceo" ||
                              cf.position[0] === "CTO" ||
                              cf.position[0] === "cto"
                            ) ? "uppercase" : "capitalize"
                          }}
                        >
                          {cf.position[0]}
                        </div>
                        <div className="member-bio-see-more">{cf.bio}</div>
                        {cf.linkedin_link ? (
                          <a
                            href={prependHttp(cf.linkedin_link)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkedin-link"
                          >
                            <div
                              style={{ color: "black", textDecoration: "none" }}
                              className="member-linkedin-see-more"
                            >
                              <img
                                className="linkedin-logo-see-more"
                                src={linkedinicon}
                                alt=""
                              />
                              <span className="linked-in-profile">
                                Linkedin Profile
                              </span>
                            </div>
                          </a>
                        ) : (
                          <div style={{ height: "53px" }}></div>
                        )}
                      </div>
                    )}
                  </>
                ))
              : null}
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="founding-team-member-see-more d-flex justify-content-center">
            {props.cFou
              ? props.cFou.map((cf, idx) => (
                  <>
                    {idx < 3 ? null : (
                      <div className="member-see-more mx-2">
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            display: "block",
                            margin: "30px auto 5px",
                          }}
                          src={cf.profile_image ? cf.profile_image : membericon}
                          alt=""
                        />
                        <div className="member-fullname">
                          <span className="member-name-see-more">
                            {cf.name}
                          </span>
                        </div>
                        <div className="member-position-see-more">
                          {cf.position[0]}
                        </div>
                        <div className="member-bio-see-more">{cf.bio}</div>
                        {cf.linkedin_link ? (
                          <a
                            href={prependHttp(cf.linkedin_link)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkedin-link"
                          >
                            <div
                              style={{ color: "black", textDecoration: "none" }}
                              className="member-linkedin-see-more"
                            >
                              <img
                                className="linkedin-logo-see-more"
                                src={linkedinicon}
                                alt=""
                              />
                              <span className="linked-in-profile">
                                Linkedin Profile
                              </span>
                            </div>
                          </a>
                        ) : (
                          <div style={{ height: "53px" }}></div>
                        )}
                      </div>
                    )}
                  </>
                ))
              : null}
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

class StartupSeeMorePage extends React.Component {
  render() {
    const { userInfos } = this.props;

    return (
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, .85)",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <div
          className="screen"
          style={{
            paddingTop: "120px",
            animation: `fadeIn 1s`,
            paddingBottom: "1px",
          }}
        >
          <div className="dashboard-container">
            <div
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                width: "100%",
                textAlign: "right",
                marginBottom: "-50px",
                position: "relative",
                right: "15px",
              }}
            >
              <Link to="/startup/preview" style={{ color: "black" }}>
                <i className="fa fa-times-thin testing"></i>
              </Link>
            </div>
            <div
              className="content"
              style={{
                height: "100%",
                paddingBottom: "20px",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            >
              <div className="founding-team-see-more">
                <div
                  className="founding-team-top-see-more"
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    margin: "auto",
                  }}
                >
                  Founding Team
                </div>
                <ControlledCarousel cFou={userInfos} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  investorUser: state.auth.user,
  userInfos: state.auth.startupUser.co_founders,
  index: state.investorflowControl.index,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchUserProfile: offset => {
  //   return dispatch(fetchUserProfile(offset));
  // },
  fetchUserDashboard: () => {
    return dispatch(fetchUserDashboard());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StartupSeeMorePage);
