import UserType from './userType'
import { createStartupIdentifier, createInvestorIdentifier, createScoutIdentifier } from './identifiers'
import { emitEvent, identify, page, resetIdentity } from './segmentClient'

import { requireNonEmpty } from '../utils/general'

const USER_SIGNUP = 'User Signup'
const USER_LOGIN = 'User Login'
const USER_SIGNUP_FAILED = 'User Signup Failed'
const USER_LOGIN_FAILED = 'User Login Failed'
const STARTUP_MATCH_DISPLAYED = 'Startup Match Displayed'
const INVESTOR_MATCH_DISPLAYED = 'Investor Match Displayed'
const SCOUT_MATCH_DISPLAYED = 'Scout Match Displayed'

const USER_ONBOARDING_COMPLETED = 'User Onboarding Completed'

export function identifyStartup(startupId, firstName, lastName, email, isGoogleSignup) {
  requireNonEmpty(startupId, 'Startup id must be non-empty to identify a startup.')
  requireNonEmpty(firstName, 'Startup first name must be non-empty to identify a startup.')
  // requireNonEmpty(lastName, 'Startup last name must be non-empty to identify a startup.')
  requireNonEmpty(email, 'Startup email must be non-empty to identify a startup.')
  requireNonEmpty(isGoogleSignup, 'Startup isGoogleSignup must be non-empty to identify a startup.')

  const startupUniversalId = createStartupIdentifier(startupId)
  var identificationProperties = {
    userType: UserType.STARTUP,
    firstName,
    lastName,
    email,
    isGoogleSignup,
  }

  if (identificationProperties.lastName.trim() === '') {
    delete identificationProperties.lastName
  }

  identify(startupUniversalId, identificationProperties)
}

export function identifyScout(scoutId, firstName, lastName, email, isGoogleSignup) {
  requireNonEmpty(scoutId, 'Scout id must be non-empty to identify a scout.')
  requireNonEmpty(firstName, 'Scout first name must be non-empty to identify a scout.')
  // requireNonEmpty(lastName, 'Scout last name must be non-empty to identify a scout.')
  requireNonEmpty(email, 'Scout email must be non-empty to identify a scout.')
  requireNonEmpty(isGoogleSignup, 'Scout isGoogleSignup must be non-empty to identify a scout.')

  const scoutUniversalId = createScoutIdentifier(scoutId)
  var identificationProperties = {
    userType: UserType.SCOUT,
    firstName,
    lastName,
    email,
    isGoogleSignup,
  }

  if (identificationProperties.lastName.trim() === '') {
    delete identificationProperties.lastName
  }

  identify(scoutUniversalId, identificationProperties)
}

export function identifyInvestor(investorId, firstName, lastName, email, isGoogleSignup) {
  requireNonEmpty(investorId, 'Investor id must be non-empty to identify an investor.')
  requireNonEmpty(firstName, 'Investor first name must be non-empty to identify an investor.')
  // requireNonEmpty(lastName, 'Investor last name must be non-empty to identify an investor.')
  requireNonEmpty(email, 'Investor email must be non-empty to identify an investor.')
  requireNonEmpty(isGoogleSignup, 'Investor isGoogleSignup must be non-empty to identify an investor.')

  const investorUniversalId = createInvestorIdentifier(investorId)
  var identificationProperties = {
    userType: UserType.INVESTOR,
    firstName,
    lastName,
    email,
    isGoogleSignup,
  }

  if (identificationProperties.lastName.trim() === '') {
    delete identificationProperties.lastName
  }

  identify(investorUniversalId, identificationProperties)
}

export function resetUserIdentity() {
  resetIdentity()
}

export function emitStartupSignup() {
  emitEvent(USER_SIGNUP, {})
}

export function emitStartupLogin() {
  emitEvent(USER_LOGIN, {})
}

export function emitStartupOnboardingCompleted() {
  emitEvent(USER_ONBOARDING_COMPLETED, {})
}

export function emitScoutSignup() {
  emitEvent(USER_SIGNUP, {})
}

export function emitScoutLogin() {
  emitEvent(USER_LOGIN, {})
}

export function emitScoutOnboardingCompleted() {
  emitEvent(USER_ONBOARDING_COMPLETED, {})
}

export function emitInvestorSignup() {
  emitEvent(USER_SIGNUP, {})
}

export function emitInvestorLogin() {
  emitEvent(USER_LOGIN, {})
}

export function emitInvestorOnboardingCompleted() {
  emitEvent(USER_ONBOARDING_COMPLETED, {})
}

export function emitStartupSignupFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for startup signup failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for startup signup failure.')
  // errorDetails is optional

  emitEvent(USER_SIGNUP_FAILED, {
    userType: UserType.STARTUP,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}

export function emitStartupLoginFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for startup login failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for startup login failure.')
  // errorDetails is optional

  emitEvent(USER_LOGIN_FAILED, {
    userType: UserType.STARTUP,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}
export function emitScoutLoginFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for scout login failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for scout login failure.')
  // errorDetails is optional

  emitEvent(USER_LOGIN_FAILED, {
    userType: UserType.SCOUT,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}
export function emitScoutSignupFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for scout signup failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for scout signup failure.')
  // errorDetails is optional

  emitEvent(USER_SIGNUP_FAILED, {
    userType: UserType.SCOUT,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}
export function emitInvestorSignupFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for investor signup failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for investor signup failure.')
  // errorDetails is optional

  emitEvent(USER_SIGNUP_FAILED, {
    userType: UserType.INVESTOR,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}

export function emitInvestorLoginFailed(isGoogleUser, errorCode, errorDetails) {
  requireNonEmpty(isGoogleUser, 'isGoogleUser must be non-empty for investor login failure.')
  requireNonEmpty(errorCode, 'errorCode must be non-empty for investor login failure.')
  // errorDetails is optional

  emitEvent(USER_LOGIN_FAILED, {
    userType: UserType.INVESTOR,
    isGoogleUser,
    errorCode,
    errorDetails,
  })
}

export function emitStartupMatchDisplayed(investorId) {
  requireNonEmpty(investorId, 'investorId must be non-empty for startup match displayed.')

  emitEvent(STARTUP_MATCH_DISPLAYED, {
    investor: createInvestorIdentifier(investorId),
  })
}

export function emitScoutMatchDisplayed(scoutId) {
  requireNonEmpty(scoutId, 'scoutId must be non-empty for startup match displayed.')

  emitEvent(SCOUT_MATCH_DISPLAYED, {
    investor: createScoutIdentifier(scoutId),
  })
}

export function emitInvestorMatchDisplayed(startupId) {
  requireNonEmpty(startupId, 'startupId must be non-empty for investor match displayed.')

  emitEvent(INVESTOR_MATCH_DISPLAYED, {
    startup: createStartupIdentifier(startupId),
  })
}

export function emitPageEvent() {
  page()
}
