import React, { useState, useEffect } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Row, Col, Spin, Avatar, Popover } from 'antd'
import moment from 'moment'
import { toggleLogoToHome } from '../../../redux/investorFlowControl/investorFlowControlActions'
import './Header.scss'
import logo from '../../../assets/images/LogoExitFund.svg'
import NoNotification from '../../../assets/images/approvedStartup/emtyNotificationIcon.svg'
import { Container, Nav, Navbar } from 'react-bootstrap'
import Bell from '../../../assets/images/bell.svg'
import ProfileToggle from '../../../pages/TrackingPortal/TrackingProfile/ProfileToggle'
import * as StratupApprovedActions from '../../../redux/startupApprovedFlowControl/StratupApprovedFlowControlActions'
import * as TrackingActions from '../../../redux/trackingFlowControl/TrackingFlowControlActions'
const HeaderTracking = props => {
  const currentPath = props.location.pathname.split('/')[2]
  const { loading, unread_notification_count, unread_notifications } = useSelector(
    state => state.StratupApprovedFlowControlReducers,
  )
  const dispatch = useDispatch()
  const [unread, setUnread] = useState(0)

  // Fetches the profile data and the unread notification count on component mount.
  useEffect(() => {
    dispatch(TrackingActions.fetchProfileData())
    dispatch(StratupApprovedActions.fetchUnreadNotificationCount())
  }, [])

  // Updates the component state with the latest unread notification count.
  useEffect(() => {
    setUnread(unread_notification_count)
  }, [unread_notification_count])

  /**
   * Handles the visibility change of the notification dropdown.
   * Resets the unread count to 0 when the dropdown is not visible.
   * @param {boolean} visibility - The visibility state of the notification dropdown.
   */
  const VisibleChangeHandler = visiblity => {
    if (!visiblity) {
      setUnread(0)
    }
  }

  /**
   * Handles the click event on the notification icon.
   * Fetches the unread notifications on click.
   */
  const onNotificationClick = async () => {
    await dispatch(StratupApprovedActions.fetchUnreadNotifications())
  }

  /**
   * Handles the click event to clear all notifications.
   * Clears all notifications when clicked.
   */
  const onClearNotification = async () => {
    await dispatch(StratupApprovedActions.clearAllNotification())
  }

  /**
   * Checks if the current path corresponds to monthly updates.
   * @returns {boolean} - True if the current path is related to monthly updates, false otherwise.
   */
  const getIsMonthlyUpdateActive = () => {
    let isActive = false
    if (['submit-monthly-update', 'monthly-update', 'edit-monthly-update']?.includes(currentPath)) {
      isActive = true
    }
    return isActive
  }

  /**
   * Component that displays notifications.
   * @component
   * @returns {JSX.Element} Notifications component.
   */
  const notifications = (
    <div className="tracking-notifications-content-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className="pop-header">
            <h4>Notification</h4>
            <span style={{ cursor: 'pointer' }} className="clear-all" onClick={() => onClearNotification()}>
              Clear all
            </span>
          </div>
          <div className="pop-content">
            {unread_notifications?.data?.map(item => (
              <div className="line-hr">
                <Row gutter={12}>
                  <Col span={4}>
                    <div className="alignments">
                      <Avatar src={item?.profile_pic}>{item?.first_name?.[0]?.toUpperCase() || 'A'}</Avatar>
                    </div>
                  </Col>
                  <Col span={20}>
                    <div className="alignments">
                      <span className="notification-msg">{item?.activity}</span>
                      <span className="timing">{moment(item?.timestamp).utc(true).fromNow()}</span>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <div className="pop-footer">
            <span
              className="view-all"
              onClick={() => {
                setUnread(0)
                props.history.push('/tracking/notifications')
              }}
            >
              View All
            </span>
          </div>
        </>
      )}
    </div>
  )

  // Content when there are no notifications.
  const emptyNotificationContent = (
    <div className="empty-tracking-notification-content-wrap">
      <div className="pop-header">
        <h4>Notification</h4>
        <span className="clear-all" onClick={() => props.history.push('/tracking/notifications')}>
          View all
        </span>
      </div>
      <div className="pop-content">
        <img src={NoNotification} alt="" />
        <span className="no-notification-text">Notifications not found</span>
      </div>
    </div>
  )

  return (
    <div className="toolbar sticky-top w-100">
      <div className="header m-0">
        <Navbar expand="lg" className="w-100 ">
          <Container className="headerTop" fluid>
            <Navbar.Brand className="left">
              <NavLink
                onClick={toggleLogoToHome}
                style={{ textDecoration: 'none' }}
                to="/tracking/monthly-update"
              >
                <div className="logo" id="home-logo">
                  <img
                    src={logo}
                    className=""
                    alt="Logo - Exitfund"
                    style={{ height: 'auto', width: '171px' }}
                  />
                </div>
              </NavLink>
            </Navbar.Brand>
            <Navbar id="basic-navbar-nav" className="d-md-flex d-sm-block justify-content-center">
              <Nav className="d-flex justify-content-center">
                <Nav className={`text-dark px-2 ${getIsMonthlyUpdateActive() ? 'active' : ''}`}>
                  <Link to="/tracking/monthly-update">Monthly Updates</Link>
                </Nav>
                <Nav className={`text-dark px-2 ${currentPath === 'history' ? 'active' : ''}`}>
                  <Link to="/tracking/history">History</Link>
                </Nav>
              </Nav>
            </Navbar>
            <div className="d-flex align-items-center right">
              <Popover
                arrowContent={null}
                content={unread_notifications?.data?.length > 0 ? notifications : emptyNotificationContent}
                placement="bottomRight"
                trigger="click"
                onVisibleChange={visiblity => VisibleChangeHandler(visiblity)}
              >
                <Badge count={unread} onClick={() => onNotificationClick()} className="mr-3 icon-pointer">
                  <img src={Bell} alt="" />
                </Badge>
              </Popover>

              <ProfileToggle />
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
  )
}

export default withRouter(HeaderTracking)
