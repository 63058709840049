/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './StartupCard.scss'
import linkedinImage from '../../assets/images/Linkedin@2x.png'
import linkImage from '../../assets/images/icon1.png'
import seeMoreImage from '../../assets/images/icon2.png'
import { formatCurrency } from '../../utils/general'
import membericon from '../../assets/images/FounderPhoto.png'
import startUpDefaultIcon from '../../assets/images/FillerStartupIcon.png'
import { connect } from 'react-redux'
import { Row, Col, Progress } from 'reactstrap'
import Carousel from 'react-bootstrap/Carousel'
import prependHttp from 'prepend-http'

// StartupCard component for displaying information about a startup.
class StartupCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
    }
  }

  /**
   * Renders the cofounders carousel.
   *
   * @param {Array} cFou - Array of cofounders.
   * @returns {React.Component} - Cofounders carousel component.
   */
  renderCarousel = cFou => {
    const handleSelect = (selectedIndex, e) => {
      this.setState({ index: selectedIndex })
    }

    return (
      <div className="cofounders-carousel">
        <Carousel
          interval={null}
          activeIndex={this.state.index}
          onSelect={handleSelect}
          className={`mb-0 ${cFou && cFou.length < 4 ? 'str-arrows' : ''}`}
        >
          <Carousel.Item>
            <div className="d-flex flex-wrap pb-4">
              {cFou
                ? cFou.map((cofounder, idx) => (
                    <>
                      {idx > 2 ? null : (
                        <div className="py-2 px-3 mr-2 preview-team-card">
                          <img
                            src={cofounder.profile_image ? cofounder.profile_image : membericon}
                            alt=""
                            className="mt-2 preview-member-pic"
                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                          />
                          {cofounder.linkedin_link ? (
                            <a
                              href={prependHttp(cofounder.linkedin_link)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img className="preview-linkedin" src={linkedinImage} alt="" />
                            </a>
                          ) : null}
                          <h5
                            className="pt-3 purple-colored font-weight-600"
                            style={{ wordWrap: 'break-word' }}
                          >
                            {cofounder.name}
                          </h5>
                          <h6
                            className="preview-team-position"
                            style={{
                              textTransform:
                                cofounder.position[0] === 'CMO' ||
                                cofounder.position[0] === 'cmo' ||
                                cofounder.position[0] === 'COO' ||
                                cofounder.position[0] === 'coo' ||
                                cofounder.position[0] === 'CFO' ||
                                cofounder.position[0] === 'cfo' ||
                                cofounder.position[0] === 'CEO' ||
                                cofounder.position[0] === 'ceo' ||
                                cofounder.position[0] === 'CTO' ||
                                cofounder.position[0] === 'cto'
                                  ? 'uppercase'
                                  : 'capitalize',
                            }}
                          >
                            {cofounder.position[0]}
                          </h6>
                        </div>
                      )}
                    </>
                  ))
                : null}
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="d-flex flex-wrap pb-4">
              {cFou
                ? cFou.map((cofounder, idx) => (
                    <>
                      {idx < 3 ? null : (
                        <div className="py-2 px-3 mr-2 preview-team-card">
                          <img
                            src={cofounder.profile_image ? cofounder.profile_image : membericon}
                            alt=""
                            className="mt-2 preview-member-pic"
                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                          />
                          {cofounder.linkedin_link ? (
                            <a
                              href={prependHttp(cofounder.linkedin_link)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img className="preview-linkedin" src={linkedinImage} alt="" />
                            </a>
                          ) : null}
                          <h5
                            className="pt-3 purple-colored font-weight-600"
                            style={{ wordWrap: 'break-word' }}
                          >
                            {cofounder.name}
                          </h5>
                          <div
                            className="preview-team-position"
                            style={{
                              textTransform:
                                cofounder.position[0] === 'CMO' ||
                                cofounder.position[0] === 'cmo' ||
                                cofounder.position[0] === 'COO' ||
                                cofounder.position[0] === 'coo' ||
                                cofounder.position[0] === 'CFO' ||
                                cofounder.position[0] === 'cfo' ||
                                cofounder.position[0] === 'CEO' ||
                                cofounder.position[0] === 'ceo' ||
                                cofounder.position[0] === 'CTO' ||
                                cofounder.position[0] === 'cto'
                                  ? 'uppercase'
                                  : 'capitalize',
                            }}
                          >
                            {cofounder.position[0]}
                          </div>
                        </div>
                      )}
                    </>
                  ))
                : null}
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    )
  }

  render() {
    const { user, setCoFounderSeeMore } = this.props

    const sectors = {
      agtech: 'Agriculture / Agtech',
      ai: 'Artificial Intelligence',
      ar: 'Augmented Reality',
      biomed: 'Biomedical',
      biotech: 'Biotech',
      blockchain: 'Blockchain',
      community: 'Community',
      crowdfund: 'Crowdfunding',
      devtools: 'Developer Tools',
      diversity: 'Diversity',
      drones: 'Drones',
      education: 'Education',
      energy: 'Energy',
      enterprise: 'Enterprise',
      entertain: 'Entertainment',
      gaming: 'Esports / Online Gaming',
      banking: 'Financial / Banking',
      government: 'Government',
      hardware: 'Hardware',
      health: 'Healthcare',
      market: 'Marketplace',
      media: 'Media / Advertising',
      hardtech: 'Moonshots / Hard Tech',
      robotics: 'Robotics',
      security: 'Security',
      sport: 'Sport/Fitness',
      transport: 'Transportation',
      travel: 'Travel',
      vr: 'Virtual Reality',
      other: 'Other',
    }

    const progresses = {
      mockups: 'Mockups/Renderings',
      prototype: 'Prototype/Pre-Launch',
      beta: 'Beta Launched',
      users: 'Early Users Acquired',
      revenue: 'Early Revenue Generated',
      ideas: 'Idea/Sketches',
      preorders: 'Taking Preorders',
      product: 'Product Launched',
    }

    let sortedSectors = []

    if (user.sectors) {
      const mappedSectors = user.sectors.map(s => {
        return sectors[s] ? sectors[s] : s
      })

      sortedSectors = mappedSectors.sort((a, b) => {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })
    }

    const cofounders = user.co_founders ? user.co_founders : []

    let sortedCofounders = []
    if (cofounders.length) {
      const ceo = cofounders.filter(c => c.position[0] === 'CEO' || c.position[0] === 'ceo')
      const cto = cofounders.filter(c => c.position[0] === 'CTO' || c.position[0] === 'cto')
      const coo = cofounders.filter(c => c.position[0] === 'COO' || c.position[0] === 'coo')
      const cfo = cofounders.filter(c => c.position[0] === 'CFO' || c.position[0] === 'cfo')
      const cmo = cofounders.filter(c => c.position[0] === 'CMO' || c.position[0] === 'cmo')
      const rest = cofounders.filter(
        c =>
          c.position[0] !== 'CMO' &&
          c.position[0] !== 'cmo' &&
          c.position[0] !== 'CEO' &&
          c.position[0] !== 'ceo' &&
          c.position[0] !== 'CTO' &&
          c.position[0] !== 'cto' &&
          c.position[0] !== 'COO' &&
          c.position[0] !== 'coo' &&
          c.position[0] !== 'CFO' &&
          c.position[0] !== 'cfo',
      )
      if (ceo.length) {
        sortedCofounders.push(ceo[0])
      }
      if (cto.length) {
        sortedCofounders.push(cto[0])
      }
      if (coo.length) {
        sortedCofounders.push(coo[0])
      }
      if (cfo.length) {
        sortedCofounders.push(cfo[0])
      }
      if (cmo.length) {
        sortedCofounders.push(cmo[0])
      }
      if (rest.length) {
        rest.forEach(r => sortedCofounders.push(r))
      }
    }

    if (cofounders.length !== sortedCofounders.length) {
      const rest = cofounders.filter(c => c.position.length === 0)
      sortedCofounders = sortedCofounders.concat(rest)
    }

    return (
      // <div className="d-none d-md-block">
      //   <div
      //     className="dashboard-container-preview d-flex flex-column justify-content-between"
      //     style={blur ? { filter: "blur(5px)" } : { display: "block" }}
      //   >
      <>
        <Row>
          <Col xs={5} className="p-5 preview-left">
            <div className="inner-left">
              <div>
                {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? (
                  <img
                    className="investor-profile-pic"
                    style={{
                      marginTop: '0px',
                      objectFit: 'cover',
                    }}
                    src={user.profile_pic_link}
                    alt=""
                    id="avataImg"
                    // shape="circle"
                  />
                ) : (
                  <img
                    style={{
                      marginTop: '0px',
                    }}
                    src={startUpDefaultIcon}
                    alt=""
                    id="avataImg"
                    className="investor-profile-pic"
                  />
                )}
              </div>
              <h1 className="purple-colored font-weight-900 pt-4 mb-1" style={{ wordWrap: 'break-word' }}>
                {user.company_name}
              </h1>
              <h5>{user.location}</h5>
              <div className="pt-3 d-flex" style={{ flexWrap: 'wrap' }}>
                {sortedSectors
                  ? sortedSectors.map(sector => (
                      <span
                        style={{
                          border: '1px solid grey',
                          borderRadius: '50px',
                        }}
                        className="py-1 px-2 mr-1 mb-1 sector-list-size"
                      >
                        {sector}
                      </span>
                    ))
                  : null}
              </div>
              <h5 className="pt-4">{user.startup_pitch}</h5>
              <div className="pt-3 font-weight-600" style={{ textDecoration: 'underline' }}>
                <a href={user.company_link} target="_blank" rel="noopener noreferrer">
                  <span className="purple-colored font-weight-500">{user.company_link}</span>
                  <img src={linkImage} style={{ height: '15px' }} className="pl-2" alt="company-logo" />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={7} className="pt-5 preview-right">
            <h2 style={{ fontWeight: '700' }}>
              Seeking{' '}
              <span className="gold-colored">{user.round_size ? formatCurrency(user.round_size) : ''}</span>
            </h2>
            <div className="py-0 my-0">
              <Progress value={user.raised} max={user.round_size} color="warning" className="m-0">
                {' '}
                <div
                  style={{
                    color: 'black',
                    position: 'absolute',
                    zIndex: '0',
                    fontFamily: 'Roboto',
                    fontWeight: '600',
                    width: '90%',
                    fontSize: '15px',
                  }}
                  className="d-flex justify-content-between"
                >
                  <div className="ml-3">Raised {user.raised ? formatCurrency(user.raised) : '$0'}</div>
                  <div className="mr-3">{Math.round((user.raised * 100) / user.round_size)}%</div>
                </div>
              </Progress>
            </div>
            <div className="d-flex justify-content-between align-items-center pt-3">
              <h4 className="pt-3 font-weight-600">Founding Team</h4>
              <div
                className="purple-colored font-weight-600 d-flex seemore-button"
                onClick={() => {
                  if (
                    window.location.pathname === '/investor/startup/' ||
                    window.location.pathname === '/investor/dashboard'
                  ) {
                    setCoFounderSeeMore(user.co_founders)
                  }
                  this.props.seeMoreClick()
                }}
              >
                <span className="mobile-seemore">See More</span>
                <img src={seeMoreImage} style={{ height: '25px' }} className="pl-2" alt="seemore" />
              </div>
            </div>

            {sortedCofounders.length ? this.renderCarousel(sortedCofounders) : null}

            <div className="d-flex">
              <div className="flex-grow-1">
                <h4 className="font-weight-600">Traction</h4>
                <div>
                  {user.progress
                    ? user.progress.map(progress => (
                        <span
                          style={{
                            border: '1px solid grey',
                            borderRadius: '50px',
                          }}
                          className="py-2 px-3 mr-1 mb-1 d-inline-block progress-list-size"
                        >
                          {progresses[progress] ? progresses[progress] : progress}
                        </span>
                      ))
                    : null}
                </div>
              </div>
              <div className="slide-div" style={user.slide_deck ? { paddingLeft: '40px' } : null}>
                <h4 className="font-weight-600">Slide Deck</h4>
                {user.slide_deck ? (
                  <div
                    style={{
                      border: '2px solid #162642',
                      color: '#162642',
                      display: 'inline-block',
                      borderRadius: '10px',
                    }}
                    className="font-weight-900 text-nowrap slide-deck-size slide-deck-button"
                    onClick={() => this.props.openSlideDeck()}
                  >
                    View Slide Deck
                  </div>
                ) : (
                  <div className="missing" style={{ color: '#b5b5b5' }}>
                    No Uploaded Slide Deck
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ position: 'relative', margin: '20px' }} />
        {/* <div className="bottom mt-1">
            <button className="button-left">Pass</button>
            <button className="button-right">Invite</button>
          </div> */}
        {/* <Row className="pt-5">
          <Col className="p-0">
            <Button
              className="startup-card-pass-button py-4"
              onClick={() =>
                this.props.dispatch({
                  type: "SET_FEEDBACK_MODAL",
                  payload: true,
                })
              }
            >
              <span className="pass-button-text">Pass</span>
            </Button>
          </Col>
          <Col className="p-0">
            <Button className="startup-card-invite-button py-4">
              <span className="invite-button-text">Invite</span>
            </Button>
          </Col>
        </Row> */}
        {/* </div>
      </div> */}
      </>
    )
  }
}

const mapStateToProps = state => ({
  investorUser: state.auth.user,
  userInfos: state.investorflowControl.userInfos,
  index: state.investorflowControl.index,
})

const mapDispatchToProps = dispatch => ({
  setCoFounderSeeMore: cf => {
    return dispatch({ type: 'SET_COFOUNDER_SEEMORE', payload: cf })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupCard)
