import React from "react";
import "./StartupLogOutPage.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchStartupUserLogout } from "../../../redux/auth/authActions";

class StartupLogOutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
    };
  }

  componentDidMount() {
    this.setState({ firstName: this.props.user.first_name });
    this.props.fetchStartupUserLogout();
    setTimeout(() => {
      this.props.history.push("/startup/login");
    }, 2000);
  }
  render() {
    return (
      <div className="investor-logout-page" style={{ paddingTop: "100px" }}>
        <div className="logout-title">
          You have been successfully logged out!
        </div>
        <div className="logout-mid">
          Have a great day, <span>{this.state.firstName}</span>!
        </div>

        <div className="redirect">Redirecting to the homepage ...</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStartupUserLogout: () => {
    return dispatch(fetchStartupUserLogout());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StartupLogOutPage)
);
