import * as React from 'react'

function AddIcon(props) {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13 0.5C13 21.3 13 26.5 13 26.5" stroke="#162642" stroke-width="3" />
      <path d="M0 14C20.8 14 26 14 26 14" stroke="#162642" stroke-width="3" />
    </svg>
  )
}

export default AddIcon
