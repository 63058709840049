import React from "react";
import "./InvestorWelcomeBackPage.scss";
import {Link} from "react-router-dom"
class AfterInvite extends React.Component {

  render() {


    return(<div>

            <div className="after-invite-container">
              <div className="after-invite-content">
                <div className="thats-it font-lato">That's it for now!</div>
                <div className="check-back font-lato">Check back soon to discover new deals.</div>
                <div className="allow-us">Completing your profile allows us to</div>
                <div className="show-startups">show you the most relevant startups</div>
                <div className="after-invite-button">
                  <Link className="complete-profile"to ="/investor/dashboard">Complete Profile</Link>
                </div>
              </div>
            </div>

        </div>)
  }
}



export default AfterInvite;
