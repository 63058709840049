import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { Link, withRouter } from 'react-router-dom'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Button as AntButton, Input } from 'antd'
import { connect } from 'react-redux'
import { gapi } from 'gapi-script'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import './LoginForm.scss'
import HeaderOnboarding from '../Header/HeaderOnboarding/HeaderOnboarding'
import {
  fetchInvestorSigninByEmail,
  UPDATE_LOCAL_INVESTOR_USER,
  logoutLocalInvestorUser,
  fetchStartupSigninByEmail,
  updateLocalStartupUser,
  fetchInvestorSigninByGoogle,
  fetchStartupSigninByGoogle,
  fetchScoutSigninByGoogle,
  logoutLocalStartupUser,
  selectedAsInvestor,
  selectedAsStartup,
  selectedAsScout,
  toggleStartup,
  toggleInvestor,
  toggleScout,
  updateLocalScoutUser,
  fetchScoutSigninByEmail,
} from '../../redux/auth/authActions'

import {
  nextStepInvestor,
  prevStepInvestor,
  updateStep,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import {
  nextStepStartup,
  updateStartupStep,
  prevStepStartup,
} from '../../redux/startupFlowControl/startupFlowControlActions'

import {
  emitInvestorLoginFailed,
  emitStartupLoginFailed,
  emitScoutLoginFailed,
} from '../../metrics/metricsEmitter'
import {
  nextStepScout,
  prevStepScout,
  updateScoutStep,
} from '../../redux/scoutFlowControl/scoutFlowControlActions.js'
import { calcCurrentStartupStep } from '../../utils/startupStep'
import { calcCurrentInvestorStep } from '../../utils/investorStep'
import { calcCurrentScoutStep } from '../../utils/scoutStep'
import { Checkbox, Divider } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
class LoginForm1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      hidden: true,
      error: '',
      email: '',
      password: '',
    }
  }

  /**
   * Handles changes in the form fields.
   * Updates the corresponding state and, if applicable, the local user data.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  handleFormFieldChange = event => {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      [name]: value?.trim(),
    })
    if (value?.trim()) {
      if (this.props.startup) {
        this.props.updateLocalStartupUser({ [name]: value?.trim() })
      }
      if (this.props.investor) {
        this.props.updateLocalInvestorUser({ [name]: value?.trim() })
      }
      if (this.props.scout) {
        this.props.updateLocalScoutUser({ [name]: value?.trim() })
      }
    }
  }

  // Initializes the Google API client on component mount.
  componentDidMount() {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_API_KEY,
        scope: 'email',
      })
    }

    gapi.load('client:auth2', start)
    document.querySelector('body').scrollTo(0, 0)
  }

  // Toggles the visibility of the password input.
  toggleShow = () => {
    this.setState({
      hidden: !this.state.hidden,
    })
  }

  /**
   * Checks if the submit button should be enabled based on email and password validity.
   *
   * @returns {boolean} - True if the submit button should be enabled, otherwise false.
   */
  isSubmitEnabled = () => {
    const validEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/

    const { email, password } = this.props?.startup
      ? this.props?.startupUser
      : this.props?.investor
      ? this.props?.user
      : this.props?.scoutUser
    if (validEmail.test(email) && password?.length >= 8) {
      return true
    }
    return false
  }

  /**
   * Handles the login process for investors, startups, and scouts.
   * Redirects to the appropriate page based on the user's status.
   */
  investorOnClick = () => {
    const email = this.props.user.email
    const password = this.props.user.password
    const nextStepInvestor = this.props.nextStepInvestor
    const fetchInvestorSigninByEmail = this.props.fetchInvestorSigninByEmail

    fetchInvestorSigninByEmail({
      email,
      password,
    })
      .then(data => {
        this.setState({
          ...this.state,
          status: data.status.status,
        })
        localStorage.setItem('startup_account', data.startup_account)
        if (data?.thankyou_screen_viewed) {
          this.props.history.replace('/investor/deals')
        } else {
          nextStepInvestor()
        }
      })
      .catch(err => {
        let parsedStatus
        if (err === 'wrong credentials') {
          parsedStatus = 'Incorrect password.'
        } else if (err === 'user does not exist') {
          parsedStatus = 'That email is not associated with an account.'
        } else if (err === 'please confirm your email address') {
          parsedStatus = 'Check your inbox for a confirmation email.'
        } else if (err === 'registered with google account') {
          parsedStatus = 'You are registered with Google. Please login above.'
        } else if (err === 'account deleted') {
          parsedStatus = "Your account's been deleted; please contact us."
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  // Handles the login process for startups.
  startupOnClick = () => {
    const email = this.props.startupUser.email
    const password = this.props.startupUser.password
    const nextStepStartup = this.props.nextStepStartup
    const fetchStartupSigninByEmail = this.props.fetchStartupSigninByEmail

    fetchStartupSigninByEmail({
      email,
      password,
    })
      .then(data => {
        let status = data.status.status
        localStorage.setItem('investor_account', data.investor_account)
        nextStepStartup()
      })
      .catch(err => {
        let parsedStatus
        if (err === 'wrong credentials') {
          parsedStatus = 'Incorrect password.'
        } else if (err === 'user does not exist') {
          parsedStatus = 'That email is not associated with an account.'
        } else if (err === 'please confirm your email address') {
          parsedStatus = 'Check your inbox for a confirmation email.'
        } else if (err === 'registered with google account') {
          parsedStatus = 'You are registered with Google. Please login above.'
        } else if (err === 'account deleted') {
          parsedStatus = "Your account's been deleted; please contact us."
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  // Handles the login process for scouts.
  scoutOnClick = () => {
    const email = this.props.scoutUser.email
    const password = this.props.scoutUser.password
    const nextStepScout = this.props.nextStepScout
    const fetchScoutSigninByEmail = this.props.fetchScoutSigninByEmail

    fetchScoutSigninByEmail({
      email,
      password,
    })
      .then(data => {
        if (data?.onboarding_step < 5) {
          nextStepScout()
        } else {
          this.props.history.push('/scout/invite')
        }
      })
      .catch(err => {
        let parsedStatus
        if (err === 'wrong credentials') {
          parsedStatus = 'Incorrect password.'
        } else if (err === 'user does not exist') {
          parsedStatus = 'That email is not associated with an account.'
        } else if (err === 'please confirm your email address') {
          parsedStatus = 'Check your inbox for a confirmation email.'
        } else if (err === 'registered with google account') {
          parsedStatus = 'You are registered with Google. Please login above.'
        } else if (err === 'account deleted') {
          parsedStatus = "Your account's been deleted; please contact us."
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handles the Google login process for investors.
   *
   * @param {Object} googleUser - Google user object.
   */
  investorGoogleOnClick = googleUser => {
    const fetchInvestorSigninByGoogle = this.props.fetchInvestorSigninByGoogle
    const updateStep = this.props.updateStep

    fetchInvestorSigninByGoogle(googleUser)
      .then(() => {
        selectedAsInvestor()
        const step = calcCurrentInvestorStep(this.props.user, true)
        updateStep(step)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'user does not exist') {
          parsedStatus = 'User does not exist, Please signup first.'
        } else if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handles the Google login process for scouts.
   *
   * @param {Object} googleUser - Google user object.
   */
  scoutGoogleOnClick = googleUser => {
    const fetchScoutSigninByGoogle = this.props.fetchScoutSigninByGoogle
    const updateScoutStep = this.props.updateScoutStep

    fetchScoutSigninByGoogle(googleUser)
      .then(() => {
        selectedAsScout()
        const step = calcCurrentScoutStep(this.props.scoutUser, true)
        updateScoutStep(step)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'user does not exist') {
          parsedStatus = 'User does not exist, Please signup first.'
        } else if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handles the Google login process for startups.
   *
   * @param {Object} googleUser - Google user object.
   */
  startupGoogleOnClick = googleUser => {
    const fetchStartupSigninByGoogle = this.props.fetchStartupSigninByGoogle
    const updateStartupStep = this.props.updateStartupStep

    fetchStartupSigninByGoogle(googleUser)
      .then(() => {
        selectedAsStartup()
        const step = calcCurrentStartupStep(this.props.startupUser, true)
        updateStartupStep(step)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'user does not exist') {
          parsedStatus = 'User does not exist, Please signup first.'
        } else if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else if (err == 'You are being rate limited try again in some time') {
          parsedStatus = 'You are being rate limited try again in some time'
        } else if (err === 'user is paused') {
          this.props.history.push('/suspend')
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handles the Google login failure for investors.
   *
   * @param {string} errorCode - Google login error code.
   * @param {string} errorDetails - Details about the Google login failure.
   */
  investorGoogleLoginFailure = (errorCode, errorDetails) => {
    emitInvestorLoginFailed(true, errorCode, errorDetails)
  }

  /**
   * Handles the Google login failure for startups.
   *
   * @param {string} errorCode - Google login error code.
   * @param {string} errorDetails - Details about the Google login failure.
   */
  startupGoogleLoginFailure = (errorCode, errorDetails) => {
    emitStartupLoginFailed(true, errorCode, errorDetails)
  }

  /**
   * Handles the Google login failure for scouts.
   *
   * @param {string} errorCode - Google login error code.
   * @param {string} errorDetails - Details about the Google login failure.
   */

  scoutGoogleLoginFailure = (errorCode, errorDetails) => {
    emitScoutLoginFailed(true, errorCode, errorDetails)
  }

  // Toggles the login error alert.
  toggleAlert = () => {
    this.setState({
      error: '',
    })
  }

  /**
   * Returns the forgot password URL based on the user type (startup, investor, scout).
   *
   * @returns {string} - Forgot password URL.
   */
  forgotUrl = () => {
    const { startup, investor, scout } = this.props
    if (startup) {
      return '/startup/forgot-password'
    } else if (investor) {
      return '/investor/forgot-password'
    } else if (scout) {
      return '/scout/forgot-password'
    }
  }

  render() {
    if (this.props.token !== null) {
      return <Redirect to="/" />
    }
    const { history, authLoading } = this.props
    const { startup, investor, scout } = this.props
    const isEnabled = this.isSubmitEnabled()
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          {startup ? (
            <link rel="canonical" href="https://exitfund.com/startup/login" />
          ) : investor ? (
            <link rel="canonical" href="https://exitfund.com/investor/login" />
          ) : (
            <link rel="canonical" href="https://exitfund.com/scout/login" />
          )}
        </Helmet>
        <HeaderOnboarding />

        <div className="register mb-5">
          <hr className="d-block d-sm-none mobile-line"></hr>
          <div className="sign-in-with-google">
            <div className="form-input">
              <ButtonGroup aria-label="">
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'startup' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    !(this.props.pageType === 'startup') && history.push('/startup/login')
                  }}
                  size="lg"
                >
                  Startup
                </Button>
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'investor' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    !(this.props.pageType === 'investor') && history.push('/investor/login')
                  }}
                  size="lg"
                >
                  Investor
                </Button>
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'scout' ? 'active-btn' : 'btn'}
                  onClick={() => {
                    !(this.props.pageType === 'scout') && history.push('/scout/login')
                  }}
                  size="lg"
                >
                  Scout
                </Button>
              </ButtonGroup>
              <div className="custom-google-login">
                <GoogleLogin
                  cookiePolicy={'single_host_origin'}
                  onSuccess={googleUser => {
                    startup
                      ? this.startupGoogleOnClick(googleUser)
                      : investor
                      ? this.investorGoogleOnClick(googleUser)
                      : this.scoutGoogleOnClick(googleUser)
                  }}
                  onError={({ error, details }) => {
                    startup
                      ? this.startupGoogleLoginFailure(error, details)
                      : investor
                      ? this.investorGoogleLoginFailure(error, details)
                      : this.scoutGoogleLoginFailure(error, details)
                  }}
                  width="250px"
                />
              </div>
            </div>
          </div>

          <Divider>Or</Divider>
          <form className="form-input" autoComplete="new-password">
            {this.state.error && (
              <p className={this.state.error ? 'sign-up-error' : 'close'}>
                {this.state.error}
                <CloseCircleOutlined onClick={this.toggleAlert} className="mt-1 float-right" />
              </p>
            )}

            <p className="form-field">Email Address </p>
            <Input
              onChange={this.handleFormFieldChange}
              className="text-field-input"
              placeholder="Enter email address"
              name="email"
              type="text"
              autoComplete="off"
              value={this.state.email}
            />
            <div className="group">
              <p className="form-field">Password</p>
              <Input
                onChange={this.handleFormFieldChange}
                className="text-field-input"
                placeholder="Enter password"
                type={this.state.hidden ? 'password' : 'text'}
                autoComplete="off"
                name="password"
                value={this.state.password}
                onKeyPress={event => {
                  if (event.key === 'Enter' && isEnabled) {
                    if (startup) {
                      this.startupOnClick()
                    } else if (investor) {
                      this.investorOnClick()
                    } else if (scout) {
                      this.scoutOnClick()
                    }
                  }
                }}
              />
              <i
                onClick={this.toggleShow}
                className={
                  !this.state.hidden
                    ? 'fa fa-eye 3x passing-line-through register-password-icon'
                    : 'fa fa-eye-slash 3x passing-line-through register-password-icon'
                }
                style={{
                  bottom: '23%',
                  position: 'absolute',
                  textDecoration: this.state.hidden ? '' : '',
                }}
              />
            </div>
            <div className="remember-section d-flex justify-between align-center mt-3">
              <Checkbox>Remember me</Checkbox>
              <Link
                to={this.forgotUrl}
                style={{
                  color: '#1D6AC5',
                  fontSize: '15px',
                }}
                className="forgot-password"
              >
                Forgot Password?
              </Link>
            </div>
            <AntButton
              loading={authLoading}
              disabled={!isEnabled}
              onClick={investor ? this.investorOnClick : startup ? this.startupOnClick : this.scoutOnClick}
              style={{
                color: '#ffffff',
                fontWeight: '400',
                fontSize: '18px',
              }}
              className="sign-up"
            >
              Login
            </AntButton>

            <p className="text-center agreement-policy mb-3 mt-3">
              {startup ? (
                <Link to="/startup/signup">Don't have an account? Sign up</Link>
              ) : investor ? (
                <Link to="/investor/signup">Don't have an account? Sign up</Link>
              ) : (
                <Link to="/scout/signup">Don't have an account? Sign up</Link>
              )}
            </p>
          </form>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  loginFailed: state.investorflowControl.loginFailed,
  startupLoginFailed: state.startupflowControl.loginFailed,
  scoutLoginFailed: state.scoutflowControl.loginFailed,
  startup: state.auth.startup,
  investor: state.auth.investor,
  scout: state.auth.scout,
  token: state.auth.token,
  startupUser: state.auth.startupUser,
  scoutUser: state.auth.scoutUser,
  authLoading: state?.auth?.loading,
})

const mapDispatchToProps = dispatch => ({
  toggleStartupPage: () => dispatch(toggleStartup()),
  toggleInvestorPage: () => dispatch(toggleInvestor()),
  toggleScoutPage: () => dispatch(toggleScout()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  prevStepStartup: () => dispatch(prevStepStartup()),
  prevStepScout: () => dispatch(prevStepScout()),
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  nextStepScout: () => dispatch(nextStepScout()),
  logoutLocalInvestorUser: () => dispatch(logoutLocalInvestorUser()),
  logoutLocalStartupUser: () => dispatch(logoutLocalStartupUser()),
  selectedAsInvestor: () => dispatch(selectedAsInvestor()),
  selectedAsStartup: () => dispatch(selectedAsStartup()),

  updateStep: step => {
    dispatch(updateStep(step))
  },

  updateStartupStep: step => {
    dispatch(updateStartupStep(step))
  },
  updateScoutStep: step => {
    dispatch(updateScoutStep(step))
  },
  updateLocalInvestorUser: changedProperties => {
    const action = { type: UPDATE_LOCAL_INVESTOR_USER, payload: changedProperties }
    dispatch(action)
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  updateLocalScoutUser: changedProperties => {
    dispatch(updateLocalScoutUser(changedProperties))
  },

  responseGoogle: response => {
    const action = { type: 'GOOGLE_LOGIN_FIELD', text: response.profileObj }
    dispatch(action)
  },
  isGoogleLogged: () => {
    const action = { type: 'SUCCESSFUL_GOOGLE_LOGIN', text: true }
    dispatch(action)
  },
  fetchInvestorSigninByEmail: user => {
    return dispatch(fetchInvestorSigninByEmail(user))
  },

  fetchStartupSigninByEmail: startupUser => {
    return dispatch(fetchStartupSigninByEmail(startupUser))
  },

  fetchScoutSigninByEmail: scoutUser => {
    return dispatch(fetchScoutSigninByEmail(scoutUser))
  },

  fetchInvestorSigninByGoogle: googleUser => {
    return dispatch(fetchInvestorSigninByGoogle(googleUser))
  },

  fetchStartupSigninByGoogle: googleUser => {
    return dispatch(fetchStartupSigninByGoogle(googleUser))
  },
  fetchScoutSigninByGoogle: googleUser => {
    return dispatch(fetchScoutSigninByGoogle(googleUser))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm1))
