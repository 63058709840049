import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'

import MobileTabs from '../../../components/MobileTabs/MobileTabs'

import { Container, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap'
import './StartupProfilePage.scss'
import membericon from '../../../assets/images/FounderPhoto.png'
import startUpDefaultIcon from '../../../assets/images/FillerStartupIcon.png'
import pdfIcon from '../../../assets/images/pdf.png'
import linkedinImage from '../../../assets/images/Linkedin@2x.png'

import { connect } from 'react-redux'

import { formatCurrency, formatFileToName } from '../../../utils/general'

const StartupProfilePage = ({ user, history }) => {
  const cofounders = user.co_founders ? user.co_founders : []

  let sortedCofounders = []
  if (cofounders.length) {
    const ceo = cofounders.filter(c => c.position[0] === 'CEO' || c.position[0] === 'ceo')
    const cto = cofounders.filter(c => c.position[0] === 'CTO' || c.position[0] === 'cto')
    const coo = cofounders.filter(c => c.position[0] === 'COO' || c.position[0] === 'coo')
    const cfo = cofounders.filter(c => c.position[0] === 'CFO' || c.position[0] === 'cfo')
    const cmo = cofounders.filter(c => c.position[0] === 'CMO' || c.position[0] === 'cmo')
    const rest = cofounders.filter(
      c =>
        c.position[0] !== 'CMO' &&
        c.position[0] !== 'cmo' &&
        c.position[0] !== 'CEO' &&
        c.position[0] !== 'ceo' &&
        c.position[0] !== 'CTO' &&
        c.position[0] !== 'cto' &&
        c.position[0] !== 'COO' &&
        c.position[0] !== 'coo' &&
        c.position[0] !== 'CFO' &&
        c.position[0] !== 'cfo',
    )
    if (ceo.length) {
      sortedCofounders.push(ceo[0])
    }
    if (cto.length) {
      sortedCofounders.push(cto[0])
    }
    if (coo.length) {
      sortedCofounders.push(coo[0])
    }
    if (cfo.length) {
      sortedCofounders.push(cfo[0])
    }
    if (cmo.length) {
      sortedCofounders.push(cmo[0])
    }
    if (rest.length) {
      rest.forEach(r => sortedCofounders.push(r))
    }
  }

  if (cofounders.length !== sortedCofounders.length) {
    const rest = cofounders.filter(c => c.position.length === 0)
    sortedCofounders = sortedCofounders.concat(rest)
  }

  let completedCofounders = 0
  cofounders.forEach(c => {
    if (c.primary) {
      if (
        c.name !== '' &&
        c.position.length &&
        c.bio !== '' &&
        c.linkedin_link !== '' &&
        c.linkedin_link !== null
      ) {
        completedCofounders += 1
      }
    } else {
      if (c.name !== '' && c.position.length && c.bio !== '') {
        completedCofounders += 1
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="d-none d-md-block" style={{ paddingTop: '120px', animation: `fadeIn 1s` }}>
        <Container className="text-justify">
          <Row className="pb-4 justify-content-between mx-auto profile-header">
            <Col className="investor-page-title">Profile</Col>
            <Col style={{ textAlign: 'end' }}>
              <Button className="investor-preview-button" onClick={() => history.push('/startup/preview')}>
                Profile Preview
              </Button>
              <Button
                className="investor-edit-button"
                onClick={() => {
                  history.push('/startup/profile/edit')
                }}
              >
                Edit
              </Button>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col sm={12} className="mx-auto">
              <Col
                sm={10}
                className={
                  user.co_founders_check && user.company_logo_check
                    ? 'mx-auto p-3 profile-success'
                    : 'mx-auto p-3 profile-fail'
                }
              >
                {user.co_founders_check && user.company_logo_check ? (
                  <>
                    <span className="font-weight-700">Profile complete!</span>
                    &nbsp; You can now access relevant investors in your Discover portal.
                  </>
                ) : (
                  <>
                    <span className="font-weight-700">
                      Complete your profile to see investors in your Discover portal.
                    </span>{' '}
                    &nbsp; Required remaining: {user.profile_pic_link ? null : ' Logo'}
                    {!user.profile_pic_link && !user.co_founders_check ? ',' : null}
                    {user.co_founders_check ? null : ' Founding Team Info'}
                  </>
                )}
              </Col>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <p>
                {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? null : (
                  <span className="unfilled-msg">Add your company's logo.</span>
                )}
              </p>
              <Card
                body
                className={
                  !user.profile_pic_link && user.profile_pic_link !== 'None' ? 'px-5 unfilled' : 'px-5'
                }
              >
                <Row className="align-items-center">
                  <Col style={{ maxWidth: 'fit-content' }}>
                    {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? (
                      <img
                        src={user.profile_pic_link}
                        alt=""
                        shape="circle"
                        className="investor-profile-pic"
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <img src={startUpDefaultIcon} alt="" shape="circle" className="investor-profile-pic" />
                    )}
                  </Col>
                  <Col className="pl-5 ipad-toggle">
                    <div className="investor-profile-edit-title pb-2">Company Name</div>
                    <div className="investor-profile-edit-text">{user.company_name}</div>
                  </Col>
                  <Col className="ipad-toggle">
                    <div className="investor-profile-edit-title pb-2">Location</div>
                    <div className="investor-profile-edit-text">{user.location}</div>
                  </Col>
                  <div className="d-block d-md-none pl-5">
                    <Row>
                      <div className="investor-profile-edit-title pb-2">Company Name</div>
                      <div className="investor-profile-edit-text ipad-profile-text pl-2 ml-1">
                        {user.company_name}
                      </div>
                    </Row>
                    <Row>
                      <div className="investor-profile-edit-title pb-2">Location</div>
                      <div className="investor-profile-edit-text pl-5 ml-4 ipad-profile-text">
                        {user.location}
                      </div>
                    </Row>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <p>
                {(cofounders[0] &&
                  (!cofounders[0].linkedin_link ||
                    !cofounders[0].name ||
                    !cofounders[0].position ||
                    !cofounders[0].bio)) ||
                (cofounders[1] && (!cofounders[1].name || !cofounders[1].position || !cofounders[1].bio)) ||
                (cofounders[2] && (!cofounders[2].name || !cofounders[2].position || !cofounders[2].bio)) ||
                (cofounders[3] && (!cofounders[3].name || !cofounders[3].position || !cofounders[3].bio)) ||
                (cofounders[4] && (!cofounders[4].name || !cofounders[4].position || !cofounders[4].bio)) ? (
                  <span className="unfilled-msg">Complete your founding team's information.</span>
                ) : null}
              </p>
              <Card
                body
                className={
                  !cofounders[0].linkedin_link ||
                  !cofounders[0].name ||
                  !cofounders[0].position ||
                  !cofounders[0].bio ||
                  (cofounders[1] && (!cofounders[1].name || !cofounders[1].position || !cofounders[1].bio)) ||
                  (cofounders[2] && (!cofounders[2].name || !cofounders[2].position || !cofounders[2].bio)) ||
                  (cofounders[3] && (!cofounders[3].name || !cofounders[3].position || !cofounders[3].bio)) ||
                  (cofounders[4] && (!cofounders[4].name || !cofounders[4].position || !cofounders[4].bio))
                    ? 'px-5 unfilled'
                    : 'px-5'
                }
              >
                <CardTitle className="investor-profile-edit-title">
                  <Row>
                    <Col>Founding Team</Col>
                    <Col
                      className={`text-right investor-profile-edit-text ${
                        completedCofounders === cofounders.length ? 'text-muted' : 'font-color-prime'
                      }`}
                    >
                      {completedCofounders}/{cofounders.length} Profiles Completed!
                    </Col>
                  </Row>
                </CardTitle>
                <CardText>
                  <Row className="justify-content-left investor-web-team">
                    {cofounders.length > 0 ? (
                      sortedCofounders.map(founder => (
                        <Col xs={6} className="pb-3">
                          <Card
                            className={
                              (cofounders[0].name === founder.name && !founder.linkedin_link) ||
                              !founder.name ||
                              !founder.position ||
                              !founder.bio
                                ? 'px-3 unfilled-cofounders'
                                : 'px-3'
                            }
                            style={{ minHeight: '100%' }}
                          >
                            <div className="text-center mt-4 mb-3">
                              <img
                                src={founder.profile_image ? founder.profile_image : membericon}
                                alt=""
                                id="avataImg"
                                className="mb-2 investor-profile-pic"
                                style={{ objectFit: 'cover' }}
                              />
                              {founder.linkedin_link ? (
                                <img
                                  src={linkedinImage}
                                  alt=""
                                  id="avataImg"
                                  className="p-3 investor-linkedin-icon"
                                />
                              ) : null}
                              <h3 className="purple-colored font-weight-bold mb-0">{founder.name}</h3>
                              <h5
                                style={{
                                  textTransform:
                                    founder.position[0] === 'CMO' ||
                                    founder.position[0] === 'cmo' ||
                                    founder.position[0] === 'COO' ||
                                    founder.position[0] === 'coo' ||
                                    founder.position[0] === 'CFO' ||
                                    founder.position[0] === 'cfo' ||
                                    founder.position[0] === 'CEO' ||
                                    founder.position[0] === 'ceo' ||
                                    founder.position[0] === 'CTO' ||
                                    founder.position[0] === 'cto'
                                      ? 'uppercase'
                                      : 'capitalize',
                                }}
                              >
                                {founder.position[0]}
                              </h5>
                              <h6>{founder.bio}</h6>
                            </div>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col xs={6}>
                        <Card className="px-3" style={{ minHeight: '100%' }}>
                          <div className="text-center">
                            <img src={membericon} alt="" id="avataImg" className="mb-2" />
                            <h3 className="purple-colored">{`${user.first_name} ${user.last_name}`}</h3>
                            <h5>{user.position[0]}</h5>
                            <p>{user.bio}</p>
                          </div>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <Card body className="px-5">
                <CardTitle className="investor-profile-edit-title">Short Pitch</CardTitle>
                <CardText className="investor-profile-edit-text ml-25">{user.startup_pitch}</CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col sm="10" className="mx-auto">
              <Card body className="px-5">
                <CardTitle>
                  <Row>
                    <Col>
                      <div className="investor-profile-edit-title">Sector</div>
                    </Col>
                  </Row>
                </CardTitle>
                <CardText
                  className="py-2 investor-profile-edit-sectors d-flex ml-3"
                  style={{ flexWrap: 'wrap' }}
                >
                  {user.sectors.map(sector => (
                    <span className="investor-web-list">{sector}</span>
                  ))}
                </CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <Card body className="px-5">
                <CardTitle className="investor-profile-edit-title">Fundraising</CardTitle>
                <CardText className="ml-25">
                  <Row>
                    <Col>
                      <h5 className="d-inline font-weight-600">Round Size</h5>
                      <span className="ml-3 investor-profile-edit-text">
                        {user.round_size ? formatCurrency(user.round_size) : ''}
                      </span>
                    </Col>
                    <Col>
                      <h5 className="d-inline">
                        <span className="font-weight-600">Raised</span>
                        <span className="font-weight-400">&nbsp;(current round)</span>
                      </h5>
                      <span className="ml-3 investor-profile-edit-text">
                        {user.raised ? formatCurrency(user.raised) : '$0'}
                      </span>
                    </Col>
                  </Row>
                </CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col sm="10" className="mx-auto">
              <Card body className="px-5">
                <CardTitle>
                  <Row>
                    <Col>
                      <div className="investor-profile-edit-title">Traction</div>
                    </Col>
                  </Row>
                </CardTitle>
                <CardText
                  className="py-2 d-flex investor-profile-edit-sectors ml-25"
                  style={{ flexWrap: 'wrap' }}
                >
                  {user.progress.map(p => (
                    <span className="investor-web-list">{p}</span>
                  ))}
                </CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <Card body className="px-5">
                <CardTitle className="investor-profile-edit-title">Website</CardTitle>
                <CardText className="investor-profile-edit-text ml-25">{user.company_link}</CardText>
              </Card>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm="10" className="mx-auto">
              <p>
                {user.slide_deck ? null : (
                  <span className="unfilled-msg">Complete your slide deck upload.</span>
                )}
              </p>
              <Card body className={user.slide_deck ? 'px-5' : 'px-5 unfilled'}>
                <CardTitle className="investor-profile-edit-title">Attachments</CardTitle>
                <CardText>
                  {user.slide_deck ? (
                    <Row className="align-items-center ml-25">
                      <img src={pdfIcon} alt="default" style={{ width: '100px' }} />
                      <span className="pl-3 investor-profile-edit-text">
                        {formatFileToName(user.slide_deck)}
                      </span>
                    </Row>
                  ) : (
                    <div className="text-muted investor-profile-edit-text">No Uploaded Slide Deck</div>
                  )}
                </CardText>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <MobileTabs
        tabOne="Basic Info"
        tabTwo="Team"
        tabThree="Progress"
        pageOne={
          <div>
            {' '}
            {/* <Button
              className="mobile-toggle-button mobile-edit"
              onClick={() => {
                history.push("/startup/profile/edit");
              }}
            >
              Edit
            </Button> */}
            <Card
              body
              style={{
                border: 'none',
                padding: '0px',
                zIndex: '1',
                width: '100%',
                paddingTop: '60px',
                paddingBottom: '60px',
                maxWidth: '660px',
                margin: 'auto',
                height: '100%',
                maxHieght: '575px',
                animation: `fadeIn 1.6s`,
              }}
            >
              <Col>
                <Row style={{ display: 'block', margin: '0px auto 25px' }}>
                  {/* <div style={{width: "140px", height: "140px"}}> */}
                  {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? (
                    <img
                      src={user.profile_pic_link}
                      alt=""
                      shape="circle"
                      style={{
                        borderRadius: '50%',
                        height: '140px',
                        width: '140px',
                        display: 'block',
                        margin: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <img
                      src={startUpDefaultIcon}
                      style={{
                        borderRadius: '50%',
                        height: '140px',
                        width: '140px',
                        display: 'block',
                        margin: 'auto',
                      }}
                      alt=""
                    />
                  )}
                  {/* </div> */}
                </Row>
                <Row className="pt-2 pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Company Name</div>
                  <div className="mobile-info">{user.company_name}</div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Location</div>
                  <div className="mobile-info">{user.location}</div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Sector</div>
                  <div className="py-2 mobile-info-no-margin">
                    <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                      {user.sectors.map(sector => (
                        <span className="mobile-list">{sector}</span>
                      ))}
                    </CardText>
                  </div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Short Pitch</div>
                  <div className="mobile-info">{user.startup_pitch}</div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Website</div>
                  <div className="mobile-info">{user.company_link}</div>
                </Row>
              </Col>
            </Card>
          </div>
        }
        pageTwo={
          <div style={{ animation: `fadeIn 1.6s` }}>
            <div className="d-flex pb-2 mb-3 justify-content-between align-items-baseline">
              <div
                className={`mobile-header font-weight-700 pb-4 ${
                  completedCofounders === user.num_team_members ? 'text-muted' : 'font-color-prime'
                }`}
                style={{ color: 'grey', paddingTop: '60px' }}
              >
                {completedCofounders}/{user.num_team_members} Profiles Completed
                {completedCofounders === user.num_team_members ? '!' : ''}
              </div>
              {/* <Button
                className="mobile-toggle-button mobile-edit"
                onClick={() => {
                  history.push("/startup/profile/edit");
                }}
              >
                Edit
              </Button> */}
            </div>
            <Card
              body
              style={{
                border: 'none',
                padding: '0px',
                maxWidth: '660px',
                margin: 'auto',
                zIndex: '1',
                top: '-20px',
                width: '100%',
                height: '100%',
                minHeight: '575px',
                animation: `fadeIn 2s`,
              }}
            >
              <Col className="justify-content-center">
                {cofounders.length > 0 ? (
                  sortedCofounders.map(founder => (
                    <Row className="pb-3">
                      <Card className="px-3" style={{ minHeight: '100%', width: '100%' }}>
                        <div className="text-center d-block m-auto p-3">
                          <img
                            src={founder.profile_image ? founder.profile_image : membericon}
                            alt=""
                            id="avataImg"
                            className="mb-2"
                            style={{
                              borderRadius: '50%',
                              height: '120px',
                              width: '120px',
                              objectFit: 'cover',
                            }}
                          />
                          {founder.linkedin_link ? (
                            <img
                              src={linkedinImage}
                              alt=""
                              id="avataImg"
                              className="p-4"
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                width: '85px',
                              }}
                            />
                          ) : null}
                          <h4 className="font-23 purple-colored font-weight-bold my-1">{founder.name}</h4>
                          <h5
                            style={{
                              textTransform:
                                founder.position[0] === 'CMO' ||
                                founder.position[0] === 'cmo' ||
                                founder.position[0] === 'COO' ||
                                founder.position[0] === 'coo' ||
                                founder.position[0] === 'CFO' ||
                                founder.position[0] === 'cfo' ||
                                founder.position[0] === 'CEO' ||
                                founder.position[0] === 'ceo' ||
                                founder.position[0] === 'CTO' ||
                                founder.position[0] === 'cto'
                                  ? 'uppercase'
                                  : 'capitalize',
                            }}
                          >
                            {founder.position[0]}
                          </h5>
                          <p>{founder.bio}</p>
                        </div>
                      </Card>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Card className="px-3" style={{ minHeight: '100%', width: '100%' }}>
                      <div className="text-center d-block m-auto p-3">
                        <img
                          src={membericon}
                          alt=""
                          id="avataImg"
                          className="mb-2"
                          style={{ borderRadius: '50%', height: '120px' }}
                        />
                        <h3 className="purple-colored font-weight-bold my-1">{`${user.first_name} ${user.last_name}`}</h3>
                        <h5 className="px-1">{user.position[0]}</h5>
                        <p>{user.bio}</p>
                      </div>
                    </Card>
                  </Row>
                )}
              </Col>
            </Card>
          </div>
        }
        pageThree={
          <div>
            {/* <Button
              className="mobile-toggle-button mobile-edit"
              onClick={() => {
                history.push("/startup/profile/edit");
              }}
            >
              Edit
            </Button> */}
            <Card
              body
              style={{
                border: 'none',
                padding: '60px 0px',
                zIndex: '1',
                width: '100%',
                maxWidth: '660px',
                margin: 'auto',
                height: '100%',
                minHeight: '575px',
                animation: `fadeIn 1.6s`,
              }}
            >
              <Col>
                <Row className="pt-2 pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Round Size</div>
                  <div className="mobile-info">
                    <NumberFormat
                      value={user.round_size}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      renderText={value => <div>{value}</div>}
                    />
                  </div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Raised (current round)</div>
                  <div className="mobile-info">
                    <NumberFormat
                      value={user.raised}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      renderText={value => <div>{value}</div>}
                    />
                  </div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Traction</div>
                  <div className="py-2 mobile-info-no-margin">
                    <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                      {user.progress.map(p => (
                        <span className="mobile-list">{p}</span>
                      ))}
                    </CardText>
                  </div>
                </Row>
                <Row className="pb-4 mobile-row">
                  <div className="pb-1 mobile-header">Attachments</div>
                  <div className="mobile-info">
                    {' '}
                    <CardText>
                      {user.slide_deck ? (
                        <Row className="align-items-center">
                          <img
                            src={pdfIcon}
                            alt="default"
                            style={{
                              width: '50px',
                              marginTop: '10px',
                              marginLeft: '20px',
                            }}
                          />
                          <span className="pl-3 investor-profile-edit-text">
                            {formatFileToName(user.slide_deck)}
                          </span>
                        </Row>
                      ) : (
                        <div className="text-muted investor-profile-edit-text">No Uploaded Slide Deck</div>
                      )}
                    </CardText>
                  </div>
                </Row>
              </Col>
            </Card>
          </div>
        }
      />
      <div className="push" />
      <div className="buttons-container" />
      <div className="group-buttons">
        <Button
          className="d-block w-70 mx-auto font-roboto mb-2 font-weight-600"
          style={{
            borderRadius: '5px',
            color: '#162642',
            backgroundColor: 'white',
            border: '1px solid #162642',
            outline: 'none',
          }}
          onClick={() => {
            history.push('/startup/profile/edit')
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
})

export default withRouter(connect(mapStateToProps)(StartupProfilePage))
