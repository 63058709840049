import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
// import { Button } from "react-bootstrap";

import tickMark from '../../assets/images/on_boarding_imgs/tickMark.svg'

import './PositionButton.scss'

import { updateLocalStartupUser } from '../../redux/auth/authActions'

const PositionButton = ({ startupPosition, updateLocalStartupUser, position, startupUser }) => {
  const { co_founders } = useSelector(state => state.auth.startupUser)

  return (
    <div className="position-container">
      <button
        onClick={() => {
          if (position === startupPosition) {
            updateLocalStartupUser({
              position: '',
              co_founders: [{ ...startupUser.co_founders[0], position: '' }],
            })
          } else {
            updateLocalStartupUser({
              position: startupPosition,
              // co_founders: { ...startupUser.co_founders, position: startupPosition },
              co_founders: [{ ...startupUser.co_founders[0], position: startupPosition }],
            })
          }
        }}
        // className={position === startupPosition ? 'selected-position positon-buttons' : 'position-buttons'}
        className={
          position === null
            ? co_founders[0].position === startupPosition
              ? 'selected-position positon-buttons'
              : 'position-buttons'
            : position === startupPosition
            ? 'selected-position positon-buttons'
            : 'position-buttons'
        }
      >
        {startupPosition}
        <img src={tickMark} alt="" />
      </button>
    </div>
  )
}

const mapStateToProps = state => ({
  position: state.auth.startupUser.position,
})

const mapDispatchToProps = dispatch => ({
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PositionButton)
