export const register = {
  INITIALIZE_SIGNUP: 'INITIALIZE_SIGNUP',
  SIGNUP_FAILED: 'SIGNUP_FAILED',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
}
export const login = {
  INITIALIZE_LOGIN: 'INITIALIZE_LOGIN',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
}
export const SET_ERRORS = 'SET_ERRORS'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const UPDATE_INFO_LOADING = 'UPDATE_INFO_LOADING'
export const SET_UPDATE_INFO = 'SET_UPDATE_INFO'
export const GO_BACK = 'GO_BACK'
export const SAVE_AND_LOGOUT = 'SAVE_AND_LOGOUT'
export const SAVE_AND_LOGOUT_COMPLETED = 'SAVE_AND_LOGOUT_COMPLETED'
export const CLEAR_ALL = 'CLEAR_ALL'
