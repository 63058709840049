export function calcCurrentInvestorStep(user, confirmedEmail) {
  if (
    !user.first_name
    // || !user.last_name
  ) {
    return 1
  }
  /*
    Check that the email is neither confirmed via a confirmation link
    not confirmed via the user's email_confirmed field.
  */
  if (!(confirmedEmail || user.email_confirmed)) {
    return 2
  } else {
    return 2
  }
}
