import React, { Component } from "react";

class AngelCommunityPage extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "https://embed.typeform.com/embed.js";
    document.body.appendChild(script);
  }

  render() {
    return (
      <iframe id="typeform-full"
        title="Angel Registration Form"
        style={{position: "absolute", width: "100%", height:"100%"}}
        frameborder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src="https://form.typeform.com/to/IRoONxBE"
      />
    );
  }
}

export default AngelCommunityPage;
