import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Steps, Button as AntButton } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import {
  updateLocalScoutUser,
  fetchScoutUserInfoUpdate,
  fetchScoutUserLogout,
} from '../../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../../redux/scoutFlowControl/scoutFlowControlActions'
import { ReactComponent as Scout } from '../../../assets/images/on_boarding_imgs/options/Scout.svg'
import { ReactComponent as Fund } from '../../../assets/images/on_boarding_imgs/options/Fund.svg'
import { ReactComponent as Accelerator } from '../../../assets/images/on_boarding_imgs/options/Accelerator.svg'
import { ReactComponent as Incubator } from '../../../assets/images/on_boarding_imgs/options/Incubator.svg'
import { ReactComponent as FamilyOffice } from '../../../assets/images/on_boarding_imgs/options/FamilyOffice.svg'
import './Step02OptionsForCloselyRelatedTo.scss'

function Step02OptionsForCloselyRelatedTo({ onCountinueClick }) {
  const { Step } = Steps
  const { scoutUser, loading } = useSelector(state => state.auth)
  const { saveAndExitClicked } = useSelector(state => state.scoutflowControl)
  const dispatch = useDispatch()
  const [partnerType, setPartnerType] = useState()
  const isEnabled = scoutUser?.partner_type?.length > 0

  // Handle save and exit functionality when the saveAndExitClicked state changes.
  useEffect(() => {
    if (saveAndExitClicked) {
      scoutOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  // Update the local state with the partner type from scoutUser.
  useEffect(() => {
    setPartnerType(scoutUser?.partner_type)
  }, [scoutUser?.partner_type])

  // Update the local scout user state when the partner type changes.
  useEffect(() => {
    if (partnerType) {
      dispatch(updateLocalScoutUser({ partner_type: partnerType }))
    }
  }, [partnerType])

  /**
   * Handles the click event for scout options, updating the partner type and triggering further actions.
   *
   * @param {boolean} isNextOrBack - Indicates if the action is a next or back click.
   */
  const scoutOnClick = isNextOrBack => {
    dispatch(fetchScoutUserInfoUpdate({ partner_type: partnerType, onboarding_step: isEnabled ? 2 : 1 }))
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          // history.push('/investor/login')
          dispatch(fetchScoutUserLogout())
          window.location.href = '/scout/login'
        }
      })
  }

  /**
   * Handles the click event for the "Next" button.
   *
   * @param {Event} e - The click event.
   */
  const scoutNextOnClick = e => {
    scoutOnClick(true)
  }

  /**
   * Renders an option with the specified type and icon.
   *
   * @param {string} type - The type of the scout option.
   * @param {ReactNode} icon - The icon for the scout option.
   * @returns {ReactNode} - The rendered scout option.
   */
  const renderOption = (type, icon) => (
    <div
      onClick={() => setPartnerType(type)}
      className={`relate-btn ${scoutUser?.partner_type === type && 'selected'}`}
    >
      {icon}
      <span className="relate-btn-text">{type}</span>
    </div>
  )

  return (
    <div className="closely-relate-option-step-wrap">
      <Container className="mb-0">
        <Row>
          <Col md={8} className="mx-auto mb-4">
            <h4 className="heading-question">
              Which of the options do you most closely <span className="heading-bold">relate to?</span>
            </h4>
          </Col>
        </Row>
      </Container>
      <Container className="IdentifyAsAnyOne mt-5">
        <Row>
          <Col
            lg={8}
            md={10}
            className="mx-auto d-flex align-items-center justify-content-center relate-wrapper"
          >
            {renderOption('Scout', <Scout />)}
            {renderOption('Fund', <Fund />)}
            {renderOption('Accelerator', <Accelerator />)}
            {renderOption('Incubator', <Incubator />)}
            {renderOption('Family Office', <FamilyOffice />)}
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <AntButton
                loading={loading}
                disabled={!isEnabled}
                onClick={() => scoutNextOnClick()}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </AntButton>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Step02OptionsForCloselyRelatedTo
