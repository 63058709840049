import React from 'react'
import { connect } from 'react-redux'
import './StartupNotificationsPage.scss'
import { Container } from 'reactstrap'

import Switch from '../../../components/Switch/Switch'
import { sendMondayNote } from '../../../redux/startupFlowControl/startupFlowControlActions'

class StartupNotificationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value1: null,
    }
  }

  componentDidMount() {
    let { user } = this.props
    this.setState({
      value1: user.monday_notification,
    })
  }

  handleClick1 = () => {
    this.setState({ value1: !this.state.value1 })
    this.props.sendMondayNote({ monday_notification: !this.state.value1 })
  }

  render() {
    const { value1 } = this.state
    return (
      <Container className="settings-mobile-container" style={{ animation: `fadeIn 1s` }}>
        <div className="settings-mobile-header">Email me...</div>
        <hr></hr>
        <div className="settings-mobile-option">
          <div className="justify-content-start">Every Monday with my waiting deals</div>
          <div>
            <Switch
              disabled={false}
              isOn={value1}
              handleToggle={this.handleClick1.bind(this)}
              inputId="testId1"
            />
          </div>
        </div>
        <hr></hr>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
})

const mapDispatchToProps = dispatch => ({
  sendMondayNote: monday_note => {
    dispatch(sendMondayNote(monday_note))
  },
})

export default connect(mapStateToProps, mapDispatchToProps, null)(StartupNotificationsPage)
