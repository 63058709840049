import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import './StartupDeletePage.scss'
import { verifyPassword } from '../../../redux/startupFlowControl/startupFlowControlActions'

class InvestorDeletePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      incorrect: false,
      correct: false,
    }
  }
  onChange = e => {
    this.setState({ value: e.target.value })
  }
  render() {
    if (this.state.correct) {
      return <Redirect to="/startup/deleteconfirm" />
    }
    return (
      <Container
        style={{
          textAlign: 'center',
          animation: `fadeIn 1s`,
        }}
        className="delete-container"
      >
        <Row>
          <Col className="mx-auto mb-3">
            <h2 className="delete-msg">Delete my Exitfund.com account</h2>
          </Col>
        </Row>
        <Row>
          <Col className="m-auto" xs={12} md={8} lg={5}>
            <input
              placeholder="Enter Password"
              style={{
                padding: '1rem',
                borderRadius: '5px',
                width: '100%',
              }}
              className="delete-input"
              onChange={this.onChange}
              value={this.state.value}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  this.props
                    .verifyPassword(this.state.value)
                    .then(res => {
                      if (res) {
                        this.setState({ correct: true })
                      } else {
                        this.setState({ incorrect: true })
                      }
                    })
                    .catch(() => this.setState({ incorrect: true }))
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto mt-2" xs={12} md={8} lg={5}>
            <button
              disabled={!this.state.value}
              className={this.state.value ? 'delete-button-active' : 'delete-button'}
              style={{ padding: '1rem', border: '5px', width: '100%' }}
              onClick={() => {
                this.props
                  .verifyPassword(this.state.value)
                  .then(res => {
                    if (res) {
                      this.setState({ correct: true })
                    } else {
                      this.setState({ incorrect: true })
                    }
                  })
                  .catch(() => this.setState({ incorrect: true }))
                // if (this.state.value === this.props.user.password) {
                //   this.setState({ correct: true });
                // } else {
                //   this.setState({ incorrect: true });
                // }
              }}
            >
              Delete my Exitfund.com account
            </button>
          </Col>
        </Row>

        <div className="text-center">{this.state.incorrect ? 'Incorrect Password.' : null}</div>
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth.startupUser,
})
const mapDispatchToProps = dispatch => ({
  verifyPassword: pw => {
    return dispatch(verifyPassword(pw))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(InvestorDeletePage)
