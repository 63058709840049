import React, { useEffect } from 'react'

import './TeamInfoPage.scss'

import './TeamInfoData'

import { connect, useDispatch } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'

import PositionButtonList from '../../../components/PositionButtonList/PositionButtonList'

import { prevStepStartup, nextStepStartup } from '../../../redux/startupFlowControl/startupFlowControlActions'
import { SaveAndExitClickedReset } from '../../../redux/startupFlowControl/startupFlowControlActions'
import validator from 'validator'
import { InputNumber } from 'antd'
import {
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  fetchStartupUserLogout,
} from '../../../redux/auth/authActions'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
// import './index.css';
import { Steps, Progress } from 'antd'
const { Step } = Steps

const TeamInfoPage = ({
  onCountinueClick,
  stepStartup,
  prevStepStartup,
  nextStepStartup,
  updateLocalStartupUser,
  startupUser,
  fetchStartupUserInfoUpdate,
  updateStartupUserInfoFailed,
  saveAndExitClicked,
}) => {
  const dispatch = useDispatch()

  //  Check if the conditions for enabling a feature are met.
  let isEnabled =
    startupUser?.co_founders[0]?.bio &&
    startupUser?.co_founders[0]?.position &&
    (startupUser.hasOwnProperty('linkedin')
      ? startupUser.linkedin
      : startupUser.co_founders[0].linkedin_link) &&
    startupUser.num_team_members > 0 &&
    startupUser.num_team_members <= 6 &&
    startupUser?.co_founders[0]?.bio.length > 0 &&
    startupUser?.co_founders[0]?.position.length > 0

  const [value, setValue] = React.useState(200)
  const [linkedinErr, setLinkedinErr] = React.useState('')
  const [teamSizeErr, setTeamSizeErr] = React.useState('')

  /**
   * Find the position of the first co-founder in the startup user data.
   * @description - If the position is an array, return the first element; otherwise, return the position.
   * @returns {string|null} The position of the first co-founder or null if not found.
   */
  const findPosition = () => {
    try {
      if (typeof startupUser?.co_founders[0]?.position === 'object') {
        return startupUser?.co_founders[0]?.position[0]
      } else {
        return startupUser?.co_founders[0]?.position
      }
    } catch (err) {
      return null
    }
  }

  /**
   * Handle the input change event for the bio field.
   * Update local startup user data with the new bio value.
   * @param {object} e - The input change event.
   */
  const handleInputChangeBio = e => {
    const charCount = e.target.value.length
    const charLeft = 200 - charCount
    setValue(charLeft)

    const coFoundersObject = [
      {
        ...startupUser?.co_founders[0],
        bio: e.target.value?.trimStart() || null,
        position: findPosition(),
      },
    ]

    updateLocalStartupUser({
      bio: e.target.value?.trimStart(),
      co_founders: coFoundersObject,
      position: findPosition(),
    })
  }

  // Set the value of the users bio length
  useEffect(() => {
    startupUser?.bio === null
      ? setValue(200 - startupUser?.co_founders[0]?.bio?.length || 200)
      : setValue(200 - startupUser?.bio?.length || 200)
  }, [])

  /**
   * Handle the input change event for the LinkedIn field.
   * Update local startup user data with the new LinkedIn value.
   * @param {object} e - The input change event.
   */
  const handleInputChangeLinkedIn = e => {
    if (!validator.isURL(e.target.value) && e.target.value !== 'N/A' && e.target.value !== 'n/a') {
      setLinkedinErr('Please enter a valid LinkedIn. If you do not have LinkedIn, you can enter N/A.')
    } else if (e.target.value !== 'n/a' && e.target.value !== 'N/A' && !e.target.value.includes('linkedin')) {
      setLinkedinErr('Please enter a valid LinkedIn. If you do not have LinkedIn, you can enter N/A.')
    } else {
      setLinkedinErr('')
    }
    const coFoundersObject = [
      {
        ...startupUser?.co_founders[0],
        linkedin: e.target.value,
        position: findPosition(),
      },
    ]

    updateLocalStartupUser({
      linkedin: e.target.value,
      co_founders: coFoundersObject,
    })
    //updateLocalStartupUser({ linkedin: e.target.value })
  }

  /**
   * Handle the input change event for the team size field.
   * Update local startup user data with the new team size value.
   * @param {number} value - The team size value.
   */
  const handleInputChangeTeamSize = value => {
    let inputStringTeamSize = value
    if (inputStringTeamSize > 6) {
      setTeamSizeErr('You can select a maximum of 6.')
    } else {
      setTeamSizeErr('')
    }

    const teamSize =
      inputStringTeamSize === undefined ||
      inputStringTeamSize === null ||
      inputStringTeamSize === '' ||
      isNaN(inputStringTeamSize)
        ? null
        : inputStringTeamSize

    const coFoundersObject = [
      {
        ...startupUser?.co_founders[0],
        position: findPosition(),
      },
    ]
    updateLocalStartupUser({
      num_team_members: teamSize,
      co_founders: coFoundersObject,
    })
  }

  /**
   * Handle the click event for the "Next" button in the startup onboarding process.
   * Update startup user data and navigate to the next step.
   * @param {boolean} isNextOrBack - Indicates whether it's a "Next" or "Back" action.
   */
  const startupOnClick = isNextOrBack => {
    const restructured_coFounder = [
      {
        ...startupUser?.co_founders[0],
        primary: startupUser?.co_founders[0]?.primary,
        first_name: startupUser?.first_name?.trimEnd(),
        last_name: startupUser?.last_name?.trimEnd(),
        email: startupUser?.email,
        position: findPosition(),
        bio: startupUser?.co_founders[0]?.bio?.trimEnd(),
        linkedin_link: startupUser?.linkedin,
      },
    ]
    fetchStartupUserInfoUpdate({
      // bio: startupUser.bio,
      num_team_members: startupUser.num_team_members,
      // position: startupUser.position,
      co_founders: restructured_coFounder,
      // linkedin: startupUser.linkedin,
      onboarding_step: isEnabled ? 6 : 5,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            // nextStepStartup()
            // onCountinueClick()
          } else {
            prevStepStartup()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }

  /**
   * Handle the click event for the "Back" button in the startup onboarding process.
   * Navigate to the previous step.
   * @param {object} e - The click event.
   */
  const startupBackOnClick = e => {
    startupOnClick(e, false)
  }

  /**
   * Handle the click event for the "Next" button in the startup onboarding process.
   * Invoke the startupOnClick function with the "Next" action and call onCountinueClick.
   * @param {object} e - The click event.
   */
  const startupNextOnClick = e => {
    startupOnClick(true)
    onCountinueClick()
  }

  /**
   * Handle the save and exit action.
   * @description - If the save and exit action is triggered, invoke the startupOnClick function with the "Back" action, and reset the save and exit clicked state.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      startupOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  /**
   * Get the current position of the first co-founder.
   * @returns {string|null} The position of the first co-founder or null if not found.
   */
  const currentPosition = () => {
    if (startupUser?.co_founders !== null) {
      const position = findPosition()

      return position
    }
  }

  return (
    <div>
      {/*** Heading is here ***/}
      <Container className="mb-3">
        <Row>
          <Col lg={7} md={8} className="mx-auto">
            <h4 className="heading-question">
              Tell us about <span className="font-weight-bold">your team.</span>
            </h4>
          </Col>
        </Row>
      </Container>

      {/* Body Content is Here */}
      <Container className="mb-3">
        <Row>
          <Col lg={7} md={8} className="mx-auto mb-3">
            <div className="startup-info-container padding-100">
              <p className="position-text">Your Position</p>

              <PositionButtonList selectedPosition={''} startupUser={startupUser} />

              <p className="startup-name">Your Short Bio (1-2 sentences)</p>
              <textarea
                onChange={handleInputChangeBio}
                className="info-container2"
                maxLength="200"
                type="text"
                placeholder="I am a Harvard graduate with over 5 years of experience in software engineering, having previously worked at Google."
                value={
                  startupUser?.bio === null
                    ? startupUser?.co_founders[0].bio?.trimStart()
                    : startupUser?.bio?.trimStart()
                }
              ></textarea>
              <p
                className={
                  value === 0 ? 'text-danger text-right text-secondary' : 'text-right text-secondary'
                }
              >
                You have {value} of 200 characters remaining
              </p>
              <p className="startup-name">Your LinkedIn</p>
              <input
                className="text-field startup-input"
                onChange={handleInputChangeLinkedIn}
                type="text"
                placeholder="https://www.linkedin.com/in/johnny-appleseed"
                value={
                  startupUser.hasOwnProperty('linkedin')
                    ? startupUser.linkedin
                    : startupUser.co_founders[0].linkedin_link
                }
              />
              <p className="text-danger">{linkedinErr}</p>
              <p className="startup-name ">
                {`How many people are on ${startupUser.company_name}'s founding team?`}
              </p>

              <div className="text-field-input-wrap">
                <InputNumber
                  className="text-field startup-input"
                  value={startupUser.num_team_members}
                  onChange={value => {
                    handleInputChangeTeamSize(value)
                  }}
                  placeholder="4"
                  min={0}
                  parser={value =>
                    value
                      ?.replace(/\./, '')
                      ?.replace(/\-/, '')
                      ?.replace(/^0+(?=\d)/, '')
                  }
                />
              </div>
              <p className="text-danger">{teamSizeErr}</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                disabled={!isEnabled}
                onClick={startupNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
})

const mapDispatchToProps = dispatch => ({
  prevStepStartup: () => dispatch(prevStepStartup()),
  nextStepStartup: () => dispatch(nextStepStartup()),

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamInfoPage)
