import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import './FooterInvestorMobile.scss'
import syllabus from '../../../assets/images/investers/deals.svg'
import exams from '../../../assets/images/investers/history.svg'

const FooterInvestorMobile = props => {
  return (
    <div className="footer-section">
      <div
        className={`footer-list text-dark px-2 ${
          props?.location?.pathname === '/investor/deals' ? 'active' : null
        }`}
      >
        <img src={syllabus} />
        <Link to="/investor/deals">Deals</Link>
      </div>
      <div
        className={`footer-list text-dark px-2 ${
          props?.location?.pathname === '/investor/history-invested' ||
          props?.location?.pathname === '/investor/history-passed'
            ? 'active'
            : null
        }`}
      >
        <img src={exams} />
        <Link to="/investor/history-invested">History</Link>
      </div>
    </div>
  )
}

export default withRouter(FooterInvestorMobile)
