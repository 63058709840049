import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'

import './ViewFeedbackModal.scss'

const ViewFeedbackModal = ({ dispatch, viewFeedbackModal, feedbackInformation }) => {
  const onClose = () => {
    dispatch({ type: 'SET_VIEW_FEEDBACK_MODAL', payload: false })
  }

  const { first_name, reason, comment } = feedbackInformation

  return (
    <Modal isOpen={viewFeedbackModal} style={{ padding: '100px 0' }}>
      <ModalBody className="text-center mt-0 p-5">
        <h3>{first_name ? `${first_name}'s Feedback` : 'Anonymous Feedback'}</h3>
        <div className="pt-3 font-weight-bold">This investor passed because of</div>
        <div className="purple-colored pt-1" style={{ fontWeight: 'bold', fontSize: '20px' }}>
          {reason}
        </div>
        <hr style={{ borderTop: '1px solid #162642' }} />
        <div style={{ fontSize: '15px' }}>{comment}</div>
        <hr style={{ borderTop: '1px solid #162642' }} />
        <Button
          onClick={onClose}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #162642',
            color: '#162642',
            padding: '10px 40px',
          }}
          className="mt-2"
        >
          Close
        </Button>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => ({
  feedbackInformation: state.startupflowControl.feedbackInformation,
  viewFeedbackModal: state.startupflowControl.viewFeedbackModal,
})

export default connect(mapStateToProps)(ViewFeedbackModal)
