import api from '../../utils/api'

export const SET_PODCAST_VIEW_DETAILS = 'SET_PODCAST_VIEW_DETAILS'

// Action to view podcast details
export const setPodcastViewDetails = podcastViewDetails => ({
  type: SET_PODCAST_VIEW_DETAILS,
  payload: podcastViewDetails,
})

// Action to view all reacord of the podcast by users Id
export const fetchPodcastViewDetails = id => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/marketing/get-single?id=${id}`).then(res => {
      if (res.result) {
        dispatch(setPodcastViewDetails(res.data))
        return res.req
      } else {
        console.error(res)
        return false
      }
    })
  }
}
