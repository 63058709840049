import React, { useEffect, useState } from 'react'
import './StartupInfoPage.scss'
import { DatePicker, Button as AntButton, message } from 'antd'
import 'antd/dist/antd.css'
import { ArrowRightOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import validator from 'validator'
import { nextStepStartup } from '../../../redux/startupFlowControl/startupFlowControlActions'
import { SaveAndExitClickedReset } from '../../../redux/startupFlowControl/startupFlowControlActions'
import {
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  fetchStartupUserLogout,
} from '../../../redux/auth/authActions'
import backdoor_VP8 from '../../../assets/images/on_boarding_imgs/backdoor_VP8.gif'
import moment from 'moment'

const StartupInfoPage = ({
  startupUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  emailConfirmationRedirect,
  setCountibueButtonEnabled,
  onCountinueClick,
  setStartInfoFlag,
  startInfoFlag,
  saveAndExitClicked,
}) => {
  const { startup_pitch, company_link, company_name, founded_date } = startupUser
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(200)
  const [websiteErr, setWebsiteErr] = React.useState('')

  // Check if the conditions are met to enable the Continue button.
  const isEnabled =
    startup_pitch &&
    company_link &&
    company_name &&
    startup_pitch.length > 0 &&
    company_link.length > 0 &&
    company_name.length > 0 &&
    founded_date &&
    (validator.isURL(company_link) || company_link === 'N/A' || company_link === 'n/a')

  //  Update character count value when startup_pitch is not null.
  useEffect(() => {
    startupUser?.startup_pitch !== null && setValue(200 - startupUser?.startup_pitch?.length || 200)
  }, [])

  // Set startInfoFlag to false after 5 seconds.
  useEffect(() => {
    setTimeout(() => {
      setStartInfoFlag(false)
    }, 5000)
  }, [])

  // Check if Save and Exit button is clicked.
  useEffect(() => {
    if (saveAndExitClicked) {
      startupOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  /**
   * Handle input change for startup_pitch.
   * @param {Object} e - The event object.
   */
  const handleInputChangePitch = e => {
    const charCount = e.target.value.length
    const charLeft = 200 - charCount
    setValue(charLeft)
    let startupPitchValue = e.target.value?.trimStart()
    updateLocalStartupUser({ startup_pitch: startupPitchValue ? startupPitchValue : '' })

    if (
      e.target.value &&
      company_link &&
      company_name &&
      e.target.value.length > 0 &&
      company_link.length > 0 &&
      company_name.length > 0 &&
      (validator.isURL(company_link) || company_link === 'N/A' || company_link === 'n/a')
    ) {
      setCountibueButtonEnabled(true)
    } else {
      setCountibueButtonEnabled(false)
    }
  }

  /**
   * Handle input change for company_name.
   * @param {Object} e - The event object.
   */
  const handleInputChangeCompany = e => {
    updateLocalStartupUser({ company_name: e.target.value.trimStart() })
    if (
      startup_pitch &&
      company_link &&
      e.target.value &&
      startup_pitch.length > 0 &&
      company_link.length > 0 &&
      e.target.value.length > 0 &&
      founded_date &&
      (validator.isURL(company_link) || company_link === 'N/A' || company_link === 'n/a')
    ) {
      setCountibueButtonEnabled(true)
    } else {
      setCountibueButtonEnabled(false)
    }
  }

  /**
   * Handle input change for founded_date.
   * @param {Date} date - The selected date.
   */
  const handleInputChangeCompanyFoundedDate = date => {
    updateLocalStartupUser({ founded_date: date ? moment(date).format('YYYY-MM-DD') : '' })
    if (
      startup_pitch &&
      company_link &&
      date &&
      startup_pitch.length > 0 &&
      company_link.length > 0 &&
      (validator.isURL(company_link) || company_link === 'N/A' || company_link === 'n/a')
    ) {
      setCountibueButtonEnabled(true)
    } else {
      setCountibueButtonEnabled(false)
    }
  }

  /**
   * Handle input change for website.
   * @param {Object} e - The event object.
   */
  const handleInputChangeWebsite = e => {
    if (e.target.value
      // && e.target.value !== 'https://'
    ) {
      const newValue = `${e.target.value?.trimStart()}`
      if (!validator.isURL(e.target.value) && e.target.value !== 'N/A' && e.target.value !== 'n/a') {
        setWebsiteErr('Please enter a valid URL. If there is no URL, you can enter N/A.')
      } else {
        setWebsiteErr('')
      }
      updateLocalStartupUser({ company_link: newValue })
    } else {
      updateLocalStartupUser({ company_link: '' })
    }
    if (
      startup_pitch &&
      e.target.value &&
      company_name &&
      startup_pitch.length > 0 &&
      e.target.value.length > 0 &&
      company_name.length > 0 &&
      founded_date &&
      (validator.isURL(e.target.value) || e.target.value === 'N/A' || e.target.value === 'n/a')
    ) {
      setCountibueButtonEnabled(true)
    } else {
      setCountibueButtonEnabled(false)
    }
  }

  /**
   * Handle startupOnClick function.
   * @param {boolean} saveAndExit - Indicates whether it's a save and exit action.
   */
  const startupOnClick = saveAndExit => {
    fetchStartupUserInfoUpdate(
      {
        startup_pitch: startup_pitch?.trimEnd(),
        company_link,
        company_name: company_name?.trimEnd(),
        founded_date,
        onboarding_step: isEnabled ? 1 : 0,
      },
      emailConfirmationRedirect,
    )
      .then(success => {
        onCountinueClick()
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }
  return (
    <div className="startup-info-wrapper">
      {/*** Heading is here ***/}
      {startInfoFlag ? (
        <>
          <Container className="mb-3 mt-5">
            <Row>
              <Col md={6} className="mx-auto text-center mt-5">
                <img src={backdoor_VP8} width="250" style={{ animation: `fadeIn 1.5s` }} />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mx-auto text-center mt-3">
                <h5 className="font-weight-light" style={{ animation: `fadeIn 1.5s` }}>
                  Let's get started!
                </h5>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <span>
          <Container className="mb-3">
            <Row>
              <Col lg={7} md={8} className="mx-auto">
                <h4 className="heading-question">
                  Tell us about your <span className="font-weight-bold">startup.</span>
                </h4>
              </Col>
            </Row>
          </Container>

          {/* Body Container is Here */}
          <Container className="mb-4">
            <Row>
              <Col lg={7} md={8} className="mx-auto">
                <div className="startup-info-container">
                  <p className="startup-name">Company Name</p>
                  <input
                    onChange={handleInputChangeCompany}
                    className="text-field"
                    type="text"
                    placeholder="My Startup"
                    value={startupUser.company_name}
                  />
                  <p className="startup-name">Company Founded Date</p>
                  <DatePicker
                    onChange={e => handleInputChangeCompanyFoundedDate(e)}
                    value={startupUser?.founded_date && moment(startupUser?.founded_date)}
                    size="large"
                    className="text-field"
                    format={'MM/DD/YYYY'}
                    disabledDate={current => current && current.isAfter(moment())}
                  />
                  <p className="startup-name">Company Website</p>
                  <input
                    onChange={handleInputChangeWebsite}
                    className="text-field"
                    type="text"
                    style={{
                      borderColor:
                        startupUser.company_link &&
                          !validator.isURL(startupUser.company_link) &&
                          startupUser.company_link !== 'N/A' &&
                          startupUser.company_link !== 'n/a'
                          ? 'red'
                          : null,
                    }}
                    value={startupUser.company_link}
                    placeholder="https://www.company.com"
                  />
                  <p className="text-danger">{websiteErr}</p>
                  <p className="startup-name">Short Pitch (1-2 sentences)</p>
                  <textarea
                    onChange={handleInputChangePitch}
                    className="info-container2"
                    type="textarea"
                    maxLength="200"
                    value={startupUser.startup_pitch?.trimStart()}
                    placeholder="Facebook brings your offline relationships online. It lets you connect with your friends and family even when you are not in person."
                  ></textarea>
                  <p
                    className={
                      value === 0 ? 'text-danger text-right text-secondary' : 'text-right text-secondary'
                    }
                  >
                    You have {value} of 200 characters remaining
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          {/* New Back & Continue buttons */}
          <section className="bottomBtns">
            <Container className="mb-5">
              <Row>
                <Col md={5} xs={12} className="text-center mx-auto">
                  <AntButton
                    disabled={!isEnabled}
                    onClick={() => {
                      startupOnClick(true)
                    }}
                    className="continueBtnOnboard btn-block"
                  >
                    Continue <ArrowRightOutlined />
                  </AntButton>
                </Col>
              </Row>
            </Container>
          </section>
        </span>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
})

const mapDispatchToProps = dispatch => ({
  nextStepStartup: () => dispatch(nextStepStartup()),

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  fetchStartupUserInfoUpdate: (changedProperties, emailConfirmRedirect) => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties, emailConfirmRedirect))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupInfoPage)
