import React from 'react'

export default function StartupStepDoneIcon(props) {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.15626 13.1082C7.40536 11.2302 7.50402 9.36744 7.13965 7.42757C8.12042 7.4803 8.4506 6.73396 8.77004 6.00385C9.20572 5.0111 9.74495 4.09643 10.4991 3.32778C10.9787 2.838 11.1233 2.19814 11.2357 1.54408C11.3011 1.16178 11.3558 0.776447 11.4545 0.403278C11.5473 0.0514043 11.7915 -0.0773794 12.1353 0.0453201C12.6707 0.236975 13.0096 0.609129 13.1181 1.19626C13.2773 2.06428 13.0233 2.86842 12.7605 3.66952C12.6404 4.0366 12.4694 4.38645 12.3581 4.75556C12.2145 5.23317 12.3542 5.41773 12.8406 5.41672C13.7677 5.41469 14.6957 5.40455 15.6228 5.38934C16.1258 5.38123 16.5762 5.48162 16.795 6.01703C17.0089 6.54129 16.8966 7.01384 16.499 7.40019C16.2685 7.62429 16.3124 7.75308 16.5576 7.94777C17.1506 8.42032 17.1467 9.11697 16.5537 9.60979C16.3222 9.80246 16.3447 9.91705 16.5156 10.1351C17.0666 10.8378 16.8282 11.5466 15.9832 11.7494C15.6902 11.8194 15.6189 11.9715 15.7117 12.2605C15.9842 13.1123 15.6364 13.6376 14.7582 13.668C12.528 13.7441 10.3018 13.7461 8.11065 13.1934C7.81564 13.1204 7.50011 13.1366 7.15626 13.1082Z" fill="#7C8E54" />
      <path d="M7.00429 9.97481C6.8646 11.1724 6.73956 12.2442 6.61354 13.3161C6.57349 13.6609 6.39277 13.8251 6.05184 13.7937C5.95513 13.7846 5.85452 13.8079 5.75976 13.7947C5.05935 13.6964 4.54454 13.8819 4.30228 14.6597C4.25441 14.8138 3.92521 14.968 3.73179 14.9619C2.8868 14.9325 2.04474 14.8331 1.19975 14.7824C0.823656 14.7601 0.651727 14.6009 0.619491 14.2014C0.426071 11.8285 0.214091 9.45765 0.0157871 7.08478C-0.0408712 6.41146 0.0275094 6.3425 0.682987 6.4003C2.48237 6.55951 4.28176 6.71871 6.07822 6.91239C6.25796 6.93166 6.52367 7.16793 6.56079 7.34539C6.74737 8.24789 6.87339 9.16459 7.00429 9.97481ZM4.99683 7.54719C4.99683 9.40593 4.99683 11.2261 4.99683 13.0535C5.27328 13.0535 5.59174 13.0535 5.93462 13.0535C6.19251 11.5486 6.34393 10.0661 6.12902 8.5663C5.99226 7.60093 5.98346 7.60296 4.99683 7.54719Z" fill="#7C8E54" />
    </svg>

  )
}
