export const GET_BLOG_LIST_DATA_INIT = 'GET_BLOG_LIST_DATA_INIT'
export const GET_BLOG_LIST_DATA_SUCCESS = 'GET_BLOG_LIST_DATA_SUCCESS'
export const GET_BLOG_LIST_DATA_FAIL = 'GET_BLOG_LIST_DATA_FAIL'

export const GET_BLOG_DETAILS_INIT = 'GET_BLOG_DETAILS_INIT'
export const GET_BLOG_DETAILS_SUCCESS = 'GET_BLOG_DETAILS_SUCCESS'
export const GET_BLOG_DETAILS_FAIL = 'GET_BLOG_DETAILS_FAIL'

export const SUBSCRIBE_TO_NEWSLATTER_INIT = 'SUBSCRIBE_TO_NEWSLATTER_INIT'
export const SUBSCRIBE_TO_NEWSLATTER_SUCCESS = 'SUBSCRIBE_TO_NEWSLATTER_SUCCESS'
export const SUBSCRIBE_TO_NEWSLATTER_FAIL = 'SUBSCRIBE_TO_NEWSLATTER_FAIL'
