/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import RegisterForm from '../../../components/RegisterForm/RegisterForm'
import NewHomePage from '../../NewHomePage/NewHomePage'

// import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import './SignUpStartup.scss'

import {
  updateStartupStep,
  setConfirmedEmail,
} from '../../../redux/startupFlowControl/startupFlowControlActions'
import { calcCurrentStartupStep } from '../../../utils/startupStep'
import StartupInformation from '../StartupInfoPage/StartupInformation'

const SignUpStartup = ({
  stepStartup,
  googleName,
  startupUser,
  confirmedEmail,
  updateStartupStep,
  setConfirmedEmail,
  updateUser,
  toggleStartup,
}) => {
  /**
   * Handle actions based on the URL parameters.
   * @description - If the 'confirmed' parameter is 'True', sets the confirmed email and updates user information.
   *              - Calculates the current step in the startup onboarding process and updates the startup step state.
   */
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.get('confirmed') === 'True') {
      setConfirmedEmail()

      const fName = urlParams.get('fn').split('_').join(' ')
      const lName = urlParams.get('ln').split('_').join(' ')
      const email = urlParams.get('email')
      updateUser(fName, lName, email)
    }

    if (stepStartup === 2) {
      return
    }

    toggleStartup()

    const currentStep = calcCurrentStartupStep(startupUser, confirmedEmail)
    updateStartupStep(currentStep)
  }, [confirmedEmail])

  switch (stepStartup) {
    case 1:
      return <RegisterForm startup={'startup'} pageType={'startup'} />
    case 2:
      return <StartupInformation />
    case 3:
      return <StartupInformation />
    default:
      return <RegisterForm startup={'startup'} pageType={'startup'} />
  }
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  googleName: state.startupflowControl.user.googleProfileObj.givenName,
  startupUser: state.auth.startupUser,
  confirmedEmail: state.startupflowControl.confirmedEmail,
})

const mapDispatchToProps = dispatch => ({
  updateStartupStep: step => {
    dispatch(updateStartupStep(step))
  },
  setConfirmedEmail: () => {
    dispatch(setConfirmedEmail())
  },
  updateUser: (first_name, last_name, email) => {
    dispatch({
      type: 'UPDATE_LOCAL_STARTUP_USER',
      payload: { first_name, last_name, email },
    })
  },
  toggleStartup: () => {
    dispatch({
      type: 'TOGGLE_STARTUP_BUTTON',
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpStartup)
