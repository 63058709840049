const INITIAL_STATE = {
  classNames: "",
  rotationFinished: false
};

const animationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START_STOP_ANIMATION":
      return {
        ...state,
        classNames: action.text
      };

    case "ON_ANIMATION_START":
      return {
        ...state,
        rotationFinished: false
      };

    case "ON_ANIMATION_END":
      return {
        ...state,
        rotationFinished: true
      };

    case "ON_ANIMATION_RESET":
      return {
        ...state,
        classNames: "",
        rotationFinished: false
      };

    default:
      return state;
  }
};

export default animationReducer;
