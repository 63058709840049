import { SET_PODCASTS } from './podcastsActions'
import { SET_PODCASTS_COUNT } from './podcastsActions'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  podcasts: [],
  podcastst_count: 0,
}

// Define a Redux reducer function
const podcastsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PODCASTS:
      return {
        ...state,
        podcasts: payload,
      }
    case SET_PODCASTS_COUNT:
      return {
        ...state,
        podcastst_count: payload,
      }

    default:
      return state
  }
}

export default podcastsReducer
