import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Container } from "reactstrap"

import { changePassword } from "../../../redux/startupFlowControl/startupFlowControlActions"

class StartupMobilePWPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: "none",
      hide: "block",
    }
  }
  showModal = () => {
    this.setState({ show: "block", hide: "none" })
  }

  render() {
    return (
      <Container
        className="settings-mobile-container"
        style={{ animation: `fadeIn 1s` }}
      >
        <div style={{ display: this.state.hide }}>
          <div className="settings-mobile-header w-90">
            Are you sure you want to change your password?
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: "18px",
            }}
            className="d-block m-auto w-70 text-center"
          >
            If so, we'll email you a password reset link.
          </div>
          <div className="col pt-5">
            <button
              style={{
                color: "#FFF",
                textDecoration: "none",
                verticalAlign: "super",
              }}
              onClick={() => {
                this.props.changePassword()
                this.showModal()
              }}
              className="full-mobile-button mobile-primary"
            >
              Yes, I'm sure
            </button>
            <Link to="/startup/account">
              <button className="full-mobile-button mobile-secondary">
                No, take me back
              </button>
            </Link>
          </div>
        </div>
        <div style={{ display: this.state.show }}>
          <div className="changepw-container">
            <div className="changepw-content-title">Check your inbox!</div>
            <div className="changepw-content">
              We've sent you an email with a link to reset your password.
            </div>
            <div>
              <Link style={{ color: "#FFF", textDecoration: "none" }} to="/">
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#5e51f4",
                    outline: "none",
                    borderRadius: "5px",
                    margin: "40px auto",
                    display: "block",
                    height: "60px",
                    border: "none",
                    width: "90%",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  Back to Landing Page
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
  changePassword: () => {
    dispatch(changePassword())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupMobilePWPage)
