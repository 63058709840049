import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PositionButton from '../PositionButton/PositionButton'
import { updateLocalStartupUser } from '../../redux/auth/authActions'
import { Input } from 'reactstrap'

import './PositionButtonList.scss'

import POSSIBLE_TEAM_MEMBER_POSITIONS from '../../pages/Startup/TeamInfoPage/TeamInfoData'

const PositionButtonList = ({ userPosition, updateLocalStartupUser, startupUser }) => {
  const positionComponent = POSSIBLE_TEAM_MEMBER_POSITIONS.map((position, i) => {
    return <PositionButton key={i} startupPosition={position} startupUser={startupUser} />
  })

  return (
    <div>
      <div className="startup-info-container d-flex justify-content-center justify-content-md-start mb-3">
        {positionComponent}
      </div>
      <Input
        className="startup-info-input"
        type="text"
        placeholder="Other position (e.g. Head of Product)"
        style={{ height: '50%' }}
        value={POSSIBLE_TEAM_MEMBER_POSITIONS.includes(userPosition) ? '' : userPosition}
        maxLength="28"
        disabled={POSSIBLE_TEAM_MEMBER_POSITIONS.includes(userPosition)}
        onChange={event =>
          updateLocalStartupUser({
            position: event.target.value?.trimStart(),
            co_founders: [{ ...startupUser.co_founders[0], position: event.target.value }],
          })
        }
      />
    </div>
  )
}

const mapStateToProps = state => ({
  userPosition: state.auth.startupUser?.co_founders[0]?.position,
})

const mapDispatchToProps = dispatch => ({
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PositionButtonList)
