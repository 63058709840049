import { lazy } from 'react'
export const Portfolio = lazy(() => import('../pages/PortfolioPages/Portfolio'))
export const Faqs = lazy(() => import('../pages/FaqsNewPages/Faqs'))
export const NewHomePage = lazy(() => import('../pages/NewHomePage/NewHomePage'))
export const Suspend = lazy(() => import('./Suspend/suspend'))
export const BlogListPage = lazy(() => import('../pages/BlogListPage/BlogListPage'))
export const PodcastList = lazy(() => import('../pages/PodcastList/PodcastList'))
export const PodcastViewDetails = lazy(() => import('../pages/PodcastViewDetails/PodcastViewDetails'))
export const BlogViewDetails = lazy(() => import('../pages/BlogViewDetails/BlogViewDetails'))
export const TermsPage = lazy(() => import('../pages/TermsPolicy/TermsPage'))
export const PolicyPage = lazy(() => import('../pages/TermsPolicy/PolicyPage'))
export const ThankYouPage = lazy(() => import('../pages/Startup/SignUpStartup/ThankYouPage/ThankYouPage'))
export const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage/ForgotPasswordPage'))
export const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'))
