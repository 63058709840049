import { LOGOUT_LOCAL_INVESTOR_USER, LOGOUT_LOCAL_STARTUP_USER } from '../auth/authActions'
import {
  EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS,
  EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS,
} from '../EducationGetAccredited/actionTypes'
import {
  EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS,
  EDUCATION_LEARN_INVEST_LOGIN_SUCCESS,
} from '../EducationLearnInvest/actionTypes'

const INITIAL_STATE = {
  investorPage: true,
  startupPage: false,
  scoutPage: false,
  bdrColor: '#162642',
  bdrColor2: '#D3D3D3',
  pageType: '',
}

const STATE_STARTUP = {
  investorPage: false,
  startupPage: true,
  scoutPage: false,
  bdrColor: '#D3D3D3',
  bdrColor2: '#5E51F4',
}

const STATE_SCOUT = {
  investorPage: false,
  startupPage: false,
  scoutPage: true,
  bdrColor: '#D3D3D3',
  bdrColor2: '#162642',
}

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case "SET_CURRENT_USER":
    //   return {
    //     ...state,
    //     currentUser: action.payload
    //   };

    case 'TOGGLE_STARTUP_BUTTON':
      return STATE_STARTUP
    // return {
    //   ...state,
    //   investorPage: false,
    //   startupPage: true,
    //   bdrColor: "#D3D3D3",
    //   bdrColor2: "#162642"
    // };

    case 'TOGGLE_INVESTOR_BUTTON':
      return INITIAL_STATE
    // return {
    //   ...state,
    //   investorPage: true,
    //   startupPage: false,
    //   bdrColor: "#162642",
    //   bdrColor2: "#D3D3D3"
    // };

    case 'TOGGLE_SCOUT_BUTTON':
      return STATE_SCOUT
    // return {
    //   ...state,
    //   investorPage: true,
    //   startupPage: false,
    //   bdrColor: "#5E51F4",
    //   bdrColor2: "#D3D3D3"
    // };
    case 'SET_PAGE_TYPE':
      return {
        ...state,
        pageType: action.payload,
      }

    case LOGOUT_LOCAL_INVESTOR_USER:
      return INITIAL_STATE

    case LOGOUT_LOCAL_STARTUP_USER:
      return STATE_STARTUP

    case EDUCATION_LEARN_INVEST_LOGIN_SUCCESS:
    case EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS:
    case EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS:
    case EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS:
      return {
        ...INITIAL_STATE,
        investorPage: false,
        startupPage: false,
        scoutPage: false,
      }

    default:
      return state
  }
}

export default homeReducer
