import React from "react";
import "./StartupFeedbackPage.scss";
import membericon from "../../../assets/images/FounderPhoto.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { Modal, Button } from 'antd';

// import LOCATION_DATA from "../../../components/LocationCardList/LocationData"

import { fetchUserProfile } from "../../../redux/investorFlowControl/investorFlowControlActions";

class StartupFeedbackPage extends React.Component {
  componentDidMount() {
    const fetchUserProfile = this.props.fetchUserProfile;
    fetchUserProfile(0);
  }

  render() {
    const { user } = this.props;

    let spanIndex = 0;

    return (
      <div className="screen-preview">
        <div className="investor-modal-preview">
          <Link className="link-preview" to="/startup/dashboard">
            Close
          </Link>
          <div className="dashboard-container-preview">
            <div className="content">
              <div className="content-left">
                <div style={{ height: "160px" }}>
                  {user &&
                  user.profile_pic_link &&
                  user.profile_pic_link !== "None" ? (
                    <img
                      className="investor-img"
                      style={{
                        height: "130px",
                        width: "130px",
                        marginLeft: "-13px",
                        marginTop: "20px",
                      }}
                      src={user.profile_pic_link}
                      alt=""
                      id="avataImg"
                      shape="circle"
                    />
                  ) : (
                    <img
                      src={membericon}
                      alt=""
                      id="avataImg"
                      className="investor-default-img"
                    />
                  )}
                </div>
                <div className="investor-name">{user.company_name}</div>
                <span className="investor-address">{user.location}</span>

                <div className="investor-brief">{user.startup_pitch}</div>
                <div className="investor-brief">{user.company_link}</div>
              </div>
              <div className="content-right">
                <div className="investor-invest">
                  <span
                    style={{
                      float: "left",
                      fontSize: "37px",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                    }}
                  >
                    Seeking
                  </span>
                  <span
                    style={{
                      fontSize: "37px",
                      float: "left",
                      marginLeft: "10px",
                      color: "#EDC432",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                    }}
                  >
                    {user.round_size}
                  </span>
                </div>
                <div style={{ fontSize: "25px" }}>{user.raised}</div>
                <div className="investor-sectors">
                  <div
                    className="investor-sector-title"
                    style={{ fontFamily: "Roboto" }}
                  >
                    Founding Team
                  </div>
                  <div className="investor-sector-content">
                    {user.sectors.map((x) => {
                      spanIndex++;
                      if (spanIndex < 8) {
                        return (
                          <span className="traction-each-content-preview">
                            {" "}
                            {x}{" "}
                          </span>
                        );
                      } else {
                        return "";
                      }
                    })}
                    {spanIndex > 8 && (
                      <div
                        className="see-more"
                        style={{ float: "right", marginRight: "-20px" }}
                      >
                        see more
                      </div>
                    )}
                  </div>
                </div>
                <div className="prior-investment">
                  <div className="prior-investment-title">Traction</div>
                  <div className="prior-invest-content">
                    <div className="prior-invest-each">
                      <span className="traction-each-content-preview">
                        Product Launched
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <button className="button-left">Pass</button>
              <button className="button-right">Invite</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
  userInfos: state.investorflowControl.userInfos,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfile: (offset) => {
    return dispatch(fetchUserProfile(offset));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartupFeedbackPage);
