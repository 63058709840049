import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.css'
import App from './containers/App'
import 'tachyons'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './redux/store'

import { GoogleOAuthProvider } from '@react-oauth/google'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept()
}

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </PersistGate> */}
      </GoogleOAuthProvider>
    </Provider>,
    rootElement,
  )
} else {
  render(
    <Provider store={store}>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </PersistGate> */}
      </GoogleOAuthProvider>
    </Provider>,
    rootElement,
  )
}

serviceWorker.unregister()
