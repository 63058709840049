export const EDUCATION_LEARN_INVEST_SIGNUP_INIT = 'EDUCATION_LEARN_INVEST_SIGNUP_INIT'
export const EDUCATION_LEARN_INVEST_SIGNUP_SUCCESS = 'EDUCATION_LEARN_INVEST_SIGNUP_SUCCESS'
export const EDUCATION_LEARN_INVEST_SIGNUP_FAIL = 'EDUCATION_LEARN_INVEST_SIGNUP_FAIL'

export const EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_INIT = 'EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_INIT'
export const EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_SUCCESS = 'EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_SUCCESS'
export const EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_FAIL = 'EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_FAIL'

export const EDUCATION_LEARN_INVEST_LOGIN_INIT = 'EDUCATION_LEARN_INVEST_LOGIN_INIT'
export const EDUCATION_LEARN_INVEST_LOGIN_SUCCESS = 'EDUCATION_LEARN_INVEST_LOGIN_SUCCESS'
export const EDUCATION_LEARN_INVEST_LOGIN_FAIL = 'EDUCATION_LEARN_INVEST_LOGIN_FAIL'

export const EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_INIT = 'EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_INIT'
export const EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS = 'EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS'
export const EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_FAIL = 'EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_FAIL'

export const FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT = 'FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT'
export const FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS =
  'FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS'
export const FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL = 'FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL'

export const UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_INIT =
  'UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_INIT'
export const UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_SUCCESS =
  'UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_SUCCESS'
export const UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_FAILED =
  'UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_FAILED'

export const UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT =
  'UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT'
export const UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS =
  'UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS'
export const UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL =
  'UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL'

export const UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_INIT = 'UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_INIT'
export const UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_SUCCESS = 'UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_SUCCESS'
export const UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_FAILED = 'UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_FAILED'

export const GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT =
  'GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT'
export const GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED =
  'GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED'

export const SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT =
  'SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT'
export const SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS =
  'SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS'
export const SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED =
  'SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED'

export const GET_EDUCATION_LEARN_INVEST_SYLLABUS_INIT = 'GET_EDUCATION_LEARN_INVEST_SYLLABUS_INIT'
export const GET_EDUCATION_LEARN_INVEST_SYLLABUS_SUCCESS = 'GET_EDUCATION_LEARN_INVEST_SYLLABUS_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_SYLLABUS_FAILED = 'GET_EDUCATION_LEARN_INVEST_SYLLABUS_FAILED'

export const GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_INIT =
  'GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_INIT'
export const GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_FAILED =
  'GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_FAILED'

export const MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_INIT =
  'MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_INIT'
export const MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_SUCCESS =
  'MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_SUCCESS'
export const MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_FAILED =
  'MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_FAILED'

export const GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_INIT =
  'GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_INIT'
export const GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_FAILED =
  'GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_FAILED'

export const MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_INIT = 'MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_INIT'
export const MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_SUCCESS =
  'MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_SUCCESS'
export const MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_FAILED =
  'MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_FAILED'

export const GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_INIT = 'GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_INIT'
export const GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_FAILED = 'GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_FAILED'

export const GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_INIT = 'GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_INIT'
export const GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_FAILED = 'GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_FAILED'

export const POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_INIT = 'POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_INIT'
export const POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_SUCCESS =
  'POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_SUCCESS'
export const POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_FAILED = 'POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_FAILED'

export const GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_INIT = 'GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_INIT'
export const GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_SUCCESS = 'GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_FAILED = 'GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_FAILED'

export const GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_INIT = 'GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_INIT'
export const GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_SUCCESS =
  'GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_SUCCESS'
export const GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_FAILED = 'GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_FAILED'

export const POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_INIT = 'POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_INIT'
export const POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_SUCCESS =
  'POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_SUCCESS'
export const POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_FAILED = 'POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_FAILED'

export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_INIT =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_INIT'
export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_SUCCESS =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_SUCCESS'
export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_FAILED =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_FAILED'

export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_INIT =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_INIT'
export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_SUCCESS =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_SUCCESS'
export const FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_FAILED =
  'FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_FAILED'

export const CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT = 'CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT'
export const CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS = 'CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS'
export const CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED = 'CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED'

export const FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT =
  'FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT'
export const FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS =
  'FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS'
export const FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED =
  'FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED'

export const COMPLETE_EDUCATION_TOUR_INIT = 'COMPLETE_EDUCATION_TOUR_INIT'
export const COMPLETE_EDUCATION_TOUR_SUCCESS = 'COMPLETE_EDUCATION_TOUR_SUCCESS'
export const COMPLETE_EDUCATION_TOUR_FAILED = 'COMPLETE_EDUCATION_TOUR_FAILED'
