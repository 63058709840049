import React from 'react'
import './InvestorWelcomeBackPage.scss'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/LogoExitFund.svg'

class SecondInvite extends React.Component {
  render() {
    return (
      <div>
        <div className="second-invite-container">
          <div className="second-invite-content">
            <div className="connection-created font-lato font-weight-600">Connection Created!</div>
            <div className="want-to-connect font-lato font-weight-400">[Startup] also wants to connect.</div>
            <img src={logo} alt="" className="logo-center d-block mt-4 pt-4 mx-auto" />
            <div className="pt-4 mt-4 check-email-text">Check your email for your warm intro.</div>
            <div className="excited">We're excited for you!</div>
            <div className="second-invite-button">
              <Link className="discovering" to="/investor/dashboard">
                Continue Discovering
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SecondInvite
