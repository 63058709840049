import React from 'react'
import './StartupDeletePage.scss'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { deleteAccount } from '../../../redux/startupFlowControl/startupFlowControlActions'

class InvestorDeleteConfirmPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleted: false,
    }
  }
  render() {
    if (this.state.deleted) {
      return <Redirect to="/startup/alreadydelete" />
    }
    return (
      <Container
        style={{
          textAlign: 'center',
          animation: `fadeIn 1s`,
        }}
        className="delete-container"
      >
        <Row>
          <Col xs={12} md={10} lg={8} className="mx-auto mb-3">
            <h2>Are you absolutely sure you want to terminate your account?</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              You will lose <span style={{ fontWeight: '700' }}>all data</span> associated with your profile.
            </h4>
          </Col>
        </Row>
        <div className="backtosettings-bottom-confirm">
          <Link
            className="d-md-block d-none"
            style={{ color: '#FFF', textDecoration: 'none' }}
            to="/startup/settings"
          >
            No, take me back
          </Link>
          <Link
            className="d-md-none d-block"
            style={{ color: '#FFF', textDecoration: 'none' }}
            to="/startup/account"
          >
            No, take me back
          </Link>
        </div>
        <button
          className="delete-my-account"
          onClick={() => {
            this.props.deleteAccount()
            this.setState({ deleted: true })
          }}
        >
          <Link style={{ color: '#162642', textDecoration: 'none' }} to="/startup/alreadydelete">
            Yes, delete my account
          </Link>
        </button>
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth.startupUser,
})
const mapDispatchToProps = dispatch => ({
  deleteAccount: () => {
    dispatch(deleteAccount())
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(InvestorDeleteConfirmPage)
