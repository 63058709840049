import React, { Component } from 'react'
import { Progress } from 'reactstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import _ from 'lodash'

import './SlideDeckModal.scss'

import { Button, Modal, ModalBody, ModalFooter, Container } from 'reactstrap'

class SlideDeckViewer extends Component {
  constructor(props) {
    super(props)

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    this.state = {
      loadingError: null,
      loadingProgressPercentage: 0,
      lastLoadingProgressDisplayTimeInMilliseconds: null,
      numPages: null,
      pageNumber: 1,
      pdfPageWidth: null,
      remainingLoadingTimeInSeconds: null,
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.changePage = this.changePage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.isDocumentStillLoading = this.isDocumentStillLoading.bind(this)
    this.hasDocumentFailedToLoad = this.hasDocumentFailedToLoad.bind(this)
    this.hasDocumentLoadedSuccessfully = this.hasDocumentLoadedSuccessfully.bind(this)

    /*
      Intentionally using a function reference to ensure that the add and remove
      event listeners actually remove the throttled calls to resizing the page
      width on a component mount or unmount, so we don't continuously spam calls.
    */
    this.throttledResizePageWidth = _.throttle(this.resizePageWidth, 500)
    this.pdfDocumentWrapper = React.createRef()
  }

  /*
    Unfortunately, we need to add this fancy resizing logic to determine the
    wrapper of the pdf document react component to dynamically size the pdf
    page component. We were originally using plain css to set the width and
    height to 100% but this resulted in the react-pdf library preventing any
    interactions with any components within the modal after iterating through
    the pages on mobile devices. Most likely this is due to the library internally
    having it's own width/height calculations/rendering. Instead, we rely on
    the react-pdf library to honor the aspect ratio of the PDF file in the canvas
    itself by dynamically providing a width based upon the size of the modal
    container which grows/shrinks the PDF document page based upon such width.

    Reference: https://github.com/wojtekmaj/react-pdf/issues/129
  */
  componentDidMount() {
    this.resizePageWidth()
    window.addEventListener('resize', this.throttledResizePageWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizePageWidth)
  }

  resizePageWidth = () => {
    this.setState({ pdfPageWidth: this.pdfDocumentWrapper.current.getBoundingClientRect().width })
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
      loadingError: null,
      loadingProgressPercentage: 100,
    })
  }

  onDocumentLoadProgress = ({ loaded, total }) => {
    const loadingProgressPercentage = ((loaded / total) * 100).toString()

    const currentTimeInMillis = new Date().getTime()
    const lastLoadingProgressDisplayTimeInMilliseconds = this.state
      .lastLoadingProgressDisplayTimeInMilliseconds
    if (
      lastLoadingProgressDisplayTimeInMilliseconds === null ||
      lastLoadingProgressDisplayTimeInMilliseconds === undefined ||
      /*
        Opting to only update the progress bar every 1/2th of a second since
        higher download speeds can trigger a lot more document loading progress
        calls due to the reactpdf library Document object.
      */
      currentTimeInMillis - lastLoadingProgressDisplayTimeInMilliseconds > 1000 / 2
    ) {
      // Hacking in division by Mb. Total and loaded should be in MB
      // which is why we divid by M (10e6) and Bytes over bits (8)
      const remainingLoadingTimeInSeconds = Math.round((total - loaded) / (10e6 / 8))
      this.setState({
        loadingProgressPercentage,
        remainingLoadingTimeInSeconds,
        lastLoadingProgressDisplayTimeInMilliseconds: currentTimeInMillis,
      })
    }
  }

  onDocumentLoadError = error => {
    this.setState({
      numPages: null,
      loadingError: error,
      loadingProgressPercentage: 0,
    })
  }

  changePage = offset => {
    this.setState(prevState => {
      return {
        ...prevState,
        pageNumber: prevState.pageNumber + offset,
      }
    })
  }

  isDocumentStillLoading = () => {
    return !this.hasDocumentFailedToLoad() && !this.hasDocumentLoadedSuccessfully()
  }

  hasDocumentFailedToLoad = () => {
    return this.state.loadingError !== undefined && this.state.loadingError !== null
  }

  hasDocumentLoadedSuccessfully = () => {
    return this.state.numPages !== undefined && this.state.numPages !== null
  }

  previousPage = () => {
    this.changePage(-1)
  }

  nextPage = () => {
    this.changePage(1)
  }

  render() {
    const hasDocumentLoadedSuccessfully = this.hasDocumentLoadedSuccessfully()

    return (
      <Container>
        {this.isDocumentStillLoading() && (
          <div className="slide-deck-modal-contents" style={{ textAlign: 'center' }}>
            <h4>Loading PDF...</h4>
            <br />
            <div className="d-none d-lg-block">
              <Progress striped color="purple" value={this.state.loadingProgressPercentage} />
            </div>
            <div className="d-block d-lg-none">
              <Progress striped color="purple" value={this.state.loadingProgressPercentage} />
            </div>
            <br />
            <h5>
              We'll have this deck ready for you in
              <strong>
                {!this.state.remainingLoadingTimeInSeconds
                  ? ''
                  : ` ${this.state.remainingLoadingTimeInSeconds}`}
              </strong>
              ...
            </h5>
          </div>
        )}
        {this.hasDocumentFailedToLoad() && (
          <div className="slide-deck-modal-contents" style={{ textAlign: 'center' }}>
            <h5 style={{ fontWeight: 'bold' }}>We ran into an issue loading this startup's slide deck.</h5>
            <br />
            <h5>Try loading the deck again or refreshing this page!</h5>
          </div>
        )}
        <div ref={this.pdfDocumentWrapper}>
          <Document
            file={this.props.slideDeckFile || null}
            onLoadSuccess={this.onDocumentLoadSuccess}
            onLoadError={this.onDocumentLoadError}
            onLoadProgress={this.onDocumentLoadProgress}
          >
            <Page
              hidden={!hasDocumentLoadedSuccessfully}
              pageNumber={this.state.pageNumber}
              width={this.state.pdfPageWidth}
              className="slide-deck-page"
            />
          </Document>
        </div>
        {hasDocumentLoadedSuccessfully && (
          <div>
            <p>
              Page {this.state.pageNumber || (this.state.numPages ? 1 : '--')} of{' '}
              {this.state.numPages || '--'}
            </p>
            <Button
              disabled={this.state.pageNumber <= 1}
              onClick={this.previousPage}
              style={{
                backgroundColor: 'white',
                border: '1px solid #162642',
                fontWeight: '400',
                color: '#162642',
              }}
            >
              Previous
            </Button>
            <Button
              disabled={this.state.pageNumber >= this.state.numPages}
              onClick={this.nextPage}
              style={{
                marginLeft: '8px',
                backgroundColor: 'white',
                border: '1px solid #162642',
                fontWeight: '600',
                color: '#162642',
              }}
            >
              Next
            </Button>
          </div>
        )}
      </Container>
    )
  }
}

export class SlideDeckModal extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
  }

  toggle = () => {
    this.props.dispatch({ type: 'SET_SLIDE_DECK_MODAL', payload: false })
  }

  render() {
    return (
      <Modal isOpen={this.props.slideDeckModalOpen} toggle={this.toggle} size="xl">
        <ModalBody>
          <SlideDeckViewer slideDeckFile={this.props.slideDeckSelected} />
        </ModalBody>
        <ModalFooter>
          <Button style={{ backgroundColor: '#162642' }} onClick={this.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SlideDeckModal
