import React from 'react'
import './Switch.scss'

// Switch component for toggling between states.
class Switch extends React.Component {
  render() {
    const { isOn, disabled, handleToggle, inputId } = this.props

    return (
      <div className="switch">
        <input
          checked={isOn}
          disabled={disabled || false}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={inputId}
          type="checkbox"
        />
        <label
          style={{ background: isOn && '#162642 ' }}
          className="react-switch-label"
          htmlFor={inputId}
          disabled={disabled || false}
        >
          <span className={`react-switch-button`} />
        </label>
      </div>
    )
  }
}

export default Switch
