import { ReactComponent as PrototypePreLaunch } from '../../assets/images/on_boarding_imgs/milestones/Prototype_Pre_Launch.svg'
import { ReactComponent as BetaLaunched } from '../../assets/images/on_boarding_imgs/milestones/Beta_Launched.svg'
import { ReactComponent as ProductLaunched } from '../../assets/images/on_boarding_imgs/milestones/Product_Launched.svg'
import { ReactComponent as UsersAcquired } from '../../assets/images/on_boarding_imgs/milestones/Users_Acquired.svg'
import { ReactComponent as RevenueGenerated } from '../../assets/images/on_boarding_imgs/milestones/Revenue_Generated.svg'

// List of the Milestones card data
const MILESTONES_CARD_DATA = [
  {
    id: 0,
    title: 'Prototype/Pre-Launch',
    image: PrototypePreLaunch,
  },
  {
    id: 1,
    title: 'Beta Launched',
    image: BetaLaunched,
  },

  {
    id: 2,
    title: 'Product Launched',
    image: ProductLaunched,
  },
  {
    id: 3,
    title: 'Users Acquired',
    image: UsersAcquired,
  },
  {
    id: 4,
    title: 'Revenue Generated',
    image: RevenueGenerated,
  },
]

export default MILESTONES_CARD_DATA
