import React from "react"
import { Link } from "react-router-dom"
import "./StartupSettingsPage.scss"
import { connect } from "react-redux"
class StartupChangePWPage extends React.Component {
  handleEmailAddress = (string) => {
    let str = ""
    for (var i = 0; i < string.length; i++) {
      if (string[i] === "@") {
        str = string[0] + "xxxxxxxxx" + string.slice(i)
      }
    }
    return str
  }

  render() {
    return (
      <div style={{ paddingTop: "80px", animation: `fadeIn 1s`, overflow: "hidden" }}>
        <div className="changepw-container">
          <div className="changepw-content-title">Check your inbox!</div>
          <div className="changepw-content">
            We've sent you an email with a link to reset your password.
          </div>
          <div>
            <Link style={{ color: "#FFF", textDecoration: "none" }} to="/">
              <button className="back-after-reset mobile-primary">
                Back to Landing Page
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
})

export default connect(mapStateToProps)(StartupChangePWPage)
