export const SET_EMAIL_NOTIFICATION_INIT = 'SET_EMAIL_NOTIFICATION_INIT'
export const SET_EMAIL_NOTIFICATION_SUCCESS = 'SET_EMAIL_NOTIFICATION_SUCCESS'
export const SET_EMAIL_NOTIFICATION_FAILED = 'SET_EMAIL_NOTIFICATION_FAILED'
export const GET_EMAIL_NOTIFICATION_INIT = 'GET_EMAIL_NOTIFICATION_INIT'
export const GET_EMAIL_NOTIFICATION_SUCCESS = 'GET_EMAIL_NOTIFICATION_SUCCESS'
export const GET_EMAIL_NOTIFICATION_FAILED = 'GET_EMAIL_NOTIFICATION_FAILED'
export const GET_UPDATE_PROFILE_DATA_INIT = 'GET_UPDATE_PROFILE_DATA_INIT'
export const GET_UPDATE_PROFILE_DATA_SUCCESS = 'GET_UPDATE_PROFILE_DATA_SUCCESS'
export const GET_UPDATE_PROFILE_DATA_FAILED = 'GET_UPDATE_PROFILE_DATA_FAILED'
export const GET_QUESTION_INIT = 'GET_QUESTION_INIT'
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS'
export const GET_QUESTION_FAILED = 'GET_QUESTION_FAILED'
export const POST_ANSWER_INIT = 'POST_ANSWER_INIT'
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS'
export const POST_ANSWER_FAILED = 'POST_ANSWER_FAILED'
export const FETCH_STATUS_INIT = 'FETCH_STATUS_INIT'
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS'
export const FETCH_STATUS_FAILED = 'FETCH_STATUS_FAILED'
export const POST_STATUS_INIT = 'POST_STATUS_INIT'
export const POST_STATUS_SUCCESS = 'POST_STATUS_SUCCESS'
export const POST_STATUS_FAILED = 'POST_STATUS_FAILED'
export const GET_INVESTOR_FEEDBACK_INIT = 'GET_INVESTOR_FEEDBACK_INIT'
export const GET_INVESTOR_FEEDBACK_SUCCESS = 'GET_INVESTOR_FEEDBACK_SUCCESS'
export const GET_INVESTOR_FEEDBACK_FAILED = 'GET_INVESTOR_FEEDBACK_FAILED'
export const NOTIFICATION_DROPDOWN_INIT = 'NOTIFICATION_DROPDOWN_INIT'
export const NOTIFICATION_DROPDOWN_SUCCESS = 'NOTIFICATION_DROPDOWN_SUCCESS'
export const NOTIFICATION_DROPDOWN_FAILED = 'NOTIFICATION_DROPDOWN_FAILED'
export const GET_NOTIFICATION_DATA_INIT = 'GET_NOTIFICATION_DATA_INIT'
export const GET_NOTIFICATION_DATA_SUCCESS = 'GET_NOTIFICATION_DATA_SUCCESS'
export const GET_NOTIFICATION_DATA_FAILED = 'GET_NOTIFICATION_DATA_FAILED'
export const GET_UNREAD_NOTIFICATION_COUNT_INIT = 'GET_UNREAD_NOTIFICATION_COUNT_INIT'
export const GET_UNREAD_NOTIFICATION_COUNT_SUCCESS = 'GET_UNREAD_NOTIFICATION_COUNT_SUCCESS'
export const GET_UNREAD_NOTIFICATION_COUNT_FAILED = 'GET_UNREAD_NOTIFICATION_COUNT_FAILED'
export const GET_UNREAD_NOTIFICATION_DATA_INIT = 'GET_UNREAD_NOTIFICATION_DATA_INIT'
export const GET_UNREAD_NOTIFICATION_DATA_SUCCESS = 'GET_UNREAD_NOTIFICATION_DATA_SUCCESS'
export const GET_UNREAD_NOTIFICATION_DATA_FAILED = 'GET_UNREAD_NOTIFICATION_DATA_FAILED'
export const CLEAR_NOTIFICATION_DATA_INIT = 'CLEAR_NOTIFICATION_DATA_INIT'
export const CLEAR_NOTIFICATION_DATA_SUCCESS = 'CLEAR_NOTIFICATION_DATA_SUCCESS'
export const CLEAR_NOTIFICATION_DATA_FAILED = 'CLEAR_NOTIFICATION_DATA_FAILED'
export const PUBLIC_LINK_INIT = 'PUBLIC_LINK_INIT'
export const PUBLIC_LINK_SUCCESS = 'PUBLIC_LINK_SUCCESS'
export const PUBLIC_LINK_FAILED = 'PUBLIC_LINK_FAILED'
export const STARTUP_DETAIL_INIT = 'STARTUP_DETAIL_INIT'
export const STARTUP_DETAIL_SUCCESS = 'STARTUP_DETAIL_SUCCESS'
export const STARTUP_DETAIL_FAILED = 'STARTUP_DETAIL_FAILED'
export const POST_LINK_TOKEN_INIT = 'POST_LINK_TOKEN_INIT'
export const POST_LINK_TOKEN_SUCCESS = 'POST_LINK_TOKEN_SUCCESS'
export const POST_LINK_TOKEN_FAILED = 'POST_LINK_TOKEN_FAILED'
export const SET_ACCESS_TOKEN_INIT = 'SET_ACCESS_TOKEN_INIT'
export const SET_ACCESS_TOKEN_SUCCESS = 'SET_ACCESS_TOKEN_SUCCESS'
export const SET_ACCESS_TOKEN_FAILED = 'SET_ACCESS_TOKEN_FAILED'
export const GET_COMPLETED_TOUR_INIT = 'GET_COMPLETED_TOUR_INIT'
export const GET_COMPLETED_TOUR_SUCCESS = 'GET_COMPLETED_TOUR_SUCCESS'
export const GET_COMPLETED_TOUR_FAILED = 'GET_COMPLETED_TOUR_FAILED'
export const GET_CUSTOMER_INFO_INIT = 'GET_CUSTOMER_INFO_INIT'
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS'
export const GET_CUSTOMER_INFO_FAILED = 'GET_CUSTOMER_INFO_FAILED'
export const POST_EMAIL_INIT = 'POST_EMAIL_INIT'
export const POST_EMAIL_SUCCESS = 'POST_EMAIL_SUCCESS'
export const POST_EMAIL_FAILED = 'POST_EMAIL_FAILED'
export const CREATE_CUSTOMER_INIT = 'CREATE_CUSTOMER_INIT'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED'
export const UPDATE_CUSTOMER_INIT = 'UPDATE_CUSTOMER_INIT'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED'
export const SUBMIT_DOC_INIT = 'SUBMIT_DOC_INIT'
export const SUBMIT_DOC_SUCCESS = 'SUBMIT_DOC_SUCCESS'
export const SUBMIT_DOC_FAILED = 'SUBMIT_DOC_FAILED'
export const ADD_FUNDING_ACCOUNT_INIT = 'ADD_FUNDING_ACCOUNT_INIT'
export const ADD_FUNDING_ACCOUNT_SUCCESS = 'ADD_FUNDING_ACCOUNT_SUCCESS'
export const ADD_FUNDING_ACCOUNT_FAILED = 'ADD_FUNDING_ACCOUNT_FAILED'
export const MANAGE_BANK_ACCOUNT_INIT = 'MANAGE_BANK_ACCOUNT_INIT'
export const MANAGE_BANK_ACCOUNT_SUCCESS = 'MANAGE_BANK_ACCOUNT_SUCCESS'
export const MANAGE_BANK_ACCOUNT_FAILED = 'MANAGE_BANK_ACCOUNT_FAILED'
export const DELETE_BANK_ACCOUNT_INIT = 'DELETE_BANK_ACCOUNT_INIT'
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS'
export const DELETE_BANK_ACCOUNT_FAILED = 'DELETE_BANK_ACCOUNT_FAILED'
export const GET_WITHDRAW_MONEY_INIT = 'GET_WITHDRAW_MONEY_INIT'
export const GET_WITHDRAW_MONEY_SUCCESS = 'GET_WITHDRAW_MONEY_SUCCESS'
export const GET_WITHDRAW_MONEY_FAILED = 'GET_WITHDRAW_MONEY_FAILED'
export const POST_WITHDRAW_MONEY_INIT = 'POST_WITHDRAW_MONEY_INIT'
export const POST_WITHDRAW_MONEY_SUCCESS = 'POST_WITHDRAW_MONEY_SUCCESS'
export const POST_WITHDRAW_MONEY_FAILED = 'POST_WITHDRAW_MONEY_FAILED'
export const GET_INVESTOR_HISTORY_INIT = 'GET_INVESTOR_HISTORY_INIT'
export const GET_INVESTOR_HISTORY_SUCCESS = 'GET_INVESTOR_HISTORY_SUCCESS'
export const GET_INVESTOR_HISTORY_FAILED = 'GET_INVESTOR_HISTORY_FAILED'
export const GET_INVESTOR_DETAIL_INIT = 'GET_INVESTOR_DETAIL_INIT'
export const GET_INVESTOR_DETAIL_SUCCESS = 'GET_INVESTOR_DETAIL_SUCCESS'
export const GET_INVESTOR_DETAIL_FAILED = 'GET_INVESTOR_DETAIL_FAILED'
export const GET_PROFILE_COMPLETION_INIT = 'GET_PROFILE_COMPLETION_INIT'
export const GET_PROFILE_COMPLETION_SUCCESS = 'GET_PROFILE_COMPLETION_SUCCESS'
export const GET_PROFILE_COMPLETION_FAILED = 'GET_PROFILE_COMPLETION_FAILED'
export const UPDATE_PROFILE_COMPLETION_INIT = 'UPDATE_PROFILE_COMPLETION_INIT'
export const UPDATE_PROFILE_COMPLETION_SUCCESS = 'UPDATE_PROFILE_COMPLETION_SUCCESS'
export const UPDATE_PROFILE_COMPLETION_FAILED = 'UPDATE_PROFILE_COMPLETION_FAILED'
export const GET_TRANSFER_FUND_INIT = 'GET_TRANSFER_FUND_INIT'
export const GET_TRANSFER_FUND_SUCCESS = 'GET_TRANSFER_FUND_SUCCESS'
export const GET_TRANSFER_FUND_FAILED = 'GET_TRANSFER_FUND_FAILED'
export const CHANGE_SETTINGS_PROFILE_ACTIVE_KEY = 'CHANGE_SETTINGS_PROFILE_ACTIVE_KEY'
export const ADD_EMAIL_SVP_INIT = 'ADD_EMAIL_SVP_INIT'
export const ADD_EMAIL_SVP_SUCCESS = 'ADD_EMAIL_SVP_SUCCESS'
export const ADD_EMAIL_SVP_FAILED = 'ADD_EMAIL_SVP_FAILED'
export const SEND_FUNDRAISE_INVITE_INIT = 'SEND_FUNDRAISE_INVITE_INIT'
export const SEND_FUNDRAISE_INVITE_SUCCESS = 'SEND_FUNDRAISE_INVITE_SUCCESS'
export const SEND_FUNDRAISE_INVITE_FAILED = 'SEND_FUNDRAISE_INVITE_FAILED'
export const GET_FUNDRAISE_INVITE_HISTORY_INIT = 'GET_FUNDRAISE_INVITE_HISTORY_INIT'
export const GET_FUNDRAISE_INVITE_HISTORY_SUCCESS = 'GET_FUNDRAISE_INVITE_HISTORY_SUCCESS'
export const GET_FUNDRAISE_INVITE_HISTORY_FAILED = 'GET_FUNDRAISE_INVITE_HISTORY_FAILED'
export const GET_POST_HISTORY_DATA_INIT = 'GET_POST_HISTORY_DATA_INIT'
export const GET_POST_HISTORY_DATA_SUCCESS = 'GET_POST_HISTORY_DATA_SUCCESS'
export const GET_POST_HISTORY_DATA_FAILED = 'GET_POST_HISTORY_DATA_FAILED'
export const GET_FILE_UPLOAD_URL_INIT = 'GET_FILE_UPLOAD_URL_INIT'
export const GET_FILE_UPLOAD_URL_SUCCESS = 'GET_FILE_UPLOAD_URL_SUCCESS'
export const GET_FILE_UPLOAD_URL_FAILED = 'GET_FILE_UPLOAD_URL_FAILED'
export const CREATE_FUNDRAISE_POST_INIT = 'CREATE_FUNDRAISE_POST_INIT'
export const CREATE_FUNDRAISE_POST_SUCCESS = 'CREATE_FUNDRAISE_POST_SUCCESS'
export const CREATE_FUNDRAISE_POST_FAILED = 'CREATE_FUNDRAISE_POST_FAILED'
