import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button, Container } from 'reactstrap'

class StartupAccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // showModal = () => {
  //   this.setState({ show: "block", hide: "none" })
  // }
  // hideModal = () => {
  //   this.setState({ show: "none", hide: "block" })
  // }

  render() {
    return (
      <Container className="settings-mobile-container">
        <div style={{ display: this.state.hide }}>
          <div className="settings-mobile-header">Login Information</div>
          <hr></hr>
          <div className="settings-mobile-option" style={{ position: 'relative', top: '7.5px' }}>
            <div>Password</div>
            <div className="dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
            <Link
              className="change"
              style={{
                fontSize: '.9rem',
                padding: '6px 8px',
                backgroundColor: 'transparent',
                position: 'relative',
                top: '-7.5px',
              }}
              to="/startup/cpw"
            >
              Change
            </Link>
          </div>
          <hr></hr>

          <div>
            <div style={{ marginTop: '50%' }} className="settings-mobile-header">
              Manage Account
            </div>
            <Button
              onClick={() => this.props.history.push('/startup/logout')}
              outline
              style={{
                borderRadius: '5px',
                padding: '7px 15px',
                display: 'block',
                // width: "40%",
                fontWeight: '600',
                color: '#162642',
                borderColor: '#162642',
                margin: '20px auto',
              }}
              className="settings-mobile-option"
            >
              Log out of Exitfund.com
            </Button>
            <span
              style={{
                textAlign: 'center',
                display: 'block',
                width: '90%',
                margin: 'auto',
              }}
            >
              <Link
                style={{
                  padding: '0 10px',
                  fontFamily: 'Roboto',
                  fontSize: '13.5px',
                  color: '#B5B5B5',
                  textDecoration: 'none',
                }}
                to="/startup/delete"
              >
                Delete my Exitfund.com account
              </Link>
            </span>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
})

export default withRouter(connect(mapStateToProps, null)(StartupAccountPage))
