// import IconImpactStartup from '../../assets/images/on_boarding_imgs/iconImpactStartupActive.png'
// import IconImpactStartupActive from '../../assets/images/on_boarding_imgs/iconImpactStartup.png'
// import IconFemaleFounder from '../../assets/images/on_boarding_imgs/iconFemaleFounderActive.png'
// import IconFemaleFounderActive from '../../assets/images/on_boarding_imgs/iconFemaleFounder.png'
// import IconFounderOfColor from '../../assets/images/on_boarding_imgs/iconFounderOfColorActive.png'
// import IconFounderOfColorActive from '../../assets/images/on_boarding_imgs/iconFounderOfColor.png'
// import IconLGBTQ_Founder from '../../assets/images/on_boarding_imgs/iconLGBTQ_Founder.png'

import ImpactStartupActiveUpdated from '../../assets/images/on_boarding_imgs/ImpactStartupUpdated.svg'
import ImpactStartupUpdated from '../../assets/images/on_boarding_imgs/ImpactStartupActiveUpdated.svg'
import FemaleFounderActiveUpdated from '../../assets/images/on_boarding_imgs/FemaleFounderUpdated.svg'
import FemaleFounderUpdated from '../../assets/images/on_boarding_imgs/FemaleFounderActiveUpdated.svg'
import LGBTQFounderUpdated from '../../assets/images/on_boarding_imgs/LGBTQFounderUpdated.svg'
import FounderOfColorUpdated from '../../assets/images/on_boarding_imgs/FounderOfColorActiveUpdated.svg'
import FounderOfColorActiveUpdated from '../../assets/images/on_boarding_imgs/FounderOfColorUpdated.svg'

// Startup Identify card data
export const STARTUP_IDENTIFY_CARD_DATA = [
  {
    id: 0,
    title: 'Impact Startup',
    image: ImpactStartupUpdated,
    ActiveImg: ImpactStartupActiveUpdated,
  },
  {
    id: 1,
    title: 'Female Founder',
    image: FemaleFounderUpdated,
    ActiveImg: FemaleFounderActiveUpdated,
  },
  {
    id: 2,
    title: 'Founder of Color',
    image: FounderOfColorUpdated,
    ActiveImg: FounderOfColorActiveUpdated,
  },
  {
    id: 3,
    title: 'LGBTQ Founder',
    image: LGBTQFounderUpdated,
    ActiveImg: LGBTQFounderUpdated,
  },
]

// Investors Identify card data
export const INVESTOR_IDENTIFY_CARD_DATA = [
  {
    id: 0,
    title: 'Female Investor',
    image: FemaleFounderUpdated,
    ActiveImg: FemaleFounderActiveUpdated,
  },
  {
    id: 1,
    title: 'Investor of Color',
    image: FounderOfColorUpdated,
    ActiveImg: FounderOfColorActiveUpdated,
  },
  {
    id: 2,
    title: 'LGBTQ Investor',
    image: LGBTQFounderUpdated,
    ActiveImg: LGBTQFounderUpdated,
  },
]
