//List of the Milestones data.
const MILESTONES_DATA = [
  'Idea/Sketches',
  'Mockups/Renderings',
  'Prototype/Pre-Launch',
  'Beta Launched',
  'Taking Preorders',
  'Product Launched',
  'Early Users Acquired',
  'Early Revenue Generated',
]

export default MILESTONES_DATA
