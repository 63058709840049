import React, { useEffect, useState } from 'react'

import { Container, Row, Col, InputGroup, FormControl, Navbar, Button as RButton } from 'react-bootstrap'

import groupOfPeople from '../../../../assets/images/on_boarding_imgs/groupOfPeople.svg'
import peopleSetRejected from '../../../../assets/images/on_boarding_imgs/peopleSetRejected.svg'
import tracking_img from '../../../../assets/images/on_boarding_imgs/tracking_img.svg'
import checkMark from '../../../../assets/images/on_boarding_imgs/checkMark.svg'
import startup_approved_img from '../../../../assets/images/on_boarding_imgs/startup_approved_img.svg'
import investor_approved_img from '../../../../assets/images/on_boarding_imgs/investor_approved_img.svg'
import { useHistory, withRouter, Redirect } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../../utils/api'
import moment from 'moment'
import { Button, message, Spin, Form, Input } from 'antd'
import * as TrackingActions from '../../../../redux/trackingFlowControl/TrackingFlowControlActions'
import {
  ResendApplication,
  ResendInvestorApplication,
  handleInvestorThankyouScreenViewed,
  handleThankyouScreenViewed,
} from '../../../../redux/auth/authActions'
import './ThankYouPage.scss'

function ThankYouPage(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { startup, token, startupUser, user, investor } = useSelector(state => state.auth)
  const [waitListData, setWaitListData] = useState(undefined)
  const [inviteEmail, setInviteEmail] = useState('')
  const [inviteEmailErr, setInviteEmailErr] = useState('')
  const [loader, setLoader] = useState(false)
  const [form] = Form.useForm()
  let IsStartup = history?.location?.pathname?.includes('startup')

  // Scroll to the top of the page and fetch startup completed steps if the user is logged in. Redirects to login page if no token is found.
  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
    if (localStorage.getItem('token') || token) {
      if (IsStartup) {
        api.get(`${process.env.REACT_APP_API_URL}/startup/completed-steps`).then(res => setWaitListData(res))
      }
    } else {
      history.push('login')
    }
  }, [])

  // Redirect to the first onboarding step if the startup user's status is incomplete.
  useEffect(() => {
    if (startupUser?.status?.status == 'incomplete') {
      history.push('/startup/onboarding/step-1')
    }
  }, [startupUser])

  // Redirect to the first onboarding step if the investor user's status is incomplete.
  useEffect(() => {
    if (user?.status?.status == 'incomplete') {
      history.push('/investor/onboarding/step-1')
    }
  }, [user])
  console.log({ waitListData })
  // Investor completed steps if the user is logged in and not a startup.
  useEffect(() => {
    console.log({ id: user?.id, status: user?.status?.status })
    if (user?.id && user?.status?.status !== 'incomplete' && user?.status?.status !== 'approved' && !IsStartup) {
      api.get(`${process.env.REACT_APP_API_URL}/investor/completed-steps`).then(res => setWaitListData(res))
    }
  }, [user?.id])

  useEffect(() => {
    if (startupUser.thankyou_screen_viewed == true) {
      if (waitListData?.status === 'tracking') {
        history.push('/tracking/monthly-update')
      } else if (waitListData?.status === 'approved') {
        history.push('/startup/approve/dashboard-status')
      }
    }
  }, [startupUser.thankyou_screen_viewed])
  /**
   * Handles resending the application for approval.
   * @param {string} status - Either 'investor' or 'startup'.
   */
  const onHandleNewApplication = status => {
    let payload = {
      resubmit: true,
    }
    if (status === 'investor') {
      dispatch(ResendInvestorApplication(payload))
    } else if (status == 'startup') {
      dispatch(ResendApplication(payload))
    }
  }

  // Handles the submission of an email invitation.
  const handleEmailSubmit = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (inviteEmail === '') {
      setInviteEmailErr('Please enter email')
    } else if (regex.test(inviteEmail) === false) {
      setInviteEmailErr('Please enter a valid email')
    } else {
      setLoader(true)
      const result = inviteEmail.split(',').map(d => d.trim())
      api
        .post(`${process.env.REACT_APP_API_URL}/${IsStartup || startup ? 'startup' : 'investor'}/invite`, {
          email: result,
        })
        .then(res => {
          setLoader(false)
          if (res.result) {
            message.success(res.message === 'invited!' ? 'Invited!' : res.message)
            setInviteEmail('')
            form.resetFields()
          } else {
            message.error(res.error)
          }
        })
    }
  }

  // Redirect the startup user to the login page if their status is 'tracking' or 'approved' and email is not confirmed.
  useEffect(() => {
    if (IsStartup) {
      if (
        (waitListData?.status == 'tracking' || waitListData?.status == 'approved') &&
        !startupUser?.email_confirmed
      ) {
        localStorage.clear('')
        props.history.push('/startup/login')
      }
    }
  }, [waitListData?.status, startupUser?.email_confirmed])

  // Redirect the investor user to the login page if their status is 'approved' and email is not confirmed.
  useEffect(() => {
    if (!IsStartup) {
      if (waitListData?.status == 'approved' && !user?.email_confirmed) {
        localStorage.clear('')
        props.history.push('/investor/login')
      }
    }
  }, [waitListData?.status, user?.email_confirmed])

  // Handles the logout process by clearing local storage and redirecting to the home page.
  const handleLogout = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  //  Redirects to the home page if the investor user's status is 'incomplete'.
  if (user?.status?.status === 'incomplete' && !IsStartup) {
    return <Redirect to="/" />
  }

  // Redirects to the investor deals page if the investor user's status is 'approved'.
  if (user?.status?.status === 'approved' && !IsStartup && user.thankyou_screen_viewed === true) {
    return <Redirect to="/investor/deals" />
  }

  console.log({ waitListData, user })

  return (
    <>
      <div className="onboarding-header-main-wrap bg-white sticky-top">
        <Navbar>
          <Container
            fluid
            className="px-3 py-3 mt-md-0 pt-md-3 py-md-1 d-flex align-items-center justify-content-between"
          >
            <Navbar.Brand href="/" className="">
              <div className="thankyouHeaderLogo"></div>
            </Navbar.Brand>

            <div className="d-flex align-items-center justify-content-end hire-btn-group">
              {IsStartup || startup ? (
                <button className="hire-btn" onClick={() => window.open('/recruit', '_blank')}>
                  Hire Engineering Talent
                </button>
              ) : (
                <button
                  className="hire-btn"
                  onClick={() => window.open('/education/learn-invest/', '_blank')}
                >
                  Start Startup Investing Education
                </button>
              )}
              <button className="logout-btn" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </Container>
        </Navbar>
      </div>
      <div className="thankyou-wrapper">
        {/*** Body Content is here ***/}
        <Container className="thankYouSet">
          {!waitListData && !user?.status?.status ? (
            <Row className="mt-5 pt-5">
              <Col md={4} className="mx-auto text-center mt-5 pt-5">
                <Spin spinning={true} />
              </Col>
            </Row>
          ) : (
            <Row className="w-100 mx-auto">
              <Col
                xs={12}
                md={12}
                lg={4}
                className="ml-auto left pt-5 d-flex justify-content-center justify-content-lg-start"
              >
                {(waitListData?.status === 'pending' || user?.status?.status === 'pending') ? (
                  <img src={groupOfPeople} alt="" />
                ) : waitListData?.status === 'rejected' ? (
                  <img src={peopleSetRejected} alt="" />
                ) : waitListData?.status === 'tracking' ? (
                  <img src={tracking_img} alt="" />
                ) : waitListData?.status === 'approved' && (IsStartup || startup) ? (
                  <img src={startup_approved_img} alt="" />
                ) : (waitListData?.status === 'approved' || user?.status?.status === 'approved') ? (
                  <img src={investor_approved_img} alt="" />
                ) : (
                  <img src={peopleSetRejected} alt="" />
                )}
              </Col>
              <Col xs={12} md={12} lg={8} className="right mt-0 pt-5">
                <div className="">

                  {/* =============================== Header Part Start=========================================== */}

                  {waitListData?.status === 'pending' || waitListData?.status === 'approved' || user?.status?.status === 'approved' ? (
                    <>
                      <img src={checkMark} alt="" className="checkMark" />

                      {(waitListData?.status === 'approved' || user?.status?.status === 'approved') ? (
                        <>
                          <h4 className="font-weight-normal mt-3">Congratulations!</h4>
                        </>
                      ) : (
                        <>
                          <h5 className="mt-3 thankyou-text">Thank you!</h5>
                          <p className="excited-text">We're excited to check out your application.</p>
                        </>
                      )}
                    </>
                  ) : waitListData?.status === 'tracking' ? (
                    <>
                      <img src={checkMark} alt="" className="checkMark" />
                      <p className="mt-3" style={{ fontSize: '22px' }}>
                        We are interested!
                      </p>
                    </>
                  ) : (IsStartup || startup) && waitListData?.remaining_days !== 0 ? (
                    <>
                      <div className="calenderImg">
                        <h2>{waitListData?.remaining_days}</h2>
                        <p>Days left</p>
                      </div>
                    </>
                  ) : (
                    <h4 className="mt-5 mb-5 pb-3 font-weight-normal">Thank you for applying!</h4>
                  )}

                  {/* =============================== Header Part Ends=========================================== */}


                  {/**
                   *                          Middle Section Below
                   */}


                  {/*========== When Startup is Pending =========== */}
                  {IsStartup && waitListData?.status === 'pending' && (
                    waitListData?.waitlist_pos <= 5 ? (
                      <div className="my-4 middleSet mx-auto">
                        You are position{' '}
                        <span class="badge badge-secondary text-white display-number mx-1">
                          {waitListData?.waitlist_pos}
                        </span>
                        in the waitlist. We are working on completing diligence on your startup. We will
                        make your application decision in{' '}
                        <span class="badge badge-secondary text-white display-number mx-1">
                          {waitListData?.response_period}
                        </span>{' '}
                        days
                      </div>
                    ) : (
                      <div className="my-4 middleSet mx-auto">
                        You are position
                        <span class="badge badge-secondary text-white mx-1">
                          {waitListData?.waitlist_pos}
                        </span>{' '}
                        in the waitlist. We are moving as fast as possible. Please wait for us to get back
                        to you.
                      </div>
                    )
                  )}

                  {/*========== When Startup is Tracking =========== */}
                  {IsStartup && waitListData?.status === 'tracking' && (
                    <div className="my-4 middleSet mx-auto">
                      <p className="tracking-details" style={{ lineHeight: '48px' }}>
                        We have reviewed your application and believe that you could be a good fit for us
                        and our community in the future. This means we are interested but just need to see a
                        bit more. You can share{' '}
                        <span className="tracking-monthly-update">monthly updates</span> on your progress
                        through our platform to keep us in sync.
                      </p>
                      <p className="font-weight-bold" style={{ color: '#7C8E54', fontSize: '18px' }}>
                        Click the button below to get started.
                      </p>

                      <Button
                        className="tracking-started"
                        onClick={() => {
                          dispatch(handleThankyouScreenViewed())
                          history.push('/tracking/monthly-update')
                        }}
                      >
                        Get Started
                      </Button>

                    </div>
                  )}

                  {/*========== When Startup is Approved =========== */}
                  {IsStartup && waitListData?.status === 'approved' && (
                    <div className="my-4 middleSet mx-auto">
                      <p>
                        We have reviewed your application and believe that you are a great fit for us and
                        our community. Welcome to Exitfund!
                      </p>
                      <p className="tracking-text approvedText">Click the button below to get started.</p>
                      <Button
                        className="tracking-started"
                        onClick={() => {
                          dispatch(handleThankyouScreenViewed())
                          history.push('/startup/approve/dashboard-status')
                        }}
                      >
                        Get Started
                      </Button>
                    </div>
                  )}

                  {/*========== When Startup is Rejected =========== */}
                  {IsStartup && waitListData?.status === 'rejected' &&

                    (waitListData?.remaining_days > 0 ? (
                      <>
                        <div className="my-4 middleSet mx-auto">
                          After much thought, we have decided that at this time{' '}
                          <span className="tracking-name">{waitListData?.startup_name}</span> is not a
                          good fit for us and our community. In case you believe we should reconsider{' '}
                          <span className="tracking-name">{waitListData?.startup_name}</span> in the
                          future, you are welcome to reapply in{' '}
                          {/* <span className="tracking-badge">{waitListData?.remaining_months}</span> months on{' '} */}
                          <span className="tracking-badge">{waitListData?.remaining_days}</span> days on{' '}
                          <span className="tracking-badge">
                            {moment(waitListData?.next_date).format('MM/DD/YYYY')}
                          </span>{' '}
                          using the button below.
                          <p className="tracking-text mt-4 mb-0">We wish you the best of luck!</p>
                        </div>
                        <Button className="my-4 btn-sm-block rejectedAppBtn" disabled>
                          Submit a New Application
                        </Button>
                      </>
                    ) : <>
                      <div className="my-4 middleSet mx-auto">
                        After much thought, we have decided that at this time you are not a good fit for
                        us and our community. In case you believe we should reconsider you in the
                        future, you are welcome to reapply now using the button below!
                        <p className="tracking-text mt-4 mb-0">We wish you the best of luck!</p>
                      </div>
                      <Button
                        className="my-4 btn-sm-block rejectedAppBtn new-application-btn"
                        onClick={() => {
                          onHandleNewApplication('startup')
                        }}
                      >
                        Submit a New Application
                      </Button>
                    </>)
                  }

                  {/*========== When Investor is Pending =========== */}
                  {!IsStartup && user?.status?.status === 'pending' && (
                    user?.waitlist?.position <= 5 ? (
                      <div className="my-4 middleSet mx-auto">
                        You are position{' '}
                        <span class="badge badge-secondary text-white display-number mx-1">
                          {user?.waitlist?.position}
                        </span>
                        in the waitlist. We are working on completing diligence on your investor. We will
                        make your application decision in{' '}
                        <span class="badge badge-secondary text-white display-number mx-1">
                          {user?.waitlist?.response_period}
                        </span>{' '}
                        days
                      </div>
                    ) : (
                      <div className="my-4 middleSet mx-auto">
                        You are position
                        <span class="badge badge-secondary text-white mx-1">
                          {user?.waitlist?.position}
                        </span>{' '}
                        in the waitlist. We are moving as fast as possible. Please wait for us to get back
                        to you.
                      </div>
                    )
                  )}


                  {/*========== When Investor is Approved =========== */}
                  {
                    !IsStartup && user?.status?.status == 'approved' && user.thankyou_screen_viewed === true ? (
                      <Redirect to={'/investor/deals'} />
                    )
                      : user?.status?.status == 'approved' &&
                      investor === true && (
                        <>
                          <div className="my-4 middleSet mx-auto">
                            <p>
                              We have reviewed your application and believe that you are a great fit as a part
                              of our investor community. Welcome to Exitfund!
                            </p>
                            <p className="tracking-text approvedText">
                              Click the button below to get started.
                            </p>
                            <Button
                              className="tracking-started"
                              onClick={() => {
                                dispatch(handleInvestorThankyouScreenViewed())
                                history.push('/investor/deals')
                              }}
                            >
                              Get Started
                            </Button>
                          </div>
                        </>
                      )
                  }

                  {/*========== When Investor is Rejected =========== */}
                  {!IsStartup && user?.status?.status === 'rejected' &&

                    (waitListData?.remaining_days > 0 ? (
                      <>
                        <div className="my-4 middleSet mx-auto">
                          After much thought, we have decided that at this time{' '}
                          <span className="tracking-name">{waitListData?.startup_name}</span> is not a
                          good fit for us and our community. In case you believe we should reconsider{' '}
                          <span className="tracking-name">{waitListData?.startup_name}</span> in the
                          future, you are welcome to reapply in{' '}
                          {/* <span className="tracking-badge">{waitListData?.remaining_months}</span> months on{' '} */}
                          <span className="tracking-badge">{waitListData?.remaining_days}</span> days on{' '}
                          <span className="tracking-badge">
                            {moment(waitListData?.next_date).format('MM/DD/YYYY')}
                          </span>{' '}
                          using the button below.
                          <p className="tracking-text mt-4 mb-0">We wish you the best of luck!</p>
                        </div>
                        <Button className="my-4 btn-sm-block rejectedAppBtn" disabled>
                          Submit a New Application
                        </Button>
                      </>
                    ) : <>
                      <div className="my-4 middleSet mx-auto">
                        After much thought, we have decided that at this time you are not a good fit for
                        us and our community. In case you believe we should reconsider you in the
                        future, you are welcome to reapply now using the button below!
                        <p className="tracking-text mt-4 mb-0">We wish you the best of luck!</p>
                      </div>
                      <Button
                        className="my-4 btn-sm-block rejectedAppBtn new-application-btn"
                        onClick={() => {
                          onHandleNewApplication('investor')
                        }}
                      >
                        Submit a New Application
                      </Button>
                    </>)
                  }

                  {/* =============================================== */}


                  {/**
                   *              Lower Section Below
                   */}

                  {
                    waitListData?.status === 'pending' && (
                      <div className="inviteTextBox">
                        <p className="invite-text">Meanwhile, invite your friends to sign up!</p>
                        <Form onFinish={handleEmailSubmit} form={form}>
                          <Input.Group compact>
                            <Input
                              onChange={e => {
                                setInviteEmail(e.target.value)
                                setInviteEmailErr('')
                              }}
                              // style={{ minWidth: '230px' }}
                              size="large"
                              placeholder="my.friend@gmail.com"
                              className="invite-email text-left "
                              value={inviteEmail}
                            />
                            <Button
                              type="submit"
                              size="large"
                              className="inviteBtn"
                              onClick={() => !loader && handleEmailSubmit()}
                            >
                              {loader ? (
                                <>
                                  <Spin className="inviteSpin mt-1 mr-2" /> Invite
                                </>
                              ) : (
                                'Invite'
                              )}
                            </Button>
                          </Input.Group>
                        </Form>
                        <p className="text-danger">{inviteEmailErr}</p>
                      </div>
                    )
                    // ))
                  }
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  )
}

export default withRouter(ThankYouPage)
