import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { connect } from "react-redux"

import "./MobileTabs.scss"
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { updateTab } from "../../redux/investorFlowControl/investorFlowControlActions"
import { updateStartupTab } from "../../redux/startupFlowControl/startupFlowControlActions"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Roboto",
    width: "100%",
  },
}))

const MobileTabs = (props) => {
  const {
    tabOne,
    tabTwo,
    tabThree,
    pageOne,
    pageTwo,
    pageThree,
    investor,
    updateTab,
    updateStartupTab,
    tabInvestor,
    tabStartup,
    startupUser,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const color = createMuiTheme({
    palette: {
      primary: {
        light: "#5e51f4",
        main: "#5e51f4",
        dark: "#002884",
        contrastText: "#fff",
      },
    },
  })
  const tab = investor ? tabInvestor : tabStartup

  const [value, setValue] = React.useState(tab || 0)

  const handleChange = (event, newValue) => {
    investor ? updateTab(newValue) : updateStartupTab(newValue)
    setValue(newValue)
    window.scrollTo(0, 0)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
    window.scrollTo(0, 0)
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={color}>
        <div
          className="text-justify d-block d-md-none"
          style={{ paddingTop: "120px" }}
        >
          <AppBar
            position="fixed"
            style={{ marginTop: "74px", zIndex: "2" }}
            color="white"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={tabOne} {...a11yProps(0)} />
              <Tab label={tabTwo} {...a11yProps(1)} />
              <Tab label={tabThree} {...a11yProps(2)} />
            </Tabs>
            {investor ? (
              null
              /*<div
                className={
                  "mx-auto w-100 p-2 profile-success"
                }
                style={{ textAlign: "center" }}
              >
                <>
                  <span className="font-weight-700">Profile complete!</span>
                  &nbsp; You can now access relevant startups in your Discover
                  portal.
                </>
              </div>
              */
            ) : (
              <div
                className={
                  startupUser.co_founders_check &&
                  startupUser.company_logo_check
                    ? "mx-auto w-100 p-2 profile-success"
                    : "mx-auto w-100 p-2 profile-fail"
                }
                style={{ textAlign: "center" }}
              >
                {startupUser.co_founders_check &&
                startupUser.profile_pic_link ? (
                  <>
                    <span className="font-weight-700">Profile complete!</span>
                    &nbsp; You can now access relevant investors in your
                    Discover portal.
                  </>
                ) : (
                  <>
                    <span className="font-weight-700">
                      Complete your profile to see investors in your Discover
                      portal. <br></br>
                    </span>{" "}
                    Required remaining:
                    {startupUser.profile_pic_link ? null : " Photo"}
                    {!startupUser.profile_pic_link &&
                    !startupUser.co_founders_check
                      ? ","
                      : null}
                    {startupUser.co_founders_check
                      ? null
                      : " Founding Team Info"}
                  </>
                )}
              </div>
            )}
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {pageOne}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {pageTwo}
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              {pageThree}
            </TabPanel>
          </SwipeableViews>
        </div>
      </ThemeProvider>
    </div>
  )
}

const mapStateToProps = (state) => ({
  investor: state.auth.investor,
  startupUser: state.auth.startupUser,
  tabInvestor: state.investorflowControl.tabInvestor,
  tabStartup: state.startupflowControl.tabStartup,
})

const mapDispatchToProps = (dispatch) => ({
  updateTab: (tab) => {
    dispatch(updateTab(tab))
  },
  updateStartupTab: (tab) => {
    dispatch(updateStartupTab(tab))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileTabs)
