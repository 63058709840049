import React from 'react'
import LocationCard from '../LocationCard/LocationCard'

import LOCATION_DATA from './LocationData'

import './LocationCardList.scss'

import { connect } from 'react-redux'

let locations = LOCATION_DATA

/**
 * LocationCardList component for displaying a list of location cards.
 *
 * @param {Object} props - Component props.
 * @param {string} props.searchField - Search field for filtering locations.
 * @param {string} props.scoutSearchField - Scout search field for filtering locations.
 * @param {boolean} props.startup - Indicates if the user is a startup.
 * @param {boolean} props.scout - Indicates if the user is a scout.
 * @returns {React.Component} - LocationCardList component.
 */
const LocationCardList = ({ searchField, scoutSearchField, startup, scout }) => {
  const filteredLocation = locations.filter(location => {
    if (scout) {
      return location.city.toLowerCase().includes(scoutSearchField)
    } else {
      return location.city.toLowerCase().includes(searchField)
    }
  })
  const cardComponent = filteredLocation.map((location, i) => {
    return <LocationCard key={i} city={location.city} state={location.state} LocationImage={location.image} />
  })

  return <div className="card-component text-center">{cardComponent}</div>
}

const mapStateToProps = state => ({
  searchField: state.investorflowControl.searchField,
  scoutSearchField: state.scoutflowControl.searchField,
  startup: state.auth.startup,
  scout: state.auth.scout,
})

export default connect(mapStateToProps, null)(LocationCardList)
