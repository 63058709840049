import React from 'react'
import './StartupPreviewPage.scss'
import { NavLink, withRouter } from 'react-router-dom'
import StartupCard from '../../../components/StartupCard/StartupCard'
import membericon from '../../../assets/images/FounderPhoto.png'
import startUpDefaultIcon from '../../../assets/images/FillerStartupIcon.png'
import websiteImage from '../../../assets/images/icon1.png'
import seemoreImage from '../../../assets/images/icon2.png'
import BottomNav from '../StartupDashboard/DashboardBottomNav'

import StartupViewSlideDeckModal from '../StartupProfilePage/StartupViewSlideDeckModal'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CardText, Card, Progress, Col, Row } from 'reactstrap'
import { formatCurrency } from '../../../utils/general'

import { fetchUserProfile } from '../../../redux/investorFlowControl/investorFlowControlActions'

class StartupPreviewPage extends React.Component {
  render() {
    const { user, setSlideDeckModal, setCoFounder } = this.props

    const cofounders = user.co_founders ? user.co_founders : []

    let sortedCofounders = []
    if (cofounders.length) {
      const ceo = cofounders.filter(c => c.position[0] === 'CEO' || c.position[0] === 'ceo')
      const cto = cofounders.filter(c => c.position[0] === 'CTO' || c.position[0] === 'cto')
      const coo = cofounders.filter(c => c.position[0] === 'COO' || c.position[0] === 'coo')
      const cfo = cofounders.filter(c => c.position[0] === 'CFO' || c.position[0] === 'cfo')
      const cmo = cofounders.filter(c => c.position[0] === 'CMO' || c.position[0] === 'cmo')
      const rest = cofounders.filter(
        c =>
          c.position[0] !== 'CMO' &&
          c.position[0] !== 'cmo' &&
          c.position[0] !== 'CEO' &&
          c.position[0] !== 'ceo' &&
          c.position[0] !== 'CTO' &&
          c.position[0] !== 'cto' &&
          c.position[0] !== 'COO' &&
          c.position[0] !== 'coo' &&
          c.position[0] !== 'CFO' &&
          c.position[0] !== 'cfo',
      )
      if (ceo.length) {
        sortedCofounders.push(ceo[0])
      }
      if (cto.length) {
        sortedCofounders.push(cto[0])
      }
      if (coo.length) {
        sortedCofounders.push(coo[0])
      }
      if (cfo.length) {
        sortedCofounders.push(cfo[0])
      }
      if (cmo.length) {
        sortedCofounders.push(cmo[0])
      }
      if (rest.length) {
        rest.forEach(r => sortedCofounders.push(r))
      }
    }

    if (cofounders.length !== sortedCofounders.length) {
      const rest = cofounders.filter(c => c.position.length === 0)
      sortedCofounders = sortedCofounders.concat(rest)
    }

    return (
      <div className="screen-preview">
        <div className="mobile-preview d-block d-md-none">
          <div style={{ backgroundColor: 'white', padding: '15px' }} className="d-block d-md-none">
            <div
              className="header investor-header"
              style={{
                position: 'fixed',
                // borderBottom: "1px solid lightGrey",
                boxShadow: '0 8px 9px -10px lightGrey',
                margin: '0',
                backgroundColor: 'white',
                zIndex: '10',
                top: '0',
                left: '0',
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  <NavLink className="logo" to="/startup/profile">
                    <i className="fa fa-chevron-left"></i>
                  </NavLink>
                  <span
                    className="text-uppercase"
                    style={{
                      fontSize: '22px',
                      fontFamily: 'Roboto',
                      color: '#162642',
                    }}
                  >
                    Preview
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div
            className="dashboard-preview-container-mobile d-block d-md-none mobile-preview-header"
            style={{
              textAlign: 'center',
              color: 'white',
              backgroundColor: '#162642',
              padding: '12.5px 0',
              position: 'fixed',
              zIndex: '10',
              marginTop: '45px',
            }}
          >
            This is what investors will see when they view your profile.
          </div>

          <div className="d-block d-md-none" style={{ animation: `fadeIn 2s` }}>
            <div>
              <Card
                body
                style={{
                  padding: '20px',
                  margin: '0px 15px 100px',
                  border: '1px solid #DEDEDF',
                  borderRadius: '10px',
                  position: 'relative',
                  top: '100px',
                }}
              >
                <Col>
                  {/* <Row sm={12} style={{ display: "block", margin: "auto" }}> */}{' '}
                  <img
                    className="mobile-prof-pic"
                    src={
                      user && user.profile_pic_link && user.profile_pic_link !== 'None'
                        ? user.profile_pic_link
                        : startUpDefaultIcon
                    }
                    alt=""
                    id="avataImg"
                    shape="circle"
                  />
                  <div className="mobile-website">
                    {user.company_link ? (
                      <a href={user.company_link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '25px' }} src={websiteImage} alt="company site" />
                      </a>
                    ) : null}
                  </div>
                  {/* <Col sm={9} className="mobile-preview-right"> */}
                  <div
                    className="d-flex flex-dir-col pl-2 mobile-name-location"
                    style={{ marginTop: '-15px' }}
                  >
                    {' '}
                    {/* <div className="d-flex justify-content-between"> */}
                    <h3 className="font-20 purple-colored pt-3 mb-0 font-weight-900 pr-5 mr-3">
                      {user.company_name}
                    </h3>
                    {/* </div> */}
                    <h5 className="mobile-location">{user.location}</h5>
                  </div>
                  <div className="mobile-card-sectors" style={{ minWidth: '250px' }}>
                    {' '}
                    {user.sectors.map(sector => (
                      <span
                        style={{
                          border: '1px solid #162642',
                          borderRadius: '50px',
                          color: '#162642',
                        }}
                        className="py-1 px-2 mr-1 mb-1"
                      >
                        {sector}
                      </span>
                    ))}
                  </div>
                  <Row className="mobile-row pt-4 mt-1">
                    <div className="mobile-info">{user.startup_pitch}</div>
                  </Row>
                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600">
                      {' '}
                      Seeking {/* <span className="gold-colored"> */}
                      {user.round_size ? formatCurrency(user.round_size) : ''}
                      {/* </span> */}
                    </h5>
                    <div className="mobile-info-no-margin py-0 my-0">
                      {' '}
                      <Progress value={user.raised} max={user.round_size} color="warning" className="m-0">
                        <div
                          style={{
                            color: 'black',
                            position: 'absolute',
                            zIndex: '0',
                            fontFamily: 'Roboto',
                            width: '100%',
                            fontSize: '15px',
                          }}
                          className="d-flex justify-content-between"
                        >
                          <div className="ml-3">
                            Raised {user.raised ? formatCurrency(user.raised) : '$0'}
                          </div>
                          <div className="mr-3">{Math.round((user.raised * 100) / user.round_size)}%</div>
                        </div>
                      </Progress>
                    </div>
                  </Row>
                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600">Founding Team</h5>
                    <div className="mobile-info-no-margin">
                      {sortedCofounders &&
                        sortedCofounders.map(cofounder => (
                          <div
                            style={{
                              minWidth: '180px',
                              height: '60px',
                            }}
                            className="mobile-founders py-1 px-2 d-flex justify-content-between w-100"
                            onClick={() => {
                              setCoFounder(cofounder)
                              this.props.history.push('/startup/cofounder')
                            }}
                          >
                            <div>
                              <div style={{ height: '55px', width: '55px' }}>
                                <img
                                  src={cofounder.profile_image ? cofounder.profile_image : membericon}
                                  alt=""
                                  style={{
                                    height: '55px',
                                    width: '55px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    marginTop: '0',
                                    paddingTop: '0',
                                  }}
                                />
                              </div>
                              <div className="mobile-founder">
                                <div className="pt-2 purple-colored font-weight-600">{cofounder.name}</div>
                                <div
                                  className="preview-team-position-m"
                                  style={{
                                    textTransform:
                                      cofounder.position[0] === 'CMO' ||
                                      cofounder.position[0] === 'cmo' ||
                                      cofounder.position[0] === 'COO' ||
                                      cofounder.position[0] === 'coo' ||
                                      cofounder.position[0] === 'CFO' ||
                                      cofounder.position[0] === 'cfo' ||
                                      cofounder.position[0] === 'CEO' ||
                                      cofounder.position[0] === 'ceo' ||
                                      cofounder.position[0] === 'CTO' ||
                                      cofounder.position[0] === 'cto'
                                        ? 'uppercase'
                                        : 'capitalize',
                                  }}
                                >
                                  {cofounder.position[0]}
                                </div>
                              </div>
                            </div>
                            <div>
                              <img
                                src={seemoreImage}
                                alt=""
                                style={{
                                  width: '25px',
                                  position: 'relative',
                                  top: '0px',
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </Row>
                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600">Traction </h5>
                    <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                      {user.progress.map(progress => (
                        <span
                          className="mobile-list"
                          style={{
                            border: 'none',
                            backgroundColor: '#F2F2FF',
                          }}
                        >
                          {progress}
                        </span>
                      ))}
                    </CardText>
                  </Row>
                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600">Slide Deck</h5>
                    {user.slide_deck ? (
                      <div
                        style={{
                          border: '1px solid #162642',
                          color: '#162642',
                          display: 'block',
                          margin: 'auto',
                          width: '100%',
                          borderRadius: '5px',
                          textAlign: 'center',
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                        }}
                        className="py-3 px-3 text-nowrap font-weight-900"
                        onClick={() => setSlideDeckModal(true)}
                      >
                        View Slide Deck
                      </div>
                    ) : (
                      <div className="mobile-info-no-margin" style={{ color: '#b5b5b5' }}>
                        {' '}
                        No Uploaded Slide Deck
                      </div>
                    )}
                  </Row>
                </Col>
              </Card>
              <div className="push" />
              <div className="push" />

              <div style={{ position: 'fixed', bottom: '60px', width: '100%' }} className="bottom m-auto">
                <button
                  style={{ cursor: 'default' }}
                  className="mobile-pass mobile-edit mr-1 ml-3 font-16 font-weight-900"
                >
                  Pass
                </button>
                <button
                  style={{ cursor: 'default' }}
                  className="mobile-pass mobile-save ml-1 mr-3 font-16 font-weight-900"
                >
                  Invite
                </button>

                <BottomNav />
              </div>
            </div>
          </div>
        </div>

        <div className="investor-modal-preview d-none d-md-block">
          <div className="close-container-preview d-none d-md-block" style={{ animation: `fadeIn 1s` }}>
            <Link className="link-preview" to="/startup/profile">
              Close
            </Link>
          </div>

          <div
            className="dashboard-container-preview-1 purple-colored font-weight-600 p-3 d-none d-md-block"
            style={{ textAlign: 'center', animation: `fadeIn 1s` }}
          >
            This is what investors will see when they view your profile.
          </div>
          <div className="d-none d-md-block" style={{ animation: `fadeIn 1s` }}>
            <div className="dashboard-container-preview d-flex flex-column justify-content-between">
              <StartupCard
                user={user}
                seeMoreClick={() => this.props.history.push('/startup/seemore')}
                openSlideDeck={setSlideDeckModal}
              />
              <div className="bottom mt-1">
                <button style={{ cursor: 'default' }} className="button-left">
                  Pass
                </button>
                <button style={{ cursor: 'default' }} className="button-right">
                  Invite
                </button>
              </div>
            </div>
          </div>
        </div>
        <StartupViewSlideDeckModal />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
  userInfos: state.investorflowControl.userInfos,
})

const mapDispatchToProps = dispatch => ({
  fetchUserProfile: offset => {
    return dispatch(fetchUserProfile(offset))
  },
  setSlideDeckModal: status => {
    dispatch({ type: 'SET_SLIDE_DECK_MODAL', payload: true })
  },
  setCoFounder: cf => {
    dispatch({ type: 'SET_SELECTED_STR_COFOUNDER', payload: cf })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StartupPreviewPage))
