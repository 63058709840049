import React from "react"
import { connect } from "react-redux"
import { Container } from "reactstrap"

import Switch from "../../../components/Switch/Switch"
import { displayProfile } from "../../../redux/startupFlowControl/startupFlowControlActions"

class StartupPrivacyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value3: null,
    }
  }

  componentDidMount() {
    let { user } = this.props
    this.setState({
      value3: user.show_profile,
    })
  }

  handleClick3 = () => {
    this.setState({ value3: !this.state.value3 })
    this.props.displayProfile({ visible: !this.state.value3 })
  }

  render() {
    const { value3 } = this.state
    return (
      <Container
        className="settings-mobile-container"
        style={{ animation: `fadeIn 1s` }}
      >
        <div className="settings-mobile-header">Connections</div>
        <hr></hr>
        <div className="settings-mobile-option">
          <div className="justify-content-start">
            Allow my profile to be visible in Discover
          </div>
          <div>
            <Switch
              isOn={value3}
              handleToggle={this.handleClick3.bind(this)}
              inputId="testId3"
              disabled={
                !(
                  this.props.user.co_founders_check &&
                  this.props.user.company_logo_check
                )
              }
            />
          </div>
        </div>
        <hr></hr>
        {this.props.user.co_founders_check &&
        this.props.user.company_logo_check ? null : (
          <div className="font-roboto investor-incomplete px-2 text-left">
            <span className="font-weight-500">
              You can't make your profile visible until you{" "}
            </span>
            <span className="font-weight-700">
              complete your info in Profile!
            </span>
          </div>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
})

const mapDispatchToProps = (dispatch) => ({
  displayProfile: (display_profile) => {
    dispatch(displayProfile(display_profile))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(StartupPrivacyPage)
