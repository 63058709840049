import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Input, Button as AntButton } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateLocalScoutUser,
  fetchScoutUserInfoUpdate,
  fetchScoutUserLogout,
} from '../../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../../redux/scoutFlowControl/scoutFlowControlActions'
import { withRouter } from 'react-router-dom'
import './Step05ShortBio.scss'

function Step05ShortBio({ history }) {
  const { TextArea } = Input
  const { scoutUser, loading } = useSelector(state => state.auth)
  const { saveAndExitClicked } = useSelector(state => state.scoutflowControl)
  const dispatch = useDispatch()
  const isEnabled = scoutUser?.bio?.length > 0
  const [remainingLimit, setRemainingLimit] = useState(200)

  /**
   * Handles the change event of the TextArea for short bio input.
   *
   * @param {string} value - The new value of the TextArea.
   * @description -  Updates the remaining character limit and dispatches the updated bio to the Redux store.
   */
  const onChange = value => {
    setRemainingLimit(200 - value.length)
    dispatch(updateLocalScoutUser({ bio: value }))
  }

  /**
   * Handles the click event when moving to the next step or going back.
   *
   * @param {boolean} isNextOrBack - Indicates whether the user is moving to the next step (true) or going back (false).
   * @description - Dispatches an action to update user information and navigate accordingly.
   */
  const scoutOnClick = isNextOrBack => {
    dispatch(fetchScoutUserInfoUpdate({ bio: scoutUser?.bio, onboarding_step: isEnabled ? 5 : 4 }))
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            history.push('/scout/congratulations')
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          // history.push('/investor/login')
          window.location.href = '/scout/login'
          dispatch(fetchScoutUserLogout())
        }
      })
  }

  // Handles the click event when moving to the next step.
  const scoutNextOnClick = e => {
    scoutOnClick(true)
  }

  /**
   * Handle the case when the save and exit button is clicked.
   * @description - If saveAndExitClicked is true, it invokes scoutOnClick with isNextOrBack set to false and resets the saveAndExitClicked state.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      scoutOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  return (
    <div className="short-bio-step-wrap">
      {/*** Heading is here ***/}
      <Container className="mb-0">
        <Row>
          <Col md={7} className="mx-auto">
            <h4 className="heading-question">
              Share a bit of <b className="bold">background.</b>
            </h4>
            <p className="heading-question-small">Your Short Bio (1-2 sentences)</p>
          </Col>
        </Row>
      </Container>

      {/*** Body Content is here ***/}
      <Container className="">
        <Row>
          <Col md={7} className="mx-auto">
            <div className="w-100 short-bio-textarea">
              <TextArea
                rows={4}
                showCount
                maxLength={200}
                value={scoutUser.bio}
                onChange={e => onChange(e.target.value)}
                className="short-bio-textarea"
                placeholder="I work at Google as a software engineer and have many friends and colleagues who are working on interesting startups, who will eventually be seeking capital."
              />
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md={7} className="mx-auto">
            <p
              className={
                remainingLimit === 0 ? 'text-danger text-right text-secondary' : 'text-right text-secondary'
              }
            >
              {remainingLimit}/200
            </p>
          </Col>
        </Row> */}
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <AntButton
                loading={loading}
                disabled={!isEnabled}
                onClick={scoutNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Finished!
              </AntButton>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default withRouter(Step05ShortBio)
