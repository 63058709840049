import React, { Component, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
// import { toggleStartup } from '../../redux/auth/authActions'

import LoginForm1 from './LoginForm1'

// Functional component for the Startup login page.
const LoginStartup = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    // Dispatches an action to toggle the startup button.
    dispatch({
      type: 'TOGGLE_STARTUP_BUTTON',
    })
  }, [])
  return <LoginForm1 pageType={'startup'} />
}

export default LoginStartup
