import React, { lazy } from 'react'
//Investor portal

export const InvestorPassedHistory = lazy(() =>
  import('../pages/Investor/InvestorDeals/InvestorPassedHistory'),
)
export const InvestorAnotherInvestment = lazy(() =>
  import('../pages/Investor/InvestorDeals/AnotherInvestment'),
)
export const InvestorEmailNotification = lazy(() =>
  import('../pages/Investor/InvestorSetting/InvestorEmailNotification'),
)
export const InvestorSettingTaxes = lazy(() =>
  import('../pages/Investor/InvestorSetting/InvestorSettingTaxes'),
)
export const InvestorKyc = lazy(() => import('../pages/Investor/InvestorSetting/InvestorKyc'))
export const CompleteKyc = lazy(() => import('../pages/Investor/InvestorDeals/CompleteKyc'))
export const BankingDocVerification = lazy(() =>
  import('../pages/Investor/InvestorSetting/Banking/BankingProfileInformation/BankingDocVerification'),
)
export const BankingProfileInfo = lazy(() =>
  import('../pages/Investor/InvestorSetting/Banking/BankingProfileInformation/BankingProfileInfo'),
)
export const EnterEmail = lazy(() => import('../pages/Investor/InvestorSetting/Banking/EnterEmail'))
export const InvestorChangePassword = lazy(() =>
  import('../pages/Investor/InvestorSetting/InvestorChangePassword'),
)
export const SignUpInvestor = React.lazy(() => import('../pages/Investor/SignUpInvestor/SignUpInvestor'))

export const InvestorAlreadyDeletePage = React.lazy(() =>
  import('../pages/Investor/InvestorDeletePage/InvestorAlreadyDeletePage'),
)
export const InvestorFirstInvitePage = React.lazy(() =>
  import('../pages/Investor/InvestorWelcomeBackPage/InvestorFirstInvitePage'),
)
export const InvestorSecondInvitePage = React.lazy(() =>
  import('../pages/Investor/InvestorWelcomeBackPage/InvestorSecondInvitePage'),
)
export const InvestorAfterInvitePage = React.lazy(() =>
  import('../pages/Investor/InvestorWelcomeBackPage/InvestorAfterInvitePage'),
)
export const InvestorProfile = React.lazy(() => import('../pages/Investor/InvestorProfile/InvestorProfile'))
export const InvestorDealDetail = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/InvestorDealDetail'),
)
export const MonthlyUpdates = React.lazy(() => import('../pages/Investor/InvestorDeals/MonthlyUpdates'))
export const MonthlyUpdatesAnalytics = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/MonthlyUpdatesAnalytics'),
)

export const PassDeal = React.lazy(() => import('../pages/Investor/InvestorDeals/PassDeal'))
export const PublicQAForum = React.lazy(() => import('../pages/Investor/InvestorDeals/PublicQAForum'))
export const ReviewMemo = React.lazy(() => import('../pages/Investor/InvestorDeals/ReviewMemo'))
/**
 * When redirecting to old code
 * export const InvestorInvestingSteps = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/InvestorInvestingSteps/InvestorInvestingSteps'),
  )
 */

export const InvestorInvestingSteps = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/InvestorInvestingStepsFlow/InvestorInvestingStepsFlow'),
)
export const InvestorDealDwolla = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/InvestorDealDwollaFlow/InvestorDealDwollaFlow'),
)

export const InvestorHistory = React.lazy(() => import('../pages/Investor/InvestorDeals/InvestorHistory'))
export const InvestorTaxes = lazy(() => import('../pages/Investor/InvestorSetting/InvestorTaxes'))
export const InvestorSetting = React.lazy(() =>
  import('../pages/Investor/InvestorSetting/InvestorBankingSetting'),
)

export const InvestorTour = React.lazy(() => import('../pages/Investor/InvestorTour/InvestorTour'))
export const InvestorTourHistory = React.lazy(() =>
  import('../pages/Investor/InvestorTour/InvestorTourHistory'),
)
export const InvestorTourKarma = React.lazy(() => import('../pages/Investor/InvestorTour/InvestorTourKarma'))
export const InvestorTourProfile = React.lazy(() =>
  import('../pages/Investor/InvestorTour/InvestorTourProfile'),
)
export const InvestorNotifications = React.lazy(() =>
  import('../pages/Investor/InvestorNotifications/InvestorNotifications'),
)
export const InvestorDeals = React.lazy(() => import('../pages/Investor/InvestorDeals/InvestorDeals'))
export const InvestorStepPage = React.lazy(() =>
  import('../pages/Investor/InvestorStepPage/InvestorStepPage'),
)
export const IncreaseInvestment = React.lazy(() =>
  import('../pages/Investor/InvestorDeals/IncreaseInvestment'),
)

export const Step12AccreditionStatus = React.lazy(() =>
  import('../pages/InvestorOnboarding/Step12AccreditionStatus/Step12AccreditionStatus'),
)

export const LoginInvestor = React.lazy(() => import('../components/LoginForm/LoginInvestor'))
