import ai from '../../assets/images/on_boarding_imgs/sectors/Artificial_Intelligence.svg'
import healthcare from '../../assets/images/on_boarding_imgs/sectors/Healthcare.svg'
import marketplace from '../../assets/images/on_boarding_imgs//sectors/Marketplace.svg'
import enterprise from '../../assets/images/on_boarding_imgs/sectors/Enterprise.svg'
import education from '../../assets/images/on_boarding_imgs/sectors/Education.svg'

// List of the Trending card data
const TRENDING_SECTOR_DATA = [
  {
    id: 0,
    title: 'Artificial Intelligence',
    image: ai,
  },
  {
    id: 1,
    title: 'Healthcare',
    image: healthcare,
  },
  {
    id: 2,
    title: 'Marketplace',
    image: marketplace,
  },
  {
    id: 3,
    title: 'Enterprise',
    image: enterprise,
  },
  {
    id: 4,
    title: 'Education',
    image: education,
  },
]
export default TRENDING_SECTOR_DATA
