import React, { useEffect, useState } from 'react'
import { Input, message } from 'antd'
import { AimOutlined, SearchOutlined } from '@ant-design/icons'
import AddIcon from '../../assets/images/Icons/AddIcon'

/**
 * Represents a component for handling location input and autocomplete.
 * @component
 * @param {Object} props - The properties of the component.
 * @param {string} props.location - The current location value.
 * @param {function} props.setLocation - Callback to set the location value.
 * @param {boolean} props.multipleLocations - Indicates if multiple locations are allowed.
 * @param {function} props.onAddLocation - Callback triggered when adding a location.
 * @param {Array} props.locationList - List of already added locations.
 * @param {function} props.setAbleToAdd - Callback to set the ability to add a location.
 * @param {boolean} props.ableToAdd - Indicates whether it's possible to add a location.
 * @returns {JSX.Element} - The rendered component.
 */
const Location = props => {
  const {
    location,
    setLocation,
    multipleLocations,
    onAddLocation,
    locationList,
    setAbleToAdd,
    ableToAdd,
  } = props
  let autoComplete = null
  let autoComplete1 = null
  const [locationText, setLocationText] = useState()

  // Set the location text when the location changes.
  useEffect(() => {
    setLocationText(location)
  }, [location])

  /**
   * Initialize the Google Places Autocomplete.
   * If multipleLocations is false, it initializes autoComplete for a single location. Otherwise, it initializes autoComplete1 for the multipleLocations location.
   */
  useEffect(() => {
    /*global google*/
    if (!multipleLocations) {
      autoComplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['(cities)'],
      })
      autoComplete.addListener('place_changed', handlePlaceSelect)
    } else {
      autoComplete1 = new google.maps.places.Autocomplete(document.getElementById('autocomplete1'), {
        types: ['(cities)'],
      })
      autoComplete1.addListener('place_changed', handlePlaceSelect1)
    }
  }, [])

  //  Handles the selection of a place for the first location.
  const handlePlaceSelect = () => {
    const addressObject = autoComplete.getPlace()
    if (addressObject.formatted_address) {
      setLocation && setLocation(addressObject.formatted_address)
      if (setAbleToAdd && ableToAdd && addressObject) {
        setAbleToAdd(true)
      }
    } else {
      message.error('Enter a valid location.')
    }
  }

  // Handles the selection of a place for the multipleLocations location.
  const handlePlaceSelect1 = () => {
    const addressObject = autoComplete1.getPlace()
    if (setAbleToAdd && ableToAdd && addressObject) {
      setAbleToAdd(true)
    }
    setLocation && setLocation(addressObject.formatted_address)
  }

  // Handles the addition of a location.
  const handleAddLocation = () => {
    if (ableToAdd) {
      if (locationList?.includes(location)) {
        message.error('You have already added this location.')
        return
      }
      setAbleToAdd(false)
      // onAddLocation()
    } else {
      message.error('Enter a valid location.')
    }
  }

  return (
    <div className="location-container">
      {!multipleLocations ? (
        <>
          <Input
            id="autocomplete"
            size="large"
            onChange={e => {
              if (ableToAdd) {
                setAbleToAdd(false)
              }
              setLocationText(e.target.value?.trimStart())
            }}
            placeholder="Enter your city, state"
            value={locationText}
            className=" inputs-box"
            onKeyPress={event => {
              if (event.key === 'Enter') {
                ableToAdd && handleAddLocation()
              }
            }}
          />
          {/* <div className="locationAddIcon" onClick={() => handleAddLocation()}>
            <AddIcon />
          </div> */}
        </>
      ) : (
        <Input
          id="autocomplete1"
          onChange={e => {
            setLocation(e.target.value?.trimStart())
            if (ableToAdd) {
              setAbleToAdd(false)
            }
          }}
          placeholder="Location"
          value={locationText}
          className=" inputs-box"
        />
      )}
    </div>
  )
}

export default Location
