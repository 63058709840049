import React, { Component } from "react";

class AngelCommunityPage extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = "https://fs4.formsite.com/include/form/embedManager.js?442040376";

    script.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      EmbedManager.embed({
        key: "https://fs4.formsite.com/res/showFormEmbed?EParam=PyBo85CFjhFr4VKn-OOuZa6GiyI3BUqI&442040376",
        width: "100%"
      });
    });

    document.body.appendChild(script);
  }

  render() {
    return (
      <div>
        <div name="form442040376" id="formAnchor442040376"></div>
      </div>
    );
  }
}

export default AngelCommunityPage;
