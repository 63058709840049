import React, { Component } from 'react'
import './Section.scss'
import twitterIcon from '../../assets/images/footer/twitterIcon.svg'
import linkedinIcon from '../../assets/images/footer/linkedinIcon.svg'
import instagramIcon from '../../assets/images/footer/instagramIcon.svg'
import facebookIcon from '../../assets/images/footer/facebookIcon.svg'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'

// Footer of the main home page.
export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row className="justify-content-center socialIcons">
            <Col xs="auto">
              <a target="_blank" href="https://twitter.com/exitfundvc">
                <img src={twitterIcon} alt="twitter" height="auto" width="auto" />
              </a>
            </Col>
            <Col xs="auto">
              <a target="_blank" href="https://www.linkedin.com/company/exitfund">
                <img src={linkedinIcon} alt="twitter" height="auto" width="auto" />
              </a>
            </Col>
            <Col xs="auto">
              <a
                target="_blank"
                href="https://www.instagram.com/exitfundventure/
"
              >
                <img src={instagramIcon} alt="twitter" height="auto" width="auto" />
              </a>
            </Col>
            <Col xs="auto">
              <a target="_blank" href="https://www.facebook.com/exitfundvc">
                <img src={facebookIcon} alt="twitter" height="auto" width="auto" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <h4 className="mt-1 mb-0">
                <a href="mailto:hello@exitfund.com">hello@exitfund.com</a>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link className="" to="/termsofservice">
                Terms of Service
              </Link>
              <Link className="" to="/policy">
                Privacy Policy
              </Link>
              <Link className="" to="/">
                ©{moment().format('YYYY')} Exitfund
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}
