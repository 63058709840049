import React, { useEffect } from 'react'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { Input, Spin, message } from 'antd'
import {
  updateLocalStartupUser,
  updateLocalInvestorUser,
  fetchStartupUserInfoUpdate,
  fetchInvestorUserInfoUpdate,
  fetchStartupUserLogout,
  fetchInvestorUserLogout,
  fetchInvestorInviteCode,
} from '../../../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../../../redux/startupFlowControl/startupFlowControlActions'

import { connect, useDispatch, useSelector } from 'react-redux'
import { ArrowRightOutlined, ContainerOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import './InviteCode.scss'

// import '../InvestorOnboarding.scss'

const InviteCode = ({
  updateLocalStartupUser,
  updateLocalInvestorUser,
  startupUser,
  onCountinueClick,
  startup,
  fetchStartupUserInfoUpdate,
  verifyInviteCode,
  investor,
  investorUser,
  fetchInvestorUserInfoUpdate,
  fetchInvestorInviteCode,
  inviteCodeInvestor
}) => {
  const [inviteCode, setInviteCode] = React.useState('')
  const [clicked, setClicked] = React.useState(false)
  const [error, setError] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const history = useHistory()
  const { saveAndExitClicked } = useSelector(state => state.startupflowControl)
  console.log({ inviteCodeInvestor })
  const dispatch = useDispatch()

  /**
   * Update the user status to 'pending' and navigate to the next step.
   * @description - If the update is successful, navigate to the '/startup/thankyou' page for startups. If it's an investor, invoke the onCountinueClick function.
   */
  const updateStatus = () => {
    setLoader(true)
    if (startup) {
      fetchStartupUserInfoUpdate({
        status: 'pending',
        onboarding_step: 9,
      }).then(success => {
        setLoader(false)
        if (success) {
          history.push('/startup/thankyou')
        } else {
          setError('Please try again.')
          // alert('invalid invite code')
        }
      })
    } else {
      fetchInvestorUserInfoUpdate({
        onboarding_step: 11,
      }).then(success => {
        setLoader(false)
        if (success) {
          onCountinueClick()
        } else {
          setError('Please try again.')
        }
      })
    }
  }

  /**
   * Verify the invite code and update the local user data.
   * @description - If it's a startup, navigate to the '/startup/thankyou' page on success. If it's an investor, invoke the onCountinueClick function on success.
   */
  const verify = e => {
    setClicked(true)
    setLoader(true)
    if (startup) {
      updateLocalStartupUser({ invite_code: inviteCode })
      fetchStartupUserInfoUpdate({
        invite_code: inviteCode,
        onboarding_step: 9,
        status: 'pending',
      }).then(success => {
        setLoader(false)
        if (success) {
          history.push('/startup/thankyou')
        } else {
          message.error('The invite code you entered is not valid. Please try again.')
          setError('The invite code you entered is not valid. Please try again.')
          // alert('invalid invite code')
        }
      })
    } else {
      updateLocalInvestorUser({ invite_code: inviteCode })
      fetchInvestorInviteCode({
        invite_code: inviteCode,
        onboarding_step: 11,
        // status: 'pending',
      })
        .then(success => {
          console.log({ success })
          setLoader(false)

          if (success.result) {
            onCountinueClick()
            // history.push('/investor/thankyou')
          } else {
            updateLocalInvestorUser({ invite_code: null })
            message.error(success.error)
            setInviteCode('')
            setError('The invite code you entered is not valid. Please try again.')
            // alert('invalid invite code')
          }
        })
        .then(() => {
          if (saveAndExitClicked) {
            localStorage.clear()
            window.location.href = '/investor/login'
            dispatch(fetchStartupUserLogout())
          }
        })
    }
  }

  /**
   * Verify the invite code and update the local user data.
   * @description - If it's a startup, update the user and logout on successful saveAndExitClicked. If it's an investor, update the user and logout on successful saveAndExitClicked.
   */
  const verifySaveAndLogout = () => {
    if (startup) {
      updateLocalStartupUser({ invite_code: inviteCode })
      fetchStartupUserInfoUpdate({
        invite_code: inviteCode,
        onboarding_step: 9,
      }).then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
    } else {
      updateLocalInvestorUser({ invite_code: inviteCode })
      fetchInvestorUserInfoUpdate({
        invite_code: inviteCode,
        onboarding_step: 11,
      }).then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/investor/login'
          dispatch(fetchInvestorUserLogout())
        }
      })
    }
  }

  // const setInviteValue = e => {
  //   setInviteCode(e.target.value)
  // }

  /**
   * useEffect to handle cleanup actions when saveAndExitClicked is true.
   * @description - Invoke the startupOnClick function with appropriate parameters and reset the saveAndExitClicked state.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      verifySaveAndLogout()
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  useEffect(() => {
    if (!startup) {
      if (inviteCodeInvestor !== null) {
        setInviteCode(inviteCodeInvestor)
      } else {
        setInviteCode('')
      }
    }

  }, [])

  return (
    <div className="invite-code-wrapper">
      <Container className="mb-0 mt-5">
        <Row>
          <Col lg={7} md={8} className="mx-auto mb-4">
            <h4 className="heading-question">
              Input your <span className="font-weight-bold">invite code </span> if you have one.
            </h4>
            <p className="heading-question-small mt-2">
              If not, please click {startup ? 'finished.' : 'continue.'}{' '}
            </p>
          </Col>
        </Row>
      </Container>

      {/*** Body Content is here ***/}
      <Container className="InviteCode mb-5">
        <Row>
          <Col lg={7} md={8} className="mx-auto">
            <div className="InnerSet" style={{ padding: 0, maxWidth: 620 }}>
              {startup ?
                <Input
                  size="large"
                  placeholder="ABCD0587"
                  onChange={e => {
                    setInviteCode(e.target.value)
                    setError('')
                  }}
                  value={inviteCode}
                  className={error == '' ? '' : 'error-inpt'}
                />
                :
                <Input
                  size="large"
                  placeholder="ABCD0587"
                  onChange={e => {
                    setInviteCode(e.target.value)
                    setError('')
                  }}
                  value={inviteCode}
                  className={error == '' ? '' : 'error-inpt'}
                  disabled={inviteCodeInvestor !== null ? true : false}
                />
              }

              <p className="text-danger">
                {error && <InfoCircleOutlined />} {error}
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                className="continueBtnOnboard btn-block"
                type="primary"
                disabled={error !== ''}
                onClick={() => {
                  if (inviteCode.trim()) {
                    !loader && verify()
                  } else {
                    !loader && updateStatus()
                  }
                }}
              >
                {' '}
                {startup ? (
                  <>
                    <Spin spinning={loader} className="inviteSpinner mr-3" />
                    Finished!
                  </>
                ) : (
                  <>
                    <Spin spinning={loader} className="inviteSpinner mr-3" />
                    Continue <ArrowRightOutlined />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  updateUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
  tags: state.auth.startupUser.tags,
  startup: state.auth.startup,
  investor: state.auth.investor,
  investorUser: state.auth.user,
})
const mapDispatchToProps = dispatch => ({
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },
  fetchInvestorInviteCode: changedProperties => {
    return dispatch(fetchInvestorInviteCode(changedProperties))
  },
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteCode)
