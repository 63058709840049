import React, { useEffect, useState } from 'react'

import './MilestonesPage.scss'

import { Button, Col, Container, Row } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'

import MILESTONES_DATA from './MilestonesData'

import { prevStepStartup, nextStepStartup } from '../../../redux/startupFlowControl/startupFlowControlActions'

import {
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  fetchStartupUserLogout,
  updateLocalInvestorUser,
} from '../../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../../redux/startupFlowControl/startupFlowControlActions'

import MileStonesCardList from '../../../components/MileStonesCardList/MileStonesCardList'
import { Steps, Progress } from 'antd'
import 'antd/dist/antd.css'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import '../../../components/MileStonesCardList/MileStoneCardList.scss'
const MilestonesPage = ({
  onCountinueClick,
  stepStartup,
  progress,
  updateUserInfoFailed,
  prevStepStartup,
  nextStepStartup,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  startupUser,
  startup,
  saveAndExitClicked,
}) => {
  const progresses = startupUser.progress
  const dispatch = useDispatch()

  const progressCopy = progresses.slice()

  const [maxMilestonesSelectedErr, setMaxMilestonesSelectedErr] = useState('')

  /**
   * Handle checkbox change event.
   * @param {Object} e - The event object.
   */
  const handleCheckBoxChange = e => {
    if (e.target.checked) {
      if (!progresses.includes(e.target.name)) {
        progressCopy.push(e.target.name)
      }
    } else {
      if (progresses.includes(e.target.name)) {
        const index = progressCopy.findIndex(element => element === e.target.name)
        progressCopy.splice(index, 1)
      }
    }
    updateLocalStartupUser({ progress: progressCopy })
  }

  /**
   * Handle card change event.
   * @param {Object} e - The event object.
   * @param {Array} progresses - The array of progresses.
   */
  const handleCardChange = (e, progresses) => {
    let x = e.target.id
    if (!progresses.includes(x)) {
      progressCopy.push(x)
    } else {
      if (progresses.includes(x)) {
        const index = progressCopy.findIndex(element => element === x)
        progressCopy.splice(index, 1)
      }
    }
    if (startup) {
      updateLocalStartupUser({ progress: progressCopy })
    }
  }

  /**
   * Handle startup on-click function.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   */
  const startupOnClick = isNextOrBack => {
    fetchStartupUserInfoUpdate({ progress, onboarding_step: isEnabled ? 5 : 4 })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          } else {
            prevStepStartup()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }

  /**
   * Handle startup next on-click function.
   * @param {Object} e - The event object.
   */
  const startupNextOnClick = e => {
    startupOnClick(true)
  }

  // Handle the effect when the save and exit action is clicked.
  useEffect(() => {
    if (saveAndExitClicked) {
      startupOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  /**
   * Set milestones based on the provided ID.
   * @param {string} id - The ID of the milestone.
   */
  const setMilestones = id => {
    let x = id
    if (!startupUser.progress.includes(x)) {
      progressCopy.push(x)
    } else {
      if (startupUser.progress.includes(x)) {
        const index = progressCopy.findIndex(element => element === x)
        progressCopy.splice(index, 1)
      }
    }

    if (startupUser) {
      updateLocalStartupUser({ progress: progressCopy })
    } else {
      updateLocalInvestorUser({ progress: progressCopy })
    }
  }

  // Check if the milestones selection is enabled.
  const isEnabled = progress && progress.length <= 3 && progress.length > 0

  // Check if the maximum number of milestones is selected.
  const maxMilestonesSelected = progress.length === 3

  // Generate a checklist based on the MILESTONES_DATA array.
  const checkList = MILESTONES_DATA.map((milestone, i) => {
    return (
      <div
        className={progresses.includes(milestone) ? 'selected sector-milestone' : 'sector-milestone'}
        key={i}
      >
        <div className="checkbox">
          <input
            checked={progresses.includes(milestone)}
            onChange={event => {
              handleCheckBoxChange(event, progresses)
            }}
            type="checkbox"
            id={milestone}
            name={milestone}
            disabled={maxMilestonesSelected && !progresses.includes(milestone)}
          />
          {milestone === MILESTONES_DATA[0] ||
          milestone === MILESTONES_DATA[1] ||
          milestone === MILESTONES_DATA[2] ||
          milestone === MILESTONES_DATA[3] ||
          milestone === MILESTONES_DATA[4] ||
          milestone === MILESTONES_DATA[5] ||
          milestone === MILESTONES_DATA[6] ||
          milestone === MILESTONES_DATA[7] ? (
            <label
              name={milestone}
              htmlFor={milestone}
              onClick={event => {
                // handleCardChange(event, progresses);
                if (!maxMilestonesSelected || (maxMilestonesSelected && progresses.includes(milestone))) {
                  handleCardChange(event, progresses)
                }
              }}
              id={milestone}
            >
              <span
                id={milestone}
                className={progresses.includes(milestone) ? 'sector-text-color' : ''}
                style={maxMilestonesSelected && !progresses.includes(milestone) ? { color: '#B5B5B5' } : null}
              >
                {milestone}
              </span>
            </label>
          ) : (
            <label name={milestone} htmlFor={milestone}>
              <span className={progresses.includes(milestone) ? 'sector-text-color' : ''}>{milestone}</span>
            </label>
          )}
        </div>
      </div>
    )
  })

  const { Step } = Steps

  return (
    <div>
      <Container className="mb-5">
        <Row>
          <Col lg={8} md={8} className="mx-auto">
            <h4 className="heading-question">
              Select up to three of your most impressive <span className="font-weight-bold">milestones.</span>
            </h4>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
          <Col md={12} className="">
            <form>
              <MileStonesCardList
                setMaxMilestonesSelectedErr={setMaxMilestonesSelectedErr}
                maxMilestonesSelectedErr={maxMilestonesSelectedErr}
                setMilestones={setMilestones}
                maxMilestonesSelected={maxMilestonesSelected}
              />
            </form>
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                disabled={!isEnabled}
                onClick={startupNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  updateUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
  progress: state.auth.startupUser.progress,
  startup: state.auth.startup,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
})

const mapDispatchToProps = dispatch => ({
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepStartup: () => dispatch(nextStepStartup()),

  prevStepStartup: () => dispatch(prevStepStartup()),

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MilestonesPage)
