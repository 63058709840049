import React from "react";
import { connect } from "react-redux";

import { Col, Row, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import discoverActive from "../../../assets/images/discover-icon-active.png";
import discoverInactive from "../../../assets/images/discover-icon-inactive.png";
import historyActive from "../../../assets/images/history-icon-active.png";
import historyInactive from "../../../assets/images/history-icon-inactive.png";

import {
  setDiscoverView,
  setHistoryView,
  fetchUserHistoryAll,
  fetchUserHistoryConnected,
  fetchUserHistoryPassed,
} from "../../../redux/startupFlowControl/startupFlowControlActions";

const BottomNav = ({
  history,
  setDiscoverView,
  setHistoryView,
  dashboardView,
  fetchUserHistoryAll,
  fetchUserHistoryConnected,
  fetchUserHistoryPassed,
}) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);

  const path = window.location.pathname;

  return (
    <div
      style={{ position: "fixed", width: "100%", bottom: "0" }}
      className="dashboard-bottom-navigation"
    >
      <Row className="d-flex border m-0">
        <Col className="text-center mt-0 pr-0">
          <Button
            style={{
              width: "100%",
              backgroundColor: "transparent",
              border: "none",
            }}
            className={
              dashboardView === "discover"
                ? "active-bottom-nav"
                : "inactive-bottom-nav"
            }
            onClick={() => {
              setDiscoverView();
              history.push("/startup/dashboard");
            }}
            disabled={path === "/startup/preview"}
          >
            <div>
              <img
                src={
                  dashboardView === "discover"
                    ? discoverActive
                    : discoverInactive
                }
                alt="discover-icon"
              />
            </div>
            <div className="font-weight-bold">Discover</div>
          </Button>
        </Col>
        <Col className="text-center mt-0 pl-0">
          <Button
            style={{
              width: "100%",
              backgroundColor: "transparent",
              border: "none",
            }}
            className={
              dashboardView === "history"
                ? "active-bottom-nav"
                : "inactive-bottom-nav"
            }
            onClick={() => {
              setHistoryView();
              fetchUserHistoryAll();
              fetchUserHistoryConnected();
              fetchUserHistoryPassed();
              history.push("/startup/dashboard");
            }}
            disabled={path === "/startup/preview"}
          >
            <div>
              {" "}
              <img
                src={
                  dashboardView === "history" ? historyActive : historyInactive
                }
                alt="discover-icon"
              />
            </div>
            <div className="font-weight-bold">History</div>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stepInvestor: state.startupflowControl.stepInvestor,
  user: state.auth.user,
  userInfos: state.startupflowControl.userInfos,
  dashboardView: state.startupflowControl.dashboardView,
});

const mapDispatchToProps = (dispatch) => ({
  setDiscoverView: () => dispatch(setDiscoverView()),
  setHistoryView: () => dispatch(setHistoryView()),
  fetchUserHistoryAll: () => {
    return dispatch(fetchUserHistoryAll());
  },
  fetchUserHistoryConnected: () => {
    return dispatch(fetchUserHistoryConnected());
  },
  fetchUserHistoryPassed: () => {
    return dispatch(fetchUserHistoryPassed());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BottomNav));
