import { SET_POSTS } from './marketingTabBlogOrPodcastActions'
import { SET_POSTS_COUNT } from './marketingTabBlogOrPodcastActions'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  posts: [],
  post_count: 0,
}

// Define a Redux reducer function
const marketingTabBlogOrPodcastReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_POSTS:
      return {
        ...state,
        posts: payload,
      }
    case SET_POSTS_COUNT:
      return {
        ...state,
        post_count: payload,
      }
    default:
      return state
  }
}

export default marketingTabBlogOrPodcastReducer
