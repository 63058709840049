export const EDUCATION_GET_ACCREDITED_SIGNUP_INIT = 'EDUCATION_GET_ACCREDITED_SIGNUP_INIT'
export const EDUCATION_GET_ACCREDITED_SIGNUP_SUCCESS = 'EDUCATION_GET_ACCREDITED_SIGNUP_SUCCESS'
export const EDUCATION_GET_ACCREDITED_SIGNUP_FAIL = 'EDUCATION_GET_ACCREDITED_SIGNUP_FAIL'

export const EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_INIT = 'EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_INIT'
export const EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_SUCCESS = 'EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_SUCCESS'
export const EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_FAIL = 'EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_FAIL'

export const EDUCATION_GET_ACCREDITED_LOGIN_INIT = 'EDUCATION_GET_ACCREDITED_LOGIN_INIT'
export const EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS = 'EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS'
export const EDUCATION_GET_ACCREDITED_LOGIN_FAIL = 'EDUCATION_GET_ACCREDITED_LOGIN_FAIL'

export const EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_INIT = 'EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_INIT'
export const EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS = 'EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS'
export const EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_FAIL = 'EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_FAIL'

export const FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT = 'FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT'
export const FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS = 'FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS'
export const FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL = 'FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL'

export const UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_INIT = 'UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_INIT'
export const UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_SUCCESS = 'UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_SUCCESS'
export const UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_FAILED = 'UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_FAILED'

export const UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT = 'UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT'
export const UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS = 'UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS'
export const UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL = 'UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL'

export const UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_INIT = 'UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_INIT'
export const UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_SUCCESS = 'UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_SUCCESS'
export const UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_FAILED = 'UPDATE_EDUCATION_GET_ACCREDITED_PASSWORD_FAILED'

export const GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT = 'GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT'
export const GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS = 'GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS'
export const GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED = 'GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED'

export const SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT = 'SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT'
export const SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS = 'SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS'
export const SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED = 'SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED'