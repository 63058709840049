import { getNumberFromCurrency } from './general'

/**
 * Checks if the amount raised in the current round is greater than or equal to the total round size.
 *
 * @param {string} raised - The amount raised in the current round (formatted as currency).
 * @param {string} round_size - The total round size (formatted as currency).
 * @returns {boolean} - True if the amount raised is greater than or equal to the total round size, false otherwise.
 */
export function isDisplayRaisedGreaterOrEqualToRoundSize(raised, round_size) {
  const raisedThisRound = getNumberFromCurrency(raised)
  const totalRoundSize = getNumberFromCurrency(round_size)

  // Ignore the case where the raisedThisRound and totalRoundSize values
  // cannot have the number value extracted from the strings.
  if (raisedThisRound == null || totalRoundSize == null) {
    return false
  }

  return raisedThisRound >= totalRoundSize
}
// From Login open last step page issues
// export function calcCurrentStartupStep(startupUser, isEmailConfirmed) {
//   // if (startupUser.approved) {
//   //   return 11
//   // }
//   if (!startupUser.first_name || !startupUser.last_name) {
//     return 1
//   }

//   /*
//     Check that the email is neither confirmed via a confirmation link
//     not confirmed via the user's email_confirmed field.
//   */
//   if (!(isEmailConfirmed || startupUser.email_confirmed)) {
//     return 2
//   }
//   if (!startupUser.company_name || !startupUser.company_link || !startupUser.startup_pitch) {
//     return 3
//   }
//   if (!startupUser.location) {
//     return 5
//   }
//   if (!startupUser.sectors || startupUser.sectors.length === 0) {
//     return 6
//   }
//   if (
//     startupUser.round_size === null ||
//     startupUser.raised === null ||
//     // Handle the case where the current round raised is leww than the total round size.
//     isDisplayRaisedGreaterOrEqualToRoundSize(startupUser.raised, startupUser.round_size)
//   ) {
//     return 7
//   }
//   if (!startupUser.progress || startupUser.progress.length === 0) {
//     return 8
//   }
//   if (!startupUser.position || !startupUser.bio || !startupUser.num_team_members) {
//     return 9
//   }
//   if (!startupUser.slide_deck) {
//     return 10
//   } else {
//     return 2
//   }
// }

/**
 * Calculates the current step in the startup onboarding process based on user information.
 *
 * @param {Object} startupUser - Information about the startup user.
 * @param {boolean} isEmailConfirmed - Indicates whether the user's email is confirmed.
 * @returns {number} - The current step in the onboarding process.
 */
export function calcCurrentStartupStep(startupUser, isEmailConfirmed) {
  if (
    !startupUser.first_name
    // || !startupUser.last_name
  ) {
    return 1
  }
  /*
    Check that the email is neither confirmed via a confirmation link
    not confirmed via the user's email_confirmed field.
  */
  if (!(isEmailConfirmed || startupUser.email_confirmed)) {
    return 2
  } else {
    return 2
  }
}
