import React, { Component } from 'react'

import warningIcon from '../../../assets/images/warning-icon.png'
import './StartupSlideDeckUploadModal.scss'

import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, Container, Spinner } from 'reactstrap'

export class StartupSlideDeckUploadModal extends Component {
  static averageUploadSpeedInBytes = 1e7 / 8 // 10Mbps = 10 / 8 MBps

  constructor(props) {
    super(props)
    const fileSizeInBytes = this.props.file.size
    const remainingUploadTimeInSeconds = Math.round(
      fileSizeInBytes / StartupSlideDeckUploadModal.averageUploadSpeedInBytes,
    )

    this.state = {
      // 10e3 represents the number of milliseconds in a second
      remainingUploadTimeInSeconds,
    }

    this.toggle = this.toggle.bind(this)
    this.decrementRemainingUploadTime = this.decrementRemainingUploadTime.bind(this)
  }

  decrementRemainingUploadTime() {
    this.setState(state => {
      const newRemainingTime = state.remainingUploadTimeInSeconds - 1
      return { remainingUploadTimeInSeconds: newRemainingTime < 0 ? 0 : newRemainingTime }
    })
  }

  componentDidMount() {
    this.internal = setInterval(() => this.decrementRemainingUploadTime(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  toggle = () => {
    this.props.hideSlideDeckUploadModal()
  }

  render() {
    return (
      // <Modal isOpen={this.props.file} toggle={this.toggle} centered={true} size="m">
      <Modal isOpen={false} toggle={this.toggle} centered={true} size="m">
        <ModalBody>
          <Container>
            {this.props.hasUploadFailed ? (
              <div className="slide-deck-modal-contents">
                <p className="upload-modal-title uploadFailErr" style={{ color: '#FF0302' }}>
                  We ran into an issue while uploading your deck.
                </p>
                <img
                  style={{ width: '4rem', paddingTop: '1.2rem', paddingBottom: '1.2rem' }}
                  src={warningIcon}
                  alt="slide deck upload warning"
                />
                <p className="upload-modal-description">Please check your connection and try again.</p>
              </div>
            ) : (
              <div className="slide-deck-modal-contents">
                <h5 className="upload-modal-title" style={{ color: '#162642' }}>
                  Deck upload in progress!
                </h5>
                <div style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
                  {/* <Spinner type="grow" />
                  <Spinner type="grow" className="ml-1" />
                  <Spinner type="grow" className="ml-1" /> */}
                  <h4>Loading...</h4>
                </div>
                <p className="upload-modal-description">
                  Your deck will finish uploading in
                  <span style={{ color: '#162642', fontWeight: 'bold' }}>
                    {' '}
                    ~{this.state.remainingUploadTimeInSeconds}{' '}
                  </span>
                  seconds.
                </p>
              </div>
            )}
          </Container>
        </ModalBody>

        {this.props.hasUploadFailed && (
          <ModalFooter>
            <div style={{ margin: '0 auto' }}>
              <Button
                color="link"
                style={{ color: '#BDBDBD', fontWeight: 'bold', textDecoration: 'underline' }}
                onClick={this.toggle}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  file: state.startupflowControl.slideDeckUploadModal.file,
  hasUploadFailed: state.startupflowControl.slideDeckUploadModal.hasUploadFailed,
})

const mapDispatchToProps = dispatch => ({
  hideSlideDeckUploadModal: () => {
    dispatch({ type: 'HIDE_SLIDE_DECK_UPLOAD_MODAL' })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupSlideDeckUploadModal)
