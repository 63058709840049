import * as actionTypes from './actionTypes'

const INITIAL_STATE = {
  loading: false,
  authErrorMessage: '',
  profile_data: {},
}

const EducationLearnInvestReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_SIGNUP_GOOGLE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_LOGIN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload?.user,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload?.user,
        loading: false,
      }
    case actionTypes.EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: { ...state.profile_data, profile_pic: action.payload?.url },
      }
    case actionTypes.UPLOAD_EDUCATION_GET_ACCREDITED_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_GET_ACCREDITED_PROFILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
      case actionTypes.GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.GET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.SET_EDUCATION_GET_ACCREDITED_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default EducationLearnInvestReducer
