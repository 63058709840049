import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './suspend.scss'
import suspend from '../../assets/images/suspend.svg'
import Footer from '../../components/NewPageComponents/Footer'
import Group from '../../assets/images/exitfund.svg'

const Suspend = props => {
  const path = useLocation()
  const routeChange = () => {
    if (path.pathname === '/suspend') {
      localStorage.clear()
      props.history.push('/startup')
    }
  }
  return (
    <div className="suspend-container">
      <div className="navbar-wrap">
        <img className="navbar-logo" alt="logo" src={Group} />
      </div>
      <div className="account-suspend-wrap">
        <img src={suspend} />
        <h3>Account Suspended</h3>
        <div className="card-wrap">
          <p>
            Your account has been suspended for not following our{' '}
            <a href="https://www.exitfund.com/termsofservice" target="_blank">
              Terms and Conditions.
            </a>
          </p>
          <p>
            You may email us at<a href="mailto:hello@exitfund.com"
              style={{ fontWeight: 600 }}
            > hello@exitfund.com </a>for further
            information.
          </p>
        </div>
        <button className="return-btn" onClick={routeChange}>
          Return to Home Page{' '}
        </button>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Suspend)
