import fetch from 'cross-fetch'

import { getTokenInLocaleStorage } from './general'

/**
 * Send a JSON request with optional token authentication.
 * @param {string} method - The HTTP method (GET, POST, PATCH, etc.).
 * @param {string} url - The URL to send the request to.
 * @param {object} body - The request body in JSON format.
 * @param {boolean} withToken - Flag indicating whether to include authentication token in the request.
 * @returns {Promise} - A Promise that resolves to the JSON response.
 */
function sendRequest(method, url, body, withToken) {
  const headers = {
    'Content-Type': 'application/json',
  }
  //if (withToken) {
  headers['Authorization'] = 'Bearer ' + getTokenInLocaleStorage()
  // headers['Authorization'] =
  //   'Bearer ' +
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM2MDMzNjIsIm5iZiI6MTYzMzYwMzM2MiwianRpIjoiMTYxZTE0MzgtZjAzZC00NjdkLWI2MGYtNTJmMmU2ZTU1MjZlIiwiZXhwIjoxNjMzNjA2OTYyLCJpZGVudGl0eSI6eyJlbWFpbCI6Im5ld3Rlc3RzdGFydHVwMTIzQHlvcG1haWwuY29tIiwibW9kZWwiOiJTdGFydHVwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.KbsNxzt2tROMHClAf5feKOX4MXEs505t7qM6wDxDZsI'
  // //}

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    }).then(response => response.json())
  } else {
    return fetch(url, {
      method,
      headers,
    }).then(response => response.json())
  }
}

/**
 * Send a form data request with optional token authentication.
 * @param {string} method - The HTTP method (GET, POST, PATCH, etc.).
 * @param {string} url - The URL to send the request to.
 * @param {FormData} body - The request body in form data format.
 * @param {boolean} withToken - Flag indicating whether to include authentication token in the request.
 * @returns {Promise} - A Promise that resolves to the JSON response.
 */
function sendFormDataRequest(method, url, body, withToken) {
  const headers = {
    // 'Content-Type': 'application/json',
  }
  //if (withToken) {
  headers['Authorization'] = 'Bearer ' + getTokenInLocaleStorage()
  // headers['Authorization'] =
  //   'Bearer ' +
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM2MDMzNjIsIm5iZiI6MTYzMzYwMzM2MiwianRpIjoiMTYxZTE0MzgtZjAzZC00NjdkLWI2MGYtNTJmMmU2ZTU1MjZlIiwiZXhwIjoxNjMzNjA2OTYyLCJpZGVudGl0eSI6eyJlbWFpbCI6Im5ld3Rlc3RzdGFydHVwMTIzQHlvcG1haWwuY29tIiwibW9kZWwiOiJTdGFydHVwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.KbsNxzt2tROMHClAf5feKOX4MXEs505t7qM6wDxDZsI'
  // //}

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
    }).then(response => response.json())
  } else {
    return fetch(url, {
      method,
      headers,
    }).then(response => response.json())
  }
}

/**
 * Send a form data request with optional token authentication.
 * @param {string} method - The HTTP method (GET, POST, PATCH, etc.).
 * @param {string} url - The URL to send the request to.
 * @param {FormData} body - The request body in form data format.
 * @param {boolean} withToken - Flag indicating whether to include authentication token in the request.
 * @returns {Promise} - A Promise that resolves to the JSON response.
 */
function sendRequestForm(method, url, body, withToken) {
  const headers = {
    // 'Content-Type': 'multipart/form-data',
  }
  //if (withToken) {
  headers['Authorization'] = 'Bearer ' + getTokenInLocaleStorage()
  // headers['Authorization'] =
  //   'Bearer ' +
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM2MDMzNjIsIm5iZiI6MTYzMzYwMzM2MiwianRpIjoiMTYxZTE0MzgtZjAzZC00NjdkLWI2MGYtNTJmMmU2ZTU1MjZlIiwiZXhwIjoxNjMzNjA2OTYyLCJpZGVudGl0eSI6eyJlbWFpbCI6Im5ld3Rlc3RzdGFydHVwMTIzQHlvcG1haWwuY29tIiwibW9kZWwiOiJTdGFydHVwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.KbsNxzt2tROMHClAf5feKOX4MXEs505t7qM6wDxDZsI'
  // //}

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
    }).then(response => response.json())
  } else {
    return fetch(url, {
      method,
      headers,
    }).then(response => response.json())
  }
}

/**
 * Send a file upload request.
 * @param {string} url - The URL to send the request to.
 * @param {File} file - The file to upload.
 * @param {string} fileType - The type of the file being uploaded.
 * @returns {Promise} - A Promise that resolves to the JSON response.
 */
function sendFile(url, file, fileType) {
  const headers = {
    // Do not specify Content-Type. See https://stackoverflow.com/a/49510941/10057192
    Authorization: 'Bearer ' + getTokenInLocaleStorage(),
  }
  const formData = new FormData()
  formData.append('file_obj', file)
  formData.append('type', fileType)
  return fetch(url, {
    method: 'POST',
    headers,
    body: formData,
  }).then(response => response.json())
}

// Exporting an object with various HTTP request functions
export default {
  getWithoutToken: (url, body) => {
    return sendRequest('GET', url, body, false)
  },
  post: (url, body) => {
    return sendRequest('POST', url, body, true)
  },
  postwithFormData: (url, body) => {
    return sendFormDataRequest('POST', url, body, true)
  },
  postWithoutToken: (url, body) => {
    return sendRequest('POST', url, body, false)
  },
  patch: (url, body) => {
    return sendRequest('PATCH', url, body, true)
  },
  get: (url, body) => {
    return sendRequest('GET', url, body, true)
  },
  postwithReqForm: (url, body) => {
    return sendRequestForm('POST', url, body, true)
  },
  getwithReqForm: (url, body) => {
    return sendRequestForm('GET', url, body, true)
  },
  sendFile,
}
