import { combineReducers } from 'redux'

import homeReducer from './home/homeReducer'
import investorFlowControlReducer from './investorFlowControl/investorFlowControlReducer'
import authReducer from './auth/authReducer'
import startupFlowControlReducer from './startupFlowControl/startupFlowControlReducer'
import animationReducer from './animation/animationReducer'
import marketingTabBlogOrPodcastReducer from './marketingTabBlogOrPodcast/marketingTabBlogOrPodcastReducers'
import viewDetailsReducer from './marketingTabBlogOrPodcast/ViewDetailsReducer'
import podcastsReducer from './podCasts/podcastsReducer'
import podcastViewDetailsReducer from './podCasts/podcastViewDetailsReducer'
import trackingFlowControlReducer from './trackingFlowControl/TrackingFlowControlReducers'
import scoutFlowControlReducer from './scoutFlowControl/scoutFlowControlReducer'
import hiringReducer from './hiringFlowControl/hiringReducer'
import StratupApprovedFlowControlReducers from './startupApprovedFlowControl/StratupApprovedFlowControlReducers'
import EducationLearnInvestReducer from './EducationLearnInvest/EducationLearnInvestReducer'
import EducationGetAccreditedReducer from './EducationGetAccredited/EducationGetAccreditedReducer'
import blogReducer from './blog/blogReducer'
import podcastReducer from './podcast/podcastReducer'
import portfolioReducer from './portfolio/portfolioReducer'

export default combineReducers({
  home: homeReducer,
  investorflowControl: investorFlowControlReducer,
  auth: authReducer,
  startupflowControl: startupFlowControlReducer,
  animation: animationReducer,
  marketingTabBlogOrPodcast: marketingTabBlogOrPodcastReducer,
  viewDetailsReducer: viewDetailsReducer,
  podcastsReducer: podcastsReducer,
  podcastViewDetails: podcastViewDetailsReducer,
  trackingFlowControlReducer: trackingFlowControlReducer,
  scoutflowControl: scoutFlowControlReducer,
  hiring: hiringReducer,
  StratupApprovedFlowControlReducers: StratupApprovedFlowControlReducers,
  educationLearnInvest: EducationLearnInvestReducer,
  educationGetAccredited: EducationGetAccreditedReducer,
  blog: blogReducer,
  podcast: podcastReducer,
  portfolio: portfolioReducer,
})
