export function calcCurrentScoutStep(scoutUser, confirmedEmail) {
  if (
    !scoutUser.first_name
    //  || !scoutUser.last_name
  ) {
    return 1
  }
  /*
    Check that the email is neither confirmed via a confirmation link
    not confirmed via the user's email_confirmed field.
  */
  if (!(confirmedEmail || scoutUser.email_confirmed)) {
    return 2
  } else {
    return 2
  }
}
