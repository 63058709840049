import React, { Component } from "react";
import { connect } from "react-redux";
import prependHttp from "prepend-http";
import membericon from "../../assets/images/FounderPhoto.png";
import linkedinicon from "../../assets/images/linkedinicon.png";

export class CoFounderPage extends Component {
  render() {
    const cofounder = this.props.startup
      ? this.props.selectedStrCoFounder
      : this.props.selectedInvCoFounder

    return (
      <div
        style={{
          paddingTop: "120px",
          display: "block",
          width: "90%",
          margin: "auto",
          fontFamily: "Roboto",
          animation: `fadeIn 1s`,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "block",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <img
            style={{
              objectFit: "cover",
              height: "100px",
              width: "100px",
              borderRadius: "50%",
            }}
            src={cofounder.profile_image ? cofounder.profile_image : membericon}
            alt=""
            id="avataImg"
            shape="circle"
          />
        </div>

        <h3
          className="font-23 purple-colored font-lato pt-3 mb-0 font-weight-900"
          style={{ textAlign: "center" }}
        >
          {cofounder.name || "-"}
        </h3>
        <div
          className="font-16 font-weight-600"
          style={{ textAlign: "center", textTransform: "uppercase" }}
        >
          {cofounder.position.length ? cofounder.position[0] : "-"}
        </div>
        <div
          className="mobile-info-no-margin pt-3"
          style={{ textAlign: "center" }}
        >
          {" "}
          {cofounder.bio || "-"}
        </div>
        <div className="pt-4">
          {cofounder.linkedin_link ? (
            <a
              href={prependHttp(cofounder.linkedin_link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{ color: "black", textDecoration: "none" }}
                className="member-linkedin-see-more"
              >
                <img
                  className="linkedin-logo-see-more"
                  src={linkedinicon}
                  alt=""
                />
                <span className="linked-in-profile">Linkedin Profile</span>
              </div>
            </a>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  startup: state.auth.startup,
  selectedInvCoFounder: state.investorflowControl.selectedCoFounder,
  selectedStrCoFounder: state.startupflowControl.selectedCoFounder,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CoFounderPage)
