// Import action type from an external file
import * as actionTypes from './ActionTypes'

// Define the initial state for the Redux store
const initialState = {
  loading: false,
  email_notification_data: {},
  update_profile_data: {},
  get_questions: [],
  get_status_data: {},
  investor_feedback_list: [],
  notification_count: [],
  get_notification_data: [],
  public_link_data: {},
  set_invite_code: '',
  get_startup_details: null,
  create_link_token_data: {},
  set_access_token_data: {},
  get_completed_tour_data: {},
  get_customer_info: {},
  get_customer_status: {},
  get_doc_status: {},
  manage_bank_acc_data: {},
  get_withdraw_money: {},
  get_investor_history: [],
  get_investor_detail: {},
  get_profile_completion: {},
  unread_notification_count: 0,
  unread_notifications: [],
  inviteHistoryData: [],
  postHistoryData: [],
  profileSettingsActiveTabKey: 'companyInfo',
  FileLink: null,
  postHistoryDataCount: 0,
  waitCount: 0,
  notificationLoader: false,
  tour_taken: false,
}

// Define a Redux reducer function
const StartupApprovedFlowControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMAIL_NOTIFICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_EMAIL_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        email_notification_data: action.payload,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_UPDATE_PROFILE_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_UPDATE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        update_profile_data: action.payload,
      }
    case actionTypes.GET_UPDATE_PROFILE_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_QUESTION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        get_questions: action.payload?.data,
        total_count: action.payload?.total_count,
      }
    case actionTypes.GET_QUESTION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_STATUS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        get_status_data: action.payload,
      }
    case actionTypes.FETCH_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_INVESTOR_FEEDBACK_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INVESTOR_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        investor_feedback_list: action.payload,
      }
    case actionTypes.GET_INVESTOR_FEEDBACK_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.NOTIFICATION_DROPDOWN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.NOTIFICATION_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        notification_count: action.payload,
      }
    case actionTypes.NOTIFICATION_DROPDOWN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unread_notification_count: action?.payload?.count || 0,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_DATA_INIT:
      return {
        ...state,
        notificationLoader: true,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        notificationLoader: false,
        unread_notifications: action?.payload,
      }
    case actionTypes.GET_UNREAD_NOTIFICATION_DATA_FAILED:
      return {
        ...state,
        notificationLoader: false,
      }
    case actionTypes.CLEAR_NOTIFICATION_DATA_INIT:
      return {
        ...state,
        notificationLoader: true,
      }
    case actionTypes.CLEAR_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        notificationLoader: false,
        unread_notifications: [],
        unread_notification_count: 0,
      }
    case actionTypes.CLEAR_NOTIFICATION_DATA_FAILED:
      return {
        ...state,
        notificationLoader: false,
      }
    case actionTypes.GET_NOTIFICATION_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        get_notification_data: action.payload,
      }
    case actionTypes.GET_NOTIFICATION_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.PUBLIC_LINK_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.PUBLIC_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        public_link_data: action.payload,
        set_invite_code: action.payload.invite_code,
      }
    case actionTypes.PUBLIC_LINK_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.STARTUP_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.STARTUP_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        get_startup_details: action.payload,
      }
    case actionTypes.STARTUP_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_LINK_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        create_link_token_data: action.payload,
      }
    case actionTypes.POST_LINK_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.SET_ACCESS_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        set_access_token_data: action.payload,
      }
    case actionTypes.SET_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_COMPLETED_TOUR_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_COMPLETED_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        get_completed_tour_data: action.payload,
        tour_taken: true,
      }
    case actionTypes.GET_COMPLETED_TOUR_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_CUSTOMER_INFO_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        get_customer_info: action.payload,
      }
    case actionTypes.GET_CUSTOMER_INFO_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        get_customer_status: action.payload,
      }
    case actionTypes.UPDATE_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        update_customer_status: action.payload,
      }
    case actionTypes.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SUBMIT_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        get_doc_status: action.payload,
      }
    case actionTypes.MANAGE_BANK_ACCOUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.MANAGE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        manage_bank_acc_data: action.payload,
      }

    case actionTypes.MANAGE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        manage_bank_acc_data: {},
      }
    case actionTypes.GET_WITHDRAW_MONEY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_WITHDRAW_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_withdraw_money: action.payload,
      }
    case actionTypes.GET_WITHDRAW_MONEY_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_INVESTOR_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INVESTOR_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_investor_history: action.payload,
      }
    case actionTypes.GET_INVESTOR_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        get_investor_history: [],
      }
    case actionTypes.GET_INVESTOR_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INVESTOR_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        get_investor_detail: action.payload,
      }
    case actionTypes.GET_INVESTOR_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_PROFILE_COMPLETION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_PROFILE_COMPLETION_SUCCESS:
      return {
        ...state,
        loading: false,
        get_profile_completion: action.payload,
      }
    case actionTypes.GET_PROFILE_COMPLETION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PROFILE_COMPLETION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_PROFILE_COMPLETION_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PROFILE_COMPLETION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CHANGE_SETTINGS_PROFILE_ACTIVE_KEY:
      return {
        ...state,
        profileSettingsActiveTabKey: action?.payload,
      }
    case actionTypes.DELETE_BANK_ACCOUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.DELETE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_EMAIL_SVP_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_EMAIL_SVP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      }
    case actionTypes.ADD_EMAIL_SVP_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SEND_FUNDRAISE_INVITE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SEND_FUNDRAISE_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SEND_FUNDRAISE_INVITE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_FUNDRAISE_INVITE_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_FUNDRAISE_INVITE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteHistoryData: action.payload,
      }
    case actionTypes.GET_FUNDRAISE_INVITE_HISTORY_FAILED: {
      return {
        ...state,
        loading: false,
        inviteHistoryData: [],
      }
    }
    case actionTypes.GET_POST_HISTORY_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_POST_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        postHistoryData: action.payload?.data,
        waitCount: action.payload?.wait || 0,
        postHistoryDataCount: action.payload?.total_count,
      }
    case actionTypes.GET_POST_HISTORY_DATA_FAILED:
      return {
        ...state,
        loading: false,
        postHistoryData: [],
      }
    case actionTypes.GET_FILE_UPLOAD_URL_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_FILE_UPLOAD_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        FileLink: action.payload?.url,
      }
    case actionTypes.GET_FILE_UPLOAD_URL_FAILED:
      return {
        ...state,
        loading: false,
        FileLink: null,
      }
    default:
      return { ...state }
  }
}

export default StartupApprovedFlowControlReducer
