/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import membericon from "../../../assets/images/FounderPhoto.png"
import startUpDefaultIcon from "../../../assets/images/FillerStartupIcon.png"
import { CardText, Card, Col, Row } from "reactstrap"
import BottomNav from "../../../pages/Startup/StartupDashboard/DashboardBottomNav"
import InvestorCard from "../../../components/InvestorCard/InvestorCard"
import HyperlinkWrapper from "../../../components/HyperlinkWrapper/HyperlinkWrapper";
import { getCompanyUrl } from "../../../utils/investor/priorInvestment";

class FirstVisitStartup extends React.Component {
  render() {
    const { blur } = this.props
    const user = {
      first_name: "Steve",
      last_name: "Frenkel",
      location: "Chicago, IL, USA",
      bio: "",
      sectors: [
        "Artificial Intelligence",
        "Blockchain",
        "Enterprise",
        "Financial/Banking",
        "Healthcare",
      ],
      deals: ["500"],
      syndicate: ["VU Venture Partners"],
      prior_investments: [
        { name: "Airbnb", year: "2017" },
        { name: "Stripe", year: "2019" },
        { name: "Lyft", year: "2017" },
      ],
    }

    const INVEST_DATA = {
      "0": "$25,000",
      "25": "$50,000",
      "50": "$100,000",
      "100": "$250,000",
      "250": "$500,000",
      "500": "$500,000+",
    }

    const sectors = {
      agtech: "Agriculture / Agtech",
      ai: "Artificial Intelligence",
      ar: "Augmented Reality",
      biomed: "Biomedical",
      biotech: "Biotech",
      blockchain: "Blockchain",
      community: "Community",
      crowdfund: "Crowdfunding",
      devtools: "Developer Tools",
      diversity: "Diversity",
      drones: "Drones",
      education: "Education",
      energy: "Energy",
      enterprise: "Enterprise",
      entertain: "Entertainment",
      gaming: "Esports / Online Gaming",
      banking: "Financial / Banking",
      government: "Government",
      hardware: "Hardware",
      health: "Healthcare",
      market: "Marketplace",
      media: "Media / Advertising",
      hardtech: "Moonshots / Hard Tech",
      robotics: "Robotics",
      security: "Security",
      sport: "Sport/Fitness",
      transport: "Transportation",
      travel: "Travel",
      vr: "Virtual Reality",
      other: "Other",
    }

    const mappedSectors = user.sectors.map((s) => {
      return sectors[s] ? sectors[s] : s
    })

    const sortedSectors = mappedSectors.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })

    return (
      <div>
        <div className="d-block d-md-none">
          <div>
            <Card
              body
              style={
                blur
                  ? {
                      filter: "blur(5px)",
                      margin: "0px 15px -10px",
                      border: "1px solid #DEDEDF",
                      borderRadius: "10px",
                      position: "fixed",
                      top: "100px",
                      overflow: "hidden"
                    }
                  : {
                      display: "block",
                      padding: "20px",
                      margin: "0px 15px -10px",
                      border: "1px solid #DEDEDF",
                      borderRadius: "10px",
                      position: "relative",
                      top: "-10px",
                    }
              }
            >
              <Col>
                {" "}
                <img
                  className="mobile-prof-pic"
                  src={
                    user &&
                    user.profile_pic_link &&
                    user.profile_pic_link !== "None"
                      ? user.profile_pic_link
                      : membericon
                  }
                  alt=""
                  id="avataImg"
                  shape="circle"
                />
                <div className="d-flex flex-dir-col mobile-name-location">
                  {" "}
                  <h3 className="font-20 ml-3 purple-colored pt-3 mb-0 font-weight-900 pr-5 mr-3">
                    {user.first_name} {user.last_name}
                  </h3>
                  <div className="ml-3">{user.location}</div>
                </div>
                <Row className="mobile-row pt-4 mt-3">
                  <div className="mobile-info ">{user.bio}</div>
                </Row>
                <Row className="mobile-row pt-4 mt-1">
                  <h5 className="font-weight-600"> Affiliations</h5>
                  <div className="mobile-info">
                    {user.syndicate.length ? (
                      user.syndicate.map((syn) => (
                        <h6 className="affiliation-group">{syn}</h6>
                      ))
                    ) : (
                      <div
                        className="mobile-info-no-margin"
                        style={{
                          color: "#B5B5B5",
                        }}
                      >
                        No Affiliations
                      </div>
                    )}
                  </div>
                </Row>
                <Row className="mobile-row pt-4 mt-1">
                  <h5 className="font-weight-900">Will invest up to</h5>
                  <h4
                    className="gold-colored font-weight-900"
                    style={{ marginLeft: "15px" }}
                  >
                    {INVEST_DATA[user.deals[0]]}
                  </h4>
                </Row>
                <Row className="mobile-row pt-4 mt-1">
                  <h5 className="font-weight-600 mb-2">Invests in</h5>
                  <CardText
                    className="d-flex investor-profile-edit-text"
                    style={{ flexWrap: "wrap" }}
                  >
                    {sortedSectors.map((sector) => (
                      <span
                        className="mobile-list"
                        style={{
                          border: "none",
                          backgroundColor: "#F2F2FF",
                          fontWeight: "bold",
                        }}
                      >
                        {sector}
                      </span>
                    ))}
                  </CardText>
                </Row>
                <Row className="mobile-row pt-4 mt-1">
                  <h5 className="font-weight-600">Prior investments</h5>
                  <div className="mobile-info-no-margin">
                    {user.prior_investments &&
                      user.prior_investments.length ? (
                      user.prior_investments.map((inv) => (
                        <Row
                          style={{ alignItems: "center" }}
                          className="investor-profile-edit-text ml-1 mb-3"
                        >
                          <div
                            style={{
                              minHeight: "48px",
                              height: "100%",
                              lineHeight: "45px",
                              width: "50px",
                              borderRadius: "5px",
                              border: "1px solid #DEDEDF",
                            }}
                          >
                            <HyperlinkWrapper
                              hyperlink={getCompanyUrl(inv)}
                            >
                              <img
                                style={{
                                  vAlign: "center",
                                  width: "50px",
                                  objectFit: "cover",
                                }}
                                src={
                                  inv.logo_link &&
                                  inv.logo_link !== "none found"
                                    ? inv.logo_link
                                    : startUpDefaultIcon
                                }
                                alt=""
                              />
                            </HyperlinkWrapper>
                          </div>
                          <Col>
                            <HyperlinkWrapper
                              hyperlink={getCompanyUrl(inv)}
                            >
                              <span className=" investment-card-name">
                                {inv.name}
                              </span>
                            </HyperlinkWrapper>
                            <br></br>
                            <span className="investment-card-year">
                              {inv.year}
                            </span>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <div
                        className="mobile-info-no-margin"
                        style={{ color: "#b5b5b5" }}
                      >
                        {user.first_name} hasn't filled out their prior investments yet.
                      </div>
                    )}
                  </div>
                </Row>
              </Col>
            </Card>
            <div className="push" />
            <div className="push" />

            <div
              style={{ position: "fixed", bottom: "60px", width: "100%" }}
              className="bottom m-auto"
            >
              <button className="mobile-pass mobile-edit mr-1 ml-3 font-16 font-weight-900">
                Pass
              </button>
              <button className="mobile-pass mobile-save ml-1 mr-3 font-16 font-weight-900">
                Invite
              </button>
              <BottomNav />
            </div>
          </div>
        </div>

        <div className="d-none d-md-block first-visit">
          <div
            className="dashboard-container-preview d-flex flex-column justify-content-between"
            style={blur ? { filter: "blur(5px)" } : { display: "block" }}
          >
            <InvestorCard user={user} />

            <div className="bottom mt-1">
              <button className="dashboard-button-left">Pass</button>

              <button className="button-right">Invite</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FirstVisitStartup
