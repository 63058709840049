import React from "react"
import { Container, Row, Col } from "reactstrap"

import "./StartupDeletePage.scss"
import { Link } from "react-router-dom"
class StartupAlreadyDeletePage extends React.Component {
  render() {
    return (
      <Container
        className="investor-delete-confirm-page"
        style={{
          textAlign: "center",
          animation: `fadeIn 1s`,
        }}
      >
        <Row>
          <Col className="mx-auto mb-3">
            <h1>Your Exitfund.com account has been deleted.</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto mb-3">
            <h4>We appreciate your time with us, and wish you all the best.</h4>
          </Col>
        </Row>
        <div className="backtosettings-bottom-confirm">
          <Link style={{ color: "#FFF", textDecoration: "none" }} to="/">
            Return to Homepage
          </Link>
        </div>
      </Container>
    )
  }
}
export default StartupAlreadyDeletePage
