import React from 'react'
import './LocationCard.scss'

import { connect } from 'react-redux'
import {
  updateLocalInvestorUser,
  updateLocalStartupUser,
  updateLocalScoutUser,
} from '../../redux/auth/authActions'
import { nextStep } from '../../redux/investorFlowControl/investorFlowControlActions'
import { Col, Row } from 'react-bootstrap'

// LocationCard component for selecting and displaying a location.
const LocationCard = ({
  city,
  state,
  LocationImage,
  updateLocalInvestorUser,
  updateLocalStartupUser,
  updateLocalScoutUser,
  startup,
  startupUser,
  scout,
  scoutUser,
  investorUser,
}) => {
  const text = city + ', ' + state

  const investorOnClick = e => {
    updateLocalInvestorUser({ location: text })
  }

  const startupOnClick = e => {
    updateLocalStartupUser({ location: text })
  }

  const scoutOnClick = e => {
    updateLocalScoutUser({ location: text })
  }

  // // Current location based on user type
  const currentLocation = startup ? startupUser.location : scout ? scoutUser.location : investorUser.location

  return (
    <>
      <div
        onClick={startup ? startupOnClick : scout ? scoutOnClick : investorOnClick}
        className={
          currentLocation === text
            ? 'selected2 test tc dib br5 location-card mb-3'
            : 'test tc dib br3 location-card mb-3'
        }
      >
        <div className={currentLocation === text ? 'transparent-location-layer' : ''} />
        <div>
          <Row>
            <Col xs={4} md={4} className="left">
              <LocationImage />
            </Col>
            <Col xs={8} md={8} className="right">
              <h5 className={currentLocation === text ? 'selected-sector city-title' : 'city-title'}>
                {city}
              </h5>

              <p className={currentLocation === text ? 'selected-sector state-title' : 'state-title'}>
                {state}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  startup: state.auth.startup,
  startupUser: state.auth.startupUser,
  investorUser: state.auth.user,
  scout: state.auth.scout,
  scoutUser: state?.auth?.scoutUser,
})

const mapDispatchToProps = dispatch => ({
  nextStep: () => dispatch(nextStep()),

  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalScoutUser: changedProperties => {
    dispatch(updateLocalScoutUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationCard)
