import { lazy } from 'react'

export const MonthlyUpdateAnalytic = lazy(() =>
  import('../pages/StartupApprovedPortal/MonthlyUpdates/MonthlyUpdateAnalytic'),
)
export const MonthlyUpdateform = lazy(() =>
  import('../pages/StartupApprovedPortal/MonthlyUpdates/MonthlyUpdateform'),
)
export const MonthlyUpdateHistory = lazy(() =>
  import('../pages/StartupApprovedPortal/MonthlyUpdates/MonthlyUpdateHistory'),
)

export const PublicLink = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/PublicLink'),
)
export const CompanyInfoTab = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/CompanyInfoTab'),
)
export const ChangePassword = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/ChangePassword'),
)
export const Banking = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/BankingFlow/Banking'),
)
export const EnterEmail = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/BankingFlow/EnterEmail'),
)
export const BankingDocVerification = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/BankingFlow/BankingDocVerification'),
)
export const BankingProfileInfo = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/BankingFlow/BankingProfileInfo'),
)
export const EmailNotification = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/EmailNotification'),
)

export const CompanyInfo = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/CompanyInfo'),
)
export const TeamInfo = lazy(() => import('../pages/StartupApprovedPortal/StartupApprovedProfile/TeamInfo'))

export const RoundInfo = lazy(() => import('../pages/StartupApprovedPortal/StartupApprovedProfile/RoundInfo'))
export const DocumentSection = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/Document'),
)
export const VideoSection = lazy(() => import('../pages/StartupApprovedPortal/StartupApprovedProfile/Videos'))

export const ProfileLeftPanel = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/ProfileLeftPanel'),
)
export const Status = lazy(() => import('../pages/StartupApprovedPortal/Dashboard/Status'))
export const QAList = lazy(() => import('../pages/StartupApprovedPortal/Dashboard/QAList'))
export const QAForm = lazy(() => import('../pages/StartupApprovedPortal/Dashboard/QAForm'))
export const WelcomeModal = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedModal/WelcomeModal'),
)

export const InvestorFeedback = lazy(() =>
  import('../pages/StartupApprovedPortal/Investors/InvestorFeedback'),
)
export const InvestorFeedbackMain = lazy(() =>
  import('../pages/StartupApprovedPortal/Investors/InvestorFeedbackMain'),
)
export const InvestorsHistory = lazy(() =>
  import('../pages/StartupApprovedPortal/Investors/InvestorsHistory'),
)

export const ProfileSettingLeftPanel = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/ProfileSettingLeftPanel'),
)
export const StartupApprovedProfileMain = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/ProfileMain'),
)
export const StartupApprovedSetting = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/ProfileSettingMain'),
)
export const InvestorHistoryMain = lazy(() =>
  import('../pages/StartupApprovedPortal/Investors/InvestorHistoryMain'),
)
export const QAFormMain = lazy(() => import('../pages/StartupApprovedPortal/Dashboard/QAFormMain'))
export const StatusMain = lazy(() => import('../pages/StartupApprovedPortal/Dashboard/StatusMain'))
export const PreviewInvestorView = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/PreviewInvestorView'),
)
export const StartupNotification = lazy(() =>
  import('../pages/StartupApprovedPortal/Notifications/Notification'),
)
export const OtpInput = lazy(() =>
  import('../pages/StartupApprovedPortal/StartupApprovedProfile/BankingFlow/OtpInput'),
)
export const InvestorDetail = lazy(() => import('../pages/StartupApprovedPortal/Investors/InvestorDetail'))
export const EditMonthlyUpdateform = lazy(() =>
  import('../pages/StartupApprovedPortal/MonthlyUpdates/EditMonthlyUpdateform'),
)
export const StartupDetailByLinkType = lazy(() =>
  import('../pages/Startup/StartupDetail/StartupDetailByLinkType'),
)
export const StartupDetail = lazy(() => import('../pages/Startup/StartupDetail/StartupDetail'))
export const StartupMonthlyUpdates = lazy(() =>
  import('../pages/Startup/StartupMonthlyUpdates/StartupMonthlyUpdates'),
)
export const Fundraise = lazy(() => import('../pages/StartupApprovedPortal/Fundraise/Fundraise'))
export const PostDetails = lazy(() => import('../pages/StartupApprovedPortal/Fundraise/PreviousPost'))
export const HistoryViewPost = lazy(() => import('../pages/StartupApprovedPortal/Fundraise/HistoryViewPost'))
