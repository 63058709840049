import React, { useState } from 'react'
import { Navbar } from 'react-bootstrap'
import { Container } from 'reactstrap'
import './HeaderOnboarding.scss'

import logo from '../../../assets/images/LogoExitFund.svg'
import { Button } from 'antd'

/**
 * Functional component representing the header for onboarding pages.
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onSaveAndLogout - The function to be executed on "Save & Logout" button click.
 * @param {string} [props.btnTitle] - The title of the button, defaults to 'Save & Logout'.
 * @param {boolean} [props.showSaveAndLogout=true] - Flag to show or hide the "Save & Logout" button.
 * @returns {JSX.Element} The JSX representation of the component.
 */
function HeaderOnboarding({ onSaveAndLogout, btnTitle, showSaveAndLogout = true }) {
  const [loading, setLoading] = useState(false)
  return (
    <div className="onboarding-header-main-wrap">
      <Navbar>
        <Container fluid className="mt-3 px-3">
          <Navbar.Brand href="/" className="">
            <div
              className={
                window.location.href.includes('onboarding') ? 'onboardingHeaderLogo' : 'authHeaderLogo'
              }
            ></div>
          </Navbar.Brand>
          {onSaveAndLogout && showSaveAndLogout && (
            <Button
              loading={loading}
              className="save-logout-btn"
              onClick={() => {
                onSaveAndLogout()
                setLoading(true)
                window.location.href = '/'
              }}
            >
              {btnTitle || 'Save & Logout'}
            </Button>
          )}
        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderOnboarding
