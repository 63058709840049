import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { Link, withRouter } from 'react-router-dom'
import { Button as AntButton, Input } from 'antd'
import { Button, ButtonGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  fetchInvestorSignupByEmail,
  UPDATE_LOCAL_INVESTOR_USER,
  logoutLocalInvestorUser,
  fetchStartupSignupByEmail,
  updateLocalStartupUser,
  fetchInvestorSignupByGoogle,
  fetchStartupSignupByGoogle,
  logoutLocalStartupUser,
  selectedAsInvestor,
  selectedAsStartup,
  toggleStartup,
  toggleInvestor,
  toggleScout,
  updateLocalScoutUser,
  fetchScoutSignupByEmail,
  fetchScoutSignupByGoogle,
  selectedAsScout,
  logoutLocalScoutUser,
  receiveStartupUserInfoUpdate,
} from '../../redux/auth/authActions'
import './RegisterForm.scss'
import {
  nextStepInvestor,
  prevStepInvestor,
  didEnterRegisterPage,
  updateStep,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import {
  nextStepStartup,
  updateStartupStep,
  prevStepStartup,
} from '../../redux/startupFlowControl/startupFlowControlActions'

import { emitInvestorSignupFailed, emitStartupSignupFailed } from '../../metrics/metricsEmitter'
import {
  nextStepScout,
  prevStepScout,
  updateScoutStep,
} from '../../redux/scoutFlowControl/scoutFlowControlActions.js'
import HeaderOnboarding from '../Header/HeaderOnboarding/HeaderOnboarding'
import { Divider } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { nameRegxCheck } from '../../utils/general'

class RegisterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      firstName: '',
      error: '',
      fieldError: { first_name: '', last_name: '' },
      tempUser: { first_name: '', last_name: '' },
      pageType: this.props.investor
        ? 'investor'
        : this.props.startup
        ? 'startup'
        : this.props.scout
        ? 'scout'
        : 'startup',
    }
  }

  // Check if user data is stored in local storage and update the local state accordingly
  componentDidMount() {
    if (localStorage.getItem('userData')) {
      this.props.updateLocalInvestorUser(JSON.parse(localStorage.getItem('userData')))
    }
    document.querySelector('body').scrollTo(0, 0)
  }

  /**
   * Handle form field changes and update local state accordingly.
   * Additionally, update local storage for startup, investor, or scout based on the component type.
   */
  handleFormFieldChange = event => {
    const { name, value } = event.target
    if (name == 'first_name' || name == 'last_name') {
      if (!nameRegxCheck(value)) {
        return
      }
    }
    this.setState({ tempUser: { ...this.state.tempUser, [name]: value?.trimStart() } })
    if (this.props.startup) {
      this.props.updateLocalStartupUser({ [name]: value?.trimEnd() })
    }
    if (this.props.investor) {
      this.props.updateLocalInvestorUser({ [name]: value?.trimEnd() })
    }
    if (this.props.scout) {
      this.props.updateLocalScoutUser({ [name]: value?.trimEnd() })
    }
  }

  // Toggle the visibility of the password.
  toggleShow = () => {
    this.setState({
      hidden: !this.state.hidden,
    })
  }

  /**
   * Check if the submit button should be enabled based on form field values.
   */
  isSubmitEnabled = () => {
    // Access field values here and validate them
    // const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    // const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const validEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/
    const { first_name, last_name, email, password } = this.props.startup
      ? this.props.startupUser
      : this.props.investor
      ? this.props.user
      : this.props.scoutUser
    if (
      first_name?.length > 0 &&
      last_name?.length > 0 &&
      validEmail.test(email) &&
      password?.length >= 12 &&
      !this.state.fieldError?.first_name &&
      !this.state.fieldError?.last_name
    ) {
      return true
    }
    return false
  }

  // Handle the signup process for an investor.
  investorOnClick = () => {
    const first_name = this.props.user.first_name
    const last_name = this.props.user.last_name
    const email = this.props.user.email
    const password = this.props.user.password
    const nextStepInvestor = this.props.nextStepInvestor
    const fetchInvestorSignupByEmail = this.props.fetchInvestorSignupByEmail

    fetchInvestorSignupByEmail({
      first_name,
      last_name,
      email,
      password,
    })
      .then(() => {
        nextStepInvestor()
      })
      .catch(err => {
        let parsedStatus
        if (err === 'email exists') {
          parsedStatus = 'That email is already in use. Please log in.'
        } else if (err === 'invalid email') {
          parsedStatus = 'Invalid email'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  // Handle the signup process for a startup.
  startupOnClick = () => {
    const first_name = this.props.startupUser.first_name
    const last_name = this.props.startupUser.last_name
    const email = this.props.startupUser.email
    const password = this.props.startupUser.password
    const nextStepStartup = this.props.nextStepStartup
    const fetchStartupSignupByEmail = this.props.fetchStartupSignupByEmail

    fetchStartupSignupByEmail({
      first_name,
      last_name,
      email,
      password,
    })
      .then(() => {
        localStorage.removeItem('userData')
        nextStepStartup()
      })
      .catch(err => {
        let parsedStatus
        if (err === 'email exists') {
          parsedStatus = 'That email is already in use. Please log in.'
        } else if (err === 'invalid email') {
          parsedStatus = 'Invalid email'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  // Handle the signup process for a scout.
  scoutOnClick = () => {
    const first_name = this.props.scoutUser.first_name
    const last_name = this.props.scoutUser.last_name
    const email = this.props.scoutUser.email
    const password = this.props.scoutUser.password
    const nextStepScout = this.props.nextStepScout
    const fetchScoutSignupByEmail = this.props.fetchScoutSignupByEmail

    fetchScoutSignupByEmail({
      first_name,
      last_name,
      email,
      password,
    })
      .then(() => {
        nextStepScout()
      })
      .catch(err => {
        let parsedStatus
        if (err === 'email exists') {
          parsedStatus = 'That email is already in use. Please log in.'
        } else if (err === 'invalid email') {
          parsedStatus = 'Invalid email'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handle the signup process for a scout using Google.
   * @param {object} googleUser - Google user data.
   */
  scoutGoogleOnClick = googleUser => {
    const fetchScoutSignupByGoogle = this.props.fetchScoutSignupByGoogle
    const updateScoutStep = this.props.updateScoutStep

    fetchScoutSignupByGoogle(googleUser)
      .then(() => {
        selectedAsScout()
        updateScoutStep(3)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handle the signup process for an investor using Google.
   * @param {object} googleUser - Google user data.
   */
  investorGoogleOnClick = googleUser => {
    const fetchInvestorSignupByGoogle = this.props.fetchInvestorSignupByGoogle
    const updateStep = this.props.updateStep

    fetchInvestorSignupByGoogle(googleUser)
      .then(() => {
        selectedAsInvestor()
        updateStep(3)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handle the signup process for a startup using Google.
   * @param {object} googleUser - Google user data.
   */
  startupGoogleOnClick = googleUser => {
    const fetchStartupSignupByGoogle = this.props.fetchStartupSignupByGoogle
    const updateStartupStep = this.props.updateStartupStep

    fetchStartupSignupByGoogle(googleUser)
      .then(() => {
        selectedAsStartup()
        updateStartupStep(3)
      })
      .catch(err => {
        let parsedStatus
        if (err === 'token not validated') {
          parsedStatus = 'There was an error with your Google signup. Please try again.'
        } else if (err === 'User email not available or not verified by Google.') {
          parsedStatus = 'There was an error getting your email from your Google account.'
        } else if (err === 'email exists') {
          parsedStatus = 'That Google account is already in use.'
        } else {
          parsedStatus = 'An unknown error occurred; please try again and/or contact us.'
        }
        this.setState({
          error: parsedStatus,
        })
      })
  }

  /**
   * Handle the failure of Google signup for an investor.
   * @param {string} errorCode - The error code associated with the failure.
   * @param {string} errorDetails - Additional details about the error.
   */
  investorGoogleSignupFailure = (errorCode, errorDetails) => {
    emitInvestorSignupFailed(true, errorCode, errorDetails)
  }

  /**
   * Handle the failure of Google signup for a startup.
   * @param {string} errorCode - The error code associated with the failure.
   * @param {string} errorDetails - Additional details about the error.
   */
  startupGoogleSignupFailure = (errorCode, errorDetails) => {
    emitStartupSignupFailed(true, errorCode, errorDetails)
  }

  // Toggle the error alert by clearing the error state.
  toggleAlert = () => {
    this.setState({
      error: '',
    })
  }

  render() {
    const {
      user,
      investorSignupFailed,
      startupUser,
      scoutUser,
      startupSignupFailed,
      scoutSignupFailed,
      history,
      authLoading,
    } = this.props
    const { startup, investor, scout } = this.props
    const isEnabled = this.isSubmitEnabled()
    const onHandleEnterKey = event => {
      if (isEnabled) {
        if (event.key === 'Enter') {
          if (startup) {
            this.startupOnClick()
          } else if (investor) {
            this.investorOnClick()
          } else if (scout) {
            this.scoutOnClick()
          }
        }
      }
    }
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          {this.props.pageType === 'startup' && (
            <link rel="canonical" href="https://exitfund.com/startup/signup" />
          )}
          {this.props.pageType === 'investor' && (
            <link rel="canonical" href="https://exitfund.com/investor/signup" />
          )}
          {this.props.pageType === 'scout' && (
            <link rel="canonical" href="https://exitfund.com/scout/signup" />
          )}
        </Helmet>
        <HeaderOnboarding />
        <div className="register mb-5 mt-3">
          <hr className="d-block d-sm-none mobile-line"></hr>
          <div className="sign-in-with-google">
            <div className="form-input">
              <ButtonGroup aria-label="">
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'startup' ? 'active-btn' : 'btn register-btn'}
                  onClick={() => {
                    !(this.props.pageType === 'startup') && history.push('/startup/signup')
                  }}
                  size="lg"
                >
                  Startup
                </Button>
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'investor' ? 'active-btn' : 'btn register-btn'}
                  onClick={() => {
                    !(this.props.pageType === 'investor') && history.push('/investor/signup')
                  }}
                  size="lg"
                >
                  Investor
                </Button>
                <Button
                  variant="outline-dark"
                  className={this.props.pageType === 'scout' ? 'active-btn' : 'btn register-btn'}
                  onClick={() => {
                    !(this.props.pageType === 'scout') && history.push('/scout/signup')
                  }}
                  size="lg"
                >
                  Scout
                </Button>
              </ButtonGroup>
              <div className="custom-google-login">
                <GoogleLogin
                  cookiePolicy={'single_host_origin'}
                  onSuccess={googleUser => {
                    startup
                      ? this.startupGoogleOnClick(googleUser)
                      : investor
                      ? this.investorGoogleOnClick(googleUser)
                      : this.scoutGoogleOnClick(googleUser)
                  }}
                  onError={({ error, details }) => {
                    startup
                      ? this.startupGoogleSignupFailure(error, details)
                      : investor
                      ? this.investorGoogleSignupFailure(error, details)
                      : this.scoutGoogleSignupFailure(error, details)
                  }}
                  width="250px"
                />
              </div>
            </div>
          </div>

          <Divider>Or</Divider>
          <form className="form-input">
            {(investorSignupFailed || startupSignupFailed || scoutSignupFailed) && (
              <p
                onChange={this.handleFormFieldChange}
                className={this.state.error ? 'sign-up-error' : 'close'}
              >
                {this.state.error}
                <CloseCircleOutlined onClick={this.toggleAlert} className="mt-1 float-right" />
              </p>
            )}

            <p className="form-field">Your First Name</p>
            <Input
              onChange={this.handleFormFieldChange}
              className="text-field-input name-input"
              type="text"
              placeholder="Enter first name"
              name="first_name"
              autoComplete="off"
              value={this.state.tempUser?.first_name}
              maxLength={100}
            />
            {this.state.fieldError?.first_name && (
              <p className="text-danger">{this.state.fieldError?.first_name}</p>
            )}
            <p className="form-field">Your Last Name</p>
            <Input
              onChange={this.handleFormFieldChange}
              className="text-field-input"
              type="text"
              placeholder="Enter last name"
              name="last_name"
              autoComplete="off"
              value={this.state.tempUser?.last_name}
              maxLength={100}
            />
            {this.state.fieldError?.last_name && (
              <p className="text-danger">{this.state.fieldError?.last_name}</p>
            )}
            <p className="form-field">Email Address </p>
            <Input
              onChange={this.handleFormFieldChange}
              className="text-field-input"
              placeholder="Enter email address"
              name="email"
              type="text"
              value={this.state.tempUser?.email}
              autoComplete="off"
              maxLength={254}
            />
            <div className="group">
              <p className="form-field">Password (at least 12 characters)</p>
              <Input
                onChange={this.handleFormFieldChange}
                className="text-field-input passwordInput"
                placeholder="Enter password"
                type={this.state.hidden ? 'password' : 'text'}
                name="password"
                value={this.state.tempUser?.password?.trim()}
                autoComplete="off"
                onKeyPress={onHandleEnterKey}
                maxLength={64}
              />
              <i
                onClick={this.toggleShow}
                className={
                  !this.state.hidden
                    ? 'fa fa-eye 3x passing-line-through register-password-icon'
                    : 'fa fa-eye-slash 3x passing-line-through register-password-icon'
                }
                style={{
                  bottom: '23%',
                  position: 'absolute',
                  textDecoration: this.state.hidden ? '' : '',
                }}
              />
            </div>
            <span className="agreement-policy">
              By signing up, I agree to Exitfund's <Link to="/termsofservice">terms, conditions</Link> and{' '}
              <Link to="/policy">privacy policy.</Link>{' '}
            </span>
            <AntButton
              loading={authLoading}
              disabled={!isEnabled}
              onClick={investor ? this.investorOnClick : startup ? this.startupOnClick : this.scoutOnClick}
              style={{
                color: '#ffffff',
                fontWeight: '400',
                fontSize: '18px',
              }}
              className="sign-up"
            >
              Sign Up
            </AntButton>
            <p className="text-center agreement-policy mb-3">
              {startup ? (
                <Link to="/startup/login">Already have an account? Login</Link>
              ) : investor ? (
                <Link to="/investor/login">Already have an account? Login</Link>
              ) : (
                <Link to="/scout/login">Already have an account? Login</Link>
              )}
              <>
                <p style={{ color: '#B5B5B5', fontSize: '14px' }} className="mt-md-3">
                  If you already have a{' '}
                  <span className="font-weight-bold" style={{ color: '#8A8A8A' }}>
                    startup or investor account
                  </span>
                  , please use those account details to{' '}
                  <span className="font-weight-bold" style={{ color: '#8A8A8A' }}>
                    log in
                  </span>
                  .
                </p>
              </>
            </p>
          </form>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  investorSignupFailed: state.investorflowControl.signupFailed,
  startupSignupFailed: state.startupflowControl.signupFailed,
  scoutSignupFailed: state.scoutflowControl.signupFailed,
  startup: state.auth.startup,
  investor: state.auth.investor,
  scout: state.auth.scout,
  startupUser: state.auth.startupUser,
  scoutUser: state?.auth?.scoutUser,
  authLoading: state?.auth?.loading,
})

const mapDispatchToProps = dispatch => ({
  toggleStartupPage: () => dispatch(toggleStartup()),
  toggleInvestorPage: () => dispatch(toggleInvestor()),
  toggleScoutPage: () => dispatch(toggleScout()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  prevStepStartup: () => dispatch(prevStepStartup()),
  prevStepScout: () => dispatch(prevStepScout()),
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  nextStepScout: () => dispatch(nextStepScout()),
  logoutLocalInvestorUser: () => dispatch(logoutLocalInvestorUser()),
  logoutLocalStartupUser: () => dispatch(logoutLocalStartupUser()),
  logoutLocalScoutUser: () => dispatch(logoutLocalScoutUser()),
  selectedAsInvestor: () => dispatch(selectedAsInvestor()),
  selectedAsStartup: () => dispatch(selectedAsStartup()),
  selectedAsScout: () => dispatch(selectedAsScout()),
  updateStep: step => {
    dispatch(updateStep(step))
  },
  updateScoutStep: step => {
    dispatch(updateScoutStep(step))
  },
  updateStartupStep: step => {
    dispatch(updateStartupStep(step))
  },

  updateLocalInvestorUser: changedProperties => {
    const action = { type: UPDATE_LOCAL_INVESTOR_USER, payload: changedProperties }
    dispatch(action)
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  updateLocalScoutUser: changedProperties => {
    dispatch(updateLocalScoutUser(changedProperties))
  },

  responseGoogle: response => {
    const action = { type: 'GOOGLE_LOGIN_FIELD', text: response.profileObj }
    dispatch(action)
  },
  isGoogleLogged: () => {
    const action = { type: 'SUCCESSFUL_GOOGLE_LOGIN', text: true }
    dispatch(action)
  },
  fetchInvestorSignupByEmail: user => {
    return dispatch(fetchInvestorSignupByEmail(user))
  },

  fetchStartupSignupByEmail: startupUser => {
    return dispatch(fetchStartupSignupByEmail(startupUser))
  },

  fetchScoutSignupByEmail: scoutUser => {
    return dispatch(fetchScoutSignupByEmail(scoutUser))
  },

  fetchInvestorSignupByGoogle: googleUser => {
    return dispatch(fetchInvestorSignupByGoogle(googleUser))
  },

  fetchStartupSignupByGoogle: googleUser => {
    return dispatch(fetchStartupSignupByGoogle(googleUser))
  },
  fetchScoutSignupByGoogle: googleUser => {
    return dispatch(fetchScoutSignupByGoogle(googleUser))
  },

  didEnterRegisterPage: () => {
    dispatch(didEnterRegisterPage())
  },
  receiveStartupUserInfoUpdate: data => {
    dispatch(receiveStartupUserInfoUpdate(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterForm))
