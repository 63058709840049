import React from 'react'
import './StartupDashboard.scss'
import accountPhoto from '../../../assets/images/AccountPhoto.png'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Redirect } from 'react-router-dom'

import {
  setHistorySelection,
  fetchUserHistoryAll,
  fetchUserHistoryConnected,
  fetchUserHistoryPassed,
  fetchUserHistoryCardInfo,
} from '../../../redux/startupFlowControl/startupFlowControlActions'

const INVEST_DATA = {
  0: '$25,000',
  25: '$50,000',
  50: '$100,000',
  100: '$250,000',
  250: '$500,000',
  500: '$500,000+',
}

class History extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      investorCardRoute: false,
    }
    this.routeToInvestorCard = this.routeToInvestorCard.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  routeToInvestorCard(id) {
    this.props.fetchUserHistoryCardInfo(id)
    this.setState({ investorCardRoute: true })
  }

  renderHistory() {
    if (this.props.historySelection === 'connected') {
      if (this.props.userHistoryConnected.length === 0) {
        return (
          <tr style={{ animation: `fadeIn 2s` }}>
            <td
              colSpan="4"
              style={{
                textAlign: 'center',
                border: 'none',
                color: '#162642',
                fontWeight: 'bold',
              }}
            >
              You haven't connected with any investors!
            </td>
          </tr>
        )
      }
      return (
        <>
          {this.props.userHistoryConnected.map(investor => (
            <>
              <tr
                style={{ animation: `fadeIn 2s` }}
                className="investor-card history-card"
                onClick={() => {
                  this.routeToInvestorCard(investor.id)
                }}
              >
                <th scope="row" className="table-row-item d-flex align-items-center">
                  <img
                    src={
                      investor.profile_pic_link && investor.profile_pic_link !== 'None' && investor.first_name
                        ? investor.profile_pic_link
                        : accountPhoto
                    }
                    alt="accountphoto"
                    className="investor-card-photo"
                    style={{
                      marginTop: '0px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="ml-3 investor-card-name-info">
                    <h4 className="d-md-block d-none purple-colored font-weight-600">
                      {investor.first_name ? investor.first_name : 'Anonymous'} {investor.last_name} {}
                    </h4>
                    <h4
                      className="d-md-none d-block font-weight-bold"
                      style={{ position: 'relative', top: '4px' }}
                    >
                      {investor.first_name ? investor.first_name : 'Anonymous'}{' '}
                      {investor.last_name && investor.last_name.length ? `${investor.last_name[0]}.` : ''}
                    </h4>
                    <div className="font-weight-400 d-md-block d-none">{investor.location}</div>
                  </div>
                </th>
                <td className="table-row-item align-middle font-23 font-weight-600 gold-colored mx-auto">
                  {investor.deals && investor.deals.length ? INVEST_DATA[investor.deals[0]] : ''}
                </td>
                <td className="table-row-item align-middle history-action-column font-20 mx-auto">
                  {investor.action}
                </td>
                <td className="table-row-item align-middle font-weight-status purple-colored font-20 mx-auto">
                  {investor.status}
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </>
          ))}
        </>
      )
    } else if (this.props.historySelection === 'passed') {
      if (this.props.userHistoryPassed.length === 0) {
        return (
          <tr style={{ animation: `fadeIn 2s` }}>
            <td
              colSpan="4"
              style={{
                textAlign: 'center',
                border: 'none',
                color: '#162642',
                fontWeight: 'bold',
              }}
            >
              You haven’t been passed on by any investors!
            </td>
          </tr>
        )
      }
      return (
        <>
          {this.props.userHistoryPassed.map(investor => (
            <>
              <tr className="investor-card" style={{ animation: `fadeIn 2s` }}>
                <th scope="row" className="table-row-item d-flex align-items-center">
                  <img
                    src={
                      investor.profile_pic_link && investor.profile_pic_link !== 'None' && investor.first_name
                        ? investor.profile_pic_link
                        : accountPhoto
                    }
                    alt="accountphoto"
                    className="investor-card-photo"
                    style={{
                      marginTop: '0px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="ml-3 investor-card-name-info">
                    <h4 className="d-md-block d-none purple-colored font-weight-600">
                      {investor.first_name ? investor.first_name : 'Anonymous'} {investor.last_name}
                    </h4>
                    <h4
                      className="d-md-none d-block font-weight-bold"
                      style={{ position: 'relative', top: '4px' }}
                    >
                      {investor.first_name ? investor.first_name : 'Anonymous'}{' '}
                      {investor.last_name && investor.last_name.length ? `${investor.last_name[0]}.` : ''}
                    </h4>
                    <div className="font-weight-400 d-md-block d-none">{investor.location}</div>
                  </div>
                </th>
                <td className="table-row-item align-middle font-23 font-weight-600 gold-colored mx-auto">
                  {investor.deals && investor.deals.length ? INVEST_DATA[investor.deals[0]] : ''}
                </td>
                <td className="table-row-item align-middle history-action-column font-20 mx-auto">
                  {investor.action}
                </td>
                <td className="table-row-item align-middle font-weight-status purple-colored font-20 mx-auto">
                  {investor.status ? (
                    investor.status
                  ) : (
                    <Button
                      className="startup-card-name-info-feedback-button font-weight-status font-20"
                      onClick={() => {
                        this.props.setFeedbackInformation(investor)
                        this.props.openViewFeedbackModal()
                      }}
                      // id="feedback-button"
                    >
                      View Feedback
                      <span className="feedback-button-view-icon">
                        <i className="fa fa-chevron-right"></i>
                      </span>
                    </Button>
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </>
          ))}
        </>
      )
    }
    if (this.props.userHistory.length === 0) {
      return (
        <tr style={{ animation: `fadeIn 2s` }}>
          <td
            colSpan="4"
            style={{
              textAlign: 'center',
              border: 'none',
              color: '#162642',
              fontWeight: 'bold',
            }}
          >
            You haven’t connected with or been passed on by any investors!
          </td>
        </tr>
      )
    }

    return (
      <>
        {this.props.userHistory.map(investor => (
          <>
            <tr
              style={{ animation: `fadeIn 2s` }}
              className={`investor-card ${investor.status ? 'history-card' : null}`}
              onClick={() => {
                if (investor.status) {
                  this.routeToInvestorCard(investor.id)
                }
              }}
            >
              <th scope="row" className="table-row-item d-flex align-items-center">
                <img
                  src={
                    investor.profile_pic_link && investor.profile_pic_link !== 'None' && investor.first_name
                      ? investor.profile_pic_link
                      : accountPhoto
                  }
                  alt="accountphoto"
                  className="investor-card-photo"
                  style={{
                    marginTop: '0px',
                    objectFit: 'cover',
                  }}
                />
                <div className="ml-3 investor-card-name-info">
                  <h4 className="d-md-block d-none purple-colored font-weight-600">
                    {investor.first_name ? investor.first_name : 'Anonymous'} {investor.last_name}
                  </h4>
                  <h4
                    className="d-md-none d-block font-weight-bold"
                    style={{ position: 'relative', top: '4px' }}
                  >
                    {investor.first_name ? investor.first_name : 'Anonymous'}{' '}
                    {investor.last_name && investor.last_name.length ? `${investor.last_name[0]}.` : ''}
                  </h4>
                  <div className="font-weight-400 d-md-block d-none">{investor.location}</div>
                </div>
              </th>
              <td className="table-row-item align-middle font-23 font-weight-600 gold-colored mx-auto">
                {investor.deals && investor.deals.length ? INVEST_DATA[investor.deals[0]] : ''}
              </td>
              <td className="table-row-item align-middle history-action-column font-20 mx-auto">
                {investor.action}
              </td>
              <td className="table-row-item align-middle font-weight-status purple-colored font-20 mx-auto">
                {investor.status ? (
                  investor.status
                ) : (
                  <Button
                    className="startup-card-name-info-feedback-button font-weight-status font-20"
                    onClick={() => {
                      this.props.setFeedbackInformation(investor)
                      this.props.openViewFeedbackModal()
                    }}
                    // id="feedback-button"
                  >
                    View Feedback
                    <span className="feedback-button-view-icon">
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </>
        ))}
      </>
    )
  }
  render() {
    const { blur } = this.props

    if (this.state.investorCardRoute) {
      return <Redirect to="/startup/investor/" />
    }

    return (
      <div
        className="history-container"
        style={
          blur
            ? { filter: 'blur(5px)', maxWidth: '948px', width: '90%' }
            : { display: 'block', maxWidth: '948px', width: '90%' }
        }
      >
        <div style={{ animation: `fadeIn 1s` }}>
          <button
            className={`history-button ${
              this.props.historySelection === 'all' ? 'active-history-button' : null
            }`}
            onClick={() => {
              this.props.fetchUserHistoryAll()
              this.props.setHistorySelection('all')
            }}
          >
            All
          </button>
          <button
            className={`history-button ${
              this.props.historySelection === 'connected' ? 'active-history-button' : null
            }`}
            onClick={() => {
              this.props.fetchUserHistoryConnected()
              this.props.setHistorySelection('connected')
            }}
          >
            Connected
          </button>
          <button
            className={`history-button ${
              this.props.historySelection === 'passed' ? 'active-history-button' : null
            }`}
            onClick={() => {
              this.props.fetchUserHistoryPassed()
              this.props.setHistorySelection('passed')
            }}
          >
            Passed
          </button>
        </div>

        <div className="mt-5" style={{ animation: `fadeIn 2s` }}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="table-header mx-auto">
                  <span style={{ position: 'relative', left: '-10px' }}>Investor</span>
                </th>
                <th scope="col" className="table-header mx-auto history-table-padding">
                  Invests up to
                </th>
                <th scope="col" className="table-header history-action-column mx-auto history-table-padding">
                  Action
                </th>
                <th scope="col" className="table-header mx-auto history-table-padding">
                  Status
                </th>
              </tr>
            </thead>
            <tbody style={{ animation: `fadeIn 2s` }}>{this.renderHistory()}</tbody>
          </table>
          <div className="push" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
  userInfos: state.startupflowControl.userInfos,
  userHistory: state.startupflowControl.userHistory,
  userHistoryConnected: state.startupflowControl.userHistoryConnected,
  userHistoryPassed: state.startupflowControl.userHistoryPassed,
  index: state.investorflowControl.index,
  dashboardView: state.startupflowControl.dashboardView,
  historySelection: state.startupflowControl.historySelection,
})

const mapDispatchToProps = dispatch => ({
  openViewFeedbackModal: () => {
    return dispatch({
      type: 'SET_VIEW_FEEDBACK_MODAL',
      payload: true,
    })
  },
  setFeedbackInformation: selection => {
    return dispatch({
      type: 'SET_VIEW_FEEDBACK_INFORMATION',
      payload: selection,
    })
  },
  setHistorySelection: selection => {
    return dispatch(setHistorySelection(selection))
  },
  fetchUserHistoryAll: () => {
    return dispatch(fetchUserHistoryAll())
  },
  fetchUserHistoryConnected: () => {
    return dispatch(fetchUserHistoryConnected())
  },
  fetchUserHistoryPassed: () => {
    return dispatch(fetchUserHistoryPassed())
  },
  fetchUserHistoryCardInfo: id => {
    return dispatch(fetchUserHistoryCardInfo(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
