import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Footer from '../../components/NewPageComponents/Footer'
import '../../components/Header/HeaderInvestor/Header.scss'

import {
  fetchStartupUserInfoUpdate,
  fetchInvestorUserInfoUpdate,
  fetchScoutUserInfoUpdate,
} from '../../redux/auth/authActions'
import { getUserTypeFromToken } from '../../utils/general'
import Homepage from '../../components/HomePages/Homepage'
import HeaderLoginSignup from '../../components/Header/HeaderLoginSignup'
import { setPageType } from '../../redux/home/homeActions'
import { Helmet } from 'react-helmet'
class NewHomePage extends Component {
  static getUserTypeFromToken = getUserTypeFromToken

  // Sets the page type based on the current URL and redirects the user if needed.
  componentDidMount() {
    if (this.props.history?.location?.pathname?.substring(1)) {
      if (this.props.history?.location?.pathname?.substring(1) == 'startup') {
        this.props.setPageType('startup')
      } else if (this.props.history?.location?.pathname?.substring(1) == 'scout') {
        this.props.setPageType('scouts')
      } else if (this.props.history?.location?.pathname?.substring(1) == 'investor') {
        this.props.setPageType('investor')
      }
    } else {
      this.props.setPageType('startup')
    }
  }

  /**
   * Renders the component based on the user's authentication status and type.
   * If the user is not authenticated or the token has expired, redirects to the signup page
   * based on the user type obtained from the token. Otherwise, renders the homepage.
   * @returns {JSX.Element} - The rendered component.
   */
  render() {
    if (this.props.token === null || this.props.tokenExpired) {
      this.props.clearAccounts()
    } else {
      const getUserTypeFromToken = NewHomePage.getUserTypeFromToken(this.props.token)
      // Unfortunately, we need to redirect to signup since the signup page
      // does the calculation of the current step that the startup/investor
      // is in, which is currently our centralized place for redirecting the
      // logged in user to the right place.
      if (getUserTypeFromToken === 'Startup') {
        this.props.resetAuthExpiry()
        this.props.fetchStartupUserInfo()
        return <Redirect to={{ pathname: '/startup/signup', state: { gif: 'false' } }} />
      } else if (getUserTypeFromToken === 'Investor') {
        this.props.resetAuthExpiry()
        this.props.fetchInvestorUserInfo()
        return <Redirect to={{ pathname: '/investor/signup', state: { gif: 'false' } }} />
      } else if (getUserTypeFromToken === 'Scouts') {
        this.props.resetAuthExpiry()
        this.props.fetchScoutUserInfo()
        return <Redirect to={{ pathname: '/scout/signup', state: { gif: 'false' } }} />
      }
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href="https://exitfund.com" />
        </Helmet>
        {/* <Header /> */}
        <HeaderLoginSignup />
        <Homepage />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  startupUserId: state.auth.startupUser.id,
  investorId: state.auth.user.id,
  scoutUser: state?.auth?.scoutUser?.id,
  tokenExpired: state.auth.tokenExpired,
})

const mapDispatchToProps = dispatch => ({
  clearAccounts: () => {
    dispatch({ type: 'CLEAR_ACCOUNTS' })
    dispatch({ type: 'CLEAR_INVESTOR_FLOW' })
    dispatch({ type: 'CLEAR_STARTUP_FLOW' })
  },
  resetAuthExpiry: () => {
    dispatch({ type: 'SET_AUTH_EXPIRED_FALSE' })
  },
  fetchStartupUserInfo: () => {
    // Updating with empty change to fetch data with no changes
    dispatch(fetchStartupUserInfoUpdate({}))
  },
  fetchInvestorUserInfo: () => {
    // Updating with empty change to fetch data with no changes
    dispatch(fetchInvestorUserInfoUpdate({}))
  },
  fetchScoutUserInfo: () => {
    // Updating with empty change to fetch data with no changes
    dispatch(fetchScoutUserInfoUpdate({}))
  },
  setPageType: pageType => dispatch(setPageType(pageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewHomePage)
