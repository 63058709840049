export const SET_SCOUT_CONFIRMED_EMAIL = 'SET_SCOUT_CONFIRMED_EMAIL'
export const Save_And_Exit_Clicked = 'Save_And_Exit_Clicked'
export const Save_And_Exit_Clicked_Reset = 'Save_And_Exit_Clicked_Reset'
export const SCOUT_SIGNUP_FAILED = 'SCOUT_SIGNUP_FAILED'
export const SCOUT_LOGIN_FAILED = 'SCOUT_LOGIN_FAILED'
export const UPDATE_SCOUT_STEP = 'UPDATE_SCOUT_STEP'
export const UPDATE_SCOUT_TAB = 'UPDATE_SCOUT_TAB'
export const UPDATE_SCOUT_USER_INFO_FAILED = 'UPDATE_SCOUT_USER_INFO_FAILED'
export const SHOW_SLIDE_DECK_FORM = 'SHOW_SLIDE_DECK_FORM'
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE'
export const UPDATE_INDEX = 'UPDATE_INDEX'
export const NEXT_STEP = 'NEXT_STEP'
export const NEXT_STEP_SCOUT = 'NEXT_STEP_SCOUT'
export const PREV_STEP = 'PREV_STEP'
export const PREV_STEP_SCOUT = 'PREV_STEP_SCOUT'
export const RESET_INITIAL_SCOUT_STATE = 'RESET_INITIAL_SCOUT_STATE'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE'
export const RETURN_HOME_PAGE = 'RETURN_HOME_PAGE'

export const FETCH_SCOUT_PROFILE_INIT = 'FETCH_SCOUT_PROFILE_INIT'
export const FETCH_SCOUT_PROFILE_SUCCESS = 'FETCH_SCOUT_PROFILE_SUCCESS'
export const FETCH_SCOUT_PROFILE_FAILED = 'FETCH_SCOUT_PROFILE_FAILED'

export const EDIT_SCOUT_PROFILE_INIT = 'EDIT_SCOUT_PROFILE_INIT'
export const EDIT_SCOUT_PROFILE_SUCCESS = 'EDIT_SCOUT_PROFILE_SUCCESS'
export const EDIT_SCOUT_PROFILE_FAILED = 'EDIT_SCOUT_PROFILE_FAILED'

export const UPLOAD_SCOUT_PROFILE_PICTURE_INIT = 'UPLOAD_SCOUT_PROFILE_PICTURE_INIT'
export const UPLOAD_SCOUT_PROFILE_PICTURE_SUCCESS = 'UPLOAD_SCOUT_PROFILE_PICTURE_SUCCESS'
export const UPLOAD_SCOUT_PROFILE_PICTURE_FAILED = 'UPLOAD_SCOUT_PROFILE_PICTURE_FAILED'

export const UPDATE_SCOUT_PASSWORD_INIT = 'UPDATE_SCOUT_PASSWORD_INIT'
export const UPDATE_SCOUT_PASSWORD_SUCCESS = 'UPDATE_SCOUT_PASSWORD_SUCCESS'
export const UPDATE_SCOUT_PASSWORD_FAILED = 'UPDATE_SCOUT_PASSWORD_FAILED'

export const GET_SCOUT_EMAIL_NOTIFICATIONS_INIT = 'GET_SCOUT_EMAIL_NOTIFICATIONS_INIT'
export const GET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS = 'GET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS'
export const GET_SCOUT_EMAIL_NOTIFICATIONS_FAILED = 'GET_SCOUT_EMAIL_NOTIFICATIONS_FAILED'

export const SET_SCOUT_EMAIL_NOTIFICATIONS_INIT = 'SET_SCOUT_EMAIL_NOTIFICATIONS_INIT'
export const SET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS = 'SET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS'
export const SET_SCOUT_EMAIL_NOTIFICATIONS_FAILED = 'SET_SCOUT_EMAIL_NOTIFICATIONS_FAILED'

export const BULK_EMAIL_INVITE_SCOUT_INIT = 'BULK_EMAIL_INVITE_SCOUT_INIT'
export const BULK_EMAIL_INVITE_SCOUT_SUCCESS = 'BULK_EMAIL_INVITE_SCOUT_SUCCESS'
export const BULK_EMAIL_INVITE_SCOUT_FAILED = 'BULK_EMAIL_INVITE_SCOUT_FAILED'

export const EMAIL_INVITE_SCOUT_INIT = 'EMAIL_INVITE_SCOUT_INIT'
export const EMAIL_INVITE_SCOUT_SUCCESS = 'EMAIL_INVITE_SCOUT_SUCCESS'
export const EMAIL_INVITE_SCOUT_FAILED = 'EMAIL_INVITE_SCOUT_FAILED'

export const GET_SCOUT_STARTUP_REFERRALS_INIT = 'GET_SCOUT_STARTUP_REFERRALS_INIT'
export const GET_SCOUT_STARTUP_REFERRALS_SUCCESS = 'GET_SCOUT_STARTUP_REFERRALS_SUCCESS'
export const GET_SCOUT_STARTUP_REFERRALS_FAILED = 'GET_SCOUT_STARTUP_REFERRALS_FAILED'

export const GET_SCOUT_INVESTOR_REFERRALS_INIT = 'GET_SCOUT_INVESTOR_REFERRALS_INIT'
export const GET_SCOUT_INVESTOR_REFERRALS_SUCCESS = 'GET_SCOUT_INVESTOR_REFERRALS_SUCCESS'
export const GET_SCOUT_INVESTOR_REFERRALS_FAILED = 'GET_SCOUT_INVESTOR_REFERRALS_FAILED'

export const FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_INIT = 'FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_INIT'
export const FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_SUCCESS = 'FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_SUCCESS'
export const FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_FAILED = 'FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_FAILED'

export const FETCH_SCOUT_UNREAD_NOTIFICATIONS_INIT = 'FETCH_SCOUT_UNREAD_NOTIFICATIONS_INIT'
export const FETCH_SCOUT_UNREAD_NOTIFICATIONS_SUCCESS = 'FETCH_SCOUT_UNREAD_NOTIFICATIONS_SUCCESS'
export const FETCH_SCOUT_UNREAD_NOTIFICATIONS_FAILED = 'FETCH_SCOUT_UNREAD_NOTIFICATIONS_FAILED'

export const FETCH_SCOUT_NOTIFICATIONS_INIT = 'FETCH_SCOUT_NOTIFICATIONS_INIT'
export const FETCH_SCOUT_NOTIFICATIONS_SUCCESS = 'FETCH_SCOUT_NOTIFICATIONS_SUCCESS'
export const FETCH_SCOUT_NOTIFICATIONS_FAILED = 'FETCH_SCOUT_NOTIFICATIONS_FAILED'

export const CLEAR_SCOUT_NOTIFICATIONS_INIT = 'CLEAR_SCOUT_NOTIFICATIONS_INIT'
export const CLEAR_SCOUT_NOTIFICATIONS_SUCCESS = 'CLEAR_SCOUT_NOTIFICATIONS_SUCCESS'
export const CLEAR_SCOUT_NOTIFICATIONS_FAILED = 'CLEAR_SCOUT_NOTIFICATIONS_FAILED'

export const FETCH_SCOUT_CONTRACTS_INIT = 'FETCH_SCOUT_CONTRACTS_INIT'
export const FETCH_SCOUT_CONTRACTS_SUCCESS = 'FETCH_SCOUT_CONTRACTS_SUCCESS'
export const FETCH_SCOUT_CONTRACTS_FAILED = 'FETCH_SCOUT_CONTRACTS_FAILED'

export const COMPLETE_SCOUT_TOUR_INIT = 'COMPLETE_SCOUT_TOUR_INIT'
export const COMPLETE_SCOUT_TOUR_SUCCESS = 'COMPLETE_SCOUT_TOUR_SUCCESS'
export const COMPLETE_SCOUT_TOUR_FAILED = 'COMPLETE_SCOUT_TOUR_FAILED'

export const GET_INVITE_HISTORY_INIT = 'GET_INVITE_HISTORY_INIT'
export const GET_INVITE_HISTORY_SUCCESS = 'GET_INVITE_HISTORY_SUCCESS'
export const GET_INVITE_HISTORY_FAILED = 'GET_INVITE_HISTORY_FAILED'

export const CHANGE_SETTINGS_PROFILE_ACTIVE_KEY = 'CHANGE_SETTINGS_PROFILE_ACTIVE_KEY'
