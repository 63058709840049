import React from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { Link } from 'react-router-dom'
import validator from 'validator'

import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import InvalidUserInputAlert from '../../../components/InvalidUserInputAlert/InvalidUserInputAlert'

import PlusIcon from '../../../assets/images/plusicon.png'
import MobileTabs from '../../../components/MobileTabs/MobileTabs'
import LocationAPI from './LocationAPI'
import StartupViewSlideDeckModal from './StartupViewSlideDeckModal'
import StartupSlideDeckUploadModal from '../StartupSlideDeckUpload/StartupSlideDeckUploadModal'

import { getNumberFromCurrency } from '../../../utils/general'
import { isDisplayRaisedGreaterOrEqualToRoundSize } from '../../../utils/startupStep'

import './StartupProfilePage.scss'
import membericon from '../../../assets/images/FounderPhoto.png'
import startUpDefaultIcon from '../../../assets/images/FillerStartupIcon.png'
import pdfIcon from '../../../assets/images/pdf.png'

import { connect } from 'react-redux'
import {
  Container,
  Card,
  Button,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Input,
  InputGroup,
  Spinner,
} from 'reactstrap'
import {
  fetchStartupUserInfoUpdate,
  fetchStartupUserCompletion,
  updateLocalStartupUser,
  fetchStartupFileUpload,
  removeSlideDeck,
  fetchStartupCFFileUpload,
} from '../../../redux/auth/authActions'

import { isClickedToShowForm } from '../../../redux/investorFlowControl/investorFlowControlActions'
import {
  editPhoto,
  showSlideDeckUploadModal,
  slideDeckUploadFailed,
  hideSlideDeckUploadModal,
} from '../../../redux/startupFlowControl/startupFlowControlActions'

import { formatFileToName } from '../../../utils/general'

class StartupProfileEditPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      loading: true,
      token: this.props.token,
      investor: false,
      startup: false,
      user: {
        id: 0,
        isGoogleLogged: false,
        googleProfileObj: {},
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        location: '',
        sectors: [],
        company_name: '',
        company_link: '',
        startup_pitch: '',
        bio: '',
        round_size: null,
        raised: null,
        progress: [],
        position: '',
        num_team_members: 0,
        co_founders: [],
        slide_deck: null,
        fileName: '',
        first_dashboard_visit: true,
      },
      display: false,
      saving: false,
      changesMade: false,
      chars_left_pitch: 500,
      chars_left_bio: 600,
      chars_left_name: 28,
    }
  }

  componentDidMount() {
    let { loading, token, investor, startup, user } = this.props
    const card = {
      primary: '',
      name: '',
      position: [],
      bio: '',
      linkedin_link: '',
      profile_image: '',
    }
    window.scrollTo(0, 0)
    if (user.co_founders) {
      if (user.co_founders.length === 0) {
        const cofoundingArray = []
        for (let i = 0; i < user.num_team_members; i++) {
          if (i === 0) {
            cofoundingArray.push({
              primary: user.position === 'CEO' || user.position === 'ceo' ? true : false,
              name: `${user.first_name} ${user.last_name}`,
              position: [user.position],
              bio: user.bio,
              linkedin_link: '',
              profile_image: '',
            })
          } else {
            cofoundingArray.push(card)
          }
        }
        this.setState({
          user: {
            ...user,
            co_founders: cofoundingArray,
          },
        })
      } else {
        this.setState({ loading, token, investor, startup, user })
      }
    } else {
      this.setState({ loading, token, investor, startup, user })
    }
  }

  // location edit functions
  handleLocation = location => {
    this.setState({
      user: {
        ...this.state.user,
        location: location,
      },
      changesMade: true,
    })
  }
  handleDeleteLocation = () => {
    this.setState({
      user: { ...this.state.user, location: '' },
      changesMade: true,
    })
  }
  // company name edit functions
  handleCompanyName = event => {
    this.setState({
      user: {
        ...this.state.user,
        company_name: event.target.value,
      },
      changesMade: true,
    })
  }
  handleDeleteCompanyName = () => {
    this.setState({
      user: { ...this.state.user, company_name: '' },
      changesMade: true,
    })
  }
  // team member number edit function
  handleNumberTeamMembers = num => {
    const diff = num - this.state.user.co_founders.length
    const card = {
      primary: '',
      name: '',
      position: [],
      bio: '',
      linkedin_link: '',
      profile_image: '',
    }

    if (diff > 0) {
      const cofoundingArray = this.state.user.co_founders
      for (let i = 0; i < diff; i++) {
        cofoundingArray.push(card)
      }

      this.setState({
        user: {
          ...this.state.user,
          co_founders: cofoundingArray,
          num_team_members: num,
        },
        changesMade: true,
      })
    } else if (diff < 0) {
      const cofoundingArray = this.state.user.co_founders
      for (let i = 0; i < Math.abs(diff); i++) {
        cofoundingArray.pop(card)
      }

      this.setState({
        user: {
          ...this.state.user,
          co_founders: cofoundingArray,
          num_team_members: num,
        },
        changesMade: true,
      })
    }
  }

  handleCFName = (i, event) => {
    const charCount = event.target.value.length
    const charLeft = 28 - charCount
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      {
        ...this.state.user.co_founders[i],
        name: event.target.value,
      },
      ...this.state.user.co_founders.slice(i + 1),
    ]
    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
        chars_left_name: charLeft,
      },
      changesMade: true,
    })
  }
  handleCFPosition = (i, event) => {
    let newPosition = [event.target.id]
    if (this.state.user.co_founders[i].position[0] === event.target.id) {
      newPosition = []
    }
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      {
        ...this.state.user.co_founders[i],
        position: newPosition,
      },
      ...this.state.user.co_founders.slice(i + 1),
    ]
    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
      },
      changesMade: true,
    })
  }
  handleOtherCFPosition = (i, event) => {
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      {
        ...this.state.user.co_founders[i],
        position: [event.target.value],
      },
      ...this.state.user.co_founders.slice(i + 1),
    ]
    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
      },
      changesMade: true,
    })
  }
  handleCFBio = (i, event) => {
    const charCount = event.target.value.length
    const charLeft = 600 - charCount
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      {
        ...this.state.user.co_founders[i],
        bio: event.target.value,
      },
      ...this.state.user.co_founders.slice(i + 1),
    ]
    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
        chars_left_bio: charLeft,
      },
      changesMade: true,
    })
  }
  handleCFLinkedIn = (i, event) => {
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      {
        ...this.state.user.co_founders[i],
        linkedin_link: event.target.value,
      },
      ...this.state.user.co_founders.slice(i + 1),
    ]

    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
      },
      changesMade: true,
    })
  }
  removeCofounder = i => {
    const newCofounders = [
      ...this.state.user.co_founders.slice(0, i),
      ...this.state.user.co_founders.slice(i + 1),
    ]
    const newNumMembers = this.state.user.num_team_members - 1
    this.setState({
      user: {
        ...this.state.user,
        co_founders: newCofounders,
        num_team_members: newNumMembers,
      },
      changesMade: true,
    })
  }
  addCoFounder = () => {
    const card = {
      primary: '',
      name: '',
      position: [],
      bio: '',
      linkedin_link: '',
      profile_image: '',
    }
    const cofoundingArray = this.state.user.co_founders
    const newNumMembers = this.state.user.num_team_members + 1

    cofoundingArray.push(card)

    if (this.state.user.num_team_members < 5) {
      this.setState({
        user: {
          ...this.state.user,
          co_founders: cofoundingArray,
          num_team_members: newNumMembers,
        },
        changesMade: true,
      })
    }
  }
  // pitch edit function
  handlePitch = event => {
    const charCount = event.target.value.length
    const charLeft = 500 - charCount
    this.setState({
      user: {
        ...this.state.user,
        startup_pitch: event.target.value,
        chars_left_pitch: charLeft,
      },
      changesMade: true,
    })
  }
  // fundraising edit functions
  handleRoundSize = event => {
    this.setState({
      user: {
        ...this.state.user,
        round_size: event.target.value,
      },
      changesMade: true,
    })
  }
  handleRaised = event => {
    this.setState({
      user: {
        ...this.state.user,
        raised: event.target.value,
      },
      changesMade: true,
    })
  }

  isTotalRoundSizeZero = () => {
    return getNumberFromCurrency(this.state.user.round_size) === 0
  }

  isRaisedGreaterOrEqualToRoundSize = () => {
    return isDisplayRaisedGreaterOrEqualToRoundSize(this.state.user.raised, this.state.user.round_size)
  }

  // traction/progress edit function
  handleProgress = event => {
    const progressTarget = event

    if (this.state.user.progress.includes(progressTarget)) {
      const filtered = this.state.user.progress.filter(i => i !== progressTarget)
      this.setState({
        user: {
          ...this.state.user,
          progress: filtered,
        },
        changesMade: true,
      })
    } else {
      if (this.state.user.progress.length < 3) {
        const progressArray = this.state.user.progress
        progressArray.push(progressTarget)

        this.setState({
          user: {
            ...this.state.user,
            progress: progressArray,
          },
          changesMade: true,
        })
      }
    }
  }
  // website edit function
  handleWebsite = event => {
    if (event.target.value && event.target.value !== 'https://') {
      const newValue = `https://${event.target.value.replace('https://', '')}`
      this.setState({
        user: {
          ...this.state.user,
          company_link: newValue,
        },
        changesMade: true,
      })
    } else {
      this.setState({
        user: {
          ...this.state.user,
          company_link: '',
        },
        changesMade: true,
      })
    }
  }
  // file edit functions
  handleFileChange = e => {
    const file = e.target.files[0]

    this.props.uploadPDF()
    this.props.showSlideDeckUploadModal(file)

    this.props.fetchStartupFileUpload(file, 'application', 'slide_deck').then(success => {
      if (success) {
        this.setState({
          file: file,
          user: {
            ...this.state.user,
            slide_deck: file.name,
          },
          changesMade: true,
        })
        this.props.hideSlideDeckUploadModal()
      } else {
        this.props.slideDeckUploadFailed()
      }
    })
  }
  // photo functions
  previewFile = () => {
    this.props
      .fetchStartupCFFileUpload(document.querySelector('input[type=file]').files[0], 'image')
      .then(res => {
        this.setState({
          user: {
            ...this.state.user,
            profile_pic_link: res,
          },
          changesMade: true,
        })
      })
  }
  removeFile = e => {
    e.preventDefault()
    this.props.removeSlideDeck().then(() =>
      this.setState({
        user: {
          ...this.state.user,
          slide_deck: null,
        },
        changesMade: true,
      }),
    )
  }
  handleEditPhoto = event => {
    event.preventDefault()

    if (this.refs.myavata.files[0]) {
      var fileObj = this.refs.myavata.files[0]
      this.setState({ display: false, changesMade: true })
    }
    this.props.editPhoto(fileObj)
  }
  handleCFPhoto = (e, i) => {
    this.props.fetchStartupCFFileUpload(e.target.files[0], 'image').then(res => {
      const newCofounders = [
        ...this.state.user.co_founders.slice(0, i),
        {
          ...this.state.user.co_founders[i],
          profile_image: res,
        },
        ...this.state.user.co_founders.slice(i + 1),
      ]
      this.setState({
        user: {
          ...this.state.user,
          co_founders: newCofounders,
        },
        changesMade: true,
      })
    })
  }

  render() {
    const { fetchStartupUserInfoUpdate, setSlideDeckModal, fetchStartupUserCompletion } = this.props
    let { user } = this.state
    const { changesMade } = this.state

    const mappedNumberTeamMembers = []
    for (let i = 0; i < this.state.user.num_team_members; i++) {
      mappedNumberTeamMembers.push(i)
    }

    const positionsTaken = []
    for (let i = 0; i < this.state.user.co_founders.length; i++) {
      positionsTaken.push(this.state.user.co_founders[i].position[0])
    }

    const progressChoices = ['Idea/Sketches', 'Mockups/Renderings', 'Prototype/Pre-Launch', 'Beta Launched']
    const progressChoices2 = [
      'Taking Preorders',
      'Product Launched',
      'Early Users Acquired',
      'Early Revenue Generated',
    ]

    let completedCofounders = 0
    this.state.user.co_founders.forEach(c => {
      if (c.primary) {
        if (
          c.name !== '' &&
          c.position.length &&
          c.bio !== '' &&
          c.linkedin_link !== '' &&
          c.linkedin_link !== null
        ) {
          completedCofounders += 1
        }
      } else {
        if (c.name !== '' && c.position.length && c.bio !== '') {
          completedCofounders += 1
        }
      }
    })

    let cofounderEmpty = 0
    this.state.user.co_founders.forEach(c => {
      if (
        c.name === '' &&
        c.position.length === 0 &&
        c.bio === '' &&
        c.linked_link === '' &&
        c.profile_image === ''
      ) {
        cofounderEmpty += 1
      }
    })

    return (
      <div>
        <Prompt when={changesMade} message="Are you sure you want to leave without saving your changes?" />
        <div className="d-none d-md-block" style={{ paddingTop: '120px', animation: `fadeIn 1s` }}>
          <div className="profile-edit">
            <Container className="text-justify">
              <Row className="pb-4 justify-content-between mx-auto profile-header">
                <Col className="investor-page-title">Profile</Col>
                <Col style={{ textAlign: 'end' }}>
                  <Button className="investor-preview-button" disabled>
                    Profile Preview
                  </Button>
                  <Button
                    className="investor-save-button"
                    onClick={() => {
                      this.setState({ saving: true })
                      fetchStartupUserInfoUpdate({
                        company_name: this.state.user.company_name,
                        location: this.state.user.location,
                        bio: this.state.user.bio,
                        sectors: this.state.user.sectors,
                        company_link: this.state.user.company_link,
                        startup_pitch: this.state.user.startup_pitch,
                        round_size: this.state.user.round_size,
                        raised: this.state.user.raised,
                        progress: this.state.user.progress,
                        position: this.state.user.position,
                        num_team_members: this.state.user.num_team_members,
                        co_founders: this.state.user.co_founders,
                        profile_pic_link: this.state.user.profile_pic_link,
                      })
                        .then(() => {
                          this.setState({ changesMade: false })
                        })
                        .then(() => {
                          this.props.history.push('/startup/profile')
                        })
                        .then(() => {
                          fetchStartupUserCompletion()
                        })
                    }}
                    disabled={
                      this.state.user.company_name === '' ||
                      this.state.user.location === '' ||
                      this.state.user.bio === '' ||
                      this.state.user.sectors.length === 0 ||
                      this.state.user.round_size === '$0' ||
                      this.state.user.raised === '' ||
                      this.state.user.progress.length === 0 ||
                      this.state.user.company_link === '' ||
                      cofounderEmpty ||
                      this.state.saving
                    }
                  >
                    {this.state.saving ? <Spinner size="sm" className="mr-2" /> : null}
                    Save
                  </Button>
                </Col>
              </Row>

              <Row className="pb-3">
                <Col sm={12} className="mx-auto">
                  <Col
                    sm={10}
                    className={
                      user.co_founders_check && user.company_logo_check
                        ? 'mx-auto p-3 profile-success'
                        : 'mx-auto p-3 profile-fail'
                    }
                  >
                    {user.co_founders_check && user.company_logo_check ? (
                      <>
                        <span className="font-weight-700">Profile complete!</span>
                        &nbsp; You can now access relevant investors in your Discover portal.
                      </>
                    ) : (
                      <>
                        <span className="font-weight-700">
                          Complete your profile to see investors in your Discover portal.
                        </span>{' '}
                        &nbsp; Required remaining: {user.company_logo_check ? null : ' Logo'}
                        {!user.company_logo_check && !user.co_founders_check ? ',' : null}
                        {user.co_founders_check ? null : ' Founding Team Info'}
                      </>
                    )}
                  </Col>
                </Col>
              </Row>

              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <p>
                    {this.state.user.location === '' && (
                      <span className="unfilled-msg">Complete your location information.</span>
                    )}
                    {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? null : (
                      <span className="unfilled-msg">Add your company's logo.</span>
                    )}
                  </p>
                  <Card
                    body
                    className={
                      this.state.user.location === '' ||
                      (!user.profile_pic_link && user.profile_pic_link !== 'None')
                        ? 'px-5 unfilled'
                        : 'px-5'
                    }
                  >
                    <Row>
                      <Col style={{ maxWidth: 'fit-content' }}>
                        <div style={{ textAlign: 'center' }}>
                          {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? (
                            <>
                              <div
                                style={{
                                  color: '#333',
                                  fontSize: '14px',
                                }}
                                className="mb-1"
                              >
                                *Image will be circle-cropped to the smallest width/length.
                              </div>
                              <div className="img-container">
                                <img
                                  src={user.profile_pic_link}
                                  alt=""
                                  id="avataImg"
                                  shape="circle"
                                  className="investor-profile-pic"
                                  style={{
                                    filter: 'brightness(50%)',
                                    objectFit: 'cover',
                                  }}
                                />
                                <input
                                  style={{ display: 'none' }}
                                  type="file"
                                  id="filePicker"
                                  name="file"
                                  ref="myavata"
                                  placeholder="profile image"
                                  onChange={this.previewFile}
                                />
                                <label htmlFor="filePicker">
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      textAlign: 'center',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                    className="profile-img-change"
                                  >
                                    Replace <br></br> Logo
                                  </div>
                                </label>
                              </div>
                              <Button
                                className="remove-pic"
                                onClick={() => {
                                  this.setState({
                                    user: {
                                      ...this.state.user,
                                      profile_pic_link: null,
                                    },
                                  })
                                }}
                              >
                                Remove Logo
                              </Button>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  color: '#333',
                                  fontSize: '14px',
                                }}
                                className="mb-1"
                              >
                                *Image will be circle-cropped to the smallest width/length.
                              </div>
                              <div className="img-container">
                                <img
                                  src={startUpDefaultIcon}
                                  alt=""
                                  id="avataImg"
                                  className="investor-profile-pic"
                                  style={{
                                    filter: 'brightness(20%)',
                                  }}
                                />

                                <input
                                  style={{ display: 'none' }}
                                  type="file"
                                  id="filePicker"
                                  name="file"
                                  ref="myavata"
                                  placeholder="profile image"
                                  onChange={this.previewFile}
                                />
                                <label htmlFor="filePicker">
                                  <div className="centered-img-text-1">
                                    Add <br></br> Logo
                                  </div>
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col className="pt-3 pl-5">
                        <div className="investor-profile-edit-title pb-2">Company Name</div>
                        <div>
                          <Input
                            style={{ height: '3rem' }}
                            value={this.state.user.company_name}
                            onChange={this.handleCompanyName}
                          />
                        </div>
                      </Col>
                      <Col className="pt-3">
                        <div className="investor-profile-edit-title pb-2">Location</div>
                        <div>
                          <LocationAPI
                            id="location-web"
                            value={this.state.user.location}
                            onLocationChange={location => this.handleLocation(location)}
                            onDeleteLocation={this.handleDeleteLocation}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <p>
                    {completedCofounders !== user.num_team_members ||
                    (this.state.user.co_founders[0] &&
                      (!this.state.user.co_founders[0].linkedin_link ||
                        !this.state.user.co_founders[0].name ||
                        !this.state.user.co_founders[0].position ||
                        !this.state.user.co_founders[0].bio)) ||
                    (this.state.user.co_founders[1] &&
                      (!this.state.user.co_founders[1].name ||
                        !this.state.user.co_founders[1].position ||
                        !this.state.user.co_founders[1].bio)) ||
                    (this.state.user.co_founders[2] &&
                      (!this.state.user.co_founders[2].name ||
                        !this.state.user.co_founders[2].position ||
                        !this.state.user.co_founders[2].bio)) ||
                    (this.state.user.co_founders[3] &&
                      (!this.state.user.co_founders[3].name ||
                        !this.state.user.co_founders[3].position ||
                        !this.state.user.co_founders[3].bio)) ||
                    (this.state.user.co_founders[4] &&
                      (!this.state.user.co_founders[4].name ||
                        !this.state.user.co_founders[4].position ||
                        !this.state.user.co_founders[4].bio)) ? (
                      <span className="unfilled-msg">Complete your founding team's information.</span>
                    ) : null}
                  </p>
                  <Card
                    body
                    className={
                      completedCofounders !== user.num_team_members ||
                      (this.state.user.co_founders[0] &&
                        (!this.state.user.co_founders[0].linkedin_link ||
                          !this.state.user.co_founders[0].name ||
                          !this.state.user.co_founders[0].position ||
                          !this.state.user.co_founders[0].bio)) ||
                      (this.state.user.co_founders[1] &&
                        (!this.state.user.co_founders[1].name ||
                          !this.state.user.co_founders[1].position ||
                          !this.state.user.co_founders[1].bio)) ||
                      (this.state.user.co_founders[2] &&
                        (!this.state.user.co_founders[2].name ||
                          !this.state.user.co_founders[2].position ||
                          !this.state.user.co_founders[2].bio)) ||
                      (this.state.user.co_founders[3] &&
                        (!this.state.user.co_founders[3].name ||
                          !this.state.user.co_founders[3].position ||
                          !this.state.user.co_founders[3].bio)) ||
                      (this.state.user.co_founders[4] &&
                        (!this.state.user.co_founders[4].name ||
                          !this.state.user.co_founders[4].position ||
                          !this.state.user.co_founders[4].bio))
                        ? 'px-5 unfilled'
                        : 'px-5'
                    }
                  >
                    <CardTitle className="investor-profile-edit-title">
                      <Row>
                        <Col>Founding Team</Col>
                        <Col
                          className={`text-right investor-profile-edit-text ${
                            completedCofounders === user.num_team_members ? 'text-muted' : 'font-color-prime'
                          }`}
                        >
                          {completedCofounders}/{user.num_team_members} Profiles Completed
                          {completedCofounders === user.num_team_members ? '!' : ''}
                        </Col>
                      </Row>
                    </CardTitle>
                    <CardBody>
                      <div className="investor-profile-edit-text">
                        How many people are on {user.company_name}'s founding team?
                      </div>
                      <div className="py-3">
                        <Button
                          className={`how-many-buttons ${
                            this.state.user.num_team_members === 1 ? 'active-team-num' : 'inactive-team-num'
                          }`}
                          onClick={() => this.handleNumberTeamMembers(1)}
                        >
                          1
                        </Button>{' '}
                        <Button
                          className={`how-many-buttons ${
                            this.state.user.num_team_members === 2 ? 'active-team-num' : 'inactive-team-num'
                          }`}
                          onClick={() => this.handleNumberTeamMembers(2)}
                        >
                          2
                        </Button>{' '}
                        <Button
                          className={`how-many-buttons ${
                            this.state.user.num_team_members === 3 ? 'active-team-num' : 'inactive-team-num'
                          }`}
                          onClick={() => this.handleNumberTeamMembers(3)}
                        >
                          3
                        </Button>{' '}
                        <Button
                          className={`how-many-buttons ${
                            this.state.user.num_team_members === 4 ? 'active-team-num' : 'inactive-team-num'
                          }`}
                          onClick={() => this.handleNumberTeamMembers(4)}
                        >
                          4
                        </Button>{' '}
                        <Button
                          className={`how-many-buttons ${
                            this.state.user.num_team_members === 5 ? 'active-team-num' : 'inactive-team-num'
                          }`}
                          onClick={() => this.handleNumberTeamMembers(5)}
                        >
                          5
                        </Button>{' '}
                      </div>
                      <div>
                        <Row className="my-3 d-flex justify-content-left investor-web-team">
                          {this.state.user.co_founders.map((member, i) => (
                            <Col key={i} xs={6} className="mb-3">
                              <div
                                className={
                                  (this.state.user.co_founders[0].name === member.name &&
                                    !member.linkedin_link) ||
                                  !member.name ||
                                  !member.position ||
                                  !member.bio
                                    ? ' d-flex flex-column justify-content-between unfilled-cofounders'
                                    : 'border d-flex flex-column justify-content-between'
                                }
                                style={{
                                  minHeight: '100%',
                                  borderRadius: '10px',
                                }}
                              >
                                <div className="p-3">
                                  <div className="text-center mt-0 mb-3">
                                    {member.profile_image ? (
                                      <>
                                        <div className="img-container">
                                          <img
                                            src={member.profile_image}
                                            alt=""
                                            className="investor-profile-pic"
                                            style={{
                                              filter: 'brightness(50%)',
                                              objectFit: 'cover',
                                            }}
                                          />
                                          <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id={`filePicker${i}`}
                                            name="file"
                                            placeholder="profile image"
                                            onChange={e => this.handleCFPhoto(e, i)}
                                          />
                                          <label htmlFor={`filePicker${i}`}>
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                textAlign: 'center',
                                                color: 'white',
                                                fontWeight: 'bold',
                                              }}
                                              className="profile-img-change"
                                            >
                                              Replace <br></br> Photo
                                            </div>
                                          </label>
                                        </div>
                                        <Button
                                          className="remove-pic"
                                          onClick={() => {
                                            const newCofounders = [
                                              ...this.state.user.co_founders.slice(0, i),
                                              {
                                                ...this.state.user.co_founders[i],
                                                profile_image: null,
                                              },
                                              ...this.state.user.co_founders.slice(i + 1),
                                            ]
                                            this.setState({
                                              user: {
                                                ...this.state.user,
                                                co_founders: newCofounders,
                                              },
                                            })
                                          }}
                                        >
                                          Remove Photo
                                        </Button>
                                      </>
                                    ) : (
                                      <div className="img-container">
                                        <img
                                          src={membericon}
                                          alt=""
                                          className="investor-profile-pic"
                                          style={{
                                            filter: 'brightness(60%)',
                                          }}
                                        />
                                        <input
                                          style={{ display: 'none' }}
                                          type="file"
                                          id={`filePicker${i}`}
                                          name="file"
                                          placeholder="profile image"
                                          onChange={e => this.handleCFPhoto(e, i)}
                                        />
                                        <label htmlFor={`filePicker${i}`}>
                                          <div className="centered-img-text-1">
                                            Add <br></br> Photo
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>

                                  <h5 className="pt-2 font-weight-500">
                                    {`${this.state.user.first_name} ${this.state.user.last_name}` ===
                                    member.name
                                      ? 'Your Full Name'
                                      : "Co-Founder's Full Name*"}
                                  </h5>
                                  {`${this.state.user.first_name} ${this.state.user.last_name}` ===
                                  member.name ? (
                                    <h6
                                      style={{
                                        backgroundColor: '#ECECEC',
                                        height: '38px',
                                        lineHeight: '25px',
                                        borderRadius: '5px',
                                      }}
                                      className="px-3 py-2"
                                    >
                                      {member.name}
                                    </h6>
                                  ) : (
                                    <Input
                                      className="mb-2"
                                      value={this.state.user.co_founders[i].name}
                                      maxLength="28"
                                      onChange={event => this.handleCFName(i, event)}
                                    />
                                  )}
                                  <h5 className="pt-2 font-weight-500">Position*</h5>
                                  <div className="d-flex justify-content-center mb-2">
                                    <Button
                                      className={`mr-1 ipad-positions ${
                                        member.position[0] === 'CEO' || member.position[0] === 'ceo'
                                          ? 'active-team-num'
                                          : 'inactive-team-num'
                                      }`}
                                      style={{
                                        marginLeft: '2px',
                                        marginRight: '2px',
                                        fontSize: '14px',
                                      }}
                                      disabled={
                                        (member.position[0] !== 'CEO' && positionsTaken.includes('CEO')) ||
                                        (member.position[0] !== 'ceo' && positionsTaken.includes('ceo'))
                                      }
                                      onClick={event => this.handleCFPosition(i, event)}
                                      id="CEO"
                                    >
                                      CEO
                                    </Button>{' '}
                                    <Button
                                      style={{
                                        marginLeft: '2px',
                                        marginRight: '2px',
                                        fontSize: '14px',
                                      }}
                                      className={`mr-1 ipad-positions ${
                                        member.position[0] === 'CTO' || member.position[0] === 'cto'
                                          ? 'active-team-num'
                                          : 'inactive-team-num'
                                      }`}
                                      disabled={
                                        (member.position[0] !== 'CTO' && positionsTaken.includes('CTO')) ||
                                        (member.position[0] !== 'cto' && positionsTaken.includes('cto'))
                                      }
                                      onClick={event => this.handleCFPosition(i, event)}
                                      id="CTO"
                                    >
                                      CTO
                                    </Button>{' '}
                                    <Button
                                      style={{
                                        marginLeft: '2px',
                                        marginRight: '2px',
                                        fontSize: '14px',
                                      }}
                                      className={`mr-1 ipad-positions ${
                                        member.position[0] === 'COO' || member.position[0] === 'coo'
                                          ? 'active-team-num'
                                          : 'inactive-team-num'
                                      }`}
                                      disabled={
                                        (member.position[0] !== 'COO' && positionsTaken.includes('COO')) ||
                                        (member.position[0] !== 'coo' && positionsTaken.includes('coo'))
                                      }
                                      onClick={event => this.handleCFPosition(i, event)}
                                      id="COO"
                                    >
                                      COO
                                    </Button>{' '}
                                    <Button
                                      style={{
                                        marginLeft: '2px',
                                        marginRight: '2px',
                                        fontSize: '14px',
                                      }}
                                      className={`mr-1 ipad-positions ${
                                        member.position[0] === 'CFO' || member.position[0] === 'cfo'
                                          ? 'active-team-num'
                                          : 'inactive-team-num'
                                      }`}
                                      disabled={
                                        (member.position[0] !== 'CFO' && positionsTaken.includes('CFO')) ||
                                        (member.position[0] !== 'cfo' && positionsTaken.includes('cfo'))
                                      }
                                      onClick={event => this.handleCFPosition(i, event)}
                                      id="CFO"
                                    >
                                      CFO
                                    </Button>{' '}
                                    <Button
                                      style={{
                                        marginLeft: '2px',
                                        marginRight: '2px',
                                        fontSize: '14px',
                                      }}
                                      className={` ipad-positions ${
                                        member.position[0] === 'CMO' || member.position[0] === 'cmo'
                                          ? 'active-team-num'
                                          : 'inactive-team-num'
                                      }`}
                                      disabled={
                                        (member.position[0] !== 'CMO' && positionsTaken.includes('CMO')) ||
                                        (member.position[0] !== 'cmo' && positionsTaken.includes('cmo'))
                                      }
                                      onClick={event => this.handleCFPosition(i, event)}
                                      id="CMO"
                                    >
                                      CMO
                                    </Button>{' '}
                                  </div>
                                  <Input
                                    className="mb-2"
                                    maxLength="28"
                                    placeholder="Other position (e.g. Head of Product)"
                                    value={
                                      member.position[0] === 'CMO' ||
                                      member.position[0] === 'cmo' ||
                                      member.position[0] === 'COO' ||
                                      member.position[0] === 'coo' ||
                                      member.position[0] === 'CFO' ||
                                      member.position[0] === 'cfo' ||
                                      member.position[0] === 'CEO' ||
                                      member.position[0] === 'ceo' ||
                                      member.position[0] === 'CTO' ||
                                      member.position[0] === 'cto'
                                        ? ''
                                        : member.position[0]
                                    }
                                    disabled={
                                      member.position[0] === 'CMO' ||
                                      member.position[0] === 'cmo' ||
                                      member.position[0] === 'COO' ||
                                      member.position[0] === 'coo' ||
                                      member.position[0] === 'CFO' ||
                                      member.position[0] === 'cfo' ||
                                      member.position[0] === 'CEO' ||
                                      member.position[0] === 'ceo' ||
                                      member.position[0] === 'CTO' ||
                                      member.position[0] === 'cto'
                                    }
                                    onChange={event => this.handleOtherCFPosition(i, event)}
                                  />
                                  <h5 className="pt-2 font-weight-500 text-left">
                                    Short Bio (1-2 sentences)*
                                  </h5>
                                  <textarea
                                    maxLength="600"
                                    onChange={event => this.handleCFBio(i, event)}
                                    className="short-bio-text-content-edit form-control"
                                    value={this.state.user.co_founders[i].bio}
                                  />
                                  <h5 className="pt-2 font-weight-500">
                                    {`${this.state.user.first_name} ${this.state.user.last_name}` ===
                                    member.name
                                      ? 'LinkedIn URL*'
                                      : 'LinkedIn URL'}
                                  </h5>
                                  <Input
                                    onChange={event => this.handleCFLinkedIn(i, event)}
                                    value={this.state.user.co_founders[i].linkedin_link}
                                    invalid={
                                      this.state.user.co_founders[i].linkedin_link
                                        ? !this.state.user.co_founders[i].linkedin_link.includes(
                                            'linkedin.com',
                                          )
                                        : null
                                    }
                                  />
                                </div>
                                <Button
                                  className="text-center mt-0 py-2 remove-cofounder-button"
                                  style={{
                                    backgroundColor: '#162642',
                                    color: 'white',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    borderRadius: 'unset',
                                    borderBottomRightRadius: '5px',
                                    borderBottomLeftRadius: '5px',
                                  }}
                                  disabled={
                                    `${this.state.user.first_name} ${this.state.user.last_name}` ===
                                    member.name
                                  }
                                  onClick={() => this.removeCofounder(i)}
                                >
                                  Remove Cofounder
                                </Button>
                              </div>
                            </Col>
                          ))}
                          {this.state.user.num_team_members < 5 &&
                          this.state.user.num_team_members % 2 !== 0 ? (
                            <Col xs={6} className="mb-3 add-cofounder-card">
                              <div
                                className="d-flex flex-column justify-content-between add-cofounder-border"
                                style={{
                                  minHeight: '100%',
                                  // height: "100px",
                                  borderRadius: '10px',
                                }}
                                onClick={() => this.addCoFounder()}
                              >
                                <div className="flex-column m-auto font-weight-700">
                                  <div className="add-cofounder-text">Add Cofounder</div>
                                  <div className="add-cofounder-button">
                                    <img className="plusicon" src={PlusIcon} alt="" />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {this.state.user.num_team_members < 5 &&
                          this.state.user.num_team_members % 2 === 0 ? (
                            <Col xs={12} className="mb-3 add-cofounder-card">
                              <div
                                className="d-flex flex-column justify-content-between add-cofounder-border"
                                style={{
                                  minHeight: '100%',
                                  height: '100px',
                                  borderRadius: '10px',
                                }}
                                onClick={() => this.addCoFounder()}
                              >
                                <div className="flex-column m-auto font-weight-700">
                                  <div className="add-cofounder-text">Add Cofounder</div>
                                  <div className="add-cofounder-button">
                                    <img className="plusicon" src={PlusIcon} alt="" />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <Card body className="px-5">
                    <CardTitle className="investor-profile-edit-title">Short Pitch</CardTitle>
                    <CardText>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        maxLength="500"
                        value={this.state.user.startup_pitch}
                        onChange={this.handlePitch}
                        style={{ height: '100px' }}
                        className="investor-profile-edit-text"
                      />
                    </CardText>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-3">
                <Col sm="10" className="mx-auto">
                  <Card body className="p-5">
                    <CardTitle>
                      <Row>
                        <Col>
                          <div className="investor-profile-edit-title">Sector</div>
                        </Col>
                        <Col
                          style={{
                            maxWidth: 'fit-content',
                            alignSelf: 'center',
                          }}
                        >
                          <Link className="investor-edit-change-button" to="/startup/editsector">
                            Change Sector
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                    <CardText
                      className="py-2 investor-profile-edit-sectors d-flex ml-3"
                      style={{ flexWrap: 'wrap' }}
                    >
                      {this.state.user.sectors.map((sector, index) => (
                        <span
                          key={index}
                          style={{
                            border: '1px solid #162642',
                            borderRadius: '30px',
                            padding: '10px 20px',
                            color: '#162642',
                            fontWeight: '700',
                            marginRight: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          {sector}
                        </span>
                      ))}
                    </CardText>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <Card body className="px-5">
                    <CardTitle className="investor-profile-edit-title">Fundraising</CardTitle>
                    <CardBody>
                      <Row>
                        <Col>
                          <h5 className="font-weight-600 pb-2">Round Size</h5>
                          <CurrencyInput
                            className={
                              'text-field investor-profile-edit-text form-control ' +
                              (this.isTotalRoundSizeZero() && 'red-colored-border')
                            }
                            value={this.state.user.round_size}
                            style={{ height: '3rem' }}
                            onChange={this.handleRoundSize}
                            placeholder={
                              this.state.user.round_size !== null ? this.state.user.round_size : '$100,000'
                            }
                          />
                        </Col>
                        <Col>
                          <h5 className="pb-2">
                            <span className="font-weight-600">Raised</span>
                            <span className="font-weight-400">&nbsp;(current round)</span>
                          </h5>
                          <CurrencyInput
                            className={
                              'text-field investor-profile-edit-text form-control ' +
                              (this.isRaisedGreaterOrEqualToRoundSize() && 'red-colored-border')
                            }
                            style={{ height: '3rem' }}
                            value={this.state.user.raised}
                            onChange={this.handleRaised}
                            placeholder={
                              this.state.user.raised !== null ? this.state.user.raised : '$100,000'
                            }
                          />
                        </Col>
                      </Row>
                      {(this.isTotalRoundSizeZero() && (
                        <Row>
                          <Col>
                            <InvalidUserInputAlert
                              id="startup-profile-edit-capital-alert"
                              description="Your current total round size cannot be 0."
                            />
                          </Col>
                        </Row>
                      )) ||
                        (this.isRaisedGreaterOrEqualToRoundSize() && (
                          <Row>
                            <Col>
                              <InvalidUserInputAlert
                                id="startup-profile-edit-capital-alert"
                                description="The amount you’ve raised already in this round must be less than your total round size."
                              />
                            </Col>
                          </Row>
                        ))}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <Card body className="px-5">
                    <CardTitle className="investor-profile-edit-title">
                      Traction (choose up to three)
                    </CardTitle>
                    <CardBody>
                      <Row>
                        <Col>
                          {progressChoices.map((p, index) => (
                            <div
                              key={index}
                              disabled={this.state.user.progress.length < 3 ? false : true}
                              className={
                                this.state.user.progress.includes(p)
                                  ? 'sector-text-color selected font-weight-bold invest investor-profile-edit-text'
                                  : 'sector-text-color-nonselected invest  investor-profile-edit-text'
                              }
                              style={{
                                width: '100%',
                              }}
                              onClick={() => this.handleProgress(p)}
                            >
                              <div className="checkbox  investor-profile-edit-text">
                                <input type="checkbox" checked={this.state.user.progress.includes(p)} />
                                <label>
                                  <span>{p}</span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </Col>
                        <Col>
                          {progressChoices2.map((p, index) => (
                            <div
                              key={index}
                              disabled={this.state.user.progress.length < 3 ? false : true}
                              className={
                                this.state.user.progress.includes(p)
                                  ? 'sector-text-color selected font-weight-bold invest  investor-profile-edit-text'
                                  : 'sector-text-color-nonselected invest  investor-profile-edit-text'
                              }
                              style={{
                                width: '100%',
                              }}
                              onClick={() => this.handleProgress(p)}
                            >
                              <div className="checkbox  investor-profile-edit-text">
                                <input type="checkbox" checked={this.state.user.progress.includes(p)} />
                                <label>
                                  <span>{p}</span>
                                </label>
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <Card body className="px-5">
                    <CardTitle className="investor-profile-edit-title">Website</CardTitle>
                    <CardText>
                      <Input
                        value={this.state.user.company_link}
                        onChange={this.handleWebsite}
                        style={{ height: '3rem' }}
                        className="investor-profile-edit-text"
                        invalid={
                          this.state.user.company_link
                            ? !validator.isURL(this.state.user.company_link)
                            : false
                        }
                      />
                    </CardText>
                  </Card>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col sm="10" className="mx-auto">
                  <p>
                    {this.state.user.slide_deck && this.props.user.slide_deck ? null : (
                      <span className="unfilled-msg">Complete your slide deck upload.</span>
                    )}
                  </p>
                  <Card
                    body
                    className={
                      this.state.user.slide_deck && this.props.user.slide_deck ? 'px-5' : 'px-5 unfilled'
                    }
                  >
                    <CardTitle className="investor-profile-edit-title">Attachments</CardTitle>
                    <CardBody>
                      {this.state.user.slide_deck && this.props.user.slide_deck ? (
                        <>
                          <Row>
                            <Col className="d-flex align-items-center">
                              <div
                                onClick={() => setSlideDeckModal(true)}
                                className="d-flex align-items-center pdf-icon"
                              >
                                <img src={pdfIcon} alt="default" style={{ width: '100px' }} />
                                <span className="pl-3 ml-3">
                                  <div
                                    className="investor-profile-edit-text"
                                    style={{
                                      textDecoration: 'underline',
                                      fontWeight: '700',
                                      fontSize: '23px',
                                    }}
                                  >
                                    {formatFileToName(this.state.user.slide_deck)}
                                  </div>
                                  <div className="investor-profile-edit-text">
                                    Click on the PDF Icon to <br />
                                    preview your slide deck.
                                  </div>
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <form className="text-right">
                                <input
                                  onChange={this.handleFileChange}
                                  type="file"
                                  className="input-file"
                                  id="file"
                                  accept="application/pdf"
                                ></input>
                                <label
                                  className="investor-edit-change-button"
                                  style={{ top: '25px' }}
                                  htmlFor="file"
                                >
                                  <div className="mb-0">Replace File</div>
                                </label>
                              </form>
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            <Button className="remove-file-button" onClick={this.removeFile}>
                              Remove File
                            </Button>
                          </Row>
                        </>
                      ) : (
                        <Row>
                          <Col className="d-flex align-items-center ml-25">
                            <img
                              src={pdfIcon}
                              alt="default"
                              style={{
                                width: '100px',
                                filter: 'grayscale(100%)',
                                opacity: '0.5',
                              }}
                            />
                            <span className="pl-3 text-muted" style={{ width: '400px' }}>
                              <h5 className="pt-3">No File Uploaded</h5>
                              Please only upload files in PDf format.
                            </span>
                          </Col>
                          <Col>
                            <form className="text-right">
                              <input
                                onChange={this.handleFileChange}
                                type="file"
                                className="input-file"
                                id="file"
                                accept="application/pdf"
                              ></input>
                              <label className="upload-button" htmlFor="file">
                                <h5 className="py-2 px-3 mb-0">Upload File</h5>
                              </label>
                            </form>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <MobileTabs
          tabOne="Basic Info"
          tabTwo="Team"
          tabThree="Progress"
          pageOne={
            <div>
              <form>
                <Card
                  body
                  style={{
                    border: 'none',
                    padding: '60px 0px',
                    zIndex: '1',
                    width: '100%',
                    maxWidth: '660px',
                    margin: 'auto',
                    height: '100%',
                    minHeight: '575px',
                    animation: `fadeIn 2.2s`,
                  }}
                >
                  <Col>
                    <Row style={{ display: 'block', margin: 'auto' }}>
                      {/* <div>
                          {user && user.profile_pic_link ? (
                            <img
                              src={user.profile_pic_link}
                              id="avataImg"
                              alt=""
                              shape="circle"
                              style={{
                                borderRadius: "50%",
                                filter: "brightness(50%)",
                                height: "140px",
                                width: "140px",
                                display: "block",
                                margin: "auto",
                              }} */}
                      <div style={{ textAlign: 'center' }}>
                        {user && user.profile_pic_link && user.profile_pic_link !== 'None' ? (
                          <>
                            <div
                              style={{
                                color: '#333',
                                fontSize: '14px',
                              }}
                              className="mb-1"
                            >
                              *Image will be circle-cropped to the smallest width/length.
                            </div>
                            <div className="img-container">
                              <img
                                src={user.profile_pic_link}
                                alt=""
                                id="avataImg"
                                shape="circle"
                                // className="investor-profile-pic"
                                style={{
                                  borderRadius: '50%',
                                  filter: 'brightness(50%)',
                                  height: '140px',
                                  width: '140px',
                                  display: 'block',
                                  margin: 'auto',
                                  objectFit: 'cover',
                                }}
                              />
                              <input
                                style={{ display: 'none' }}
                                type="file"
                                id="filePicker"
                                name="file"
                                ref="myavata"
                                placeholder="profile image"
                                onChange={this.previewFile}
                              />
                              <label htmlFor="filePicker">
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '45%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                  className="profile-img-change"
                                >
                                  Replace <br></br> Logo
                                </div>
                              </label>
                            </div>
                            <Button
                              style={{
                                display: 'block',
                                margin: '-20px auto 0px',
                              }}
                              className="remove-pic"
                              onClick={() => {
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    profile_pic_link: null,
                                  },
                                })
                              }}
                            >
                              Remove Logo
                            </Button>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                color: '#333',
                                fontSize: '14px',
                              }}
                              className="mb-1"
                            >
                              *Image will be circle-cropped to the smallest width/length.
                            </div>
                            <div className="img-container">
                              <img
                                src={startUpDefaultIcon}
                                alt=""
                                id="avataImg"
                                // className="investor-profile-pic"
                                style={{
                                  borderRadius: '50%',
                                  filter: 'brightness(50%)',
                                  height: '140px',
                                  width: '140px',
                                  display: 'block',
                                  margin: 'auto',
                                }}
                              />

                              <input
                                style={{ display: 'none' }}
                                type="file"
                                id="filePicker"
                                name="file"
                                ref="myavata"
                                placeholder="profile image"
                                onChange={this.previewFile}
                              />
                              <label htmlFor="filePicker">
                                <div className="centered-img-text-1">
                                  Add <br></br> Logo
                                </div>
                              </label>
                            </div>
                          </>
                        )}

                        {/* {(user && user.profile_pic_link) ||
                              this.state.display
                                ? ""
                                : ""} */}
                      </div>
                    </Row>
                    <Row className="pt-2 pb-4 mobile-row">
                      <div className="pb-1 mobile-header">Company Name</div>
                      <div className="mobile-info-no-margin">
                        <InputGroup>
                          <Input
                            style={{ height: '3rem' }}
                            value={this.state.user.company_name}
                            onChange={this.handleCompanyName}
                          />
                        </InputGroup>
                      </div>
                    </Row>
                    <Row className="pb-4 mobile-row">
                      <div className="pb-1 mobile-header">Location</div>
                      <div className="mobile-info-no-margin">
                        <LocationAPI
                          id="location-mobile"
                          value={this.state.user.location}
                          onLocationChange={location => this.handleLocation(location)}
                          onDeleteLocation={this.handleDeleteLocation}
                        />
                      </div>
                    </Row>
                    <Row className="pb-4 mobile-row">
                      <Row>
                        <Col className="mobile-header">Sector</Col>
                        <Col
                          style={{
                            maxWidth: 'fit-content',
                          }}
                        >
                          <Link
                            style={{
                              color: '#162642',
                              textDecoration: 'none',
                              fontWeight: '700',
                              fontFamily: 'Roboto',
                              fontSize: '16px',
                            }}
                            // onClick={this.toggleSectorMenu}
                            to="/startup/editsector"
                          >
                            Change Sector
                          </Link>
                        </Col>
                      </Row>
                      <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                        {this.state.user.sectors.map((sector, index) => (
                          <span key={index} className="mobile-list">
                            {sector}
                          </span>
                        ))}
                      </CardText>
                    </Row>
                    <Row className="pb-4 mobile-row">
                      <div className="pb-1 mobile-header">Short Pitch</div>
                      <div>
                        <Input
                          type="textarea"
                          name="text"
                          id="exampleText"
                          maxLength="500"
                          value={this.state.user.startup_pitch}
                          onChange={this.handlePitch}
                          style={{ height: '150px' }}
                          // className="mobile-info-no-margin"
                        />
                      </div>
                    </Row>

                    <Row className="pb-4 mobile-row">
                      <div className="pb-1 mobile-header">Website</div>
                      <div>
                        {' '}
                        <Input
                          value={this.state.user.company_link}
                          onChange={this.handleWebsite}
                          // className="mobile-info-no-margin"
                          invalid={
                            this.state.user.company_link
                              ? !validator.isURL(this.state.user.company_link)
                              : false
                          }
                        />
                      </div>
                    </Row>
                  </Col>
                  <div className="push" />
                </Card>
              </form>
            </div>
          }
          pageTwo={
            <div>
              <Card
                body
                style={{
                  border: 'none',
                  padding: '60px 0px',
                  zIndex: '1',
                  width: '100%',
                  maxWidth: '660px',
                  margin: 'auto',
                  height: '100%',
                  minHeight: '575px',
                  animation: `fadeIn 2.2s`,
                }}
              >
                <div
                  className={`mobile-header font-weight-700 pb-4 ${
                    completedCofounders === user.num_team_members ? 'text-muted' : 'font-color-prime'
                  }`}
                >
                  {completedCofounders}/{user.num_team_members} Profiles Completed
                  {completedCofounders === user.num_team_members ? '!' : ''}
                </div>
                <Col>
                  <Row className="pb-4 mobile-row">
                    <div className="pb-1 mobile-header text-center m-auto w-100 d-block">
                      Number of people on {user.company_name}'s founding team:
                    </div>
                    <div className="mx-auto mobile-info-no-margin d-flex justify-center">
                      <Button
                        className={` ${
                          this.state.user.num_team_members === 1
                            ? 'active-team-num edit-list-buttons number-padding'
                            : 'inactive-team-num edit-list-buttons number-padding'
                        }`}
                        onClick={() => this.handleNumberTeamMembers(1)}
                      >
                        1
                      </Button>{' '}
                      <Button
                        className={`${
                          this.state.user.num_team_members === 2
                            ? 'active-team-num edit-list-buttons number-padding'
                            : 'inactive-team-num edit-list-buttons number-padding'
                        }`}
                        onClick={() => this.handleNumberTeamMembers(2)}
                      >
                        2
                      </Button>{' '}
                      <Button
                        className={` ${
                          this.state.user.num_team_members === 3
                            ? 'active-team-num edit-list-buttons number-padding'
                            : 'inactive-team-num edit-list-buttons number-padding'
                        }`}
                        onClick={() => this.handleNumberTeamMembers(3)}
                      >
                        3
                      </Button>{' '}
                      <Button
                        className={` ${
                          this.state.user.num_team_members === 4
                            ? 'active-team-num edit-list-buttons number-padding'
                            : 'inactive-team-num edit-list-buttons number-padding'
                        }`}
                        onClick={() => this.handleNumberTeamMembers(4)}
                      >
                        4
                      </Button>{' '}
                      <Button
                        className={` ${
                          this.state.user.num_team_members === 5
                            ? 'active-team-num edit-list-buttons number-padding'
                            : 'inactive-team-num edit-list-buttons number-padding'
                        }`}
                        onClick={() => this.handleNumberTeamMembers(5)}
                      >
                        5
                      </Button>{' '}
                    </div>
                  </Row>
                  <div>
                    {this.state.user.num_team_members < 6 ? (
                      <Col className="mb-4 add-cofounder-card">
                        <div
                          className="mobile-info-no-margin d-flex justify-content-between add-cofounder-border"
                          style={{
                            maxHeight: '45px',
                            width: '100%',
                            margin: 'auto',
                          }}
                          onClick={() => this.addCoFounder()}
                        >
                          <div className="m-auto">
                            <div
                              style={{
                                position: 'relative',
                                top: '0px',
                                fontWeight: '900',
                              }}
                            >
                              <span
                                style={{
                                  position: 'relative',
                                  top: '10px',

                                  marginRight: '20px',
                                }}
                              >
                                <img
                                  style={{ maxHeight: '20px', width: '20px' }}
                                  className="plusicon"
                                  src={PlusIcon}
                                  alt=""
                                />
                              </span>{' '}
                              Add Cofounder
                            </div>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </div>

                  {this.state.user.co_founders.map((member, i) => (
                    <Row key={i} className="mb-3">
                      <div
                        className="border d-flex flex-column justify-content-between"
                        style={{ minHeight: '100%', borderRadius: '10px' }}
                      >
                        <div className="p-3">
                          <div className="text-center mt-0 mb-3 d-block m-auto">
                            {member.profile_image ? (
                              <>
                                <div className="img-container d-block m-auto">
                                  <img
                                    src={member.profile_image}
                                    alt=""
                                    style={{
                                      filter: 'brightness(50%)',
                                      height: '120px',
                                      width: '120px',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    id={`filePicker${i}`}
                                    name="file"
                                    placeholder="profile image"
                                    onChange={e => this.handleCFPhoto(e, i)}
                                  />
                                  <label htmlFor={`filePicker${i}`}>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        textAlign: 'center',
                                        color: 'white',
                                        fontWeight: 'bold',
                                      }}
                                      className="profile-img-change"
                                    >
                                      Replace <br></br> Photo
                                    </div>
                                  </label>
                                </div>
                                <Button
                                  className="remove-pic"
                                  onClick={() => {
                                    const newCofounders = [
                                      ...this.state.user.co_founders.slice(0, i),
                                      {
                                        ...this.state.user.co_founders[i],
                                        profile_image: null,
                                      },
                                      ...this.state.user.co_founders.slice(i + 1),
                                    ]
                                    this.setState({
                                      user: {
                                        ...this.state.user,
                                        co_founders: newCofounders,
                                      },
                                    })
                                  }}
                                >
                                  Remove Photo
                                </Button>
                              </>
                            ) : (
                              <div className="img-container d-block m-auto">
                                <img
                                  src={membericon}
                                  alt=""
                                  style={{
                                    filter: 'brightness(60%)',
                                    height: '120px',
                                    width: '120px',
                                  }}
                                />
                                <input
                                  style={{ display: 'none' }}
                                  type="file"
                                  id={`filePicker${i}`}
                                  name="file"
                                  placeholder="profile image"
                                  onChange={e => this.handleCFPhoto(e, i)}
                                />
                                <label htmlFor={`filePicker${i}`}>
                                  <div class="centered-img-text-1">
                                    Add <br></br> Photo
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>

                          <h5 className="pt-3 mobile-header">
                            {`${this.state.user.first_name} ${this.state.user.last_name}` === member.name
                              ? 'Your Full Name'
                              : "Co-Founder's Full Name*"}
                          </h5>
                          {`${this.state.user.first_name} ${this.state.user.last_name}` === member.name ? (
                            <h6
                              style={{
                                backgroundColor: 'white',
                                borderRadius: '5px',
                              }}
                              className="px-3 py-1"
                            >
                              {member.name}
                            </h6>
                          ) : (
                            <Input
                              className="mb-2"
                              value={this.state.user.co_founders[i].name}
                              maxLength="28"
                              onChange={event => this.handleCFName(i, event)}
                            />
                          )}
                          <h5 className="pt-3 mobile-header">Position*</h5>
                          <div className="d-flex justify-content-center mb-2">
                            <Button
                              className={` ${
                                member.position[0] === 'CEO' || member.position[0] === 'ceo'
                                  ? 'active-team-num edit-list-buttons'
                                  : 'inactive-team-num edit-list-buttons'
                              }`}
                              disabled={
                                (member.position[0] !== 'CEO' && positionsTaken.includes('CEO')) ||
                                (member.position[0] !== 'ceo' && positionsTaken.includes('ceo'))
                              }
                              onClick={event => this.handleCFPosition(i, event)}
                              id="CEO"
                            >
                              CEO
                            </Button>{' '}
                            <Button
                              className={` ${
                                member.position[0] === 'CTO' || member.position[0] === 'cto'
                                  ? 'active-team-num edit-list-buttons'
                                  : 'inactive-team-num edit-list-buttons'
                              }`}
                              disabled={
                                (member.position[0] !== 'CTO' && positionsTaken.includes('CTO')) ||
                                (member.position[0] !== 'cto' && positionsTaken.includes('cto'))
                              }
                              onClick={event => this.handleCFPosition(i, event)}
                              id="CTO"
                            >
                              CTO
                            </Button>{' '}
                            <Button
                              className={`${
                                member.position[0] === 'COO' || member.position[0] === 'coo'
                                  ? 'active-team-num edit-list-buttons'
                                  : 'inactive-team-num edit-list-buttons'
                              }`}
                              disabled={
                                (member.position[0] !== 'COO' && positionsTaken.includes('COO')) ||
                                (member.position[0] !== 'coo' && positionsTaken.includes('coo'))
                              }
                              onClick={event => this.handleCFPosition(i, event)}
                              id="COO"
                            >
                              COO
                            </Button>{' '}
                            <Button
                              className={` ${
                                member.position[0] === 'CFO' || member.position[0] === 'cfo'
                                  ? 'active-team-num edit-list-buttons'
                                  : 'inactive-team-num edit-list-buttons'
                              }`}
                              disabled={
                                (member.position[0] !== 'CFO' && positionsTaken.includes('CFO')) ||
                                (member.position[0] !== 'cfo' && positionsTaken.includes('cfo'))
                              }
                              onClick={event => this.handleCFPosition(i, event)}
                              id="CFO"
                            >
                              CFO
                            </Button>{' '}
                            <Button
                              className={`${
                                member.position[0] === 'CMO' || member.position[0] === 'cmo'
                                  ? 'active-team-num edit-list-buttons'
                                  : 'inactive-team-num edit-list-buttons'
                              }`}
                              disabled={
                                (member.position[0] !== 'CMO' && positionsTaken.includes('CMO')) ||
                                (member.position[0] !== 'cmo' && positionsTaken.includes('cmo'))
                              }
                              onClick={event => this.handleCFPosition(i, event)}
                              id="CMO"
                            >
                              CMO
                            </Button>{' '}
                          </div>
                          <Input
                            className="mb-2"
                            maxLength="28"
                            placeholder="Other position (e.g. Head of Product)"
                            value={
                              member.position[0] === 'CMO' ||
                              member.position[0] === 'cmo' ||
                              member.position[0] === 'COO' ||
                              member.position[0] === 'coo' ||
                              member.position[0] === 'CFO' ||
                              member.position[0] === 'cfo' ||
                              member.position[0] === 'CEO' ||
                              member.position[0] === 'ceo' ||
                              member.position[0] === 'CTO' ||
                              member.position[0] === 'cto'
                                ? ''
                                : member.position[0]
                            }
                            disabled={
                              member.position[0] === 'CMO' ||
                              member.position[0] === 'cmo' ||
                              member.position[0] === 'COO' ||
                              member.position[0] === 'coo' ||
                              member.position[0] === 'CFO' ||
                              member.position[0] === 'cfo' ||
                              member.position[0] === 'CEO' ||
                              member.position[0] === 'ceo' ||
                              member.position[0] === 'CTO' ||
                              member.position[0] === 'cto'
                            }
                            onChange={event => this.handleOtherCFPosition(i, event)}
                          />
                          <h5 className="pt-3 mobile-header">Short Bio (1-2 sentences)*</h5>
                          <textarea
                            onChange={event => this.handleCFBio(i, event)}
                            className="short-bio-text-content-edit"
                            maxLength="600"
                            value={this.state.user.co_founders[i].bio}
                          />
                          <h5 className="pt-3 mobile-header">
                            {' '}
                            {`${this.state.user.first_name} ${this.state.user.last_name}` === member.name
                              ? 'LinkedIn URL*'
                              : 'LinkedIn URL'}
                          </h5>
                          <Input
                            onChange={event => this.handleCFLinkedIn(i, event)}
                            value={this.state.user.co_founders[i].linkedin_link}
                            invalid={
                              this.state.user.co_founders[i].linkedin_link
                                ? !this.state.user.co_founders[i].linkedin_link.includes('linkedin.com')
                                : null
                            }
                          />
                        </div>
                        {`${this.state.user.first_name} ${this.state.user.last_name}` === member.name ? (
                          ''
                        ) : (
                          <Button
                            className="text-center mt-0 py-2"
                            style={{
                              backgroundColor: '#162642',
                              outline: 'none',
                              border: '1px solid #162642',
                              color: 'white',
                              width: '100%',
                              fontWeight: 'bold',
                              borderRadius: 'unset',
                              borderBottomRightRadius: '5px',
                              borderBottomLeftRadius: '5px',
                            }}
                            disabled={
                              `${this.state.user.first_name} ${this.state.user.last_name}` === member.name
                            }
                            onClick={() => this.removeCofounder(i)}
                          >
                            Remove Cofounder
                          </Button>
                        )}
                      </div>
                    </Row>
                  ))}
                </Col>
              </Card>
            </div>
          }
          pageThree={
            <div>
              <Card
                body
                style={{
                  border: 'none',
                  padding: '68px 0px',
                  zIndex: '1',
                  width: '100%',
                  maxWidth: '660px',
                  margin: 'auto',
                  height: '100%',
                  minHeight: '575px',
                  animation: `fadeIn 2.2s`,
                }}
              >
                <Col>
                  <Row className="pb-4 mobile-row">
                    <div className="pb-1 mobile-header">Round Size</div>
                    <div>
                      {' '}
                      <CurrencyInput
                        className={'text-field ' + (this.isTotalRoundSizeZero() && 'red-colored-border')}
                        value={this.state.user.round_size}
                        onChange={this.handleRoundSize}
                        placeholder={
                          this.state.user.round_size !== null ? this.state.user.round_size : '$100,000'
                        }
                      />
                    </div>
                  </Row>
                  <Row className="pb-4 mobile-row">
                    <div className="pb-1 mobile-header">Raised (current round)</div>
                    <div className="mobile-info-no-margin">
                      {' '}
                      <CurrencyInput
                        className={
                          'text-field ' + (this.isRaisedGreaterOrEqualToRoundSize() && 'red-colored-border')
                        }
                        value={this.state.user.raised}
                        onChange={this.handleRaised}
                        placeholder={this.state.user.raised !== null ? this.state.user.raised : '$100,000'}
                      />
                    </div>
                  </Row>
                  {(this.isTotalRoundSizeZero() && (
                    <Row>
                      <InvalidUserInputAlert
                        id="startup-profile-edit-capital-alert"
                        description="Your current total round size cannot be 0."
                      />
                    </Row>
                  )) ||
                    (this.isRaisedGreaterOrEqualToRoundSize() && (
                      <Row>
                        <InvalidUserInputAlert
                          id="startup-profile-edit-capital-alert"
                          description="The amount you’ve raised already in this round must be less than your total round size."
                        />
                      </Row>
                    ))}
                  <Row className="pb-4 mobile-row">
                    <div className="pb-1 mobile-header">Traction (choose up to 3)</div>
                    <div className="mobile-info-no-margin">
                      {progressChoices.map((p, index) => (
                        <div
                          key={index}
                          className={
                            this.state.user.progress.includes(p)
                              ? 'sector-text-color font-weight-bold selected invest2 w-100'
                              : 'sector-text-color-nonselected invest2 w-100'
                          }
                          onClick={() => this.handleProgress(p)}
                        >
                          <div className="checkbox">
                            <input type="checkbox" checked={this.state.user.progress.includes(p)} />
                            <label>
                              <span>{p}</span>
                            </label>
                          </div>
                        </div>
                      ))}

                      {progressChoices2.map((p, index) => (
                        <div
                          key={index}
                          className={
                            this.state.user.progress.includes(p)
                              ? 'sector-text-color selected font-weight-bold invest2 w-100'
                              : 'sector-text-color-nonselected invest2 w-100'
                          }
                          style={{
                            width: '100%',
                          }}
                          onClick={() => this.handleProgress(p)}
                        >
                          <div className="checkbox">
                            <input type="checkbox" checked={this.state.user.progress.includes(p)} />
                            <label>
                              <span>{p}</span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="pb-4 mobile-row">
                    <div className="pb-1 mobile-header">Attachments</div>
                    <div className="mobile-info-no-margin">
                      {' '}
                      {this.state.user.slide_deck ? (
                        <>
                          <Row>
                            <div
                              className="border m-auto d-block pb-4"
                              style={{
                                height: '130px',
                                width: '94%',
                                padding: '20px',
                              }}
                            >
                              <Row sm={12}>
                                <Col>
                                  <img
                                    src={pdfIcon}
                                    alt="default"
                                    style={{ width: '75px' }}
                                    onClick={() => setSlideDeckModal(true)}
                                  />
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                  <span className="float-right pb-3 attachment-size">
                                    <div
                                      className="investor-profile-edit-text"
                                      style={{ textDecoration: 'underline' }}
                                      onClick={() => setSlideDeckModal(true)}
                                    >
                                      {formatFileToName(this.state.user.slide_deck)}
                                    </div>
                                  </span>

                                  <form className="text-right">
                                    <input
                                      onChange={this.handleFileChange}
                                      type="file"
                                      className="input-file"
                                      id="file"
                                      accept="application/pdf"
                                    ></input>
                                    <label htmlFor="file">
                                      <h5
                                        className="mobile-list replace-sizing"
                                        style={{
                                          borderRadius: '5px',
                                          position: 'relative',
                                          right: '-15px',
                                        }}
                                      >
                                        Replace File
                                      </h5>
                                    </label>
                                  </form>
                                </Col>
                              </Row>
                            </div>

                            <Button
                              className="remove-file-button d-block m-auto pt-3"
                              onClick={this.removeFile}
                            >
                              Remove File
                            </Button>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <div
                              className="border m-auto d-block pb-4"
                              style={{
                                height: '130px',
                                width: '94%',
                                padding: '20px',
                              }}
                            >
                              <Row sm={12}>
                                <Col>
                                  <img
                                    src={pdfIcon}
                                    alt="default"
                                    style={{
                                      width: '75px',
                                      filter: 'grayscale(100%)',
                                      opacity: '0.5',
                                    }}
                                  />
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                  <span className="float-right pb-3">
                                    <div
                                      className="investor-profile-edit-text"
                                      style={{
                                        textDecoration: 'none',
                                        color: '#dededf',
                                      }}
                                    >
                                      No File Uploaded
                                    </div>
                                  </span>

                                  <form className="text-right">
                                    <input
                                      onChange={this.handleFileChange}
                                      type="file"
                                      className="input-file"
                                      id="file"
                                      accept="application/pdf"
                                    ></input>
                                    <label htmlFor="file">
                                      <h5
                                        className="mobile-list replace-sizing"
                                        style={{
                                          borderRadius: '5px',
                                          position: 'relative',
                                          right: '-15px',
                                          color: 'white',
                                          backgroundColor: '#162642',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Upload File
                                      </h5>
                                    </label>
                                  </form>
                                </Col>
                              </Row>
                            </div>
                          </Row>
                        </>
                      )}
                    </div>
                  </Row>
                </Col>
              </Card>
            </div>
          }
        />
        <div className="buttons-container" />
        <div className="group-buttons">
          <Button
            className="d-block w-70 mx-auto font-roboto mb-2 font-weight-600"
            style={{
              borderRadius: '5px',
              backgroundColor: '#162642',
              color: 'white',
              border: 'none',
              outline: 'none',
            }}
            onClick={() => {
              this.setState({ saving: true })
              fetchStartupUserInfoUpdate({
                company_name: this.state.user.company_name,
                location: this.state.user.location,
                bio: this.state.user.bio,
                sectors: this.state.user.sectors,
                company_link: this.state.user.company_link,
                startup_pitch: this.state.user.startup_pitch,
                round_size: this.state.user.round_size,
                raised: this.state.user.raised,
                progress: this.state.user.progress,
                position: this.state.user.position,
                num_team_members: this.state.user.num_team_members,
                co_founders: this.state.user.co_founders,
                profile_pic_link: this.state.user.profile_pic_link,
              })
                .then(() => {
                  this.setState({ changesMade: false })
                })
                .then(() => {
                  this.props.history.push('/startup/profile')
                })
                .then(() => {
                  fetchStartupUserCompletion()
                })
            }}
            disabled={
              this.state.user.company_name === '' ||
              this.state.user.location === '' ||
              this.state.user.bio === '' ||
              this.state.user.sectors.length === 0 ||
              this.state.user.round_size === '$0' ||
              this.state.user.raised === '' ||
              this.state.user.progress.length === 0 ||
              this.state.user.company_link === '' ||
              this.state.saving
            }
          >
            {this.state.saving ? <Spinner size="sm" className="mr-2" /> : null}
            Save
          </Button>
        </div>
        <StartupViewSlideDeckModal />
        {this.props.slideDeckUploadFile && (
          <StartupSlideDeckUploadModal file={this.props.slideDeckUploadFile} />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  investor: state.auth.investor,
  startup: state.auth.startup,
  user: state.auth.startupUser,
  profile_pic_link: state.investorflowControl.profile_pic_link,
  imageUri: state.investorflowControl.imageUri,
  slideDeckUploadFile: state.startupflowControl.slideDeckUploadModal.file,
})

const mapDispatchToProps = dispatch => ({
  setSlideDeckModal: status => {
    dispatch({ type: 'SET_SLIDE_DECK_MODAL', payload: true })
  },
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  isClicked: () => dispatch(isClickedToShowForm()),

  updateAddedSyndicate: angelGroups => {
    const action = { type: 'ADDED_SYNDICATE', payload: angelGroups }
    dispatch(action)
  },
  // updateAddedPriorInvestments: priorInvestment => {
  //     const action = { type: "ADDED_PRIORINVESTMENT", payload: priorInvestment };
  //     dispatch(action);
  // },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  fetchStartupUserCompletion: () => {
    dispatch(fetchStartupUserCompletion())
  },
  editPhoto: file => {
    dispatch(editPhoto(file))
  },
  uploadPDF: () => {
    const action = { type: 'PITCH_DECK_UPLOAD_SUCCESS' }
    dispatch(action)
  },
  fetchStartupFileUpload: (file, fileType, fieldName) => {
    return dispatch(fetchStartupFileUpload(file, fileType, fieldName))
  },
  fetchStartupCFFileUpload: (file, fileType, fieldName) => {
    return dispatch(fetchStartupCFFileUpload(file, fileType, fieldName))
  },
  removeSlideDeck: () => dispatch(removeSlideDeck()),
  showSlideDeckUploadModal: file => dispatch(showSlideDeckUploadModal(file)),
  slideDeckUploadFailed: () => dispatch(slideDeckUploadFailed()),
  hideSlideDeckUploadModal: () => dispatch(hideSlideDeckUploadModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartupProfileEditPage))
