// Import action type from an external file
import * as actionTypes from './ActionTypes'

// Define the initial state for the Redux store
const initialState = {
  loading: false,
  key_metric_data: {},
  single_monthly_update_data: {},
  monthly_updates_data: {},
  metric_data: [],
  profile_data: {},
  profile_pic_link_url: {},
  slide_deck_url: '',
  other_docs_link_url: '',
  done_status: false,
  email_notification_data: {},
  get_tour_token_data: {},
  video_url: {},
  is_cancel_modal_visible: false,
  activeForm: {
    activeProfileInfo: false,
    activeShortBio: false,
    activeSectors: false,
    activeProcess: false,
    activeCoInvestor: false,
  },
  profileActiveTabKey: 'company',
  profileSettingsActiveTabKey: '',
  fetchMonthlyUpdateLoading: false,
  co_investor_logos: [],
  get_startup_details: null,
  monthly_update_metric_data: [],
}

// Define a Redux reducer function
const trackingFlowControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TRACKING_MONTHLY_UPDATES_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_TRACKING_MONTHLY_UPDATES_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_TRACKING_MONTHLY_UPDATES_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_KEY_METRIC_VALUES:
      return {
        ...state,
        key_metric_data: action.payload,
      }
    case actionTypes.FETCH_SINGLE_MONTHLY_UPDATES_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_SINGLE_MONTHLY_UPDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        single_monthly_update_data: action.payload,
      }
    case actionTypes.FETCH_SINGLE_MONTHLY_UPDATES_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_MONTHLY_UPDATES_INIT:
      return {
        ...state,
        fetchMonthlyUpdateLoading: true,
      }
    case actionTypes.FETCH_MONTHLY_UPDATES_SUCCESS:
      return {
        ...state,
        fetchMonthlyUpdateLoading: false,
        monthly_updates_data: action.payload,
      }
    case actionTypes.FETCH_MONTHLY_UPDATES_FAILED:
      return {
        ...state,
        fetchMonthlyUpdateLoading: false,
      }
    case actionTypes.FETCH_METRIC_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_METRIC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        metric_data: action.payload,
      }
    case actionTypes.FETCH_METRIC_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: action.payload,
        co_investor_logos:
          action.payload.data.co_investor_info &&
          action.payload.data.co_investor_info.map(info => {
            return { logo: info?.profile_image }
          }),
      }

    case actionTypes.EMPTY_CO_INVESTOR_LOG0S:
      return {
        ...state,
        co_investor_logos: [...state.co_investor_logos],
      }
    case actionTypes.FETCH_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_PROFILE_PIC_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_INVESTOR_ICON_INIT:
      return {
        ...state,
        loading: true,
        co_investor_logos: [...state.co_investor_logos],
      }
    case actionTypes.IS_CANCEL_MODAL_VISIBLE:
      return {
        ...state,
        is_cancel_modal_visible: action.payload,
      }
    case actionTypes.ACTIVE_FORM:
      return {
        ...state,
        activeForm: action.payload,
      }
    case actionTypes.UPDATE_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: {
          ...state.profile_data,
          logo: action.payload,
          profile_pic_link: action.payload,
          slide_deck:
            action.payload.type === 'application'
              ? action.payload.url
              : state?.profile_data?.data?.slide_deck,
          other_docs_link:
            action.payload.type === 'other'
              ? action.payload.uploded
              : state?.profile_data?.data?.other_docs_link,
          video_pitch_link:
            action.payload.type === 'video_pitch'
              ? action.payload.url
              : state?.profile_data?.data?.video_pitch_link,
          demo_video_link:
            action.payload.type === 'demo_link'
              ? action.payload.url
              : state?.profile_data?.data?.demo_video_link,
        },
        profile_pic_link_url: {
          logo_url_state:
            action.payload.type === 'logo' ? action.payload.url : state.profile_pic_link_url.logo_url_state,
          profile_pic_link_state:
            action.payload.type === 'profile_pic_link'
              ? action.payload.url
              : state.profile_pic_link_url.profile_pic_link_state,
        },
        profile_image_url:
          action.payload.type === 'update_profile_image'
            ? action.payload?.url || null
            : state.profile_image_url,
        add_profile_image_url:
          action.payload.type === 'add_profile_image'
            ? action.payload?.url || null
            : state.add_profile_image_url,
        video_url: {
          video_pitch_link_url:
            action.payload.type === 'video_pitch' ? action.payload.url : state.video_url.video_pitch_link_url,
          demo_video_link_url:
            action.payload.type === 'demo_link' ? action.payload.url : state.video_url.demo_video_link_url,
        },
      }
    case actionTypes.UPDATE_INVESTOR_ICON_SUCCESS:
      const newCoInvestorLogos = [...state.co_investor_logos]
      newCoInvestorLogos.splice(action.payload.index.index, 1, { logo: action.payload.url })
      return {
        ...state,
        loading: false,
        co_investor_logos: newCoInvestorLogos,
      }
    case actionTypes.UPDATE_PROFILE_PIC_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_INVESTOR_ICON_FAILED:
      return {
        ...state,
        loading: false,
        co_investor_logos: [...state.co_investor_logos],
      }
    case actionTypes.REMOVE_CO_INVESTOR:
      const oldCoInvestorInfo = [...state.profile_data.data.co_investor_info]
      const newCoInvestorInfo = oldCoInvestorInfo.filter((_, index) => index !== action.payload)
      const newCoInvestorlogos = [...state.co_investor_logos]
      newCoInvestorlogos.splice(action.payload, 1)

      return {
        ...state,
        loading: false,
        profile_data: {
          ...state.profile_data,
          data: {
            ...state.profile_data.data,
            co_investor_info: newCoInvestorInfo,
          },
        },
        co_investor_logos: newCoInvestorlogos,
      }

    case actionTypes.CHANGE_PASSWORD_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_TEAM_MEMBER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_TEAM_MEMBER_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_EMAIL_NOTIFICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_EMAIL_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        email_notification_data: action.payload,
      }
    case actionTypes.GET_EMAIL_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.DONE_SUCCESS:
      return {
        ...state,
        done_status: true,
      }
    case actionTypes.GET_TOUR_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_TOUR_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        get_tour_token_data: action.payload,
      }
    case actionTypes.GET_TOUR_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CHANGE_PROFILE_ACTIVE_KEY:
      return {
        ...state,
        profileActiveTabKey: action?.payload,
      }
    case actionTypes.CLEAR_COMPANY_LOGO_URL:
      return {
        ...state,
        profile_pic_link_url: { ...state.profile_pic_link_url, logo_url_state: null },
      }
    case actionTypes.CLEAR_COMPANY_BANNER_URL:
      return {
        ...state,
        profile_pic_link_url: { ...state.profile_pic_link_url, profile_pic_link_state: null },
      }
    case actionTypes.CHANGE_TRACKING_SETTINGS_PROFILE_ACTIVE_KEY:
      return {
        ...state,
        profileSettingsActiveTabKey: action?.payload,
      }
    case actionTypes.SET_FLAG_LINK:
      return {
        ...state,
        CountryImgLink: action?.payload,
      }
    case actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY:
      return {
        ...state,
        profile_data: {
          ...state.profile_data,
          data: {
            ...state.profile_data.data,
            co_investor_info: action.payload,
          },
        },
      }

    case actionTypes.SHARE_MONTHLY_UPDATES_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SHARE_MONTHLY_UPDATES_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SHARE_MONTHLY_UPDATES_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        get_startup_details: action.payload,
      }
    case actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        monthly_update_metric_data: action.payload,
      }
    case actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_MONTHLY_UPDATE_URL_STATE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_MONTHLY_UPDATE_URL_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_MONTHLY_UPDATE_URL_STATE_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return { ...state }
  }
}

export default trackingFlowControlReducer
