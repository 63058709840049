import React from "react";
import "./InvestorWelcomeBackPage.scss";
import {Link} from "react-router-dom"
class FirstInvite extends React.Component {

  render() {


    return(<div>

            <div className="first-invite-container">
              <div className="first-invite-content">
                <div className="content-congratulations">Congratulations!</div>
                <div className="invited-first-startup">You just invited your first startup!</div>
                <div className="also-want">If they also want to connect, Exitfund will</div>
                <div className="send-intro">send an intro email on your behalf.</div>
                <div className="first-invite-button">
                  <Link className="got-it"to ="/investor/dashboard">Got it!</Link>
                </div>
              </div>
            </div>

        </div>)
  }
}



export default FirstInvite;
