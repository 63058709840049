import React from 'react'
import './StartupReferPage.scss'
import facebook2 from '../../../assets/images/Facebook@2x.png'
import linkedin2 from '../../../assets/images/Linkedin@2x.png'
import twitter2 from '../../../assets/images/Twitter@2x.png'
import { connect } from 'react-redux'
import { sendSUReferLink } from '../../../redux/startupFlowControl/startupFlowControlActions'
class StartupReferPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      message: '',
      sent: false,
      match: false,
    }
  }
  onChange = e => {
    this.setState({
      email: e.target.value,
      match: !!e.target.value.match(
        // eslint-disable-next-line no-control-regex
        /(?:[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      ),
    })
  }
  render() {
    const { referSent, user } = this.props
    return (
      <div
        className="investor-refer-page"
        style={{
          paddingTop: '90px',
          marginBottom: '20px',
          animation: `fadeIn 1s`,
        }}
      >
        {referSent ? (
          <div>
            <div style={{ color: '#162642' }} className="enjoy-experience">
              Thank you for helping us grow
              <div>our community, {user.last_name}</div>
            </div>
            <div className="refer-us">We're grateful!</div>
          </div>
        ) : (
          <div>
            <div className="enjoy-experience font-lato">
              {this.state.sent
                ? `Thank you for helping us grow our community, ${this.props.user.first_name}.`
                : 'Enjoying the Exitfund.com experience?'}
            </div>
            <div className="refer-us">
              {this.state.sent ? "We're grateful!" : 'Refer us to your friends!'}
            </div>
          </div>
        )}

        <div className="input-div-1">
          <input
            placeholder="myfriend@email.com"
            className="refer-input"
            value={this.state.email}
            onChange={this.onChange}
            onKeyPress={e => {
              if (e.key === 'Enter' && this.state.match) {
                this.props.sendReferLink(this.state.email).then(response => {
                  if (response) {
                    if (response.result) {
                      this.setState({
                        message: 'Sent!',
                        email: '',
                        sent: true,
                      })
                      setTimeout(() => {
                        this.setState({ message: '' })
                      }, 3000)
                    } else {
                      this.setState({
                        message: response,
                        email: '',
                      })
                      setTimeout(() => {
                        this.setState({ message: '' })
                      }, 3000)
                    }
                  } else {
                    this.setState({ message: 'Something went wrong.' })
                  }
                })
              }
            }}
          ></input>
          <button
            className="invite-button-1"
            onClick={() => {
              if (this.state.match) {
                this.props.sendReferLink(this.state.email).then(response => {
                  if (response) {
                    if (response.result) {
                      this.setState({
                        message: 'Sent!',
                        email: '',
                        sent: true,
                      })
                      setTimeout(() => {
                        this.setState({ message: '' })
                      }, 3000)
                    } else {
                      this.setState({
                        message: response,
                        email: '',
                      })
                      setTimeout(() => {
                        this.setState({ message: '' })
                      }, 3000)
                    }
                  } else {
                    this.setState({ message: 'Something went wrong.' })
                  }
                })
              }
            }}
          >
            Invite
          </button>
        </div>
        <div className="font-weight-bold text-center mt-0">
          <span style={{ visibility: 'hidden' }}>.</span>
          {this.state.message !== '' ? (
            <span style={{ animation: `fadeInAndOut 3s` }}>{this.state.message}</span>
          ) : null}
        </div>

        <div className="feel-free">Feel free to follow or mention Exitfund.com below:</div>
        <div className="refer-logos">
          <a href="https://www.linkedin.com/company/exitfund/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin2} alt="" />
          </a>
          <a href="https://twitter.com/Exitfund" target="_blank" rel="noopener noreferrer">
            <img src={twitter2} alt="" />
          </a>
          <a
            href="https://www.facebook.com/Exitfund-116211483164644/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="facebook" src={facebook2} alt="" />
          </a>
        </div>
        <div className="logo-name">
          <a
            href="https://www.linkedin.com/company/exitfund/"
            target="_blank"
            rel="noopener noreferrer"
            className="sm-text-link"
          >
            <span style={{ marginLeft: '6px', color: '#B5B5B5' }}>Linkedin</span>
          </a>
          <a
            href="https://twitter.com/Exitfund"
            target="_blank"
            rel="noopener noreferrer"
            className="sm-text-link"
          >
            <span style={{ marginLeft: '0px', color: '#B5B5B5' }}>Twitter</span>
          </a>
          <a
            href="https://www.facebook.com/Exitfund-116211483164644/"
            target="_blank"
            rel="noopener noreferrer"
            className="sm-text-link"
          >
            <span style={{ marginLeft: '-6px', color: '#B5B5B5' }}>Facebook</span>
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.startupUser,
  referSent: state.investorflowControl.referSent,
})
const mapDispatchToProps = dispatch => ({
  sendReferLink: link => {
    return dispatch(sendSUReferLink(link))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(StartupReferPage)
