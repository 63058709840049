import React from 'react'
import './StartupDashboard.scss'
import membericon from '../../../assets/images/FounderPhoto.png'
import startUpDefaultIcon from '../../../assets/images/FillerStartupIcon.png'
import logo from '../../../assets/images/LogoExitFund.svg'

import InvestorCard from '../../../components/InvestorCard/InvestorCard'
import HyperlinkWrapper from '../../../components/HyperlinkWrapper/HyperlinkWrapper'
import { getCompanyUrl } from '../../../utils/investor/priorInvestment'

import BottomNav from './DashboardBottomNav'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { CardText, Card, Col, Row, Button, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'

import {
  removeInvestor,
  setInvitedInvestor,
  handlePassInviteInvestor,
  displayProfile,
  setHistoryView,
  fetchUserHistoryAll,
  fetchUserHistoryConnected,
  fetchUserHistoryPassed,
} from '../../../redux/startupFlowControl/startupFlowControlActions'
import { fetchStartupUserInfoUpdate, fetchStartupUserCompletion } from '../../../redux/auth/authActions'

import { emitStartupMatchDisplayed } from '../../../metrics/metricsEmitter'

class Discover extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCard: false,
    }
  }

  componentDidMount() {
    let { fetchStartupUserCompletion } = this.props
    fetchStartupUserCompletion()
    window.scrollTo(0, 0)
  }

  handlePassAndInvite = (invite, investorUser) => {
    if (invite) {
      this.props.setInvitedInvestor(investorUser)
      this.props.handlePassInviteInvestor(true, investorUser.id)
    } else {
      this.props.handlePassInviteInvestor(false, investorUser.id)
    }
    this.props.removeInvestor()
  }

  updateFirstInvite = () => {
    this.props.fetchStartupUserInfoUpdate({
      first_invite: false,
    })
  }

  removeInvitedInvestor = () => {
    this.props.setInvitedInvestor()
  }

  render() {
    const {
      blur,
      userInfos,
      invitedInvestor,
      loading,
      connectedWithInvestor,
      setConnectedToFalse,
      history,
      setHistoryView,
      startupUser,
      fetchUserHistoryAll,
      fetchUserHistoryConnected,
      fetchUserHistoryPassed,
    } = this.props

    const INVEST_DATA = {
      0: '$25,000',
      25: '$50,000',
      50: '$100,000',
      100: '$250,000',
      250: '$500,000',
      500: '$500,000+',
    }

    if (loading) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    }

    if (invitedInvestor) {
      if (startupUser.first_invite) {
        return (
          <SwitchTransition mode={'out-in'}>
            <CSSTransition
              key={this.state.showCard}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              classNames="fade"
            >
              <div
                className="dashboard-container"
                style={
                  blur
                    ? { filter: 'blur(5px)', height: '100%', width: '90%' }
                    : { display: 'block', height: '100%', width: '90%' }
                }
              >
                <div className="content py-5" style={{ height: '100%', textAlign: 'center' }}>
                  <h2 className="purple-colored font-lato font-weight-600">Congratulations!</h2>
                  <h4 className="purple-colored font-lato font-weight-600">
                    You just invited your first investor!
                  </h4>
                  <div className="pt-5">
                    If they also want to connect, Exitfund.com will send an intro email on your behalf.{' '}
                  </div>
                  <div className="pt-3">
                    <Button
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: '#162642',
                        color: '#162642',
                        padding: '15px 30px',
                        textDecoration: 'none',
                        borderRadius: '5px',
                      }}
                      onClick={() => {
                        this.updateFirstInvite()
                        this.setState(prevState => ({
                          showCard: !prevState.showCard,
                        }))
                        if (!connectedWithInvestor) {
                          this.removeInvitedInvestor()
                        }
                      }}
                    >
                      Got it!
                    </Button>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        )
      }
      if (connectedWithInvestor) {
        return (
          <SwitchTransition mode={'out-in'}>
            <CSSTransition
              key={this.state.showCard}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              classNames="fade"
            >
              <div
                className="dashboard-container"
                style={
                  blur
                    ? {
                        filter: 'blur(5px)',
                        height: '100%',
                        width: '90%',
                        boxShadow: 'rgb(237, 196, 50) 0px 0px 10px',
                      }
                    : {
                        display: 'block',
                        height: '100%',
                        width: '90%',
                        boxShadow: 'rgb(237, 196, 50) 0px 0px 10px',
                      }
                }
              >
                <div className="content py-5" style={{ height: '100%', textAlign: 'center' }}>
                  <h2 className="purple-colored font-lato font-weight-600">Connection created!</h2>
                  <h4 className="purple-colored font-lato font-weight-600">
                    {invitedInvestor.first_name} {invitedInvestor.last_name} also wants to connect.
                  </h4>
                  <div>
                    <img src={logo} alt="af-logo" style={{ width: '40px', marginTop: '30px' }} />
                  </div>
                  <div className="pt-5">Check your email for your warm intro. We’re excited for you!</div>
                  <div className="pt-3">
                    <Button
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: '#162642',
                        color: '#162642',
                        padding: '15px 30px',
                        textDecoration: 'none',
                        borderRadius: '5px',
                      }}
                      onClick={() => {
                        setConnectedToFalse()
                        this.setState(prevState => ({
                          showCard: !prevState.showCard,
                        }))
                        this.removeInvitedInvestor()
                      }}
                    >
                      Continue Discovering
                    </Button>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        )
      }
      return (
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={this.state.showCard}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div
              className="dashboard-container"
              style={
                blur
                  ? { filter: 'blur(5px)', height: '100%', width: '90%' }
                  : { display: 'block', height: '100%', width: '90%' }
              }
            >
              <div className="content py-5" style={{ height: '100%', textAlign: 'center' }}>
                <h2 className="purple-colored font-lato font-weight-600">Invite Sent!</h2>
                <div className="pt-5">
                  If {invitedInvestor.first_name} {invitedInvestor.last_name} also want to connect,
                  Exitfund.com will send an intro email on your behalf.{' '}
                </div>
                <div className="pt-3">
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: '#162642',
                      color: '#162642',
                      padding: '15px 30px',
                      textDecoration: 'none',
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      this.setState(prevState => ({
                        showCard: !prevState.showCard,
                      }))
                      this.removeInvitedInvestor()
                    }}
                  >
                    Continue Discovering
                  </Button>
                </div>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )
    }

    if (startupUser.co_founders_check === false || startupUser.company_logo_check === false) {
      return (
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={this.state.showCard}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div
              className="dashboard-container"
              style={
                blur
                  ? { filter: 'blur(5px)', height: '100%', width: '90%' }
                  : { display: 'block', height: '100%', width: '90%' }
              }
            >
              <div
                className="incomplete-content vertical-padding"
                style={{ height: '100%', textAlign: 'center' }}
              >
                <h3 className="purple-colored font-lato font-weight-600">
                  Complete your profile to see investors in your Discover portal.
                </h3>
                <div className="pt-5">
                  Once you complete your profile, we can keep showing you the most relevant investors.
                </div>
                <div className="pt-5 complete-button">
                  <Link
                    style={{
                      backgroundColor: '#162642',
                      color: 'white',
                      padding: '15px 30px',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      borderRadius: '5px',
                    }}
                    to="/startup/profile/edit"
                  >
                    Complete Profile
                  </Link>
                </div>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )
    }

    if (!startupUser.show_profile) {
      return (
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={this.state.showCard}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div
              className="dashboard-container"
              style={
                blur
                  ? { filter: 'blur(5px)', height: '100%', width: '90%' }
                  : { display: 'block', height: '100%', width: '90%' }
              }
            >
              <div
                className="incomplete-content vertical-padding"
                style={{ height: '100%', textAlign: 'center' }}
              >
                <h3 className="purple-colored font-lato font-weight-600">Your profile is hidden!</h3>
                <div className="pt-5">
                  Make your profile visible to continue seeing investors in Discover.
                </div>
                <div className="py-5 complete-button">
                  <button
                    style={{
                      backgroundColor: '#162642',
                      color: 'white',
                      padding: '15px 30px',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      borderRadius: '5px',
                      fontFamily: 'Roboto',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => this.props.displayProfile({ visible: true })}
                  >
                    Make my profile visible
                  </button>
                </div>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )
    }
    if (userInfos.length === 0 && startupUser.co_founders_check && startupUser.company_logo_check) {
      return (
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={this.state.showCard}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false)
            }}
            classNames="fade"
          >
            <div
              className="dashboard-container"
              style={
                blur
                  ? { filter: 'blur(5px)', height: '100%', width: '90%' }
                  : { display: 'block', height: '100%', width: '90%' }
              }
            >
              <div
                className="incomplete-content vertical-padding"
                style={{ height: '100%', textAlign: 'center' }}
              >
                <h2 className="purple-colored font-lato font-weight-600">That's it for now!</h2>
                <h4 className="purple-colored font-lato font-weight-600">
                  We’ll let you know when more investors are interested in connecting!
                </h4>
                <div className="pt-5">
                  In the meantime, check out the investors who’ve connected with & passed on you in History.
                </div>
                <div className="py-5 complete-button">
                  <button
                    style={{
                      backgroundColor: '#162642',
                      color: 'white',
                      padding: '15px 30px',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      borderRadius: '5px',
                      fontFamily: 'Roboto',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => {
                      setHistoryView()
                      fetchUserHistoryAll()
                      fetchUserHistoryConnected()
                      fetchUserHistoryPassed()
                      history.push('/startup/dashboard')
                    }}
                  >
                    Go to History
                  </button>
                </div>
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      )
    }

    const currentInvestorUser = userInfos[0]

    const sectors = {
      agtech: 'Agriculture / Agtech',
      ai: 'Artificial Intelligence',
      ar: 'Augmented Reality',
      biomed: 'Biomedical',
      biotech: 'Biotech',
      blockchain: 'Blockchain',
      community: 'Community',
      crowdfund: 'Crowdfunding',
      devtools: 'Developer Tools',
      diversity: 'Diversity',
      drones: 'Drones',
      education: 'Education',
      energy: 'Energy',
      enterprise: 'Enterprise',
      entertain: 'Entertainment',
      gaming: 'Esports / Online Gaming',
      banking: 'Financial / Banking',
      government: 'Government',
      hardware: 'Hardware',
      health: 'Healthcare',
      market: 'Marketplace',
      media: 'Media / Advertising',
      hardtech: 'Moonshots / Hard Tech',
      robotics: 'Robotics',
      security: 'Security',
      sport: 'Sport/Fitness',
      transport: 'Transportation',
      travel: 'Travel',
      vr: 'Virtual Reality',
      other: 'Other',
    }

    const mappedSectors = currentInvestorUser.sectors.map(s => {
      return sectors[s] ? sectors[s] : s
    })

    const sortedSectors = mappedSectors.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })

    emitStartupMatchDisplayed(currentInvestorUser.id)

    return (
      <SwitchTransition mode={'out-in'}>
        <CSSTransition
          key={this.state.showCard}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
          classNames="fade"
        >
          <div>
            <div className="d-block d-md-none">
              <div>
                <Card
                  body
                  style={
                    blur
                      ? {
                          filter: 'blur(5px)',
                          padding: '20px',
                          margin: '0px 15px -10px',
                          border: '1px solid #DEDEDF',
                          borderRadius: '10px',
                          position: 'relative',
                          top: '-10px',
                        }
                      : {
                          display: 'block',
                          padding: '20px',
                          margin: '0px 15px -10px',
                          border: '1px solid #DEDEDF',
                          borderRadius: '10px',
                          position: 'relative',
                          top: '-10px',
                        }
                  }
                >
                  <Col>
                    {' '}
                    <img
                      className="mobile-prof-pic"
                      src={
                        currentInvestorUser &&
                        currentInvestorUser.profile_pic_link &&
                        currentInvestorUser.profile_pic_link !== 'None'
                          ? currentInvestorUser.profile_pic_link
                          : membericon
                      }
                      alt=""
                      id="avataImg"
                      shape="circle"
                    />
                    <div className="d-flex flex-dir-col mobile-name-location">
                      {' '}
                      <h3 className="font-20 ml-3 purple-colored pt-3 mb-0 font-weight-900 pr-5 mr-3">
                        {currentInvestorUser.first_name} {currentInvestorUser.last_name}
                      </h3>
                      <div className="ml-3">{currentInvestorUser.location}</div>
                    </div>
                    <Row className="mobile-row pt-4 mt-3">
                      <div className="mobile-info ">{currentInvestorUser.bio}</div>
                    </Row>
                    <Row className="mobile-row pt-4 mt-1">
                      <h5 className="font-weight-600"> Affiliations</h5>
                      <div className="mobile-info">
                        {currentInvestorUser.syndicate.length ? (
                          currentInvestorUser.syndicate.map(syn => (
                            <h6 className="affiliation-group">{syn}</h6>
                          ))
                        ) : (
                          <div
                            className="mobile-info-no-margin"
                            style={{
                              color: '#B5B5B5',
                            }}
                          >
                            No Affiliations
                          </div>
                        )}
                      </div>
                    </Row>
                    <Row className="mobile-row pt-4 mt-1">
                      <h5 className="font-weight-900">Will invest up to</h5>
                      <h4 className="gold-colored font-weight-900" style={{ marginLeft: '15px' }}>
                        {INVEST_DATA[currentInvestorUser.deals[0]]}
                      </h4>
                    </Row>
                    <Row className="mobile-row pt-4 mt-1">
                      <h5 className="font-weight-600 mb-2">Invests in</h5>
                      <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                        {sortedSectors.map(sector => (
                          <span
                            className="mobile-list"
                            style={{
                              border: 'none',
                              backgroundColor: '#F2F2FF',
                              fontWeight: 'bold',
                            }}
                          >
                            {sector}
                          </span>
                        ))}
                      </CardText>
                    </Row>
                    <Row className="mobile-row pt-4 mt-1">
                      <h5 className="font-weight-600">Prior investments</h5>
                      <div className="mobile-info-no-margin">
                        {currentInvestorUser.prior_investments &&
                        currentInvestorUser.prior_investments.length ? (
                          currentInvestorUser.prior_investments.map(inv => (
                            <Row
                              style={{ alignItems: 'center' }}
                              className="investor-profile-edit-text ml-1 mb-3"
                            >
                              <div
                                style={{
                                  minHeight: '48px',
                                  height: '100%',
                                  lineHeight: '45px',
                                  width: '50px',
                                  borderRadius: '5px',
                                  border: '1px solid #DEDEDF',
                                }}
                              >
                                <HyperlinkWrapper hyperlink={getCompanyUrl(inv)}>
                                  <img
                                    style={{
                                      vAlign: 'center',
                                      width: '50px',
                                      objectFit: 'cover',
                                    }}
                                    src={
                                      inv.logo_link && inv.logo_link !== 'none found'
                                        ? inv.logo_link
                                        : startUpDefaultIcon
                                    }
                                    alt=""
                                  />
                                </HyperlinkWrapper>
                              </div>
                              <Col>
                                <HyperlinkWrapper hyperlink={getCompanyUrl(inv)}>
                                  <span className=" investment-card-name">{inv.name}</span>
                                </HyperlinkWrapper>
                                <br></br>
                                <span className="investment-card-year">{inv.year}</span>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <div className="mobile-info-no-margin" style={{ color: '#b5b5b5' }}>
                            {currentInvestorUser.first_name} hasn't filled out their prior investments yet.
                          </div>
                        )}
                      </div>
                    </Row>
                  </Col>
                </Card>
                <div className="push" />
                <div className="push" />

                <div style={{ position: 'fixed', bottom: '60px', width: '100%' }} className="bottom m-auto">
                  <button
                    className="mobile-pass mobile-edit mr-1 ml-3 font-16 font-weight-900"
                    onClick={() => {
                      this.setState(prevState => ({
                        showCard: !prevState.showCard,
                      }))
                      this.handlePassAndInvite(false, currentInvestorUser)
                    }}
                  >
                    Pass
                  </button>
                  <button
                    className="mobile-pass mobile-save ml-1 mr-3 font-16 font-weight-900"
                    onClick={() => {
                      this.setState(prevState => ({
                        showCard: !prevState.showCard,
                      }))
                      this.handlePassAndInvite(true, currentInvestorUser)
                    }}
                  >
                    Invite
                  </button>

                  <BottomNav />
                </div>
              </div>
            </div>

            <div className="d-none d-md-block" style={{ marginTop: '-10px' }}>
              <div
                className="dashboard-container-preview d-flex flex-column justify-content-between"
                style={blur ? { filter: 'blur(5px)' } : { display: 'block' }}
              >
                <InvestorCard user={currentInvestorUser} />

                <div className="bottom mt-1">
                  <button
                    className="dashboard-button-left"
                    onClick={() => {
                      this.setState(prevState => ({
                        showCard: !prevState.showCard,
                      }))
                      this.handlePassAndInvite(false, currentInvestorUser)
                    }}
                  >
                    Pass
                  </button>

                  <button
                    className="button-right"
                    onClick={() => {
                      this.setState(prevState => ({
                        showCard: !prevState.showCard,
                      }))
                      this.handlePassAndInvite(true, currentInvestorUser)
                    }}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    )
  }
}

const mapStateToProps = state => ({
  startupUser: state.auth.startupUser,
  userInfos: state.startupflowControl.userInfos,
  loading: state.startupflowControl.loadingDashboard,
  historySelection: state.startupflowControl.historySelection,
  invitedInvestor: state.startupflowControl.invitedInvestor,
  connectedWithInvestor: state.startupflowControl.connectedWithInvestor,
})

const mapDispatchToProps = dispatch => ({
  removeInvestor: () => dispatch(removeInvestor()),
  handlePassInviteInvestor: (status, email) => dispatch(handlePassInviteInvestor(status, email)),
  setInvitedInvestor: data => dispatch(setInvitedInvestor(data)),
  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  displayProfile: display_profile => {
    dispatch(displayProfile(display_profile))
  },
  fetchStartupUserCompletion: () => {
    return dispatch(fetchStartupUserCompletion())
  },
  setHistoryView: () => dispatch(setHistoryView()),
  fetchUserHistoryAll: () => {
    return dispatch(fetchUserHistoryAll())
  },
  fetchUserHistoryConnected: () => {
    return dispatch(fetchUserHistoryConnected())
  },
  fetchUserHistoryPassed: () => {
    return dispatch(fetchUserHistoryPassed())
  },
  setConnectedToFalse: () => {
    dispatch({ type: 'SET_CONNECTED_INVESTOR', payload: false })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Discover))
