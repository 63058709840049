import {
  register,
  login,
  SET_ERRORS,
  SET_USER_PROFILE,
  UPDATE_INFO_LOADING,
  SET_UPDATE_INFO,
  GO_BACK,
  SAVE_AND_LOGOUT,
  SAVE_AND_LOGOUT_COMPLETED,
  CLEAR_ALL,
} from './actionTypes'

const initialState = {
  userData: null,
  loading: false,
  error: '',
  progressCount: 0,
  token: null,
  updateInfoLoading: false,
  saveAndLogout: false,
}
export const hiringReducer = (state = initialState, action) => {
  const { type, payload } = action
  if (
    type === register.INITIALIZE_SIGNUP ||
    type === register.SIGNUP_FAILED ||
    type === login.INITIALIZE_LOGIN ||
    type === login.LOGIN_FAILED
  ) {
    return {
      ...state,
      loading: !state.loading,
    }
  } else if (type === register.SIGNUP_SUCCESS || type === login.LOGIN_SUCCESS) {
    return {
      ...state,
      loading: !state.loading,
      userData: payload,
      progressCount: payload.onboarding_step,
      token: action.tokenPayload,
    }
  } else if (type === SET_ERRORS) {
    return {
      ...state,
      error: payload,
    }
  } else if (type === SET_USER_PROFILE) {
    return {
      ...state,
      userData: payload,
      progressCount: payload.onboarding_step,
      token: action.tokenPayload,
    }
  } else if (type === UPDATE_INFO_LOADING) {
    return {
      ...state,
      updateInfoLoading: !state.updateInfoLoading,
    }
  } else if (type === SET_UPDATE_INFO) {
    return {
      ...state,
      userData: payload,
      progressCount: payload.onboarding_step,
      updateInfoLoading: false,
    }
  } else if (type === GO_BACK) {
    return {
      ...state,
      progressCount: state.progressCount - 1,
    }
  } else if (type === SAVE_AND_LOGOUT) {
    return {
      ...state,
      saveAndLogout: true,
    }
  } else if (type === SAVE_AND_LOGOUT_COMPLETED) {
    return {
      ...state,
      userData: false,
      token: null,
      progressCount: 0,
      saveAndLogout: false,
    }
  } else if (type === CLEAR_ALL) {
    return {
      ...state,
      userData: null,
      error: '',
    }
  } else {
    return {
      ...state,
    }
  }
}

export default hiringReducer
