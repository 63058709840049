import React, { useState, useEffect } from 'react'

import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import SECTOR_DATA from './SectorData'

import './SectorPage.scss'

import TrendindCardList from '../../components/TrendingCardList/TrendingCardList'

import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'

import {
  fetchInvestorUserInfoUpdate,
  updateLocalInvestorUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
} from '../../redux/auth/authActions'

const SectorPage = ({
  stepInvestor,
  stepStartup,
  updateUserInfoFailed,
  updateStartupUserInfoFailed,
  updateLocalInvestorUser,
  fetchInvestorUserInfoUpdate,
  investor,
  startupUser,
  investorUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  history,
  props,
  ...otherProps
}) => {
  const sectors = investor ? investorUser.sectors : startupUser.sectors
  const [originalSectors] = useState(sectors)

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const sectorsCopy = sectors.slice()

  const handleCheckBoxChange = (e, sectors) => {
    if (e.target.checked) {
      if (!sectors.includes(e.target.name)) {
        sectorsCopy.push(e.target.name)
      }
    } else {
      if (sectors.includes(e.target.name)) {
        const index = sectorsCopy.findIndex(element => element === e.target.name)
        sectorsCopy.splice(index, 1)
      }
    }
    if (investor) {
      updateLocalInvestorUser({ sectors: sectorsCopy })
    } else {
      updateLocalStartupUser({ sectors: sectorsCopy })
    }
  }

  const investorOnClick = e => {
    fetchInvestorUserInfoUpdate({ sectors: investorUser.sectors }).then(success => {
      if (success) {
        // otherProps.nextStepInvestor();
        history.push('/investor/profile/edit')
      }
    })
  }

  const startupOnClick = e => {
    fetchStartupUserInfoUpdate({ sectors: startupUser.sectors }).then(success => {
      if (success) {
        history.push('/startup/profile/edit')
      }
    })
  }

  const handleCardChange = (e, sectors) => {
    let x = e.target.id
    if (!sectors.includes(x)) {
      sectorsCopy.push(x)
    } else {
      if (sectors.includes(x)) {
        const index = sectorsCopy.findIndex(element => element === x)
        sectorsCopy.splice(index, 1)
      }
    }
    if (investor) {
      updateLocalInvestorUser({ sectors: sectorsCopy })
    } else {
      updateLocalStartupUser({ sectors: sectorsCopy })
    }
  }

  const isEnabled = sectors && sectors.length > 0

  const isEnabled2 = sectors && sectors.length <= 8 && sectors.length > 0

  const maxSectorsSelected = sectors.length === 8

  const checkList = SECTOR_DATA.map((sector, i) => {
    return (
      <div className={sectors.includes(sector) ? 'selected sector' : 'sector'} key={i}>
        <div className="checkbox">
          <input
            checked={sectors.includes(sector)}
            onChange={event => {
              handleCheckBoxChange(event, sectors)
            }}
            type="checkbox"
            id={sector}
            name={sector}
            disabled={maxSectorsSelected && !sectors.includes(sector) && !investor}
          />
          {sector === SECTOR_DATA[1] ||
          sector === SECTOR_DATA[11] ||
          sector === SECTOR_DATA[17] ||
          sector === SECTOR_DATA[18] ? (
            <label
              name={sector}
              htmlFor={sector}
              onClick={event => {
                if (!investor) {
                  if (!maxSectorsSelected || (maxSectorsSelected && sectors.includes(sector))) {
                    handleCardChange(event, sectors)
                  }
                } else {
                  handleCardChange(event, sectors)
                }
              }}
              id={sector}
            >
              <span
                id={sector}
                className={sectors.includes(sector) ? 'sector-text-color' : ''}
                style={
                  maxSectorsSelected && !sectors.includes(sector) && !investor ? { color: '#B5B5B5' } : null
                }
              >
                {sector}
              </span>
            </label>
          ) : (
            <label name={sector} htmlFor={sector}>
              <span
                className={sectors.includes(sector) ? 'sector-text-color' : ''}
                style={
                  maxSectorsSelected && !sectors.includes(sector) && !investor ? { color: '#B5B5B5' } : null
                }
              >
                {sector}
              </span>
            </label>
          )}
        </div>
      </div>
    )
  })
  return (
    <div style={{ paddingTop: '120px' }}>
      <div className="question">
        <p className="startup-step pb-4">
          <Link to={investor ? '/investor/profile/edit' : '/startup/profile/edit'}>
            <Button
              style={{ color: '#162642', textDecoration: 'none' }}
              className="pull-left2"
              onClick={() => {
                if (investor) {
                  updateLocalInvestorUser({ sectors: originalSectors })
                } else {
                  updateLocalStartupUser({ sectors: originalSectors })
                }
              }}
            >
              Back to Profile
            </Button>
          </Link>

          <Button
            disabled={!isEnabled}
            onClick={investor ? investorOnClick : startupOnClick}
            className="pull-right"
            style={{
              backgroundColor: '#162642',
              color: '#dededf',
              fontWeight: '400',
            }}
          >
            Save Preferences
          </Button>
        </p>
        {window.location.pathname === '/startup/editsector' ||
        window.location.pathname === '/investor/editsector' ? null : (
          <p className="steps-mobile">
            {investor ? `Step ${stepInvestor - 3} of 6` : `Step ${stepStartup - 3} of 7`}
          </p>
        )}
        <span
          className="step-question"
          style={{
            marginRight: '28px',
            fontWeight: '700',
            marginBottom: '-20px',
          }}
        >
          {investor ? 'Sector Preferences' : 'Sector (Max 2)'}
        </span>
      </div>
      <form>
        <TrendindCardList />
        {checkList}
        <div className="push" />
        <div className="push" />
        <div className="buttons-container" />
        <div className="group-buttons">
          {/* <Button
            disabled={investor ? true : false}
            onClick={investor ? null : otherProps.prevStepStartup}
            className="back-button"
          >
            Back
          </Button> */}
          <Button
            disabled={investor ? !isEnabled : !isEnabled2}
            onClick={investor ? investorOnClick : startupOnClick}
            type="button"
            className="d-block w-70 mx-auto font-roboto mb-2 font-weight-600"
            style={{
              borderRadius: '5px',
              backgroundColor: '#162642',
              color: 'white',
            }}
          >
            Done
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  first_name: state.auth.user.first_name,
  stepInvestor: state.investorflowControl.stepInvestor,
  stepStartup: state.startupflowControl.stepStartup,
  sectors: state.auth.user.sectors,
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  investor: state.auth.investor,
  company: state.auth.user.company,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
})

const mapDispatchToProps = dispatch => ({
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),

  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectorPage))
