import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import { SECTOR_DATA, SECTOR_DICT } from './SectorData'
import './SectorPage.scss'
import TrendindCardList from '../../components/TrendingCardList/TrendingCardList'
import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'
import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'
import {
  fetchInvestorUserLogout,
  fetchStartupUserLogout,
  fetchScoutUserLogout,
} from '../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../redux/startupFlowControl/startupFlowControlActions'
import {
  fetchInvestorUserInfoUpdate,
  fetchScoutUserInfoUpdate,
  updateLocalInvestorUser,
  updateLocalScoutUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
} from '../../redux/auth/authActions'
import { Button as AntButton } from 'antd'
import 'antd/dist/antd.css'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useState } from 'react'

const SectorPage = ({
  stepInvestor,
  stepStartup,
  onCountinueClick,
  updateUserInfoFailed,
  updateStartupUserInfoFailed,
  updateLocalInvestorUser,
  fetchInvestorUserInfoUpdate,
  investor,
  startupUser,
  investorUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  saveAndExitClicked,
  saveAndExitClickedReset,
  history,
  expertise_type,
  ...otherProps
}) => {
  const dispatch = useDispatch()
  const { scout, scoutUser, startup, loading } = useSelector(state => state.auth)

  // Determines the sectors based on the user type (investor, scout, or startup).
  const sectors = investor
    ? investorUser.sectors
    : scout
    ? scoutUser?.sector_interests || []
    : startupUser.sectors

  /**
   * Creates a copy of the sectors array.
   * @type {Array} - Copy of the sectors array.
   */
  const sectorsCopy = sectors?.slice()

  /**
   * Checks if sectors are enabled (not null and length > 0).
   * @type {boolean} - Indicates if sectors are enabled.
   */
  const isEnabled = sectors && sectors?.length > 0

  /**
   * Checks if the maximum number of sectors is selected.
   * @type {boolean} - Indicates if the maximum number of sectors is selected.
   */
  const maxSectorsSelected = sectors?.length === 8

  /**
   * Generates a checklist of sectors based on the SECTOR_DATA array.
   * @type {Array} - List of JSX elements representing sectors.
   */
  const checkList = SECTOR_DATA.map((sector, i) => {
    return (
      <div className={sectors.includes(sector) ? 'selected sector' : 'sector'} key={i}>
        <div className="checkbox">
          <input
            checked={sectors.includes(sector)}
            onChange={event => {
              handleCheckBoxChange(event, sectors)
            }}
            type="checkbox"
            id={SECTOR_DICT[sector]}
            name={sector}
            disabled={maxSectorsSelected && !sectors?.includes(sector) && startup}
          />
          {sector === SECTOR_DATA[1] ||
          sector === SECTOR_DATA[11] ||
          sector === SECTOR_DATA[17] ||
          sector === SECTOR_DATA[18] ? (
            <label
              name={sector}
              htmlFor={sector}
              onClick={event => {
                // handleCardChange(event, sectors);
                if (startup) {
                  if (!maxSectorsSelected || (maxSectorsSelected && sectors.includes(sector))) {
                    handleCardChange(event, sectors)
                  }
                } else {
                  handleCardChange(event, sectors)
                }
              }}
              id={sector}
            >
              <span
                id={sector}
                className={sectors.includes(sector) ? 'sector-text-color mt-5 pt-5' : ''}
                style={
                  maxSectorsSelected && !sectors.includes(sector) && startup ? { color: '#B5B5B5' } : null
                }
              >
                {sector}
              </span>
            </label>
          ) : (
            <label name={sector} htmlFor={sector}>
              <span
                className={sectors.includes(sector) ? 'sector-text-color mt-5 pt-5' : ''}
                style={
                  maxSectorsSelected && !sectors.includes(sector) && !startup ? { color: '#B5B5B5' } : null
                }
              >
                {sector}
              </span>
            </label>
          )}
        </div>
      </div>
    )
  })

  /**
   * Handles the effect when the saveAndExitClicked state changes.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   * @description - Calls the appropriate next or back function based on the user type & Resets the saveAndExitClicked state.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      if (investor) {
        investorNextOnClick(false)
      } else if (scout) {
        scoutOnClick(false)
      } else {
        startupNextOnClick(false)
      }
      saveAndExitClickedReset()
    }
  }, [saveAndExitClicked])

  /**
   * Handles the change in the checkbox for sectors.
   *
   * @param {object} e - Event object representing the checkbox change.
   * @param {Array} sectors - Current sectors array.
   * @description - Updates the sectorsCopy array based on the checkbox state. Updates the user's sectors in local state.
   */
  const handleCheckBoxChange = (e, sectors) => {
    if (e.target.checked) {
      if (!sectors.includes(e.target.name)) {
        sectorsCopy.push(e.target.name)
      }
    } else {
      if (sectors.includes(e.target.name)) {
        const index = sectorsCopy.findIndex(element => element === e.target.name)
        sectorsCopy.splice(index, 1)
      }
    }
    if (investor) {
      updateLocalInvestorUser({ sectors: sectorsCopy })
    } else if (scout) {
      dispatch(updateLocalScoutUser({ sector_interests: sectorsCopy }))
    } else {
      updateLocalStartupUser({ sectors: sectorsCopy })
    }
  }

  /**
   * Handles the scoutOnClick action.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   * @description - Updates the user's sector interests and onboarding step.
   *              - Triggers the onCountinueClick function if it's a next action.
   *              - Clears local storage and logs out the scout if saveAndExitClicked is true.
   */
  const scoutOnClick = isNextOrBack => {
    dispatch(
      fetchScoutUserInfoUpdate({
        sector_interests: scoutUser.sector_interests,
        onboarding_step: isEnabled ? 3 : 2,
      }),
    )
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/scout/login'
          dispatch(fetchScoutUserLogout())
        }
      })
  }

  /**
   * Handles the investorOnClick action.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   * @description - Prepares the data based on the investor's expertise type and sectors.
   *              - Updates the user's sectors and onboarding step.
   *              - Redirects to the investor login page after successful update.
   *              - Logs out the investor and clears local storage if saveAndExitClicked is true.
   */
  const investorOnClick = isNextOrBack => {
    // let sectors = []
    // if (investorUser?.expertise_type == 'Generalist') {
    //   sectors = SECTOR_DATA.filter(item => !investorUser.sectors.includes(item))
    // } else {
    //   sectors = investorUser.sectors
    // }
    let data = [...sectors]
    if (investorUser?.expertise_type == 'Generalist' && !data?.length) {
      data = SECTOR_DATA
    }
    fetchInvestorUserInfoUpdate({ sectors: data, onboarding_step: isEnabled ? 5 : 4 })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            // onCountinueClick()
            //   otherProps.nextStepInvestor()
            // } else {
            //   otherProps.prevStepInvestor()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/investor/login'
          fetchInvestorUserLogout()
        }
      })
  }

  /**
   * Handles the startupOnClick action.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   * @description - Updates the user's sectors and onboarding step.
   *              - Triggers the onCountinueClick function if it's a next action.
   *              - Logs out the startup and clears local storage if saveAndExitClicked is true.
   */
  const startupOnClick = isNextOrBack => {
    fetchStartupUserInfoUpdate({
      sectors: startupUser.sectors,
      onboarding_step: isEnabled ? 3 : 2,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          } else {
            otherProps.prevStepStartup()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          fetchStartupUserLogout()
        }
      })
  }

  // We intentionally don't have an investorBackOnClick since the back button
  // does not exist on SectorPage since it is the first page in the investor flow.

  /**
   * Handles the investorNextOnClick action.
   * @param {object} e - Event object (unused).
   * @description - Calls the investorOnClick function, triggers onCountinueClick, and updates the investor's data.
   */
  const investorNextOnClick = e => {
    investorOnClick(e)
    onCountinueClick()
  }

  /**
   * Handles the startupNextOnClick action.
   * @param {object} e - Event object (unused).
   * @description - Calls the startupOnClick function and updates the startup's data.
   */
  const startupNextOnClick = e => {
    startupOnClick(e)
  }

  /**
   * Handles the scoutNextOnClick action.
   * @param {object} e - Event object (unused).
   * @description - Calls the scoutOnClick function with the argument true.
   */
  const scoutNextOnClick = e => {
    scoutOnClick(true)
  }

  /**
   * Handles the change in the checkbox for sectors.
   * @param {object} e - Event object representing the checkbox change.
   * @param {Array} sectors - Current sectors array.
   * @description - Updates the sectorsCopy array based on the checkbox state.
   *              - Updates the user's sectors in local state.
   */
  const handleCardChange = (e, sectors) => {
    let x = e.target.id
    if (!sectors.includes(x)) {
      sectorsCopy.push(x)
    } else {
      if (sectors.includes(x)) {
        const index = sectorsCopy.findIndex(element => element === x)
        sectorsCopy.splice(index, 1)
      }
    }
    if (investor) {
      updateLocalInvestorUser({ sectors: sectorsCopy })
    } else if (scout) {
      dispatch(updateLocalScoutUser({ sector_interests: sectorsCopy }))
    } else {
      updateLocalStartupUser({ sectors: sectorsCopy })
    }
  }
  console.log("I am here.")
  return (
    <>
      {/*** Heading is here ***/}
      <Container className="mb-3">
        <Row>
          <Col md={10} className="mx-auto text-center">
            {investor ? (
              <>
                <h4 className="heading-question">
                  Which sectors and business models are you{' '}
                  <span className="font-weight-bold">
                    {expertise_type === 'Generalist' ? 'NOT interested ' : 'interested '}
                  </span>
                  in?
                </h4>
              </>
            ) : scout ? (
              <>
                <h4 className="heading-question">
                  Which <span className="font-weight-bold">sectors</span> will the startups you refer
                  generally be in?
                </h4>
              </>
            ) : (
              <>
                <h4 className="heading-question">
                  What sectors and business models apply to{' '}
                  <span className="font-weight-bold">{startupUser.company_name}?</span>
                </h4>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <form>
        <TrendindCardList />
        {/* {checkList} */}
      </form>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container>
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <AntButton
                loading={loading}
                disabled={investorUser?.expertise_type !== 'Generalist' ? !isEnabled : false}
                onClick={investor ? investorNextOnClick : scout ? scoutNextOnClick : startupNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </AntButton>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  first_name: state.auth.user.first_name,
  stepInvestor: state.investorflowControl.stepInvestor,
  stepStartup: state.startupflowControl.stepStartup,
  sectors: state.auth.user.sectors,
  expertise_type: state.auth.user.expertise_type,
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  investor: state.auth.investor,
  company: state.auth.user.company,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
})

const mapDispatchToProps = dispatch => ({
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),

  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  fetchStartupUserLogout: () => {
    return dispatch(fetchStartupUserLogout())
  },
  fetchInvestorUserLogout: () => {
    dispatch(fetchInvestorUserLogout())
    return dispatch({
      type: 'SET_AUTH_EXPIRED',
    })
  },
  saveAndExitClickedReset: () => {
    return dispatch(SaveAndExitClickedReset())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SectorPage))
