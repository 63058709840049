import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Steps, Progress } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import LocationPage from '../../LocationPage/LocationPage'
import SectorPage from '../../SectorPage/SectorPage'
import { useHistory } from 'react-router'
import Step06InterestedLocations from '../../InvestorOnboarding/Step06InterestedLocations/Step06InterestedLocations'
import { SaveAndExitClicked } from '../../../redux/scoutFlowControl/scoutFlowControlActions'
import { useDispatch, useSelector } from 'react-redux'
import Step05ShortBio from '../../ScoutOnboarding/Step05ShortBio/Step05ShortBio'
import Step02OptionsForCloselyRelatedTo from '../../ScoutOnboarding/Step02OptionsForCloselyRelatedTo/Step02OptionsForCloselyRelatedTo'
import HeaderOnboarding from '../../../components/Header/HeaderOnboarding/HeaderOnboarding'
import { ReactComponent as DoneStepIcon } from '../../../assets/images/scouts/done_step.svg'
import './ScoutStep.scss'

const ScoutStep = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { Step } = Steps
  const [count, setCount] = React.useState(0)
  const [startInfoFlag, setStartInfoFlag] = React.useState(
    history.location.state?.gif === 'false' ? false : true,
  )
  const auth = useSelector(state => state.auth)
  const [current, setCurrent] = React.useState(0)

  /**
   * Check the onboarding step in the user's authentication data.
   * @description - If the step is non-zero, update the startInfoFlag, current step, and count accordingly.
   *
   */
  useEffect(() => {
    if (auth.scoutUser.onboarding_step) {
      if (auth.scoutUser.onboarding_step !== 0) {
        if (auth.scoutUser.onboarding_step === 5) {
          history.push('/scout/congratulations')
        } else {
          if (auth.scoutUser.onboarding_step !== 0) {
            setStartInfoFlag(false)
          }
          setCurrent(auth.scoutUser.onboarding_step)
          setCount(count + auth.scoutUser.onboarding_step * 20)
        }
      }
    } else {
      // If no onboarding step is found, set the step to 0 and count to the current count
      setCurrent(0)
      setCount(count)
    }
  }, [])

  // Update the URL with the current onboarding step.
  useEffect(() => {
    window.history.pushState({}, '', `/scout/onboarding/step-${Math.ceil((count / 10 + 1) / 2)}`)
  }, [count])

  // Function to move to the next step
  const next = () => {
    setCurrent(current + 1)
  }

  // Function to move to the previous step
  const prev = () => {
    setCurrent(current - 1)
  }

  // Function to handle the 'Continue' button click
  const onCountinueClick = () => {
    next()
    setCount(count + 20)
  }

  // Array containing step information
  const steps = [
    {
      title: 'Step 1',
      content: (
        <LocationPage
          startInfoFlag={startInfoFlag}
          setStartInfoFlag={setStartInfoFlag}
          onCountinueClick={onCountinueClick}
        />
      ),
    },
    {
      title: 'Step 2',
      content: <Step02OptionsForCloselyRelatedTo onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step 3',
      content: <SectorPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step 4',
      content: <Step06InterestedLocations onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step 5',
      content: <Step05ShortBio onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Done',
      icon: <DoneStepIcon />,
      content: <div></div>,
    },
  ]

  return (
    <div className="scout-steps-main-wrap">
      {/* Header component for onboarding */}
      <HeaderOnboarding
        onSaveAndLogout={
          startInfoFlag
            ? null
            : () => {
                dispatch(SaveAndExitClicked())
              }
        }
      />
      {/* Conditionally render steps and progress based on startInfoFlag */}
      {startInfoFlag ? (
        <span></span>
      ) : (
        <span>
          <Container className="mb-3 mt-5">
            <Row>
              <Col lg={5} md={7} xs={current == 0 ? 8 : 12} className="mx-auto">
                <Steps className="scout-steps" current={current}>
                  {steps.map((item, i) => (
                    <Step
                      className={`${current === 0 && 'firstStep'} ${item.title == 'Done' && 'done-step'} ${
                        i >= current + 2 && 'd-none'
                      } ${i <= current - 2 && 'd-none'}`}
                      icon={item.icon ? item.icon : null}
                      key={item.title}
                      title={item.title}
                    />
                  ))}
                </Steps>
              </Col>
            </Row>
          </Container>
          <Container className="mb-3">
            <Row>
              <Col md={8} className="mx-auto mb-3">
                {/* Progress component for displaying the overall progress */}
                <Progress percent={10 + count} />
              </Col>
            </Row>
          </Container>
        </span>
      )}
      {/* Render the content of the current step */}
      {steps[current].content}
      {/* Section for bottom buttons */}
      <section className="scout-steps-bottom-btns-wrap">
        <Container className="">
          <Row>
            <Col md={4} className="text-center mx-auto">
              {current > 0 && (
                <Button
                  className="backBtnOnboard btn-block mt-3 mb-5"
                  onClick={() => {
                    prev()
                    setCount(count - 20)
                  }}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ScoutStep
