import { lazy } from 'react'
//education get accredited routes

export const GetAccreditedSignup = lazy(() => import('../pages/EducationPortal/GetAccredited/Signup/Signup'))
export const GetAccreditedLogin = lazy(() => import('../pages/EducationPortal/GetAccredited/Login/Login'))
export const GetAccreditedSyllabus = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/Syllabus/GetAccreditedSyllabus.js'),
)
export const EducationEmailConfirmPage = lazy(() =>
  import('../pages/EducationPortal/EducationEmailConfirmPage/EducationEmailConfirmPage'),
)
export const GetAccreditedSyllabusSelect = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/SelectSyllabus/SelectSyllabus'),
)
export const GetAccreditedReadingMaterialDetails = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/ReadingMaterialDetails/ReadingMaterialDetails'),
)
export const GetAccreditedVideoLectureDetails = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/VideoLectureDetails/VideoLectureDetails'),
)
export const GetAccreditedQuizzes = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/Quizzes/Quizzes'),
)
export const GetAccreditedQuizDetalis = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/QuizzeDetalis/QuizDetalis'),
)
export const GetAccreditedQuizResult = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/QuizResult/QuizResult'),
)
export const GetAccreditedEducationProfile = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/EducationGetAccreditedProfile/ProfileMain'),
)
export const GetAccreditedEducationSetting = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/EducationGetAccreditedProfile/SettingMain'),
)
export const GetAccreditedEducationNotification = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/Notifications/EducationNotification'),
)
export const GetAccreditedEducationExams = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/Exams/ExamMain'),
)
export const GetAccreditedEducationExamQuiz = lazy(() =>
  import('../pages/EducationPortal/GetAccredited/Exams/ExamQuiz'),
)
export const EducationForgotPasswordPage = lazy(() =>
  import('../pages/EducationPortal/EducationForgotPasswordPage/EducationForgotPasswordPage'),
)
export const ErrorRoute404 = lazy(() => import('../pages/ErrorRoute404/ErrorRoute404'))

//education learn invest routes
export const LearnInvestSignup = lazy(() => import('../pages/EducationPortal/LearnInvest/Signup/Signup'))
export const LearnInvestLogin = lazy(() => import('../pages/EducationPortal/LearnInvest/Login/Login'))
export const InvestSyllabus = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/Syllabus/InvestSyllabus'),
)
export const SyllabusSelect = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/SelectSyllabus/SelectSyllabus'),
)
export const ReadingMaterialDetails = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/ReadingMaterialDetails/ReadingMaterialDetails'),
)
export const VideoLectureDetails = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/VideoLectureDetails/VideoLectureDetails'),
)
export const Quizzes = lazy(() => import('../pages/EducationPortal/LearnInvest/Quizzes/Quizzes'))
export const QuizDetalis = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/QuizzeDetalis/QuizDetalis'),
)
export const QuizResult = lazy(() => import('../pages/EducationPortal/LearnInvest/QuizResult/QuizResult'))
export const EducationInvestProfile = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/EducationInvestProfile/ProfileMain'),
)
export const EducationInvestSetting = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/EducationInvestProfile/SettingMain'),
)
export const EducationInvestNotification = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/Notifications/EducationNotification'),
)
export const EducationInvestExams = lazy(() => import('../pages/EducationPortal/LearnInvest/Exams/ExamMain'))
export const EducationInvestExamDetails = lazy(() =>
  import('../pages/EducationPortal/LearnInvest/Exams/ExamQuiz'),
)
export const Education = lazy(() => import('../pages/Education/Education'))
