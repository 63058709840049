import { ReactComponent as Agriculture } from '../../assets/images/on_boarding_imgs/sectors/Agriculture.svg'
import { ReactComponent as Artificial_Intelligence } from '../../assets/images/on_boarding_imgs/sectors/Artificial_Intelligence.svg'
import { ReactComponent as Augmented_Reality } from '../../assets/images/on_boarding_imgs/sectors/Augmented_Reality.svg'
import { ReactComponent as Biomedical } from '../../assets/images/on_boarding_imgs/sectors/Biomedical.svg'
import { ReactComponent as Biotech } from '../../assets/images/on_boarding_imgs/sectors/Biotech.svg'
import { ReactComponent as Blockchain } from '../../assets/images/on_boarding_imgs/sectors/Blockchain.svg'
import { ReactComponent as Enterprise } from '../../assets/images/on_boarding_imgs/sectors/Enterprise.svg'
import { ReactComponent as Entertainment } from '../../assets/images/on_boarding_imgs/sectors/Entertainment.svg'
import { ReactComponent as Community } from '../../assets/images/on_boarding_imgs/sectors/Community.svg'
import { ReactComponent as Crowdfunding } from '../../assets/images/on_boarding_imgs/sectors/Crowdfunding.svg'
import { ReactComponent as Diversity } from '../../assets/images/on_boarding_imgs/sectors/Diversity.svg'
import { ReactComponent as Drones } from '../../assets/images/on_boarding_imgs/sectors/Drones.svg'
import { ReactComponent as education } from '../../assets/images/on_boarding_imgs/sectors/Education.svg'
import { ReactComponent as Esports } from '../../assets/images/on_boarding_imgs/sectors/Esports.svg'
import { ReactComponent as Marketplace } from '../../assets/images/on_boarding_imgs/sectors/Marketplace.svg'
import { ReactComponent as SAAS } from '../../assets/images/on_boarding_imgs/sectors/SAAS.svg'
import { ReactComponent as Media } from '../../assets/images/on_boarding_imgs/sectors/Media.svg'
import { ReactComponent as Moonshots } from '../../assets/images/on_boarding_imgs/sectors/Moonshots.svg'
import { ReactComponent as Financial } from '../../assets/images/on_boarding_imgs/sectors/Financial.svg'
import { ReactComponent as Government } from '../../assets/images/on_boarding_imgs/sectors/Government.svg'
import { ReactComponent as Hardware } from '../../assets/images/on_boarding_imgs/sectors/Hardware.svg'
import { ReactComponent as Healthcare } from '../../assets/images/on_boarding_imgs/sectors/Healthcare.svg'
import { ReactComponent as Robotics } from '../../assets/images/on_boarding_imgs/sectors/Robotics.svg'
import { ReactComponent as Security } from '../../assets/images/on_boarding_imgs/sectors/Security.svg'
import { ReactComponent as Sport } from '../../assets/images/on_boarding_imgs/sectors/Sport.svg'
import { ReactComponent as Transportation } from '../../assets/images/on_boarding_imgs/sectors/Transportation.svg'
import { ReactComponent as Travel } from '../../assets/images/on_boarding_imgs/sectors/Travel.svg'
import { ReactComponent as Virtual_Reality } from '../../assets/images/on_boarding_imgs/sectors/Virtual_Reality.svg'
import { ReactComponent as E_Commerce } from '../../assets/images/on_boarding_imgs/sectors/E_Commerce.svg'
import { ReactComponent as Real_Estate } from '../../assets/images/on_boarding_imgs/sectors/Real_Estate.svg'
import { ReactComponent as Food_and_Beverages } from '../../assets/images/on_boarding_imgs/sectors/Food_and_Beverages.svg'
import { ReactComponent as Cleantech } from '../../assets/images/on_boarding_imgs/sectors/Cleantech.svg'
import { ReactComponent as Beauty } from '../../assets/images/on_boarding_imgs/sectors/Beauty.svg'
import { ReactComponent as Clothing } from '../../assets/images/on_boarding_imgs/sectors/Clothing.svg'
import { ReactComponent as Developer_tools } from '../../assets/images/on_boarding_imgs/sectors/Developer_tools.svg'
import { ReactComponent as Sex } from '../../assets/images/on_boarding_imgs/sectors/Sex.svg'
import { ReactComponent as CRM } from '../../assets/images/on_boarding_imgs/sectors/CRM.svg'
import { ReactComponent as Distributed_Workforce } from '../../assets/images/on_boarding_imgs/sectors/Distributed_Workforce.svg'
import { ReactComponent as B2B } from '../../assets/images/on_boarding_imgs/sectors/B2B.svg'
import { ReactComponent as B2B2C } from '../../assets/images/on_boarding_imgs/sectors/B2B2C.svg'
import { ReactComponent as B2C } from '../../assets/images/on_boarding_imgs/sectors/B2C.svg'
import { ReactComponent as Subscription } from '../../assets/images/on_boarding_imgs/sectors/Subscription.svg'
import { ReactComponent as Other } from '../../assets/images/on_boarding_imgs/sectors/Other.svg'
import { ReactComponent as Energy } from '../../assets/images/on_boarding_imgs/sectors/Energy.svg'

// Listout all sector data DICt.
export const SECTOR_DICT = {
  'Agriculture/Agtech': 'agtech',
  'Artificial Intelligence': 'ai',
  'Augmented Reality': 'ar',
  Biomedical: 'biomed',
  Biotech: 'biotech',
  Blockchain: 'blockchain',
  Community: 'community',
  Crowdfunding: 'crowdfund',
  Diversity: 'diversity',
  Drones: 'drones',
  Education: 'education',
  Enterprise: 'enterprise',
  Entertainment: 'entertain',
  'Esports/Online Gaming': 'gaming',
  'Financial/Banking': 'banking',
  Government: 'government',
  Hardware: 'hardware',
  Healthcare: 'health',
  Marketplace: 'market',
  'Media/Advertising': 'media',
  'Moonshots/Hard Tech': 'hardtech',
  Robotics: 'robotics',
  Security: 'security',
  'Sport/Fitness': 'sport',
  Transportation: 'transport',
  Travel: 'travel',
  'Virtual Reality': 'vr',
  Other: 'other',
}

// const SECTOR_DATA = [
//   'Agriculture/Agtech',
//   'Artificial Intelligence',
//   'Augmented Reality',
//   'Biomedical',
//   'Biotech',
//   'Blockchain',
//   'Community',
//   'Crowdfunding',
//   'Diversity',
//   'Drones',
//   'Education',
//   'Enterprise',
//   'Entertainment',
//   'Esports/Online Gaming',
//   'Financial/Banking',
//   'Government',
//   'Hardware',
//   'Healthcare',
//   'Marketplace',
//   'Media/Advertising',
//   'Moonshots/Hard Tech',
//   'Robotics',
//   'Security',
//   'Sport/Fitness',
//   'Transportation',
//   'Travel',
//   'Virtual Reality',
//   'Other',
// ]

// List out all sector data.
const SECTOR_DATA = [
  'Agriculture/Agtech',
  'Artificial Intelligence',
  'Augmented Reality',
  'Biomedical',
  'Biotech',
  'Blockchain/Cryptocurrency',
  'Enterprise',
  'Entertainment',
  'Community',
  'Crowdfunding',
  'Diversity',
  'Drones',
  'Education',
  'Energy',
  'Esports/Online Gaming',
  'Marketplace',
  'SAAS',
  'Media/Advertising',
  'Moonshots/Hard Tech',
  'Financial/Banking',
  'Government',
  'Hardware',
  'Healthcare',
  'Robotics',
  'Security',
  'Sport/Fitness',
  'Transportation',
  'Travel',
  'Virtual Reality',
  'E-Commerce',
  'Real Estate/Proptech',
  'Food and Beverages',
  'Cleantech',
  'Beauty/Cosmetics',
  'Clothing/Fashion',
  'Developer tools',
  'Sex/Relationships',
  'Sales/CRM',
  'Distributed Workforce/Future of Work',
  'B2B',
  'B2B2C',
  'B2C',
  'Subscription',
  'Other',
]

// List out all sector data images
const SECTOR_DATA_IMAGES = {
  'Agriculture/Agtech': Agriculture,
  'Artificial Intelligence': Artificial_Intelligence,
  'Augmented Reality': Augmented_Reality,
  Biomedical: Biomedical,
  Biotech: Biotech,
  'Blockchain/Cryptocurrency': Blockchain,
  Enterprise: Enterprise,
  Entertainment: Entertainment,
  Community: Community,
  Crowdfunding: Crowdfunding,
  Diversity: Diversity,
  Drones: Drones,
  Education: education,
  Energy: Energy,
  'Esports/Online Gaming': Esports,
  Marketplace: Marketplace,
  SAAS: SAAS,
  'Media/Advertising': Media,
  'Moonshots/Hard Tech': Moonshots,
  'Financial/Banking': Financial,
  Government: Government,
  Hardware: Hardware,
  Healthcare: Healthcare,
  Robotics: Robotics,
  Security: Security,
  'Sport/Fitness': Sport,
  Transportation: Transportation,
  Travel: Travel,
  'Virtual Reality': Virtual_Reality,
  'E-Commerce': E_Commerce,
  'Real Estate/Proptech': Real_Estate,
  'Food and Beverages': Food_and_Beverages,
  Cleantech: Cleantech,
  'Beauty/Cosmetics': Beauty,
  'Clothing/Fashion': Clothing,
  'Developer tools': Developer_tools,
  'Sex/Relationships': Sex,
  'Sales/CRM': CRM,
  'Distributed Workforce/Future of Work': Distributed_Workforce,
  B2B: B2B,
  B2B2C: B2B2C,
  B2C: B2C,
  Subscription: Subscription,
  Other: Other,
}

export { SECTOR_DATA, SECTOR_DATA_IMAGES }
