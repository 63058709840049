import api from '../../utils/api'
import { SECTOR_DATA } from '../../pages/SectorPage/SectorData'

import {
  investorSignupFailed,
  investorLoginFailed,
  updateUserInfoFailed,
} from '../investorFlowControl/investorFlowControlActions'

import {
  startupSignupFailed,
  startupLoginFailed,
  updateStartupUserInfoFailed,
} from '../startupFlowControl/startupFlowControlActions'

import {
  emitStartupLogin,
  emitStartupSignup,
  identifyStartup,
  emitInvestorSignup,
  emitInvestorLogin,
  identifyInvestor,
  identifyScout,
  emitScoutSignup,
  emitScoutLogin,
  resetUserIdentity,
} from '../../metrics/metricsEmitter'
import { scoutSignupFailed, scoutLoginFailed } from '../scoutFlowControl/scoutFlowControlActions.js'

/**
 * Action to set the JWT token in the state.
 * @param {string} token - The JWT token to be included in the action payload.
 * @returns {Object} The action object with type 'SET_TOKEN' and the specified payload.
 */
export const setJwtToken = token => {
  return {
    type: 'SET_TOKEN',
    payload: token,
  }
}

/**
 * Action creator function to asynchronously get the JWT token.
 * @param {string} email - The email associated with the user.
 * @param {string} userType - The type of user (e.g., 'startup', 'investor', 'scout').
 * @returns {Function} A function that accepts `dispatch` and returns a Promise.
 */
export const getJwtToken = (email, userType) => {
  return dispatch => {
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/${userType}/get-jwt-token`, email)
      .then(data => {
        if (data.result) {
          setJwtToken(data.token)
          dispatch(setJwtToken(data.token))
          return true
        } else {
          return false
        }
      })
  }
}

export const LOGOUT_LOCAL_INVESTOR_USER = 'LOGOUT_LOCAL_INVESTOR_USER'
export const LOGOUT_LOCAL_SCOUT_USER = 'LOGOUT_LOCAL_SCOUT_USER'

export const REQUEST_SIGNUP_BY_EMAIL = 'REQUEST_SIGNUP_BY_EMAIL'
export const RECEIVE_SIGNUP_BY_EMAIL = 'RECEIVE_SIGNUP_BY_EMAIL'
export const UPDATE_LOCAL_INVESTOR_USER = 'UPDATE_LOCAL_INVESTOR_USER'
export const REQUEST_SIGNIN_BY_EMAIL = 'REQUEST_SIGNIN_BY_EMAIL'
export const RECEIVE_SIGNIN_BY_EMAIL = 'RECEIVE_SIGNIN_BY_EMAIL'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const SET_PROFILE_LOADER = 'SET_PROFILE_LOADER'
export const REQUEST_USER_INFO_UPDATE = 'REQUEST_USER_INFO_UPDATE'
export const RECEIVE_USER_INFO_UPDATE = 'RECEIVE_USER_INFO_UPDATE'
export const REQUEST_LOADING = 'REQUEST_LOADING'
export const LOGOUT_LOCAL_STARTUP_USER = 'LOGOUT_LOCAL_STARTUP_USER'
export const LOGOUT_LOCAL_INVESTOR = 'LOGOUT_LOCAL_INVESTOR'
export const LOGOUT_LOCAL_STARTUP = 'LOGOUT_LOCAL_STARTUP'
export const LOGOUT_LOCAL_SCOUT = 'LOGOUT_LOCAL_SCOUT'
export const RECEIVE_STARTUP_SIGNIN_BY_EMAIL = 'RECEIVE_STARTUP_SIGNIN_BY_EMAIL'
export const RECEIVE_SCOUT_SIGNIN_BY_EMAIL = 'RECEIVE_SCOUT_SIGNIN_BY_EMAIL'
export const RECEIVE_STARTUP_USER_INFO_UPDATE = 'RECEIVE_STARTUP_USER_INFO_UPDATE'
export const RECEIVE_SCOUT_USER_INFO_UPDATE = 'RECEIVE_SCOUT_USER_INFO_UPDATE'
export const REQUEST_SCOUT_USER_INFO_UPDATE = 'REQUEST_SCOUT_USER_INFO_UPDATE'
export const UPDATE_LOCAL_STARTUP_USER = 'UPDATE_LOCAL_STARTUP_USER'

export const RECEIVE_STARTUP_FILE_UPLOAD = 'RECEIVE_STARTUP_FILE_UPLOAD'
export const RECEIVE_INVESTOR_SIGNUP_BY_GOOGLE = 'RECEIVE_INVESTOR_SIGNUP_BY_GOOGLE'
export const RECEIVE_STARTUP_SIGNUP_BY_GOOGLE = 'RECEIVE_STARTUP_SIGNUP_BY_GOOGLE'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR_FOR_GOOGLE_ACCOUNT = 'FORGOT_PASSWORD_ERROR_FOR_GOOGLE_ACCOUNT'
export const RECEIVE_SCOUT_SIGNUP_BY_GOOGLE = 'RECEIVE_SCOUT_SIGNUP_BY_GOOGLE'
export const UPDATE_LOCAL_SCOUT_USER = 'UPDATE_LOCAL_SCOUT_USER'

export const RESEND_APPLICATION_INIT = 'RESEND_APPLICATION_INIT'
export const RESEND_APPLICATION_SUCCESS = 'RESEND_APPLICATION_SUCCESS'
export const RESEND_APPLICATION_FAILED = 'RESEND_APPLICATION_FAILED'

export const RESEND_INVESTOR_APPLICATION_INIT = 'RESEND_INVESTOR_APPLICATION_INIT'
export const RESEND_INVESTOR_APPLICATION_SUCCESS = 'RESEND_INVESTOR_APPLICATION_SUCCESS'
export const RESEND_INVESTOR_APPLICATION_FAILED = 'RESEND_INVESTOR_APPLICATION_FAILED'

export const THANKYOU_SCREEN_VIEW_INIT = 'THANKYOU_SCREEN_VIEW_INIT'
export const THANKYOU_SCREEN_VIEW_SUCCESS = 'THANKYOU_SCREEN_VIEW_SUCCESS'
export const THANKYOU_SCREEN_VIEW_FAILED = 'THANKYOU_SCREEN_VIEW_FAILED'

export const INVESTOR_THANKYOU_SCREEN_VIEW_INIT = 'INVESTOR_THANKYOU_SCREEN_VIEW_INIT'
export const INVESTOR_THANKYOU_SCREEN_VIEW_SUCCESS = 'INVESTOR_THANKYOU_SCREEN_VIEW_SUCCESS'
export const INVESTOR_THANKYOU_SCREEN_VIEW_FAILED = 'INVESTOR_THANKYOU_SCREEN_VIEW_FAILED'

// Action to indicate a successful user registration.
export const registerSuccess = () => ({
  type: 'REGISTER_SUCCESS',
})

// Action to toggle the startup button.
export const toggleStartup = () => ({
  type: 'TOGGLE_STARTUP_BUTTON',
})

// Action to toggle the scout button.
export const toggleScout = () => ({
  type: 'TOGGLE_SCOUT_BUTTON',
})

// Action to toggle the investor button.
export const toggleInvestor = () => ({
  type: 'TOGGLE_INVESTOR_BUTTON',
})

// Action to indicate that the user has been selected as an investor.
export const selectedAsInvestor = () => ({
  type: 'SELECTED_AS_INVESTOR',
})

// Action to indicate that the user has been selected as a startup.
export const selectedAsStartup = () => ({
  type: 'SELECTED_AS_STARTUP',
})

// Action to indicate that the user has been selected as a scout.
export const selectedAsScout = () => ({
  type: 'SELECTED_AS_SCOUT',
})

export const accreditionToOnBoardingRoute = data => ({
  type: 'ACCREDITION_TO_ONBOARDING_ROUTE',
  payload: data,
})

// ============ Update Local User Actions ==============
// export const updateLocalInvestorUser = changedProperties => {
//   return {
//     type: UPDATE_LOCAL_INVESTOR_USER,
//     payload: changedProperties,
//   }
// }

/**
 * Action creator to update local investor user information.
 * @param {Object} changedProperties - An object containing properties representing the changed user information for an investor.
 * @returns {Object} - Redux action object.
 */
export const updateLocalInvestorUser = changedProperties => ({
  type: UPDATE_LOCAL_INVESTOR_USER,
  payload: changedProperties,
})

/**
 * Action creator to update local startup user information.
 * @param {Object} changedProperties - An object containing properties representing the changed user information for an startup.
 * @returns {Object} - Redux action object.
 */
export const updateLocalStartupUser = changedProperties => ({
  type: UPDATE_LOCAL_STARTUP_USER,
  payload: changedProperties,
})

/**
 * Action creator to update local scout user information.
 * @param {Object} changedProperties - An object containing properties representing the changed user information for an scout.
 * @returns {Object} - Redux action object.
 */
export const updateLocalScoutUser = changedProperties => ({
  type: UPDATE_LOCAL_SCOUT_USER,
  payload: changedProperties,
})

// Action creator to display the file name.
export const displayFileName = file => ({
  type: 'DISPLAY_FILE_NAME',
  payload: file,
})

// Action creator to remove the displayed file name.
export const removeFilename = () => ({
  type: 'REMOVE_FILE_NAME',
})

// Action creator to indicate that a slide deck was not provided.
export const didNotProvideSlideDeck = () => ({
  type: 'DID_NOT_PROVIDE_SLIDEDECK',
})

// ============SignUp Actions==================

//  Action to indicate the start of a signup request by email.
export const reqeustSignupByEmail = () => ({
  type: REQUEST_SIGNUP_BY_EMAIL,
})

// Action to receive signup data after a successful signup by email.
export const receiveSignupByEmail = data => ({
  type: RECEIVE_SIGNUP_BY_EMAIL,
  payload: data,
})

// Action to perform signup for an investor by email.
export const fetchInvestorSignupByEmail = user => {
  return dispatch => {
    dispatch(reqeustSignupByEmail())
    return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/investor/register`, user).then(data => {
      if (data.result) {
        const investorUser = data.user
        identifyInvestor(
          investorUser.id,
          investorUser.first_name,
          investorUser.last_name,
          investorUser.email,
          investorUser.is_google_signup,
        )
        emitInvestorSignup()
        emitInvestorLogin()
        setJwtToken(data.token)
        localStorage.setItem('userId', investorUser.id)

        dispatch(receiveSignupByEmail(data))
        return
      } else {
        dispatch(investorSignupFailed())
        throw data.error
      }
    })
  }
}

// Action to perform signup for a startup by email.
export const fetchStartupSignupByEmail = user => {
  return dispatch => {
    dispatch(reqeustSignupByEmail())
    return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/startup/register`, user).then(data => {
      if (data.result) {
        const startupUser = data.user
        identifyStartup(
          startupUser.id,
          startupUser.first_name,
          startupUser.last_name,
          startupUser.email,
          startupUser.is_google_signup,
        )
        emitStartupSignup()
        emitStartupLogin()
        setJwtToken(data.token)
        localStorage.setItem('token', data.token)
        dispatch(receiveSignupByEmail(data))
        return data.user
      } else {
        dispatch(startupSignupFailed())
        throw data.error
      }
    })
  }
}

// Action to perform signup for a scout by email.
export const fetchScoutSignupByEmail = user => {
  return dispatch => {
    try {
      dispatch(reqeustSignupByEmail())
      return api
        .postWithoutToken(`${process.env.REACT_APP_API_URL}/scout/register`, user)
        .then(data => {
          if (data.result) {
            const scoutUser = data.user
            identifyScout(
              scoutUser.id,
              scoutUser.first_name,
              scoutUser.last_name,
              scoutUser.email,
              scoutUser.is_google_signup,
            )
            emitScoutSignup()
            emitScoutLogin()
            setJwtToken(data.token)
            dispatch(receiveSignupByEmail(data))
            return
          } else {
            dispatch(scoutSignupFailed())
            throw data.error
          }
        })
        .catch(error => {
          dispatch(scoutSignupFailed())
          throw error
        })
    } catch (error) {
      dispatch(scoutSignupFailed())
      throw error
    }
  }
}

// ================= Google OAuth Actions =============

// Action to receive data after a successful investor signup by Google.
export const receiveInvestorSignupByGoogle = data => ({
  type: RECEIVE_INVESTOR_SIGNUP_BY_GOOGLE,
  payload: data,
})

// Action to perform investor signup using Google credentials.
export const fetchInvestorSignupByGoogle = googleUser => {
  return dispatch => {
    dispatch(reqeustSignupByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/investor/google-signup`, json_obj)
      .then(data => {
        if (data.result) {
          const investorUser = data.user
          identifyInvestor(
            investorUser.id,
            investorUser.first_name,
            investorUser.last_name,
            investorUser.email,
            investorUser.is_google_signup,
          )
          emitInvestorSignup()
          emitInvestorLogin()
          setJwtToken(data.token)
          dispatch(receiveInvestorSignupByGoogle(data))
          return
        } else {
          dispatch(investorSignupFailed())
          throw data.error
        }
      })
  }
}

// Action to receive data after a successful startup signup by Google.
export const receiveStartupSignupByGoogle = data => ({
  type: RECEIVE_STARTUP_SIGNUP_BY_GOOGLE,
  payload: data,
})

// Action to receive data after a successful scout signup by Google.
export const receiveScoutSignupByGoogle = data => ({
  type: RECEIVE_SCOUT_SIGNUP_BY_GOOGLE,
  payload: data,
})

// Action to perform startup signup using Google credentials.
export const fetchStartupSignupByGoogle = googleUser => {
  return dispatch => {
    dispatch(reqeustSignupByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/startup/google-signup`, json_obj)
      .then(data => {
        if (data.result) {
          const startupUser = data.user
          identifyStartup(
            startupUser.id,
            startupUser.first_name,
            startupUser.last_name,
            startupUser.email,
            startupUser.is_google_signup,
          )
          emitStartupSignup()
          emitStartupLogin()
          setJwtToken(data.token)
          dispatch(receiveStartupSignupByGoogle(data))
          return
        } else {
          dispatch(startupSignupFailed())
          throw data.error
        }
      })
  }
}

// Action to perform scut signup using Google credentials.
export const fetchScoutSignupByGoogle = googleUser => {
  return dispatch => {
    dispatch(reqeustSignupByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/scout/google-signup`, json_obj)
      .then(data => {
        if (data.result) {
          const scoutUser = data.user
          identifyScout(
            scoutUser.id,
            scoutUser.first_name,
            scoutUser.last_name,
            scoutUser.email,
            scoutUser.is_google_signup,
          )
          emitScoutSignup()
          emitScoutLogin()
          setJwtToken(data.token)
          dispatch(receiveScoutSignupByGoogle(data))
          return
        } else {
          dispatch(scoutSignupFailed())
          throw data.error
        }
      })
  }
}

// Action to update the email of the local investor user.
export const updateInvestorEmail = () => ({
  type: 'UPDATE_LOCAL_INVESTOR_USER',
  payload: { email: 'google-signin' },
})

// Action to update the email of the local startup user.
export const updateStartupEmail = () => ({
  type: 'UPDATE_LOCAL_STARTUP_USER',
  payload: { email: 'google-signin' },
})

// Action to update the email of the local scout user.
export const updateScoutEmail = () => ({
  type: 'UPDATE_LOCAL_SCOUT_USER',
  payload: { email: 'google-signin' },
})

// Action to perform investor signin using Google credentials.
export const fetchInvestorSigninByGoogle = googleUser => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/investor/google-signin`, json_obj)
      .then(data => {
        if (data.token) {
          const investorUser = data.user
          identifyInvestor(
            investorUser.id,
            investorUser.first_name,
            investorUser.last_name,
            investorUser.email,
            investorUser.is_google_signup,
          )
          emitInvestorLogin()
          setJwtToken(data.token)
          dispatch(receiveSigninByEmail(data))
          return
        } else {
          dispatch(investorLoginFailed())
          throw data.error
        }
      })
  }
}

// Action to perform startup signin using Google credentials.
export const fetchStartupSigninByGoogle = googleUser => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/startup/google-signin`, json_obj)
      .then(data => {
        if (data.token) {
          const startupUser = data.user
          identifyStartup(
            startupUser.id,
            startupUser.first_name,
            startupUser.last_name,
            startupUser.email,
            startupUser.is_google_signup,
          )
          emitStartupLogin()
          setJwtToken(data.token)
          dispatch(receiveStartupSigninByEmail(data))
          return
        } else {
          dispatch(scoutLoginFailed())
          throw data.error
        }
      })
  }
}

// Action to perform scout signin using Google credentials.
export const fetchScoutSigninByGoogle = googleUser => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    var id_token = googleUser.credential
    const json_obj = { token: id_token }
    return api
      .postWithoutToken(`${process.env.REACT_APP_API_URL}/scout/google-signin`, json_obj)
      .then(data => {
        if (data.token) {
          const scoutUser = data.user
          identifyScout(
            scoutUser.id,
            scoutUser.first_name,
            scoutUser.last_name,
            scoutUser.email,
            scoutUser.is_google_signup,
          )
          emitStartupLogin()
          setJwtToken(data.token)
          dispatch(receiveScoutSigninByEmail(data))
          return
        } else {
          dispatch(scoutLoginFailed())
          throw data.error
        }
      })
  }
}

// ==================SignIn Actions==================

// Action to request signin by email.
export const requestSigninByEmail = () => ({
  type: REQUEST_SIGNIN_BY_EMAIL,
})

// Action to receive signin by email data.
export const receiveSigninByEmail = data => ({
  type: RECEIVE_SIGNIN_BY_EMAIL,
  payload: data,
})

// Action to perform investor signin by email.
export const fetchInvestorSigninByEmail = user => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/investor/login`, user).then(data => {
      if (data.token) {
        const investorUser = data.user
        identifyInvestor(
          investorUser.id,
          investorUser.first_name,
          investorUser.last_name,
          investorUser.email,
          investorUser.is_google_signup,
        )
        emitInvestorLogin()
        setJwtToken(data.token)
        localStorage.setItem('userId', investorUser.id)
        dispatch(receiveSigninByEmail(data))
        return data.user
      } else {
        dispatch(investorLoginFailed())
        throw data.error
      }
    })
  }
}

// Action to receive startup signin by email data.
export const receiveStartupSigninByEmail = data => ({
  type: RECEIVE_STARTUP_SIGNIN_BY_EMAIL,
  payload: data,
})

// Action to perform startup signin by email.
export const fetchStartupSigninByEmail = user => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/startup/login`, user).then(data => {
      if (data.token) {
        const startupUser = data.user
        identifyStartup(
          startupUser.id,
          startupUser.first_name,
          startupUser.last_name,
          startupUser.email,
          startupUser.is_google_signup,
        )
        emitStartupLogin()
        setJwtToken(data.token)
        dispatch(receiveStartupSigninByEmail(data))
        return data.user
      } else {
        dispatch(startupLoginFailed())
        throw data.error
      }
    })
  }
}

// Action to receive scout signin by email data.
export const receiveScoutSigninByEmail = data => ({
  type: RECEIVE_SCOUT_SIGNIN_BY_EMAIL,
  payload: data,
})

// Action to perform scout signin by email.
export const fetchScoutSigninByEmail = user => {
  return dispatch => {
    dispatch(requestSigninByEmail())
    return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/scout/login`, user).then(data => {
      if (data.token) {
        const scoutUser = data.user
        if (scoutUser?.onboarding_step < 5) {
          identifyScout(
            scoutUser.id,
            scoutUser.first_name,
            scoutUser.last_name,
            scoutUser.email,
            scoutUser.is_google_signup,
          )
          emitScoutSignup()
          emitScoutLogin()
          setJwtToken(data.token)
          dispatch(receiveScoutSigninByEmail(data))
        } else {
          identifyScout(
            scoutUser.id,
            scoutUser.first_name,
            scoutUser.last_name,
            scoutUser.email,
            scoutUser.is_google_signup,
          )
          emitScoutLogin()
          setJwtToken(data.token)
          dispatch(receiveScoutSigninByEmail(data))
        }

        return scoutUser
      } else {
        dispatch(scoutLoginFailed())
        throw data.error
      }
    })
  }
}

//===============User Info Update Actions ==============

// export const fetchScoutSigninByEmail = user => {
//   return dispatch => {
//     dispatch(requestSigninByEmail())
//     return api.postWithoutToken(`${process.env.REACT_APP_API_URL}/scout/login`, user).then(data => {
//       if (data.token) {
//         const scoutUser = data.user
//         identifyScout(
//           scoutUser.id,
//           scoutUser.first_name,
//           scoutUser.last_name,
//           scoutUser.email,
//           scoutUser.is_google_signup,
//         )
//         emitScoutLogin()
//         setJwtToken(data.token)
//         localStorage.setItem('userId', scoutUser.id)
//         dispatch(receiveSigninByEmail(data))
//         return data.user
//       } else {
//         dispatch(scoutLoginFailed())
//         throw data.error
//       }
//     })
//   }
// }

// Action to request user info update.
export const requestUserInfoUpdate = () => ({
  type: REQUEST_USER_INFO_UPDATE,
})

// Action to receive user info update.
export const receiveUserInfoUpdate = data => ({
  type: RECEIVE_USER_INFO_UPDATE,
  payload: data,
})

// Action to request loading state.
export const requestLoading = () => ({
  type: REQUEST_LOADING,
})

// Action to update investor user information.
export const fetchInvestorUserInfoUpdate = (changedProperties, onSuccess, onFail) => {
  return (dispatch, getState) => {
    const expertise_type = getState().auth.user.expertise_type
    // if (changedProperties?.onboarding_step == 5) {
    //   if (expertise_type == 'Generalist') {
    //     changedProperties = {
    //       ...changedProperties,
    //       sectors: SECTOR_DATA.filter(item => !changedProperties.sectors.includes(item)),
    //     }
    //   } else {
    //     changedProperties = { ...changedProperties, sectors: changedProperties.sectors }
    //   }
    // }
    dispatch(requestUserInfoUpdate())
    return api
      .patch(`${process.env.REACT_APP_API_URL}/investor/update-info`, changedProperties)
      .then(data => {
        if (data.result) {
          onSuccess && onSuccess(data?.user)
          let newdata = { ...data.user }
          // if (changedProperties?.onboarding_step == 5) {
          //   if (expertise_type == 'Generalist') {
          //     newdata = {
          //       ...newdata,
          //       sectors: SECTOR_DATA.filter(item => !newdata.sectors.includes(item)),
          //     }
          //   }
          // }
          dispatch(receiveUserInfoUpdate(newdata))
          return data
        } else {
          onFail && onFail(data.error)
          dispatch(requestLoading())
          dispatch(updateUserInfoFailed())
          if (data.error === 'token expired' && data.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

// Action to update investor invite code.
export const fetchInvestorInviteCode = changedProperties => {
  return (dispatch, getState) => {
    const expertise_type = getState().auth.user.expertise_type
    // if (changedProperties?.onboarding_step == 5) {
    //   if (expertise_type == 'Generalist') {
    //     changedProperties = {
    //       ...changedProperties,
    //       sectors: SECTOR_DATA.filter(item => !changedProperties.sectors.includes(item)),
    //     }
    //   } else {
    //     changedProperties = { ...changedProperties, sectors: changedProperties.sectors }
    //   }
    // }
    dispatch(requestUserInfoUpdate())
    return api
      .patch(`${process.env.REACT_APP_API_URL}/investor/update-info`, changedProperties)
      .then(data => {
        if (data.result) {
          let newdata = { ...data.user }
          // if (changedProperties?.onboarding_step == 5) {
          //   if (expertise_type == 'Generalist') {
          //     newdata = {
          //       ...newdata,
          //       sectors: SECTOR_DATA.filter(item => !newdata.sectors.includes(item)),
          //     }
          //   }
          // }
          dispatch(receiveUserInfoUpdate(newdata))
          return data
        } else {
          dispatch(requestLoading())
          dispatch(updateUserInfoFailed())
          if (data.error === 'token expired' && data.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return data
        }
      })
  }
}

// Action to update scout user information.
export const fetchScoutUserInfoUpdate = changedProperties => {
  return dispatch => {
    //dispatch(requestUserInfoUpdate())
    dispatch(requestScoutUserInfoUpdate())
    return api.patch(`${process.env.REACT_APP_API_URL}/scout/update-info`, changedProperties).then(data => {
      if (data.result) {
        dispatch(receiveScoutUserInfoUpdate(data.user))
        return true
      } else {
        dispatch(updateUserInfoFailed())
        if (data.error === 'token expired' && data.is_expired) {
          dispatch(fetchScoutUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to receive updated startup user information.
export const receiveStartupUserInfoUpdate = data => ({
  type: RECEIVE_STARTUP_USER_INFO_UPDATE,
  payload: data,
})

// Action to receive updated scout user information.
export const receiveScoutUserInfoUpdate = data => ({
  type: RECEIVE_SCOUT_USER_INFO_UPDATE,
  payload: data,
})

// Action to request scout user information update.
export const requestScoutUserInfoUpdate = data => ({
  type: REQUEST_SCOUT_USER_INFO_UPDATE,
  payload: data,
})

// Action to update user profile.
export const updateUserProfile = data => ({
  type: 'UPDATE_USER_PROFILE',
  payload: data,
})

// Action to set the authentication loader state.
export const setAuthLoader = data => ({
  type: 'SET_AUTH_LOADER',
  payload: data,
})

// Action to update startup user information.
export const fetchStartupUserInfoUpdate = (changedProperties, emailConfirmRedirect) => {
  return dispatch => {
    dispatch(requestUserInfoUpdate())
    if (changedProperties.raised) {
      let raisedToInteger
      if (typeof changedProperties.raised === 'string') {
        raisedToInteger = parseInt(changedProperties.raised.replace(/,/g, '').replace(/\$/g, ''))
      } else {
        raisedToInteger = parseInt(changedProperties.raised)
      }
      changedProperties.raised = raisedToInteger
    }
    if (changedProperties.round_size) {
      let roundSizeToInteger
      if (typeof changedProperties.round_size === 'string') {
        roundSizeToInteger = parseInt(changedProperties.round_size.replace(/,/g, '').replace(/\$/g, ''))
      } else {
        roundSizeToInteger = parseInt(changedProperties.round_size)
      }
      changedProperties.round_size = roundSizeToInteger
    }
    return api
      .patch(
        `${process.env.REACT_APP_API_URL}/startup/${
          emailConfirmRedirect ? 'confirmation-signup-flow' : 'update-info'
        }`,
        changedProperties,
      )
      .then(data => {
        if (data.result) {
          dispatch(receiveStartupUserInfoUpdate(data.user))
          return true
        } else {
          dispatch(requestLoading())
          if (
            (data.error === 'token expired' && data.is_expired) ||
            data.error === 'user logged out' ||
            data.error === 'invalid token'
          ) {
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
            dispatch(fetchStartupUserLogout())
          }
          dispatch(updateStartupUserInfoFailed())
          return false
        }
      })
  }
}

//============ Upload File ==================

// Action to fetch startup file upload.
export const fetchStartupFileUpload = (file, fileType, fieldName) => {
  return dispatch => {
    dispatch(requestUserInfoUpdate())

    return api
      .sendFile(`${process.env.REACT_APP_API_URL}/startup/mime-files`, file, fileType)
      .then(data => {
        if (data.result) {
          var obj = {}
          obj[fieldName] = data.url
          dispatch(fetchStartupUserInfoUpdate(obj))
          return true
        } else {
          if (data.error === 'token expired' && data.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          dispatch(updateStartupUserInfoFailed())
          return false
        }
      })
      .catch(err => {
        dispatch(updateStartupUserInfoFailed())
        return false
      })
  }
}

// Action to fetch startup CF file upload.
export const fetchStartupCFFileUpload = (file, fileType) => {
  return dispatch => {
    return api
      .sendFile(`${process.env.REACT_APP_API_URL}/startup/image-upload`, file, fileType)
      .then(data => {
        if (data.result) {
          return data.url
        } else {
          return false
        }
      })
  }
}

// Action to fetch investor file upload.
export const fetchInvestorFileUpload = (file, fileType) => {
  return dispatch => {
    dispatch(setAuthLoader(true))
    return api.sendFile(`${process.env.REACT_APP_API_URL}/investor/mime-files`, file, fileType).then(data => {
      if (data.result) {
        dispatch(updateUserProfile(data.url))
        return data.url
      } else {
        dispatch(setAuthLoader(false))
        return false
      }
    })
  }
}

// Action to remove slide deck for startup.
export const removeSlideDeck = () => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/remove-slide-deck`, {
        remove_slide_deck: true,
      })
      .then(data => {
        if (data.result) {
          const obj = { slide_deck: null }
          dispatch(fetchStartupUserInfoUpdate(obj))
          return true
        } else {
          dispatch(updateStartupUserInfoFailed())
          return false
        }
      })
  }
}

//===============Logout ===================

// Action to logout local investor user.
export const logoutLocalInvestorUser = () => ({
  type: 'LOGOUT_LOCAL_INVESTOR_USER',
})

// Action to logout local scout user.
export const logoutLocalScoutUser = () => ({
  type: 'LOGOUT_LOCAL_SCOUT_USER',
})

// Action to logout local startup user.
export const logoutLocalStartupUser = () => ({
  type: LOGOUT_LOCAL_STARTUP_USER,
})

export const logoutLocalInvestor = () => ({
  type: LOGOUT_LOCAL_INVESTOR,
})

export const logoutLocalStartup = () => ({
  type: LOGOUT_LOCAL_STARTUP,
})

export const logoutLocalScout = () => ({
  type: LOGOUT_LOCAL_SCOUT,
})

// Action to initiate the logout process for a investor user.
export const fetchInvestorUserLogout = () => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/logout`, null).then(data => {
      resetUserIdentity()
      setJwtToken(null)
      dispatch(logoutLocalInvestorUser())
      window.localStorage.clear()
      if (data.result) {
        return true
      } else {
        return false
      }
    })
  }
}

// Action to initiate the logout process for a scout user.
export const fetchScoutUserLogout = () => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/scout/logout`, null).then(data => {
      resetUserIdentity()
      setJwtToken(null)
      dispatch(logoutLocalScoutUser())
      if (data.result) {
        return true
      } else {
        return false
      }
    })
  }
}

// Action to initiate the logout process for a startup user.
export const fetchStartupUserLogout = () => dispatch => {
  return api.post(`${process.env.REACT_APP_API_URL}/startup/logout`, null).then(data => {
    resetUserIdentity()
    setJwtToken(null)

    dispatch(logoutLocalStartupUser())
    if (data.result) {
      return true
    } else {
      return false
    }
  })
}

// Action for a successful password reset request.
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
})

// Action for an error during password reset request for a Google account.
export const forgotPasswordError = () => ({
  type: FORGOT_PASSWORD_ERROR_FOR_GOOGLE_ACCOUNT,
})

// Action to initiate the password reset process.
export const forgotPassword = (email, url) => {
  return dispatch => {
    return api
      .post(
        `${process.env.REACT_APP_API_URL}${
          url === '/recruit/forgot-password' ? '/hiring/forgot-password' : url
        }`,
        { email },
      )
      .then(data => {
        if (data.result) {
          dispatch(forgotPasswordSuccess())
          return data
        } else {
          dispatch(forgotPasswordError())
          return data
        }
      })
  }
}

// Action to initiate the password reset process for education-portal accounts.
export const forgotEducationForgotPassword = (payload, onSuccess, onFail) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/education/forgot-password`, payload)
      .then(data => {
        if (data?.result) {
          onSuccess()
        } else {
          onFail(data?.message)
        }
      })
      .catch(error => {
        onFail(error?.response?.error)
      })
  }
}

// Action to send a waitlist email according to tab.
export const sendWaitListEmail = status => {
  return dispatch => {
    return api
      .get(`${process.env.REACT_APP_API_URL}/${status ? 'investor' : 'startup'}/waitlist`)
      .then(data => {
        if (data.result) {
          return true
        } else {
          return false
        }
      })
  }
}

// Action to fetch startup user profile completion status.
export const fetchStartupUserCompletion = () => {
  return dispatch => {
    dispatch(requestUserInfoUpdate())
    return api.get(`${process.env.REACT_APP_API_URL}/startup/profile-completion-check`).then(data => {
      dispatch(receiveStartupUserInfoUpdate(data.data))
    })
  }
}

// Function to handle token expiration scenarios.
export const IsTokenExpired = (res, dispatch) => {
  if (
    (res.error === 'token expired' && res.is_expired) ||
    res.error === 'user logged out' ||
    res.error === 'invalid token'
  ) {
    dispatch(fetchStartupUserLogout())
    dispatch({
      type: 'SET_AUTH_EXPIRED',
    })
  }
}

// Action creator to resend a startup application.
export const ResendApplication = payload => {
  return dispatch => {
    dispatch({ type: RESEND_APPLICATION_INIT })
    api.post(`${process.env.REACT_APP_API_URL}/startup/completed-steps`, payload).then(res => {
      if (res.result) {
        dispatch({ type: RESEND_APPLICATION_SUCCESS, payload: res?.user })
      } else {
        dispatch({ type: RESEND_APPLICATION_FAILED })
      }
    })
  }
}

// Action creator to resend an investor application.
export const ResendInvestorApplication = payload => {
  return dispatch => {
    dispatch({ type: RESEND_INVESTOR_APPLICATION_INIT })
    api.post(`${process.env.REACT_APP_API_URL}/investor/completed-steps`, payload).then(res => {
      if (res.result) {
        dispatch({ type: RESEND_INVESTOR_APPLICATION_SUCCESS, payload: res?.user })
      } else {
        dispatch({ type: RESEND_INVESTOR_APPLICATION_FAILED })
      }
    })
  }
}

export const handleThankyouScreenViewed = () => {
  return async dispatch => {
    dispatch({
      type: THANKYOU_SCREEN_VIEW_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/thank-you-screen-viewed`)
      .then(res => {
        dispatch({
          type: THANKYOU_SCREEN_VIEW_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(error => {
        dispatch({
          type: THANKYOU_SCREEN_VIEW_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

export const handleInvestorThankyouScreenViewed = () => {
  return async dispatch => {
    dispatch({
      type: INVESTOR_THANKYOU_SCREEN_VIEW_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/thank-you-screen-viewed`)
      .then(res => {
        dispatch({
          type: INVESTOR_THANKYOU_SCREEN_VIEW_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(error => {
        dispatch({
          type: INVESTOR_THANKYOU_SCREEN_VIEW_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}
