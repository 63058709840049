import React from 'react'
import prependHttp from 'prepend-http'

/**
 * Functional React component that conditionally wraps content with a hyperlink.
 *
 * @param {Object} props - React component props.
 * @param {string} props.hyperlink - The URL for the hyperlink.
 * @param {ReactNode} props.children - The content or components to be wrapped by the hyperlink.
 * @returns {ReactNode} - Either the original content or the content wrapped in a hyperlink.
 */
const HyperlinkWrapper = ({ hyperlink, children }) => {
  return hyperlink === undefined || hyperlink === null ? (
    children
  ) : (
    <a href={prependHttp(hyperlink)} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default HyperlinkWrapper
