import React from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import "./StartupSettingsPage.scss"
import { Button, Container, Col, Row } from "reactstrap"

import Switch from "../../../components/Switch/Switch"
import SideArrow from "../../../assets/images/mobile-side-arrow.png"
import {
  sendMondayNote,
  displayProfile,
  changePassword,
} from "../../../redux/startupFlowControl/startupFlowControlActions"

class StartupSettingsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value1: null,
      value3: null,
      show: "none",
    }
  }

  componentDidMount() {
    let { user } = this.props
    this.setState({
      value1: user.monday_notification,
      value3: user.show_profile,
    })
  }

  handleClick1 = () => {
    this.setState({ value1: !this.state.value1 })
    this.props.sendMondayNote({ monday_notification: !this.state.value1 })
  }

  handleClick3 = () => {
    this.setState({ value3: !this.state.value3 })
    this.props.displayProfile({ visible: !this.state.value3 })
  }
  showModal = () => {
    this.setState({ show: "block" })
  }
  hideModal = () => {
    this.setState({ show: "none" })
  }
  render() {
    const { value1, value3 } = this.state
    return (
      <div
        style={{
          paddingTop: "5rem",
          width: "95%",
          display: "block",
          paddingLeft: "150px",
          margin: "auto",
        }}
      >
        <Container
          className="settings-container d-none d-md-block"
          style={{ animation: `fadeIn 1s` }}
        >
          <div className="settings-title text-left">Settings</div>
          <Row className="mb-5">
            <Col xs={12} md={6} className="justify-content-start">
              <div>
                <div className="email-notifacation">Email Notification</div>
                <div className="email-me">Email me...</div>
                <div className="switch1">
                  <Switch
                    isOn={value1}
                    handleToggle={this.handleClick1.bind(this)}
                    inputId="testId1"
                    disabled={false}
                  />
                  <div className="switch-right">
                    Every Monday with my waiting deals
                  </div>
                </div>
              </div>
            </Col>
            <hr />
            <Col
              xs={12}
              md={6}
              className="justify-content-start setting-bottom-item"
            >
              <div className="information">
                <div className="login-info">Login Information</div>
                <div className="pw">Password</div>
                <div className="login-bottom">
                  <button
                    className="change"
                    style={{
                      fontSize: ".9rem",
                      padding: "6px 8px",
                      backgroundColor: "transparent",
                    }}
                    onClick={this.showModal}
                  >
                    Change
                  </button>
                  <div className="dots">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                  <div className="mymodal" style={{ display: this.state.show }}>
                    <div
                      className="modal-title"
                      style={{ animation: `fadeIn .5s` }}
                    >
                      Are you sure you want to change your password?
                    </div>
                    <div
                      className="mymodal-content"
                      style={{ animation: `fadeIn 1s` }}
                    >
                      If so, we'll email you a password reset link.
                    </div>
                    <div
                      className="modal-buttons"
                      style={{ animation: `fadeIn 1.5s` }}
                    >
                      <div
                        className="modal-button-left"
                        onClick={this.hideModal}
                      >
                        <span style={{ position: "relative", top: "1px" }}>
                          No thanks
                        </span>
                      </div>

                      <Link
                        style={{
                          color: "#FFF",
                          textDecoration: "none",
                        }}
                        to="/startup/changepassword"
                      >
                        <button
                          onClick={() => this.props.changePassword()}
                          className="modal-button-right"
                        >
                          <span style={{ position: "relative", top: "-4px" }}>
                            Yes, I'm sure
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="justify-content-start">
              <div className="display-profile">
                <div className="display-profile-title">Display Profile</div>
                <div className="switch2">
                  <Switch
                    isOn={value3}
                    handleToggle={this.handleClick3.bind(this)}
                    inputId="testId3"
                    disabled={
                      !(
                        this.props.user.co_founders_check &&
                        this.props.user.company_logo_check
                      )
                    }
                  />
                  <div className="switch-right">
                    Allow my profile to be visible in Discover
                  </div>
                </div>

                {this.props.user.co_founders_check &&
                this.props.user.company_logo_check ? null : (
                  <div className="font-roboto investor-incomplete w-70">
                    <span className="font-weight-500">
                      You can't make your profile visible until you{" "}
                    </span>
                    <span className="font-weight-700">
                      complete your info in Profile!
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="justify-content-start setting-bottom-item"
            >
              <div className="manage-account">
                <div className="manage-account-title">Manage Account</div>
                <div>
                  <Link className="delete" to="/startup/delete">
                    Delete my Exitfund.com account
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div
          className="menu-dropdown-button-container settings-dropdown d-block d-md-none"
          style={{ position: "fixed", overflow: "hidden" }}
        >
          <Button
            onClick={() => this.props.history.push("/startup/notifications")}
            outline
            style={{
              borderRadius: "unset",
              borderBottom: "none",
              width: "100%",
              animation: `fadeIn .4s`,
            }}
            className="menu-dropdown-button"
          >
            Notifications{" "}
            <span className="d-block d-md-none mobile-arrow">
              <img src={SideArrow} alt="sidearrow"></img>
            </span>
          </Button>
          <hr className="menu-dropdown-button-hr" />
          <Button
            onClick={() => this.props.history.push("/startup/privacy")}
            outline
            style={{
              borderRadius: "unset",
              borderBottom: "none",
              width: "100%",
              animation: `fadeIn .8s`,
            }}
            className="menu-dropdown-button"
          >
            Privacy{" "}
            <span className="d-block d-md-none mobile-arrow">
              <img src={SideArrow} alt="sidearrow"></img>
            </span>
          </Button>
          <hr className="menu-dropdown-button-hr" />
          <Button
            onClick={() => this.props.history.push("/startup/account")}
            outline
            style={{
              borderRadius: "unset",
              borderBottom: "none",
              width: "100%",
              animation: `fadeIn 1.2s`,
            }}
            className="menu-dropdown-button"
          >
            Account{" "}
            <span className="d-block d-md-none mobile-arrow">
              <img src={SideArrow} alt="sidearrow"></img>
            </span>
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
})

const mapDispatchToProps = (dispatch) => ({
  sendMondayNote: (monday_note) => {
    dispatch(sendMondayNote(monday_note))
  },
  displayProfile: (display_profile) => {
    dispatch(displayProfile(display_profile))
  },
  changePassword: () => {
    dispatch(changePassword())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null)(StartupSettingsPage)
)
