import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import './LocationPage.scss'
import { connect } from 'react-redux'
import { Button as AntButton } from 'antd'
import { Col, Container, Row } from 'react-bootstrap'
import LocationCardList from '../../components/LocationCardList/LocationCardList'
import {
  setSearchField,
  nextStepInvestor,
  prevStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import { nextStepStartup, prevStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'

import {
  fetchInvestorUserInfoUpdate,
  fetchStartupUserInfoUpdate,
  updateLocalInvestorUser,
  updateLocalStartupUser,
  fetchScoutUserInfoUpdate,
  updateLocalScoutUser,
} from '../../redux/auth/authActions'
import {
  fetchInvestorUserLogout,
  fetchStartupUserLogout,
  fetchScoutUserLogout,
} from '../../redux/auth/authActions'
import { ArrowRightOutlined } from '@ant-design/icons'
import Location from './Location'
import { SaveAndExitClickedReset } from '../../redux/startupFlowControl/startupFlowControlActions'
import backdoor_VP8 from '../../assets/images/on_boarding_imgs/backdoor_VP8.gif'

const LocationPage = ({
  prevStepInvestor,
  prevStepStartup,
  fetchInvestorUserInfoUpdate,
  startup,
  scout,
  fetchStartupUserInfoUpdate,
  startupUser,
  fetchScoutUserLogout,
  investorUser,
  updateLocalStartupUser,
  updateLocalInvestorUser,
  onCountinueClick,
  saveAndExitClicked,
  saveAndExitClickedReset,
  startInfoFlag,
  setStartInfoFlag,
  scoutUser,
  fetchScoutUserInfoUpdate,
  updateLocalScoutUser,
}) => {
  const currentLocation = startup ? startupUser.location : scout ? scoutUser.location : investorUser.location
  console.log(currentLocation, 'currentLocationcurrentLocation')
  const isEnabledInvestor = investorUser.location && investorUser.location.length > 0
  const isEnabledStartup = startupUser.location && startupUser.location.length > 0
  const isEnabledScout = scoutUser.location && scoutUser.location.length > 0

  // Set the startup information flag to false after 3 seconds if the user is a scout.
  useEffect(() => {
    if (scout) {
      setTimeout(() => {
        setStartInfoFlag(false)
      }, 3000)
    }
  }, [])

  /**
   * Handle actions when the "Save and Exit" button is clicked.
   * If the user is a startup, it calls startupOnClick to handle actions accordingly.
   * If the user is a scout, it calls scoutOnClick to handle actions accordingly.
   * If the user is an investor, it calls investorOnClick to handle actions accordingly.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      if (startup) {
        startupOnClick(false)
      } else if (scout) {
        scoutOnClick(false)
      } else {
        investorOnClick(false)
      }
      saveAndExitClickedReset()
    }
  }, [saveAndExitClicked])

  /**
   * Handles the "Next" or "Back" button click for a scout user.
   * @param {boolean} isNextOrBack - Indicates whether the user clicked "Next" or "Back."
   * @description - call the ScoutuserInfoUpdate & update the data when user clicked "Next" or "Back."
   */
  const scoutOnClick = isNextOrBack => {
    fetchScoutUserInfoUpdate({
      location: scoutUser.location,
      onboarding_step: isEnabledScout ? 1 : 0,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          fetchScoutUserLogout()
          window.location.href = '/scout/login'
        }
      })
  }

  /**
   * Handles the "Next" or "Back" button click for a investor user.
   * @param {boolean} isNextOrBack - Indicates whether the user clicked "Next" or "Back."
   * @description - call the investoruserInfoUpdate & update the data when user clicked "Next" or "Back."
   */
  const investorOnClick = isNextOrBack => {
    fetchInvestorUserInfoUpdate({
      location: investorUser.location,
      onboarding_step: isEnabledInvestor ? 3 : 2,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          } else {
            prevStepInvestor()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/investor/login'
          fetchInvestorUserLogout()
        }
      })
  }

  /**
   * Handles the "Next" or "Back" button click for a startup user.
   * @param {boolean} isNextOrBack - Indicates whether the user clicked "Next" or "Back."
   * @description - call the startupuserInfoUpdate & update the data when user clicked "Next" or "Back."
   */
  const startupOnClick = isNextOrBack => {
    fetchStartupUserInfoUpdate({ location: startupUser.location, onboarding_step: isEnabledStartup ? 2 : 1 })
      .then(success => {
        if (isNextOrBack) {
          onCountinueClick()
        } else {
          prevStepStartup()
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          fetchStartupUserLogout()
        }
      })
  }

  /**
   * Handles the "Next" button click for an investor user.
   * @param {Event} e - The click event.
   */
  const investorNextOnClick = e => {
    investorOnClick(true)
  }

  /**
   * Handles the "Next" button click for a startup user.
   * @param {Event} e - The click event.
   */
  const startupNextOnClick = e => {
    startupOnClick(true)
  }

  /**
   * Handles the "Next" button click for a scout user.
   * @param {Event} e - The click event.
   */
  const scoutNextOnClick = e => {
    scoutOnClick(true)
  }

  return (
    <>
      {/* scout Flag */}
      {startInfoFlag ? (
        <Container className="mb-3 mt-5">
          <Row>
            <Col md={6} className="mx-auto text-center mt-5">
              <img src={backdoor_VP8} width="250" style={{ animation: `fadeIn 1.5s` }} />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mx-auto text-center mt-3">
              <h5 className="font-weight-light" style={{ animation: `fadeIn 1.5s` }}>
                Let's get started!
              </h5>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          {/*** Heading is here ***/}
          <Container className="mb-3">
            <Row>
              <Col md={6} className="mx-auto">
                {scout ? (
                  <h4 className="heading-question">
                    Where are you <span className="font-weight-bold">based?</span>
                  </h4>
                ) : startup ? (
                  <h4 className="heading-question">
                    Where is {startupUser.company_name}{' '}
                    <span className="font-weight-bold">headquartered?</span>{' '}
                  </h4>
                ) : (
                  <h4 className="heading-question">
                    Where are you
                    <span className="font-weight-bold"> based?</span>
                  </h4>
                )}
              </Col>
            </Row>
          </Container>
          {/*** Location Search is here ***/}
          <Container className="mb-3">
            <Row>
              <Col md={8} className="mx-auto text-center locationSet">
                <Location
                  location={currentLocation}
                  // setLocation={(value) => setLocation(value)}
                  setLocation={location => {
                    if (startup) {
                      updateLocalStartupUser({ location })
                    } else if (scout) {
                      updateLocalScoutUser({ location })
                    } else {
                      updateLocalInvestorUser({ location })
                    }
                  }}
                  onAddLocation={() => {}}
                />
              </Col>
            </Row>
          </Container>
          <Container className="mb-3">
            <div className="citiesWrapper">
              <span className="citiesHeading">Popular Cities</span>
            </div>
          </Container>
          <Container className="mb-3">
            <Row>
              <Col md={8} className="mx-auto text-center locationSet">
                <LocationCardList />
              </Col>
            </Row>
          </Container>
          {/* New Back & Continue buttons */}
          <section className="bottomBtns">
            <Container className="">
              <Row>
                <Col md={4} xs={12} className="text-center mx-auto">
                  <AntButton
                    disabled={startup ? !isEnabledStartup : scout ? !isEnabledScout : !isEnabledInvestor}
                    onClick={startup ? startupNextOnClick : scout ? scoutNextOnClick : investorNextOnClick}
                    className="continueBtnOnboard btn-block"
                  >
                    Continue <ArrowRightOutlined />
                  </AntButton>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  stepInvestor: state.investorflowControl.stepInvestor,
  stepStartup: state.startupflowControl.stepStartup,
  stepScout: state.scoutflowControl.stepScout,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
  searchField: state.investorflowControl.searchField,
  startup: state.auth.startup,
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  updateStartupUserInfoFailed: state.startupflowControl.updateStartupUserInfoFailed,
  startupUser: state.auth.startupUser,
  investorUser: state.auth.user,
  scout: state.auth.scout,
  scoutUser: state?.auth?.scoutUser,
  loading: state?.auth?.loading,
})

const mapDispatchToProps = dispatch => ({
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  prevStepStartup: () => dispatch(prevStepStartup()),
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  onSearchChange: location => dispatch(setSearchField(location)),
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
  updateLocalScoutUser: changedProperties => {
    dispatch(updateLocalScoutUser(changedProperties))
  },
  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },
  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  fetchScoutUserInfoUpdate: changedProperties => {
    return dispatch(fetchScoutUserInfoUpdate(changedProperties))
  },
  removeSearchField: () => {
    const action = { type: 'REMOVE_SEARCHFIELD' }
    dispatch(action)
  },
  removeUserLocation: () => {
    const action = { type: 'REMOVE_USER_LOCATION' }
    dispatch(action)
  },
  fetchStartupUserLogout: () => {
    return dispatch(fetchStartupUserLogout())
  },
  fetchInvestorUserLogout: () => {
    dispatch(fetchInvestorUserLogout())
    return dispatch({
      type: 'SET_AUTH_EXPIRED',
    })
  },
  fetchScoutUserLogout: () => {
    dispatch(fetchScoutUserLogout())
    return dispatch({
      type: 'SET_AUTH_EXPIRED',
    })
  },
  saveAndExitClickedReset: () => {
    return dispatch(SaveAndExitClickedReset())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocationPage))
