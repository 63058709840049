import api from '../../utils/api'
import { fetchStartupUserLogout } from '../auth/authActions'

export const STARTUP_SIGNUP_FAILED = 'STARTUP_SIGNUP_FAILED'
export const STARTUP_LOGIN_FAILED = 'STARTUP_LOGIN_FAILED'
export const UPDATE_STARTUP_STEP = 'UPDATE_STARTUP_STEP'
export const UPDATE_STARTUP_TAB = 'UPDATE_STARTUP_TAB'
export const UPDATE_STARTUP_USER_INFO_FAILED = 'UPDATE_STARTUP_USER_INFO_FAILED'
export const SHOW_SLIDE_DECK_FORM = 'SHOW_SLIDE_DECK_FORM'
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE'
export const UPDATE_INDEX = 'UPDATE_INDEX'

// Action to set email confiremed status
export const setConfirmedEmail = () => ({
  type: 'SET_SU_CONFIRMED_EMAIL',
})

// Action to set history selection
export const setHistorySelection = selection => ({
  type: 'SET_HISTORY_SELECTION',
  payload: selection,
})

// Action to set discover view
export const setDiscoverView = () => ({
  type: 'SET_DISCOVER_VIEW',
})

// Action to set history view
export const setHistoryView = () => ({
  type: 'SET_HISTORY_VIEW',
})

// Action to go next step
export const nextStep = () => ({
  type: 'NEXT_STEP',
})

// Action to go next startup step
export const nextStepStartup = () => ({
  type: 'NEXT_STEP_STARTUP',
})

// Action to go previous step
export const prevStep = () => ({
  type: 'PREV_STEP',
})

// Action to go previous startup step
export const prevStepStartup = () => ({
  type: 'PREV_STEP_STARTUP',
})

// Action to reset initial startup step
export const initialStartupState = () => ({
  type: 'RESET_INITIAL_STARTUP_STATE',
})

// function for indicating a failed startup signup.
export const startupSignupFailed = () => ({
  type: STARTUP_SIGNUP_FAILED,
})

// function for indicating a failed startup login.
export const startupLoginFailed = () => ({
  type: STARTUP_LOGIN_FAILED,
})

// Action to update startup step
export const updateStartupStep = stepStartup => ({
  type: UPDATE_STARTUP_STEP,
  payload: stepStartup,
})

// Action to update startup tab
export const updateStartupTab = tabStartup => ({
  type: UPDATE_STARTUP_TAB,
  payload: tabStartup,
})

// Action to update startup user info failed
export const updateStartupUserInfoFailed = () => ({
  type: UPDATE_STARTUP_USER_INFO_FAILED,
})

// Action to indicate that a click event has triggered the display of the slide deck form.
export const isClickedToShowSlideDeckForm = () => ({
  type: 'SHOW_SLIDE_DECK_FORM',
})

// Action to show upload slide deck modal
export const showSlideDeckUploadModal = data => ({
  type: 'SHOW_SLIDE_DECK_UPLOAD_MODAL',
  payload: { file: data },
})

// Action to indicate that the upload of a slide deck has failed.
export const slideDeckUploadFailed = () => ({
  type: 'SLIDE_DECK_UPLOAD_FAILED',
})

// Action to indicate that the slide deck upload modal should be hidden.
export const hideSlideDeckUploadModal = () => ({
  type: 'HIDE_SLIDE_DECK_UPLOAD_MODAL',
})

// Action to update Monday notifications.
export const updateMondayNotifications = data => ({
  type: 'UPDATE_SU_MONDAY_NOTIFICATIONS',
  payload: data,
})

// Action to update the visibility of the user's profile.
export const updateShowProfile = data => ({
  type: 'UPDATE_SU_SHOW_PROFILE',
  payload: data,
})

// Action to indicate the reception of user profile data.
export const receiveUserProfile = data => ({
  type: 'RECEIVE_SU_USER_PROFILE',
  payload: data,
})

// Action to indicate the reception of user history data.
export const receiveUserHistory = data => ({
  type: 'RECEIVE_SU_USER_HISTORY',
  payload: data,
})

// Action to indicate the reception of connected user history data.
export const receiveUserHistoryConnected = data => ({
  type: 'RECEIVE_SU_USER_HISTORY_CONNECTED',
  payload: data,
})

// Action to indicate the reception of passed user history data.
export const receiveUserHistoryPassed = data => ({
  type: 'RECEIVE_SU_USER_HISTORY_PASSED',
  payload: data,
})

// Action to trigger an update of the index.
export const updateIndex = () => ({
  type: UPDATE_INDEX,
})

// Action to set an invited investor.
export const setInvitedInvestor = data => ({
  type: 'SET_INVITED_INVESTOR',
  payload: data,
})

// Action to remove an investor.
export const removeInvestor = () => ({
  type: 'REMOVE_INVESTOR',
})

// Action to indicate the reception of a user image.
export const receiveUserImage = data => ({
  type: 'RECEIVE_SU_CO_USER_IMAGE',
  payload: data,
})

// Action to indicate loading state for the dashboard.
export const loadingDashboard = loading => ({
  type: 'LOAD_SU_DASHBOARD',
  payload: loading,
})

// Action to indicate the reception of user history card information.
export const receiveUserHistoryCardInfo = data => ({
  type: 'RECEIVE_USER_HISTORY_CARD_INFO',
  payload: data,
})

// Action to indicate that the "Save and Exit" button was clicked.
export const SaveAndExitClicked = data => ({
  type: 'Save_And_Exit_Clicked',
  payload: data,
})

// Action to indicate that the "Save and Exit" button was reset.
export const SaveAndExitClickedReset = data => ({
  type: 'Save_And_Exit_Clicked_Reset',
  payload: data,
})

// Action to fetch users dashboard data
export const fetchUserDashboard = () => {
  return dispatch => {
    dispatch(loadingDashboard(true))
    return api
      .get(`${process.env.REACT_APP_API_URL}/startup/dashboard`, undefined)
      .then(res => {
        if (res.result) {
          dispatch(receiveUserProfile(res.data))
          dispatch(loadingDashboard(false))
          return res.data
        } else {
          //console.log("get user info failed! message:" + res.message);
          dispatch(loadingDashboard(false))
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
      .catch(() => dispatch(loadingDashboard(false)))
  }
}

// Action to edit photo
export const editPhoto = file => {
  return dispatch => {
    return api.sendFile(`${process.env.REACT_APP_API_URL}/startup/mime-files`, file, 'image').then(res => {
      if (res.result) {
        dispatch(receiveUserImage(res.url))
        return true
      } else {
        //console.log("get user info failed! message:" + res.message);
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to password verifications
export const verifyPassword = pw => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/verify-password`, {
        password: pw,
      })
      .then(res => {
        if (res.result) {
          return res.result
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

// Action to delete account
export const deleteAccount = pw => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/delete-account`, {
        delete: true,
      })
      .then(res => {
        //console.log("-----delete-account---:" + Object.entries(pw));
        if (res.result) {
          //console.log("delete account success:" + Object.entries(res.message));
          dispatch(fetchStartupUserLogout())
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          //console.log(
          //   "delete account failed! message:" + Object.entries(res.message)
          // );
          return false
        }
      })
  }
}

// Action to send monday notes
export const sendMondayNote = monday_note => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/monday-notifications`, monday_note)
      .then(res => {
        //console.log("-----mondaynote:" + Object.entries(monday_note));
        if (res.result) {
          //console.log(
          //   "send Monday Notification success:" + Object.entries(res.message)
          // );
          dispatch(updateMondayNotifications(monday_note))
        } else {
          //console.log("send Monday Notification failed! message:" + res);
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

// Action to display profile photo
export const displayProfile = display_profile => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/profile-visibility`, display_profile)
      .then(res => {
        //console.log("-----display_profile:" + Object.entries(display_profile));
        if (res.result) {
          //console.log(
          //   "send display profile success:" + Object.entries(res.message)
          // );
          dispatch(updateShowProfile(display_profile))
        } else {
          //console.log("send display profile failed! message:" + res);
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

// Action to change password
export const changePassword = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/startup/change-password`).then(res => {
      if (res.result) {
        //console.log("changepassword success:");
        dispatch(fetchStartupUserLogout())
      } else {
        //console.log("change pw failed! message:");
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to send referral link
export const sendSUReferLink = email => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/startup/referral-link`, { email }).then(res => {
      if (res.result) {
        //console.log("refer link sent:" + Object.entries(email));
        dispatch({
          type: 'CHANGE_SU_REFER_INFO',
        })
        return res
      } else if (res.error === 'user exists') {
        return 'That friend already has an account with us!'
      } else {
        //console.log("get user info failed! message:" + res.message);
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to fetch users all history
export const fetchUserHistoryAll = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/startup/history-all`, undefined).then(res => {
      if (res.result) {
        dispatch(receiveUserHistory(res.data))
        return res.data
      } else {
        //console.log("get user history failed! message:" + res.message);
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to fetch users history-connected
export const fetchUserHistoryConnected = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/startup/history-connected`, undefined).then(res => {
      if (res.result) {
        dispatch(fetchUserHistoryAll())
        dispatch(fetchUserHistoryPassed())
        dispatch(receiveUserHistoryConnected(res.data))
        return res.data
      } else {
        //console.log("get user history failed! message:" + res.message);
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Action to fetch users history-passed
export const fetchUserHistoryPassed = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/startup/history-passed`, undefined).then(res => {
      if (res.result) {
        dispatch(receiveUserHistoryPassed(res.data))
        return res.data
      } else {
        //console.log("get user history failed! message:" + res.message);
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchStartupUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

// Function to handle invite investors
export const handlePassInviteInvestor = (status, id) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/dashboard`, {
        invite: status,
        user_id: id,
      })
      .then(res => {
        if (res) {
          if (res.message === 'connected') {
            dispatch({
              type: 'SET_CONNECTED_INVESTOR',
              payload: true,
            })
          } else {
            dispatch({
              type: 'SET_CONNECTED_INVESTOR',
              payload: false,
            })
          }

          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

// Action to fetch users history card info
export const fetchUserHistoryCardInfo = id => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/startup/history-connected-profile-view`, {
        user_id: id,
      })
      .then(res => {
        if (res.result) {
          dispatch(receiveUserHistoryCardInfo(res.data))
          return res.data
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchStartupUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}
