import * as actionTypes from './actionTypes'

const INITIAL_STATE = {
  loading: false,
  authErrorMessage: '',
  profile_data: {},
  emailNotifications: { new_content: false, promotional: false },
  syllabus: [],
  readingMaterialData: {},
  videoLecturesData: {},
  quizzesData: {},
  quizDetails: {},
  quizResult: {},
  examsData: [],
  examDetails: {},
  examResult: {},
  unread_notification_count: 0,
  unreadNotifications: [],
  notifications: [],
  notificationsCount: 0,
  getSyllabusLoading: false,
  getExamsLoading: false,
}

const EducationLearnInvestReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: action?.payload?.user,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_SIGNUP_GOOGLE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_LOGIN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_LOGIN_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload?.user,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload?.user,
        loading: false,
      }
    case actionTypes.EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: { ...state.profile_data, profile_pic: action.payload?.url },
      }
    case actionTypes.UPLOAD_EDUCATION_LEARN_INVEST_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profile_data: action?.payload,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PROFILE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action.payload,
      }
    case actionTypes.SET_EDUCATION_LEARN_INVEST_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_SYLLABUS_INIT:
      return {
        ...state,
        getSyllabusLoading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_SYLLABUS_SUCCESS:
      return {
        ...state,
        getSyllabusLoading: false,
        syllabus: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_SYLLABUS_FAILED:
      return {
        ...state,
        getSyllabusLoading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        readingMaterialData: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_READING_MATERIAL_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        readingMaterialData: action.payload,
      }
    case actionTypes.MARK_AS_READ_EDUCATION_LEARN_INVEST_READING_MATERIAL_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        videoLecturesData: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_VIDEO_LECTURES_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        videoLecturesData: action.payload,
      }
    case actionTypes.MARK_AS_VIEW_EDUCATION_LEARN_INVEST_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        quizzesData: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZZES_DATA_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        quizDetails: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_QUIZ_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        quizResult: action.payload,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_QUIZ_RESULT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_INIT:
      return {
        ...state,
        getExamsLoading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_SUCCESS:
      return {
        ...state,
        getExamsLoading: false,
        examsData: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAMS_DATA_FAILED:
      return {
        ...state,
        getExamsLoading: false,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        examDetails: action.payload,
      }
    case actionTypes.GET_EDUCATION_LEARN_INVEST_EXAM_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        examResult: action.payload,
      }
    case actionTypes.POST_EDUCATION_LEARN_INVEST_EXAM_RESULT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unread_notification_count: action?.payload?.count || 0,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATION_COUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadNotifications: action?.payload?.data,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_UNREAD_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action?.payload?.data,
        notificationsCount: action?.payload?.total_count,
      }
    case actionTypes.FETCH_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadNotifications: [],
        unread_notification_count: 0,
      }
    case actionTypes.CLEAR_EDUCATION_LEARN_INVEST_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.COMPLETE_EDUCATION_TOUR_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.COMPLETE_EDUCATION_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        profile_data: { ...state?.profile_data, tour_taken: true },
      }
    case actionTypes.COMPLETE_EDUCATION_TOUR_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_EDUCATION_LEARN_INVEST_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default EducationLearnInvestReducer
