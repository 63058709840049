import React from "react"
import "./StartupDashboard.scss"
import membericon from "../../../assets/images/FounderPhoto.png"
import { connect } from "react-redux"

import {
  fetchUserDashboard,
  updateIndex,
} from "../../../redux/startupFlowControl/startupFlowControlActions"
import {
  setHistorySelection,
  setHistoryView,
  fetchUserHistoryAll,
} from "../../../redux/startupFlowControl/startupFlowControlActions"
import { fetchStartupUserInfoUpdate } from "../../../redux/auth/authActions"

import BottomNav from "./DashboardBottomNav"
import Discover from "./Discover"
import History from "./History"
import ViewFeedbackModal from "../ViewFeedbackModal/ViewFeedbackModal"
import FirstVisit from "../../../components/FirstVisit/FirstVisitStartup/FirstVisitStartup"

class StartupDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hidden: false,
      visible_main: "block",
      visible_tip1: "none",
      visible_tip2: "none",
      visible_tip3: "none",
      visible_tip0: "block",
      visible_first: "block",
      visible_dashboard: "none",
      blur: true,
    }
  }

  componentDidMount() {
    this.props.fetchUserDashboard()
    this.props.fetchUserHistoryAll()
    if (this.props.user.first_dashboard_visit) {
      this.props.fetchStartupUserInfoUpdate({
        first_dashboard_visit: false,
      })
    } else {
      this.setState({
        visible_first: "none",
        visible_dashboard: "block",
        visible_main: "none",
        blur: false,
      })
    }
  }

  handleIndex = () => {
    const investorsToDisplay = this.state.investors
    investorsToDisplay.shift()
    this.setState({ investors: investorsToDisplay })
    if (investorsToDisplay.length === 0) {
      this.props.setHistoryView()
    }

    this.props.updateIndex()
  }
  toPercent = (point) => {
    let percent = Number(point * 100).toFixed(0)
    percent += "%"
    return percent
  }
  toThousands = (num) => {
    var result = [],
      counter = 0
    num = (num || 0).toString().split("")
    for (var i = num.length - 1; i >= 0; i--) {
      counter++
      result.unshift(num[i])
      if (!(counter % 3) && i !== 0) {
        result.unshift(",")
      }
    }
    return result.join("")
  }
  handleIntro = () => {
    this.setState({
      visible_first: "none",
      visible_dashboard: "block",
      visible_main: "none",
      blur: false,
    })
  }
  handleShowMe = () => {
    this.setState({ visible_tip1: "block", visible_tip0: "none" })
  }
  handleBack1 = () => {
    this.setState({ visible_tip1: "none", visible_tip0: "block" })
  }
  handleNext1 = () => {
    this.setState({ visible_tip1: "none", visible_tip2: "block" })
  }
  handleBack2 = () => {
    this.setState({ visible_tip1: "block", visible_tip2: "none" })
  }
  handleNext2 = () => {
    this.setState({ visible_tip3: "block", visible_tip2: "none" })
  }
  handleBack3 = () => {
    this.setState({ visible_tip3: "none", visible_tip2: "block" })
  }
  handleGotIt = () => {
    this.setState({
      visible_first: "none",
      visible_dashboard: "block",
      visible_tip3: "none",
      visible_main: "none",
      blur: false,
    })
  }

  render() {
    const { userInfos } = this.props

    const {
      visible_main,
      blur,
      visible_tip1,
      visible_tip2,
      visible_tip3,
      visible_tip0,
      visible_first,
      visible_dashboard,
    } = this.state

    if (userInfos) {
      return (
        <div className="screen-investor" style={{ paddingTop: "120px" }}>
          <div
            className="investor-dashboard-intro"
            style={{
              display: `${visible_main}`,
              position: "fixed",
              overflow: "hidden",
            }}
          >
            <div
              className="investor-intro-container-0"
              style={{ display: visible_tip0 }}
            >
              <div className="investor-intro-title-0">
                Your first time with us is a big deal.
              </div>
              <div className="investor-intro-content-0">
                We've put together some{" "}
                <span style={{ color: "#EDC432" }}>tips</span> to help you
                navigate our site confidently.
              </div>
              <div className="investor-intro-buttons-0 d-sm-flex d-none">
                <button
                  className="investor-intro-button-left-0"
                  onClick={this.handleIntro}
                >
                  Not now
                </button>
                <button
                  className="investor-intro-button-right-0"
                  onClick={this.handleShowMe}
                >
                  Show me!
                </button>
              </div>

              <div className="d-sm-none d-flex flex-dir-col">
                <button
                  className="investor-intro-button-m-top-0"
                  onClick={this.handleShowMe}
                >
                  Show me!
                </button>
                <button
                  className="investor-intro-button-m-bottom-0 mt-3"
                  onClick={this.handleIntro}
                >
                  Not now
                </button>
              </div>
            </div>

            <div
              className="investor-intro-background-1"
              style={{ display: visible_tip1 }}
            >
              <div className="investor-intro-container-1 tip-1">
                <div className="investor-intro-tip-1">Tip 1/3</div>
                <div className="investor-intro-title-1">Invite to Connect</div>
                <div className="investor-intro-content-1">
                  If you're interested in an investor, click "Invite". We'll
                  intro you via email if the feeling is mutual.
                </div>
                <div className="investor-intro-buttons-1 d-md-flex d-none">
                  <button
                    className="investor-intro-button-left-1"
                    onClick={this.handleBack1}
                  >
                    Back
                  </button>
                  <button
                    className="investor-intro-button-right-1"
                    onClick={this.handleNext1}
                  >
                    Next
                  </button>
                </div>
                <div className="d-md-none d-flex flex-dir-col">
                  <button
                    className="investor-intro-button-m-top-0"
                    onClick={this.handleNext1}
                  >
                    Next
                  </button>
                  <button
                    className="investor-intro-button-m-bottom-0 mt-3"
                    onClick={this.handleBack1}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="invite-border d-md-flex d-none">
                <div className="invite">Invite</div>
              </div>
              <div className="d-md-none d-block">
                <div className="mobile-invite">Invite</div>
              </div>
            </div>

            <div
              className="investor-intro-background-1"
              style={{ display: visible_tip2 }}
            >
              <div className="invest-history d-md-block d-none">History</div>
              <div className="investor-intro-container-1 tip-2">
                <div className="investor-intro-tip-1">Tip 2/3</div>
                <div className="investor-intro-title-1">
                  History and Feedback
                </div>
                <div className="investor-intro-content-1">
                  Find feedback from investors in your history. We'll find new
                  investors for you{" "}
                  <span style={{ color: "#EDC432" }}>every Monday.</span>
                </div>
                <div className="investor-intro-buttons-1  d-md-flex d-none">
                  <button
                    className="investor-intro-button-left-1"
                    onClick={this.handleBack2}
                  >
                    Back
                  </button>
                  <button
                    className="investor-intro-button-right-1"
                    onClick={this.handleNext2}
                  >
                    Next
                  </button>
                </div>
                <div className="d-md-none d-flex flex-dir-col">
                  <button
                    className="investor-intro-button-m-top-0"
                    onClick={this.handleNext2}
                  >
                    Next
                  </button>
                  <button
                    className="investor-intro-button-m-bottom-0 mt-3"
                    onClick={this.handleBack2}
                  >
                    Back
                  </button>
                </div>
                <div className=" d-md-none d-block">
                  <div className="mobile-history">History</div>
                </div>
              </div>
            </div>

            <div
              className="investor-intro-background-2"
              style={{ display: visible_tip3 }}
            >
              <div className="invest-pic">
                <img src={membericon} style={{ height: "40px" }} alt="" />
              </div>
              <div className="investor-intro-container-1 tip-3">
                <div className="investor-intro-tip-1">Tip 3/3</div>
                <div className="investor-intro-title-1">Access Menu</div>
                <div className="investor-intro-content-1">
                  Tap the profile icon to access your account menu.<br></br>
                  <br></br>
                  <span>
                    <span className="tips-complete-prompt">
                      Complete your profile ASAP:
                    </span>{" "}
                    you won’t see new investors unless you have a complete
                    profile!
                  </span>
                </div>
                <div className="investor-intro-buttons-1 d-md-flex d-none">
                  <button
                    className="investor-intro-button-left-1"
                    onClick={this.handleBack3}
                  >
                    Back
                  </button>
                  <button
                    className="investor-intro-button-right-1"
                    onClick={this.handleGotIt}
                  >
                    Got it!
                  </button>
                </div>
                <div className="d-md-none d-flex flex-dir-col">
                  <button
                    className="investor-intro-button-m-top-0"
                    onClick={this.handleGotIt}
                  >
                    Got it!
                  </button>
                  <button
                    className="investor-intro-button-m-bottom-0 mt-3"
                    onClick={this.handleBack3}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>

          {this.props.dashboardView === "discover" ? (
            <>
              <div
                style={{
                  animation: `fadeIn 1s`,
                  display: visible_first,
                }}
              >
                <FirstVisit blur={blur} />
              </div>
              <div
                style={{ animation: `fadeIn 1s`, display: visible_dashboard }}
              >
                <Discover blur={blur} />
              </div>
            </>
          ) : (
            <div style={{ animation: `fadeIn 1s` }}>
              <History blur={blur} />
            </div>
          )}
          <BottomNav />
          <ViewFeedbackModal />
        </div>
      )
    } else {
      return <div>something is wrong</div>
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.startupUser,
  userInfos: state.startupflowControl.userInfos,
  index: state.investorflowControl.index,
  dashboardView: state.startupflowControl.dashboardView,
  historySelection: state.startupflowControl.historySelection,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUserDashboard: () => {
    return dispatch(fetchUserDashboard())
  },
  updateIndex: () => {
    return dispatch(updateIndex())
  },
  setHistorySelection: (selection) => {
    return dispatch(setHistorySelection(selection))
  },
  fetchStartupUserInfoUpdate: (changedProperties) => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  setHistoryView: () => dispatch(setHistoryView()),
  fetchUserHistoryAll: () => {
    return dispatch(fetchUserHistoryAll())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupDashboard)
