import React, { useEffect, useState } from 'react'
import { Input, message, Tooltip } from 'antd'
import Autocomplete from 'react-google-autocomplete'
import {
  AimOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons'
// import AddIcon from '../../../../assets/images/Icons/AddIcon'

const MultiLocations = props => {
  const { location, setLocation, multipleLocations, onAddLocation } = props
  let autoComplete = null
  let autoComplete1 = null
  const [locationText, setLocationText] = useState()
  const [ableToAdd, setAbleToAdd] = useState(false)

  useEffect(() => {
    setLocationText(location)
  }, [location])

  useEffect(() => {
    /*global google*/
    if (!multipleLocations) {
      autoComplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
        types: ['(cities)'],
      })
      autoComplete.addListener('place_changed', handlePlaceSelect)
    } else {
      autoComplete1 = new google.maps.places.Autocomplete(document.getElementById('autocomplete1'), {
        types: ['(cities)'],
      })
      autoComplete1.addListener('place_changed', handlePlaceSelect1)
    }
  }, [])

  const handlePlaceSelect = () => {
    const addressObject = autoComplete.getPlace()
    setLocation && setLocation(addressObject.formatted_address)
    setAbleToAdd(true)
  }
  const handlePlaceSelect1 = () => {
    const addressObject = autoComplete1.getPlace()
    setLocation && setLocation(addressObject.formatted_address)
  }

  const handleAddLocation = () => {
    if (ableToAdd) {
      onAddLocation()
      setAbleToAdd(false)
    } else {
      if (location) {
        message.error('Please enter valid location.')
      } else {
        message.error('Please enter location.')
      }
    }
  }

  return (
    <div className="location-container">
      {!multipleLocations ? (
        <>
          <Input
            id="autocomplete"
            size="large"
            onChange={e => {
              if (ableToAdd) {
                setAbleToAdd(false)
              }
              setLocation(e.target.value)
            }}
            placeholder="Enter a city, country"
            value={location}
            className=" inputs-box"
            prefix={<AimOutlined />}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                ableToAdd && handleAddLocation()
              }
            }}
            // suffix={<SearchOutlined />}
          />
          <div className="locationAddIcon" onClick={handleAddLocation}>
            <PlusOutlined />
          </div>
        </>
      ) : (
        <Input
          id="autocomplete1"
          onChange={e => setLocation(e.target.value)}
          placeholder="Location"
          value={location}
          className=" inputs-box"
          prefix={<UserOutlined className="site-form-item-icon" />}
          suffix={
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      )}
    </div>
  )
}

export default MultiLocations
