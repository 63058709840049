import React from 'react'
import './StartupInvestorCard.scss'
import InvestorCard from '../../../components/InvestorCard/InvestorCard'
import HyperlinkWrapper from '../../../components/HyperlinkWrapper/HyperlinkWrapper'
import { getCompanyUrl } from '../../../utils/investor/priorInvestment'
import startUpDefaultIcon from '../../../assets/images/FillerStartupIcon.png'
import membericon from '../../../assets/images/FounderPhoto.png'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CardText, Card, Row, Col } from 'reactstrap'

import {
  fetchUserDashboard,
  updateIndex,
} from '../../../redux/investorFlowControl/investorFlowControlActions'

class StartupInvestorCard extends React.Component {
  render() {
    const { blur, userHistoryInfo } = this.props

    // List of Invest-data
    const INVEST_DATA = {
      0: '$25,000',
      25: '$50,000',
      50: '$100,000',
      100: '$250,000',
      250: '$500,000',
      500: '$500,000+',
    }

    const user = userHistoryInfo

    // list of all sectors
    const sectors = {
      agtech: 'Agriculture / Agtech',
      ai: 'Artificial Intelligence',
      ar: 'Augmented Reality',
      biomed: 'Biomedical',
      biotech: 'Biotech',
      blockchain: 'Blockchain',
      community: 'Community',
      crowdfund: 'Crowdfunding',
      devtools: 'Developer Tools',
      diversity: 'Diversity',
      drones: 'Drones',
      education: 'Education',
      energy: 'Energy',
      enterprise: 'Enterprise',
      entertain: 'Entertainment',
      'Esports / Online Gaming': 'gaming',
      'Financial / Banking': 'banking',
      government: 'Government',
      hardware: 'Hardware',
      health: 'Healthcare',
      market: 'Marketplace',
      media: 'Media / Advertising',
      hardtech: 'Moonshots / Hard Tech',
      robotics: 'Robotics',
      security: 'Security',
      sport: 'Sport/Fitness',
      transport: 'Transportation',
      travel: 'Travel',
      vr: 'Virtual Reality',
      other: 'Other',
    }

    return (
      <div
        style={{
          letterSpacing: '0px',
          paddingTop: '100px',
          animation: `fadeIn 2s`,
        }}
      >
        <div className="close-container-preview d-md-block d-none">
          <Link
            to="/startup/dashboard"
            style={{ color: '#162642', fontWeight: 'bold' }}
            className="history-link "
          >
            <i className="fa fa-arrow-left pr-2 "></i>
            Back to History
          </Link>
        </div>

        <div>
          <div className="d-block d-md-none">
            <div>
              <div className="mobile-info">
                <div className="top-connected-view">You and {user.first_name} are connected!</div>
              </div>
              <Card
                body
                style={{
                  padding: '20px',
                  margin: '0px 15px',
                  border: '1px solid #DEDEDF',
                  borderRadius: '10px',
                  position: 'relative',
                  top: '0px',
                }}
              >
                <Col>
                  <img
                    className="mobile-prof-pic"
                    src={
                      user && user.profile_pic_link && user.profile_pic_link !== 'None'
                        ? user.profile_pic_link
                        : membericon
                    }
                    alt=""
                    id="avataImg"
                    shape="circle"
                  />

                  <div className="d-flex flex-dir-col mobile-name-location">
                    {' '}
                    <h3 className="font-20 ml-3 purple-colored pt-3 mb-0 font-weight-900 pr-5 mr-3">
                      {user.first_name} {user.last_name ? user.last_name : null}
                    </h3>
                    <div className="ml-3">{user.location}</div>
                  </div>
                  <Row className="mobile-row pt-4 mt-3">
                    <div className="mobile-info ">{user.bio}</div>
                  </Row>

                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600"> Affiliations</h5>
                    <div className="mobile-info">
                      {user.syndicate && user.syndicate.length ? (
                        user.syndicate.map(syn => <h6 className="affiliation-group">{syn}</h6>)
                      ) : (
                        <div
                          className="mobile-info-no-margin"
                          style={{
                            color: '#B5B5B5',
                          }}
                        >
                          No Affiliations
                        </div>
                      )}
                    </div>
                  </Row>

                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-900">Will invest up to</h5>
                    <h4 className="gold-colored font-weight-900" style={{ marginLeft: '15px' }}>
                      {INVEST_DATA[user.deals]}
                    </h4>
                  </Row>

                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600 mb-2">Invests in</h5>
                    <CardText className="d-flex investor-profile-edit-text" style={{ flexWrap: 'wrap' }}>
                      {user.sectors && user.sectors.length
                        ? user.sectors.map(sector => (
                            <span
                              className="mobile-list"
                              style={{
                                border: 'none',
                                backgroundColor: '#F2F2FF',
                                fontWeight: 'bold',
                              }}
                            >
                              {sectors[sector] ? sectors[sector] : sector}
                            </span>
                          ))
                        : null}
                    </CardText>
                  </Row>
                  <Row className="mobile-row pt-4 mt-1">
                    <h5 className="font-weight-600">Prior investments</h5>
                    <div className="mobile-info-no-margin">
                      {user.prior_investments && user.prior_investments.length ? (
                        user.prior_investments.map(inv => (
                          <Row
                            style={{ alignItems: 'center' }}
                            className="investor-profile-edit-text ml-1 mb-3"
                          >
                            <div
                              style={{
                                minHeight: '48px',
                                height: '100%',
                                lineHeight: '45px',
                                width: '50px',
                                borderRadius: '5px',
                                border: '1px solid #DEDEDF',
                              }}
                            >
                              <HyperlinkWrapper hyperlink={getCompanyUrl(inv)}>
                                <img
                                  style={{
                                    vAlign: 'center',
                                    width: '50px',
                                    objectFit: 'cover',
                                  }}
                                  src={
                                    inv.logo_link && inv.logo_link !== 'none found'
                                      ? inv.logo_link
                                      : startUpDefaultIcon
                                  }
                                  alt=""
                                />
                              </HyperlinkWrapper>
                            </div>
                            <Col>
                              <HyperlinkWrapper hyperlink={getCompanyUrl(inv)}>
                                <span className=" investment-card-name">{inv.name}</span>
                              </HyperlinkWrapper>
                              <br></br>
                              <span className="investment-card-year">{inv.year}</span>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <div className="mobile-info-no-margin" style={{ color: '#b5b5b5' }}>
                          {user.first_name} hasn't filled out their prior investments yet.
                        </div>
                      )}
                    </div>
                  </Row>
                </Col>
              </Card>
              <div className="push" />

              <div
                style={{ position: 'fixed', bottom: '60px', width: '100%' }}
                className="bottom m-auto"
              ></div>
            </div>
          </div>

          <div className="d-none d-md-block" style={{ marginTop: '-10px' }}>
            <div
              className="dashboard-container-preview d-flex flex-column justify-content-between"
              style={blur ? { filter: 'blur(5px)' } : { display: 'block' }}
            >
              <InvestorCard user={user} />

              <div className="bottom-connected-view">You and {user.first_name} are connected!</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  investorUser: state.auth.user,
  userInfos: state.investorflowControl.userInfos,
  index: state.investorflowControl.index,
  dashboardView: state.investorflowControl.dashboardView,
  userHistoryInfo: state.startupflowControl.userHistoryInfo,
})

const mapDispatchToProps = dispatch => ({
  fetchUserDashboard: () => {
    return dispatch(fetchUserDashboard())
  },
  updateIndex: () => {
    return dispatch(updateIndex())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupInvestorCard)
