export const ADD_TRACKING_MONTHLY_UPDATES_INIT = 'ADD_TRACKING_MONTHLY_UPDATES_INIT'
export const ADD_TRACKING_MONTHLY_UPDATES_SUCCESS = 'ADD_TRACKING_MONTHLY_UPDATES_SUCCESS'
export const ADD_TRACKING_MONTHLY_UPDATES_FAILED = 'ADD_TRACKING_MONTHLY_UPDATES_FAILED'
export const EDIT_TRACKING_MONTHLY_UPDATES_INIT = 'EDIT_TRACKING_MONTHLY_UPDATES_INIT'
export const EDIT_TRACKING_MONTHLY_UPDATES_SUCCESS = 'EDIT_TRACKING_MONTHLY_UPDATES_SUCCESS'
export const EDIT_TRACKING_MONTHLY_UPDATES_FAILED = 'EDIT_TRACKING_MONTHLY_UPDATES_FAILED'
export const FETCH_KEY_METRIC_VALUES = 'FETCH_KEY_METRIC_VALUES'
export const FETCH_SINGLE_MONTHLY_UPDATES_INIT = 'FETCH_SINGLE_MONTHLY_UPDATES_INIT'
export const FETCH_SINGLE_MONTHLY_UPDATES_SUCCESS = 'FETCH_SINGLE_MONTHLY_UPDATES_SUCCESS'
export const FETCH_SINGLE_MONTHLY_UPDATES_FAILED = 'FETCH_SINGLE_MONTHLY_UPDATES_FAILED'
export const FETCH_MONTHLY_UPDATES_INIT = 'FETCH_MONTHLY_UPDATES_INIT'
export const FETCH_MONTHLY_UPDATES_SUCCESS = 'FETCH_MONTHLY_UPDATES_SUCCESS'
export const FETCH_MONTHLY_UPDATES_FAILED = 'FETCH_MONTHLY_UPDATES_FAILED'
export const FETCH_PROFILE_INIT = 'FETCH_PROFILE_INIT'
export const FETCH_METRIC_DATA_INIT = 'FETCH_METRIC_DATA_INIT'
export const FETCH_METRIC_DATA_SUCCESS = 'FETCH_METRIC_DATA_SUCCESS'
export const FETCH_METRIC_DATA_FAILED = 'FETCH_METRIC_DATA_FAILED'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED'
export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED'
export const UPDATE_PROFILE_PIC_INIT = 'UPDATE_PROFILE_PIC_INIT'
export const UPDATE_PROFILE_PIC_SUCCESS = 'UPDATE_PROFILE_PIC_SUCCESS'
export const UPDATE_PROFILE_PIC_FAILED = 'UPDATE_PROFILE_PIC_FAILED'
export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
export const ADD_TEAM_MEMBER_INIT = 'ADD_TEAM_MEMBER_INIT'
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS'
export const ADD_TEAM_MEMBER_FAILED = 'ADD_TEAM_MEMBER_FAILED'
export const DONE_SUCCESS = 'DONE_SUCCESS'
export const SET_EMAIL_NOTIFICATION_INIT = 'SET_EMAIL_NOTIFICATION_INIT'
export const SET_EMAIL_NOTIFICATION_SUCCESS = 'SET_EMAIL_NOTIFICATION_SUCCESS'
export const SET_EMAIL_NOTIFICATION_FAILED = 'SET_EMAIL_NOTIFICATION_FAILED'
export const GET_EMAIL_NOTIFICATION_INIT = 'GET_EMAIL_NOTIFICATION_INIT'
export const GET_EMAIL_NOTIFICATION_SUCCESS = 'GET_EMAIL_NOTIFICATION_SUCCESS'
export const GET_EMAIL_NOTIFICATION_FAILED = 'GET_EMAIL_NOTIFICATION_FAILED'
export const GET_TOUR_TOKEN_INIT = 'GET_TOUR_TOKEN_INIT'
export const GET_TOUR_TOKEN_SUCCESS = 'GET_TOUR_TOKEN_SUCCESS'
export const GET_TOUR_TOKEN_FAILED = 'GET_TOUR_TOKEN_FAILED'
export const IS_CANCEL_MODAL_VISIBLE = 'IS_CANCEL_MODAL_VISIBLE'
export const ACTIVE_FORM = 'ACTIVE_FORM'
export const CHANGE_PROFILE_ACTIVE_KEY = 'CHANGE_PROFILE_ACTIVE_KEY'
export const CLEAR_COMPANY_LOGO_URL = 'CLEAR_COMPANY_LOGO_URL'
export const CLEAR_COMPANY_BANNER_URL = 'CLEAR_COMPANY_BANNER_URL'
export const CHANGE_TRACKING_SETTINGS_PROFILE_ACTIVE_KEY = 'CHANGE_TRACKING_SETTINGS_PROFILE_ACTIVE_KEY'
export const SET_FLAG_LINK = 'SET_FLAG_LINK'
export const UPDATE_INVESTOR_ICON_INIT = 'UPDATE_INVESTOR_ICON_INIT'
export const UPDATE_INVESTOR_ICON_SUCCESS = 'UPDATE_INVESTOR_ICON_SUCCESS'
export const UPDATE_INVESTOR_ICON_FAILED = 'UPDATE_INVESTOR_ICON_FAILED'
export const EMPTY_CO_INVESTOR_LOG0S = 'EMPTY_CO_INVESTOR_LOG0S'
export const REMOVE_CO_INVESTOR = 'REMOVE_CO_INVESTOR'
export const ADD_NEW_CO_INVESTOR_LOCALLY = 'ADD_NEW_CO_INVESTOR_LOCALLY'
export const SHARE_MONTHLY_UPDATES_INIT = 'SHARE_MONTHLY_UPDATES_INIT'
export const SHARE_MONTHLY_UPDATES_SUCCESS = 'SHARE_MONTHLY_UPDATES_SUCCESS'
export const SHARE_MONTHLY_UPDATES_FAILED = 'SHARE_MONTHLY_UPDATES_FAILED'
export const STARTUP_MONTHLY_UPDATE_DETAIL_INIT = 'STARTUP_MONTHLY_UPDATE_DETAIL_INIT'
export const STARTUP_MONTHLY_UPDATE_DETAIL_SUCCESS = 'STARTUP_MONTHLY_UPDATE_DETAIL_SUCCESS'
export const STARTUP_MONTHLY_UPDATE_DETAIL_FAILED = 'STARTUP_MONTHLY_UPDATE_DETAIL_FAILED'
export const FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_INIT = 'FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_INIT'
export const FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_SUCCESS =
  'FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_SUCCESS'
export const FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_FAILED =
  'FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_FAILED'
export const SET_MONTHLY_UPDATE_URL_STATE_INIT = 'SET_MONTHLY_UPDATE_URL_STATE_INIT'
export const SET_MONTHLY_UPDATE_URL_STATE_SUCCESS = 'SET_MONTHLY_UPDATE_URL_STATE_SUCCESS'
export const SET_MONTHLY_UPDATE_URL_STATE_FAILED = 'SET_MONTHLY_UPDATE_URL_STATE_FAILED'
