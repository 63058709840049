import * as actionTypes from './actionTypes'

const INITIAL_STATE = {
  loading: false,
  portfolios: [],
  portfoliosCount: 0,
}

const portfolioReducer = (state = INITIAL_STATE, action) => {
  const { type } = action
  switch (type) {
    case actionTypes.FETCH_PORTFOLIOS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.FETCH_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        portfolios: action?.payload?.data,
        portfoliosCount: action?.payload?.total_count,
      }
    case actionTypes.FETCH_PORTFOLIOS_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default portfolioReducer
