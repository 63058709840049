import React from 'react'

import MILESTONES_CARD_DATA from './MileStonesCardData'

import { connect } from 'react-redux'

import './MileStoneCardList.scss'

import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'

import { updateLocalInvestorUser, updateLocalStartupUser } from '../../redux/auth/authActions'

let progress_sectors = MILESTONES_CARD_DATA

const MileStonesCardList = ({
  startupUser,
  maxMilestonesSelectedErr,
  setMaxMilestonesSelectedErr,
  setMilestones,
  maxMilestonesSelected,
}) => {
  // Get progresses from startup user
  const progresses = startupUser.progress

  const cardComponent = progress_sectors.map((progress, i) => {
    const text = progress.title

    const selectedCard = startupUser.progress
    const MileStoneImg = progress.image
    return (
      <div
        className={selectedCard.includes(text) ? 'selected2 progress-card tc dib' : 'progress-card tc dib'}
        key={i}
        onClick={event => {
          if (!maxMilestonesSelected || (maxMilestonesSelected && progresses.includes(progress.title))) {
            setMilestones(progress.title)
            setMaxMilestonesSelectedErr('')
          } else {
            setMaxMilestonesSelectedErr('You can only select up to 3.')
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-start milestone-card-background">
          <MileStoneImg style={{ width: 50, height: 50 }} />
          <h3
            className={
              selectedCard.includes(text) ? 'title-trending-card selected-sector ' : 'title-trending-card'
            }
            style={maxMilestonesSelected && !progresses.includes(progress.title) ? { color: '' } : null}
          >
            {progress.title}
          </h3>
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="d-flex align-items-center justify-content-center card-component-sectors milestones-container">
        {cardComponent}
      </div>
      <p className="text-danger text-center mt-4" style={{ fontSize: '16px' }}>
        {maxMilestonesSelectedErr}
      </p>
    </>
  )
}

const mapStateToProps = state => ({
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  investor: state.auth.investor,
  company: state.auth.user.company,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
})

const mapDispatchToProps = dispatch => ({
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MileStonesCardList)
