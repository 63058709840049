// Imports
import React, { Component } from 'react'
import { Input, InputGroup, Button } from 'reactstrap'
// import EyeIcon from "../../../assets/images/EyeIcon@2x.png";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#162642',
      main: '#162642',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
})

class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      city: '',
      query: '',
    }
    this.autocompleteInput = React.createRef()
    this.autocomplete = null
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
  }

  componentDidMount() {
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.props.id),
      { types: ['(cities)'] },
    )

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect)
  }

  handlePlaceSelect = () => {
    const addressObject = this.autocomplete.getPlace()

    this.props.onLocationChange(addressObject.formatted_address)
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <InputGroup>
            <Input
              id={this.props.id}
              style={{ borderRight: 'none', height: '3rem' }}
              value={this.props.value}
              onChange={e => {
                this.props.onLocationChange(e.target.value)
              }}
              placeholder={this.props.value ? `${this.props.value}` : 'Enter your city, state'}
            />
            {/* <InputGroupAddon addonType="append">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: 'grey',
                  border: '1px solid lightgrey',
                  borderLeft: 'none',
                }}
                onClick={this.props.onDeleteLocation}
              >
                {this.props.value ? <i className="fa fa-times-thin"></i> : null}
              </Button>
            </InputGroupAddon> */}
          </InputGroup>

          {/* <Input
            id="autocomplete"
            variant="outlined"
            color="primary"
            className="location-input"
            onChange={(e) => this.props.onLocationChange(e.target.value)}
            placeholder={
              this.props.currentLocation
                ? `${this.props.currentLocation}`
                : "Enter your city, state"
            }
            value={this.props.value}
            style={{ width: "100%" }}
          /> */}
        </ThemeProvider>
      </div>
    )
  }
}

export default Search
