import React, { useEffect, useState } from 'react'
import './StartupCapitalPage.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'antd'
import { connect, useDispatch } from 'react-redux'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import { getNumberFromCurrency } from '../../../utils/general'
import { isDisplayRaisedGreaterOrEqualToRoundSize } from '../../../utils/startupStep'
import { nextStepStartup, prevStepStartup } from '../../../redux/startupFlowControl/startupFlowControlActions'
import {
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  fetchStartupUserLogout,
} from '../../../redux/auth/authActions'
import 'antd/dist/antd.css'
import { ArrowRightOutlined } from '@ant-design/icons'
import { SaveAndExitClickedReset } from '../../../redux/startupFlowControl/startupFlowControlActions'
import '../../../components/PositionButton/PositionButton.scss'
import '../../../components/PositionButtonList/PositionButtonList.scss'
const StartupCapitalPage = ({
  onCountinueClick,
  prevStepStartup,
  startupUser,
  updateLocalStartupUser,
  fetchStartupUserInfoUpdate,
  saveAndExitClicked,
  roundinfoState,
  setRoundinfoState,
}) => {
  const dispatch = useDispatch()
  const areCapitalInputsNonEmpty =
    // Handle the case where the values are null or empty strings.
    // This is an indication that the user has not input values for these fields.
    roundinfoState.raised !== null &&
    roundinfoState.raised !== '' &&
    roundinfoState.round_size !== null &&
    roundinfoState.round_size !== '' &&
    roundinfoState.prior_rounds_total_raised !== null &&
    roundinfoState.prior_rounds_total_raised !== ''
  const isTotalRoundSizeZero =
    roundinfoState.round_size == '' || getNumberFromCurrency(roundinfoState.round_size) <= 0
  const isGraterProiorZero =
    roundinfoState.prior_rounds_total_raised == '' ||
    roundinfoState.prior_rounds_total_raised == null ||
    getNumberFromCurrency(roundinfoState.prior_rounds_total_raised) < 0
  const isEqualOrGraterRaised =
    roundinfoState.raised == '' ||
    roundinfoState.raised == null ||
    getNumberFromCurrency(roundinfoState.raised) < 0
  const shouldDisplayRaisedGreaterThanRoundSizeError = isDisplayRaisedGreaterOrEqualToRoundSize(
    roundinfoState.raised,
    roundinfoState.round_size,
  )

  const areCapitalInputsCompleteAndValid =
    areCapitalInputsNonEmpty && !shouldDisplayRaisedGreaterThanRoundSizeError
  const [roundErrorShow, setRoundErrorShow] = useState(false)
  const [raisedErrorShow, setRaisedErrorShow] = useState(false)
  const [priorRoundsErrorShow, setPriorRoundsErrorShow] = useState(false)

  // Initialize round information state when the component mounts.
  useEffect(() => {
    setRoundinfoState({ round_size: '', raised: '', prior_rounds_total_raised: '' })
  }, [])

  // Update round information state with startup user data.
  useEffect(() => {
    setRoundinfoState({
      round_size: startupUser?.round_size,
      raised: startupUser?.raised,
      prior_rounds_total_raised: startupUser?.prior_rounds_total_raised,
    })
  }, [startupUser])

  /**
   * Handle input change for money raised.
   * @param {Object} e - The event object.
   */
  const handleInputChangeMoneyRaised = e => {
    setRoundinfoState({ ...roundinfoState, raised: e.target.value })
    setRaisedErrorShow(true)
  }

  /**
   * Handle input change for prior rounds total raised.
   * @param {Object} e - The event object.
   */
  const handleInputPriorRaised = e => {
    setRoundinfoState({ ...roundinfoState, prior_rounds_total_raised: e.target.value })
    setPriorRoundsErrorShow(true)
  }

  /**
   * Handle input change for round size.
   * @param {Object} e - The event object.
   */
  const handleInputChangeRoundSize = e => {
    setRoundinfoState({ ...roundinfoState, round_size: e.target.value })
    setRoundErrorShow(true)
  }

  /**
   * Handle startup on-click function.
   * @param {boolean} isNextOrBack - Indicates whether it's a next or back action.
   * @description - Fetch the startup user Info update with onboarding step & redirect to login page
   */
  const startupOnClick = isNextOrBack => {
    const roundSizeInteger = getNumberFromCurrency(roundinfoState?.round_size)
    const raisedInteger = getNumberFromCurrency(roundinfoState?.raised)
    const priorRaisedInteger = getNumberFromCurrency(roundinfoState?.prior_rounds_total_raised)
    fetchStartupUserInfoUpdate({
      raised: raisedInteger,
      round_size: roundSizeInteger,
      prior_rounds_total_raised: priorRaisedInteger,
      onboarding_step: areCapitalInputsCompleteAndValid ? 4 : 3,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          } else {
            prevStepStartup()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }

  /**
   * Handle startup next on-click function & update the local startup users.
   * @param {Object} e - The event object.
   */
  const startupNextOnClick = e => {
    updateLocalStartupUser({
      raised: roundinfoState?.raised,
      round_size: roundinfoState?.round_size,
      prior_rounds_total_raised: roundinfoState?.prior_rounds_total_raised,
    })
    startupOnClick(true)
  }

  // Check if Save and Exit button is clicked then dispatch the SaveAndExitClickedReset.
  useEffect(() => {
    if (saveAndExitClicked) {
      startupOnClick(false)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  return (
    <div>
      {/*** Heading is here ***/}
      <Container className="mb-3">
        <Row>
          <Col lg={7} md={8} className="mx-auto ">
            <h4 className="heading-question">
              Tell us about your current <span className="font-weight-bold">round.</span>
            </h4>
          </Col>
        </Row>
      </Container>

      {/* Body Container is Here */}
      <Container className="mb-4">
        <Row>
          <Col lg={7} md={8} className="mx-auto">
            <div className="startup-info-container">
              <p className="startup-name">What is your total round size?</p>
              <CurrencyInput
                className="text-field"
                value={roundinfoState?.round_size}
                onChange={handleInputChangeRoundSize}
                placeholder="$500,000"
                type="text"
              />
              {roundErrorShow && isTotalRoundSizeZero && (
                <p className="text-danger mt-2" style={{ textAlign: 'left' }}>
                  Round size must be greater than $0.
                </p>
              )}

              <p className="startup-name">How much have you already raised in this round?</p>
              <CurrencyInput
                className="text-field"
                value={roundinfoState?.raised}
                onChange={handleInputChangeMoneyRaised}
                placeholder="$100,000"
                type="text"
              />
              {raisedErrorShow && isEqualOrGraterRaised && (
                <p className="text-danger mt-2" style={{ textAlign: 'left' }}>
                  Capital Already Raised This Round can be any amount equal or greater than $0
                </p>
              )}
              {raisedErrorShow && shouldDisplayRaisedGreaterThanRoundSizeError && (
                <p className="text-danger mt-2" style={{ textAlign: 'left' }}>
                  Round size must be greater than Capital Already Raised This Round
                </p>
              )}
              <p className="startup-name">How much have you already raised in prior rounds if any?</p>
              <CurrencyInput
                className="text-field"
                value={roundinfoState?.prior_rounds_total_raised}
                onChange={handleInputPriorRaised}
                placeholder="$0"
                type="text"
              />
              {priorRoundsErrorShow && isGraterProiorZero && (
                <p className="text-danger mt-2" style={{ textAlign: 'left' }}>
                  Prior Rounds Total Raised can be any amount equal or greater than $0
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                disabled={!areCapitalInputsCompleteAndValid}
                onClick={startupNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  saveAndExitClicked: state.startupflowControl.saveAndExitClicked,
})

const mapDispatchToProps = dispatch => ({
  prevStepStartup: () => dispatch(prevStepStartup()),
  nextStepStartup: () => dispatch(nextStepStartup()),

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(StartupCapitalPage)
