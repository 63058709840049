import { ReactComponent as NewYork } from '../../assets/images/on_boarding_imgs/New_York.svg'
import { ReactComponent as SanFrancisco } from '../../assets/images/on_boarding_imgs/San_Francisco.svg'
import { ReactComponent as Boston } from '../../assets/images/on_boarding_imgs/Boston.svg'
import { ReactComponent as LosAngeles } from '../../assets/images/on_boarding_imgs/Los_Angeles.svg'

// Loaction Data with city, state & image
const LOCATION_DATA = [
  {
    id: 1,
    city: 'San Francisco',
    state: 'CA, USA',
    image: SanFrancisco,
  },
  {
    id: 0,
    city: 'New York City',
    state: 'NY, USA',
    image: NewYork,
  },
  {
    id: 2,
    city: 'Boston',
    state: 'MA, USA',
    image: Boston,
  },
  {
    id: 3,
    city: 'Los Angeles',
    state: 'CA, USA',
    image: LosAngeles,
  },
]

export default LOCATION_DATA
