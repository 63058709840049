import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import './Footer.scss'
import MonthlyUpdate from '../../../assets/images/tracking/Monthly Updates.svg'
import History from '../../../assets/images/tracking/history.svg'

const FooterTracking = props => {
  // set currentpath from the props
  const currentPath = props.location.pathname.split('/')[2]

  /**
   * Checks if the current path is related to the monthly update.
   * @returns {boolean} True if the current path is related to the monthly update, otherwise false.
   */
  const getIsMonthlyUpdateActive = () => {
    let isActive = false
    if (['submit-monthly-update', 'monthly-update', 'edit-monthly-update']?.includes(currentPath)) {
      isActive = true
    }
    return isActive
  }
  return (
    //   <div className="footer-section">
    //   <Link to="/tracking/monthly-update">
    //     <div>
    //       <img src={MonthlyUpdate} />
    //     </div>
    //     <div className={`footer-list text-dark px-2 ${getIsMonthlyUpdateActive() ? 'active' : ''}`}>
    //       Monthly Updates
    //     </div>
    //   </Link>
    //   <Link to="/tracking/history">
    //     <div>
    //       <img src={History} />
    //     </div>
    //     <div className={`footer-list text-dark px-2 ${currentPath === 'history' ? 'active' : ''}`}>
    //       History
    //     </div>
    //   </Link>
    // </div>
    <div className="footer-section">
      <Link
        to="/tracking/monthly-update"
        className={`footer-list text-dark px-2 ${getIsMonthlyUpdateActive() ? 'active' : ''}`}
      >
        <img src={MonthlyUpdate} />
        <p className="footer-monthly-text">Monthly Updates</p>
      </Link>
      <Link
        to="/tracking/history"
        className={`footer-list text-dark px-2 ${currentPath === 'history' ? 'active' : ''}`}
      >
        <img src={History} />
        <p className="footer-monthly-text">History</p>
      </Link>
    </div>
  )
}

export default withRouter(FooterTracking)
