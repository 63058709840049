import { lazy } from 'react'
//tracking portal

export const ProfileMain = lazy(() => import('../pages/TrackingPortal/TrackingProfile/ProfileMain'))
export const CompanyInfo = lazy(() => import('../pages/TrackingPortal/TrackingProfile/CompanyInfo'))
export const RoundInfo = lazy(() => import('../pages/TrackingPortal/TrackingProfile/RoundInfo'))
export const TeamInfo = lazy(() => import('../pages/TrackingPortal/TrackingProfile/TeamInfo'))
export const DocumentSection = lazy(() => import('../pages/TrackingPortal/TrackingProfile/Document'))
export const VideoSection = lazy(() => import('../pages/TrackingPortal/TrackingProfile/Videos'))

export const ProfileSettingMain = lazy(() =>
  import('../pages/TrackingPortal/TrackingProfile/ProfileSettingMain'),
)
export const PublicLink = lazy(() => import('../pages/TrackingPortal/TrackingProfile/PublicLink'))
export const EmailNotification = lazy(() =>
  import('../pages/TrackingPortal/TrackingProfile/EmailNotification'),
)
export const ChangePassword = lazy(() => import('../pages/TrackingPortal/TrackingProfile/ChangePassword'))

export const TrackingMonthlyUpdates = lazy(() =>
  import('../pages/TrackingPortal/TrackingMonthlyUpdates/TrackingMonthlyUpdates'),
)
export const EditTrackingMonthlyUpdates = lazy(() =>
  import('../pages/TrackingPortal/TrackingMonthlyUpdatesForm/EditTrackingMonthlyUpdates'),
)
export const TrackingHistoryUpdates = lazy(() =>
  import('../pages/TrackingPortal/TrackingHistoryUpdates/TrackingHistoryUpdates'),
)

export const TrackingNotification = lazy(() => import('../pages/TrackingPortal/Notifications/Notification'))

export const TrackingPreviewInvestorView = lazy(() =>
  import('../pages/TrackingPortal/TrackingProfile/PreviewInvestorView'),
)
