import React from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import { Button, Container, Nav, Navbar } from 'react-bootstrap'

import logo from '../../assets/images/LogoExitFund.svg'

import {
  nextStepInvestor,
  toggleLogoToHome,
} from '../../redux/investorFlowControl/investorFlowControlActions'
import { nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'
import { nextStepScout } from '../../redux/scoutFlowControl/scoutFlowControlActions.js'
import { fetchInvestorUserLogout } from '../../redux/auth/authActions'

import { selectedAsInvestor, selectedAsStartup, selectedAsScout } from '../../redux/auth/authActions'

import './HeaderStartup/Header.scss'
import { message } from 'antd'

/**
 * header tool bar component
 */
const HeaderLoginSignup = ({
  nextStepStartup,
  nextStepInvestor,
  selectedAsInvestor,
  selectedAsStartup,
  toggleLogoToHome,
  investorPage,
  user,
  fetchInvestorUserLogout,
  pageTypeValue,
  nextStepScout,
  selectedAsScout,
}) => {
  const pageType = pageTypeValue || 'startup'

  /**
   * Determines the user type and triggers the appropriate actions based on the user's page type.
   *
   * @param {boolean} investorPage - A boolean indicating whether the user is on the investor page.
   */
  const getUserType = investorPage => {
    if (pageType === 'investor') {
      nextStepInvestor()
      selectedAsInvestor()
    } else if (pageType === 'startup') {
      nextStepStartup()
      selectedAsStartup()
    } else {
      nextStepScout()
      selectedAsScout()
    }
  }

  /**
   * Generates the signup link based on the user's page type.
   *
   * @returns {string} - The signup link corresponding to the user's page type.
   */
  const getToSignupLink = () => {
    let toLink = ''
    if (pageType === 'startup') {
      toLink = '/startup/signup'
    } else if (pageType === 'investor') {
      toLink = '/investor/signup'
    } else if (pageType === 'scouts') {
      toLink = '/scout/signup'
    }
    return toLink
  }

  /**
   * Generates the login link based on the user's page type.
   *
   * @returns {string} - The login link corresponding to the user's page type.
   */
  const getToLoginLink = () => {
    let toLink = ''
    if (pageType === 'startup') {
      toLink = '/startup/login'
    } else if (pageType === 'investor') {
      toLink = '/investor/login'
    } else if (pageType === 'scouts') {
      toLink = '/scout/login'
    }
    return toLink
  }
  if (user.approved) {
    return (
      <div className="header">
        <NavLink onClick={toggleLogoToHome} className="logo" to="/">
          <div className="logo" id="home-logo">
            <img src={logo} className="" alt="Logo - Exitfund" style={{ height: '45px', width: 'auto' }} />
          </div>
          {/* <img src={logo} alt="logo" />
          <div className="space" />
          <h3 className="logo-content">Exitfund.com</h3> */}
        </NavLink>
        <div className="options">
          <Button
            onClick={event => {
              event.preventDefault()
              fetchInvestorUserLogout()
            }}
            style={{
              backgroundColor: '#fff',
              border: '1px solid #162642',
              color: '#162642',
            }}
            className="option"
            to="/"
          >
            Logout
          </Button>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <Navbar bg="white" expand="lg" className="navbar-main-wrap">
          <Container className="headerTop" fluid>
            <Navbar.Brand className="left">
              <NavLink onClick={toggleLogoToHome} style={{ textDecoration: 'none' }} to="/">
                <div className="logo" id="home-logo">
                  <img
                    src={logo}
                    className=""
                    alt="Logo - Exitfund"
                    style={{ height: '45px', width: 'auto' }}
                  />
                </div>
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="right right-list">
                <Nav className="text-dark px-2">
                  <Link to="/portfolio">Portfolio</Link>
                </Nav>
                <Nav className="text-dark px-2">
                  <Link to="/faqs">FAQs</Link>
                </Nav>
                <Nav className="text-dark px-2">
                  <Link to="/blogs">Blogs</Link>
                </Nav>
                <Nav className="text-dark px-2">
                  <Link to="/podcasts">Podcasts</Link>
                </Nav>
                <Nav className="text-dark px-2">
                  <Link to="/education/learn-invest">Education</Link>
                </Nav>
                <Nav className="text-dark px-2">
                  <Link to="/recruit">Recruit</Link>
                </Nav>
                {/* <Nav className="text-dark px-2">
                  <Link
                    onClick={() => {
                      getUserType(investorPage)
                    }}
                    to={getToSignupLink()}
                  >
                    Sign Up
                  </Link>
                </Nav> */}
                <Nav className="text-dark px-2">
                  <Link
                    className="btn btn-outline-dark font-weight-bold rounded-pill border-dark px-4 applyNowButton"
                    to={getToLoginLink()}
                    onClick={() => {
                      getUserType(investorPage)
                    }}
                  >
                    {pageType === 'startup' && 'Startup '}
                    {pageType === 'investor' && 'Investor '}
                    {pageType === 'scouts' && 'Scout '}
                    Login
                  </Link>
                </Nav>
              </Nav>
              <Nav className="m-auto center-list product-hunt-btn">
                {/* <a
                  className="m-md-left m-auto p-md-0"
                  href="https://www.producthunt.com/posts/exitfund?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-exitfund"
                  target="_blank"
                >
                  <img
                    className="img-fluid"
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=419393&theme=light"
                    alt="Exitfund - We&#0032;Make&#0032;Raising&#0032;Startup&#0032;Capital&#0032;Easy&#0032;Using&#0032;Community&#0032;and&#0032;AI | Product Hunt"
                    width="250"
                    height="54"
                  />
                </a> */}
                {/* <a
                  className="m-md-left m-auto p-md-0 pb-3"
                  href="https://www.producthunt.com/posts/exitfund?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-exitfund"
                  target="_blank"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=419393&theme=light&period=daily"
                    alt="Exitfund - We&#0032;make&#0032;raising&#0032;startup&#0032;capital&#0032;easy&#0032;using&#0032;community&#0032;&#0038;&#0032;AI | Product Hunt"
                    width="250"
                    height="54"
                  />
                </a> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <div className="toolbar pl-2 pr-2">
          <div className="header">
            <NavLink onClick={toggleLogoToHome} style={{ textDecoration: 'none' }} to="/">
              <div className="logo" id="home-logo">
                <img src={logo} className="" alt="logo" style={{ height: '45px', width: 'auto' }} />
              </div>
            </NavLink>

            <div className="d-none d-lg-block">
              <div className="options d-flex align-content-center">
                <NavLink className="" to="faqs">
                  FAQs
                </NavLink>
                <NavLink className="" to="faqs">
                  Bogs
                </NavLink>
                <NavLink className="" to="faqs">
                  Podcasts
                </NavLink>

                <NavLink
                  className=""
                  to={investorPage ? '/investor/login' : '/startup/login'}
                  onClick={() => {
                    getUserType(investorPage)
                  }}
                >
                  {investorPage ? 'Investor' : 'Startup'} Login
                </NavLink>

                <NavLink
                  className=""
                  onClick={() => {
                    getUserType(investorPage)
                  }}
                  to={investorPage ? '/investor/signup' : '/startup/signup'}
                >
                  Sign up
                </NavLink>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  investorPage: state.home.investorPage,
  user: state.auth.user,
  pageTypeValue: state.home.pageType,
})

const mapDispatchToProps = dispatch => ({
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  selectedAsStartup: () => dispatch(selectedAsStartup()),
  selectedAsInvestor: () => dispatch(selectedAsInvestor()),
  toggleLogoToHome: () => dispatch(toggleLogoToHome()),
  fetchInvestorUserLogout: () => {
    return dispatch(fetchInvestorUserLogout())
  },
  nextStepScout: () => dispatch(nextStepScout()),
  selectedAsScout: () => dispatch(selectedAsScout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderLoginSignup))
