// export const setCurrentUser = user => ({
//   type: "SET_CURRENT_USER",
//   payload: user
// });

export const toggleStartup = () => ({
  type: 'TOGGLE_STARTUP_BUTTON',
})

export const toggleInvestor = () => ({
  type: 'TOGGLE_INVESTOR_BUTTON',
})

export const toggleScout = () => ({
  type: 'TOGGLE_SCOUT_BUTTON',
})

export const setPageType = payload => ({
  type: 'SET_PAGE_TYPE',
  payload,
})
