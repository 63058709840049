import { FastBackwardOutlined } from '@ant-design/icons'
// Import action type from an external file
import * as actionTypes from '../investorFlowControl/ActionTypes'
import {
  EDUCATION_LEARN_INVEST_LOGIN_SUCCESS,
  EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS,
} from '../EducationLearnInvest/actionTypes'
import {
  EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS,
  EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS,
} from '../EducationGetAccredited/actionTypes'
import {
  RECEIVE_SIGNUP_BY_EMAIL,
  REQUEST_SIGNUP_BY_EMAIL,
  UPDATE_LOCAL_INVESTOR_USER,
  RECEIVE_SIGNIN_BY_EMAIL,
  REQUEST_SIGNIN_BY_EMAIL,
  RECEIVE_USER_INFO_UPDATE,
  REQUEST_LOADING,
  REQUEST_USER_INFO_UPDATE,
  LOGOUT_LOCAL_INVESTOR_USER,
  LOGOUT_LOCAL_SCOUT_USER,
  RECEIVE_STARTUP_SIGNIN_BY_EMAIL,
  RECEIVE_SCOUT_SIGNIN_BY_EMAIL,
  RECEIVE_STARTUP_USER_INFO_UPDATE,
  UPDATE_LOCAL_STARTUP_USER,
  LOGOUT_LOCAL_STARTUP_USER,
  LOGOUT_LOCAL_INVESTOR,
  LOGOUT_LOCAL_STARTUP,
  RECEIVE_STARTUP_SIGNUP_BY_GOOGLE,
  RECEIVE_SCOUT_SIGNUP_BY_GOOGLE,
  RECEIVE_INVESTOR_SIGNUP_BY_GOOGLE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR_FOR_GOOGLE_ACCOUNT,
  UPDATE_LOCAL_SCOUT_USER,
  LOGOUT_LOCAL_SCOUT,
  RECEIVE_SCOUT_USER_INFO_UPDATE,
  SET_PROFILE_DATA,
  RESEND_APPLICATION_INIT,
  RESEND_APPLICATION_SUCCESS,
  RESEND_APPLICATION_FAILED,
  RESEND_INVESTOR_APPLICATION_INIT,
  RESEND_INVESTOR_APPLICATION_SUCCESS,
  RESEND_INVESTOR_APPLICATION_FAILED,
} from './authActions'
import { STARTUP_LOGIN_FAILED, STARTUP_SIGNUP_FAILED } from '../startupFlowControl/startupFlowControlActions'
import { LOGIN_FAILED, SIGNUP_FAILED } from '../investorFlowControl/investorFlowControlActions'
import { SCOUT_LOGIN_FAILED, SCOUT_SIGNUP_FAILED } from '../scoutFlowControl/actionTypes'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  loading: false,
  token: null,
  investor: false,
  startup: false,
  scout: false,
  tokenExpired: false,
  routeLoading: true,
  forgotPasswordResult: {
    isSubmitted: false,
    isSuccess: false,
    isGoogleAccount: false,
  },
  user: {
    id: 0,
    isGoogleLogged: false,
    googleProfileObj: {},
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    location: '',
    sectors: [],
    lookingToInvest: 0,
    isSyndicate: false,
    accreditation: '',
    deals: [],
    syndicate: null,
    bio: '',
    popularity: '',
    first_dashboard_visit: true,
    monday_notification: false,
    experience_level: '',
  },
  startupUser: {
    id: 0,
    isGoogleLogged: false,
    googleProfileObj: {},
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    location: '',
    sectors: [],
    company_name: '',
    company_link: '',
    startup_pitch: '',
    bio: '',
    round_size: null,
    raised: null,
    progress: [], //List of ids
    position: '',
    num_team_members: 0,
    slide_deck: null,
    fileName: '',
    approved: false,
    first_dashboard_visit: true,
    monday_notification: false,
  },
  scoutUser: {
    id: 0,
    isGoogleLogged: false,
    googleProfileObj: {},
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    location: '',
    bio: '',
    position: '',
    first_dashboard_visit: true,
    monday_notification: false,
  },
  isAccreditionToOnBoarding: null,
}

// Define a Redux reducer function
const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
        tokenExpired: false,
      }
    case 'SET_AUTH_EXPIRED':
      return {
        ...state,
        token: null,
        tokenExpired: true,
      }
    case 'SET_AUTH_EXPIRED_FALSE':
      return {
        ...state,
        tokenExpired: false,
      }
    case UPDATE_LOCAL_INVESTOR_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      }
    case UPDATE_LOCAL_STARTUP_USER:
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          ...payload,
        },
      }
    case UPDATE_LOCAL_SCOUT_USER:
      return {
        ...state,
        scoutUser: {
          ...state.scoutUser,
          ...payload,
        },
      }
    case 'CLEAR_ACCOUNTS':
      return {
        ...state,
        startupUser: INITIAL_STATE.startupUser,
        user: INITIAL_STATE.user,
      }

    case 'TOGGLE_STARTUP_BUTTON':
      return {
        ...state,
        investor: false,
        scout: false,
        startup: true,
      }

    case 'TOGGLE_INVESTOR_BUTTON':
      return {
        ...state,
        investor: true,
        startup: false,
        scout: false,
      }

    case 'TOGGLE_SCOUT_BUTTON':
      return {
        ...state,
        investor: false,
        startup: false,
        scout: true,
      }

    case 'REGISTER_SUCCESS':
      return {
        ...state,
        isAuth: true,
        loading: false,
      }

    case 'UPDATE_SU_MONDAY_NOTIFICATIONS':
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          monday_notification: payload.monday_notification,
        },
      }

    case 'UPDATE_SU_SHOW_PROFILE':
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          show_profile: payload.visible,
        },
      }

    case 'UPDATE_INV_MONDAY_NOTIFICATIONS':
      return {
        ...state,
        user: {
          ...state.user,
          monday_notification: payload.monday_notification,
        },
      }

    case 'UPDATE_INV_SHOW_PROFILE':
      return {
        ...state,
        user: {
          ...state.user,
          show_profile: payload.visible,
        },
      }

    case 'SELECTED_AS_INVESTOR':
      return {
        ...state,
        investor: true,
        startup: false,
      }

    case 'DID_NOT_PROVIDE_SLIDEDECK':
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          slide_deck: null,
        },
      }

    case 'SELECTED_AS_STARTUP':
      return {
        ...state,
        investor: false,
        startup: true,
      }

    case 'SELECTED_AS_SCOUT':
      return {
        ...state,
        investor: false,
        startup: false,
        scout: true,
      }

    case 'RESET_GET_STARTED':
      return {
        ...state,
        investor: false,
        startup: false,
      }

    case REQUEST_SIGNUP_BY_EMAIL:
      return {
        ...state,
        loading: true,
      }

    case STARTUP_SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
      }

    case SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
      }

    case SCOUT_SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
      }

    case RECEIVE_SIGNUP_BY_EMAIL:
      return {
        ...state,
        loading: false,
        token: payload.token,
      }

    case RECEIVE_INVESTOR_SIGNUP_BY_GOOGLE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
      }

    case RECEIVE_STARTUP_SIGNUP_BY_GOOGLE:
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
      }
    case RECEIVE_SCOUT_SIGNUP_BY_GOOGLE:
      return {
        ...state,
        scoutUser: {
          ...state.scoutUser,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
      }

    case REQUEST_SIGNIN_BY_EMAIL:
      return {
        ...state,
        loading: true,
      }

    case STARTUP_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
      }

    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
      }

    case SCOUT_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
      }

    case RECEIVE_SIGNIN_BY_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
        investor: true,
      }

    case SET_PROFILE_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
        tokenExpired: false,
        profileLoader: false,
        loading: false,
        investor: true,
      }

    case RECEIVE_STARTUP_SIGNIN_BY_EMAIL:
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
        startup: true,
      }
    case RECEIVE_SCOUT_SIGNIN_BY_EMAIL:
      return {
        ...state,
        scoutUser: {
          ...state.scoutUser,
          ...payload.user,
        },
        token: payload.token,
        tokenExpired: false,
        loading: false,
        scout: true,
      }

    case REQUEST_USER_INFO_UPDATE:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_USER_INFO_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        loading: false,
      }

    case REQUEST_LOADING:
      return {
        ...state,
        loading: false,
      }

    case RECEIVE_STARTUP_USER_INFO_UPDATE:
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          ...payload,
        },
        loading: false,
        routeLoading: false,
      }

    case RECEIVE_SCOUT_USER_INFO_UPDATE:
      const scoutUser = {
        ...state.scoutUser,
        ...payload,
      }
      return {
        ...state,
        scoutUser: scoutUser,
        loading: false,
      }

    // case RECEIVE_STARTUP_FILE_UPLOAD:
    //   return {
    //     ...state,
    //     startupUser: {
    //       ...state.startupUser,
    //       ...payload
    //     },
    //     loading: false
    //   };

    case 'RECEIVE_SU_CO_USER_IMAGE': {
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          profile_pic_link: payload,
        },
      }
    }

    case LOGOUT_LOCAL_INVESTOR_USER:
      return {
        ...INITIAL_STATE,
        startup: false,
        investor: true,
        scout: false,
        token: null,
        tokenExpired: false,
      }

    case LOGOUT_LOCAL_SCOUT_USER:
      return {
        ...INITIAL_STATE,
        startup: false,
        investor: false,
        scout: true,
        token: null,
        tokenExpired: false,
      }

    case LOGOUT_LOCAL_STARTUP_USER:
      return {
        ...INITIAL_STATE,
        startup: true,
        investor: false,
        scout: false,
        // scout: FastBackwardOutlined,
        token: null,
        tokenExpired: false,
        routeLoading: false,
      }

    case LOGOUT_LOCAL_INVESTOR:
      return {
        loading: true,
        startup: false,
        investor: true,
        scout: false,
        user: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          lookingToInvest: 0,
          isSyndicate: false,
          accreditation: '',
          deals: '',
          syndicate: null,
          bio: '',
          popularity: '',
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
          // priorInvestments: [],
        },
        startupUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          company_name: '',
          company_link: '',
          startup_pitch: '',
          bio: '',
          round_size: null,
          raised: null,
          progress: [], //List of ids
          position: '',
          num_team_members: 0,
          slide_deck: null,
          fileName: '',
          approved: false,
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
        },
        scoutUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          bio: '',
          position: '',
          first_dashboard_visit: true,
          monday_notification: false,
        },
        token: null,
        tokenExpired: false,
      }

    case LOGOUT_LOCAL_STARTUP:
      return {
        loading: true,
        startup: true,
        investor: false,
        scout: false,
        user: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          lookingToInvest: 0,
          isSyndicate: false,
          accreditation: '',
          deals: '',
          syndicate: null,
          bio: '',
          popularity: '',
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
          // priorInvestments: [],
        },
        startupUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          company_name: '',
          company_link: '',
          startup_pitch: '',
          bio: '',
          round_size: null,
          raised: null,
          progress: [], //List of ids
          position: '',
          num_team_members: 0,
          slide_deck: null,
          fileName: '',
          approved: false,
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
        },
        scoutUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          bio: '',
          position: '',
          first_dashboard_visit: true,
          monday_notification: false,
        },
        token: null,
        tokenExpired: false,
      }

    case LOGOUT_LOCAL_SCOUT:
      return {
        loading: true,
        startup: false,
        investor: false,
        scout: true,
        user: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          lookingToInvest: 0,
          isSyndicate: false,
          accreditation: '',
          deals: '',
          syndicate: null,
          bio: '',
          popularity: '',
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
          // priorInvestments: [],
        },
        startupUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          sectors: [],
          company_name: '',
          company_link: '',
          startup_pitch: '',
          bio: '',
          round_size: null,
          raised: null,
          progress: [], //List of ids
          position: '',
          num_team_members: 0,
          slide_deck: null,
          fileName: '',
          approved: false,
          first_dashboard_visit: true,
          monday_notification: false,
          //show_profile: false,
        },
        scoutUser: {
          id: 0,
          isGoogleLogged: false,
          googleProfileObj: {},
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          location: '',
          bio: '',
          position: '',
          first_dashboard_visit: true,
          monday_notification: false,
        },
        token: null,
        tokenExpired: false,
      }

    case 'DISPLAY_FILE_NAME':
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          fileName: action.payload,
        },
      }

    case 'REMOVE_USER_LOCATION':
      return {
        ...state,
        user: {
          ...state.user,
          location: '',
        },
        startupUser: {
          ...state.startupUser,
          location: '',
        },
        scoutUser: {
          ...state.scoutUser,
          location: '',
        },
      }

    case 'REMOVE_FILE_NAME':
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          fileName: '',
          slide_deck: null,
        },
      }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordResult: {
          isSubmitted: true,
          isSuccess: true,
          isGoogleAccount: false,
        },
      }

    case FORGOT_PASSWORD_ERROR_FOR_GOOGLE_ACCOUNT:
      return {
        ...state,
        forgotPasswordResult: {
          isSubmitted: true,
          isSuccess: false,
          isGoogleAccount: true,
        },
      }
    case EDUCATION_LEARN_INVEST_LOGIN_SUCCESS:
    case EDUCATION_GET_ACCREDITED_LOGIN_SUCCESS:
    case EDUCATION_LEARN_INVEST_GOOGLE_LOGIN_SUCCESS:
    case EDUCATION_GET_ACCREDITED_GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        token: payload?.token,
        tokenExpired: false,
      }
    case 'COMPLETE_SCOUT_TOUR':
      return {
        ...state,
        scoutUser: {
          ...state?.scoutUser,
        },
      }
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        user: {
          ...state?.user,
          profile_pic_link: action.payload,
        },
        loading: false,
      }
    case 'SET_AUTH_LOADER':
      return {
        ...state,
        loading: action.payload,
      }
    case actionTypes.GET_COMPLETED_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, tour_taken: true },
      }
    case RESEND_APPLICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case RESEND_APPLICATION_SUCCESS:
      return {
        ...state,
        startupUser: {
          ...state.startupUser,
          ...payload,
        },
        loading: false,
      }
    case RESEND_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case RESEND_INVESTOR_APPLICATION_INIT:
      return {
        ...state,
        loading: true,
      }
    case RESEND_INVESTOR_APPLICATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        loading: false,
      }
    case RESEND_INVESTOR_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
      }
    case 'ACCREDITION_TO_ONBOARDING_ROUTE':
      return {
        ...state,
        isAccreditionToOnBoarding: action.payload.accreditionToOnBoarding,
      }
    default:
      return state
  }
}

export default authReducer
