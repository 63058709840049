import api from '../../utils/api'
// import { fetchInvestorUserLogout } from '../auth/authActions'

export const SET_POST_VIEW_DETAILS = 'SET_POST_VIEW_DETAILS'

// Action to view podcast details
export const setPostViewDetails = postViewDetails => ({
  type: SET_POST_VIEW_DETAILS,
  payload: postViewDetails,
})

// Action to fetch all reacord of the podcast by users Id
export const fetchPostDetails = id => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/marketing/get-single?id=${id}`).then(res => {
      if (res.result) {
        dispatch(setPostViewDetails(res.data))
        return res.req
      } else {
        console.error(res)
        //console.log("get user history failed! message:" + res.message);
        return false
      }
    })
  }
}
