import { lazy } from 'react'
export const ScoutSignup = lazy(() => import('../pages/Scout/ScoutSignup/ScoutSignup'))
export const LoginScouts = lazy(() => import('../components/LoginForm/LoginScouts'))
export const ScoutProfile = lazy(() => import('../pages/ScoutPortal/ScoutProfile/ScoutProfile'))
export const ScoutSettings = lazy(() => import('../pages/ScoutPortal/ScoutSettings/ScoutSettings'))
export const ScoutInvite = lazy(() => import('../pages/ScoutPortal/ScoutInvite/ScoutInvite'))
export const ScoutDeals = lazy(() => import('../pages/ScoutPortal/ScoutDeals/ScoutDeals'))
export const ScoutReferrals = lazy(() => import('../pages/ScoutPortal/ScoutReferrals/ScoutReferrals'))
export const ScoutContracts = lazy(() => import('../pages/ScoutPortal/ScoutContracts/ScoutContracts'))
export const ScoutNotifications = lazy(() =>
  import('../pages/ScoutPortal/ScoutNotifications/ScoutNotifications'),
)
export const ScoutOnboarding = lazy(() => import('../pages/ScoutOnboarding/ScoutOnboarding'))
export const Step06Congratulations = lazy(() =>
  import('../pages/ScoutOnboarding/Step06Congratulations/Step06Congratulations'),
)
export const ScoutEmailConfirmPage = lazy(() =>
  import('../pages/Scout/ScoutEmailConfirmPage/ScoutEmailConfirmPage'),
)
export const ScoutInviteHistory = lazy(() =>
  import('../pages/ScoutPortal/ScoutInviteHistory/ScoutInviteHistory.js'),
)
