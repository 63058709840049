import { SET_POST_VIEW_DETAILS } from './ViewDetailsActions'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  // loginFailed: false,
  postViewDetails: {},
}

// Define a Redux reducer function
const viewDetailsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_POST_VIEW_DETAILS:
      return {
        ...state,
        postViewDetails: payload,
      }

    default:
      return state
  }
}

export default viewDetailsReducer
