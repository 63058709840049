import api from '../../utils/api'
import { fetchInvestorUserLogout } from '../auth/authActions'

export const SET_POSTS = 'SET_POSTS'
export const SET_POSTS_COUNT = 'SET_POSTS_COUNT'

// Action to set posts.
export const setPosts = posts => ({
  type: SET_POSTS,
  payload: posts,
})

// Action creator to set posts count.
export const setPostCount = post_count => ({
  type: SET_POSTS_COUNT,
  payload: post_count,
})

// Action to fetch posts records.
export const fetchPostsRecords = (page_num, page_size, filter_by) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/marketing/get-all`, {
        page_num,
        page_size,
        filter_by,
      })
      .then(res => {
        if (res.result) {
          dispatch(setPosts(res.data))
          dispatch(setPostCount(res.cunt))
          return res.req
        } else {
          console.error(res)

          //console.log("get user history failed! message:" + res.message);
          return false
        }
      })
  }
}
