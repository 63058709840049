/*
  Unfortunately, we have to check upon the exist of either company_name or
  company_url since the database in staging and production may have one or
  the other. We really should eventually clean the database and use ONLY
  company_url and then modify all the code to only write to company_url
  in the frontend/backend instead. The company_name field actually is a
  real company url while the "name" field is actually the company's name.
  Right now, hacking this since the impact isn't too bad and we can code around it.
*/
export function getCompanyUrl(priorInvestment) {
  if (priorInvestment.company_name !== undefined && priorInvestment.company_name !== null) {
    return priorInvestment.company_name;
  } else if (priorInvestment.company_url !== undefined && priorInvestment.company_url !== null) {
    return priorInvestment.company_url;
  } else {
    return null;
  }
}
