// Import action type from an external file
import * as actionTypes from './actionTypes'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  loading: false,
  podcastListData: [],
  totalPodcastCount: 0,
  podcastDetails: null,
}

// Define a Redux reducer function
const podcastReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.GET_PODCAST_LIST_DATA_INIT:
      return {
        ...state,
        loading: true,
        podcastListData: [],
        totalPodcastCount: 0,
      }
    case actionTypes.GET_PODCAST_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        podcastListData: payload?.data,
        totalPodcastCount: payload?.count,
      }
    case actionTypes.GET_PODCAST_LIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_PODCAST_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_PODCAST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        podcastDetails: payload,
      }
    case actionTypes.GET_PODCAST_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default podcastReducer
