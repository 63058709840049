import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTopRouteChange extends Component {
  componentDidUpdate(prevProps) {
    // Check if the route has changed
    if (this.props.location !== prevProps.location) {
      // Scroll to the top of the page
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

// Wrap the component with withRouter to access the location prop
export default withRouter(ScrollToTopRouteChange)
