const SECTOR_DATA = [
  "Agriculture / Agtech", 
  "Artificial Intelligence", 
  "Augmented Reality",
  "Biomedical",
  "Biotech",
  "Blockchain",
  "Community",
  "Crowdfunding",
  "Diversity",
  "Drones",
  "Education",
  "Enterprise",
  "Entertainment",
  "Esports / Online Gaming",
  "Financial / Banking",
  "Government",
  "Hardware",
  "Healthcare",
  "Marketplace",
  "Media / Advertising",
  "Moonshots / Hard Tech",
  "Robotics",
  "Security",
  "Sport/Fitness",
  "Transportation",
  "Travel",
  "Virtual Reality",
  "Other"
];

export default SECTOR_DATA;
