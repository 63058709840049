import * as actionTypes from './ActionTypes'
import api from '../../utils/api'
import { IsTokenExpired } from '../auth/authActions'

// Action to add Monthly Updates
export const addTrackingMonthlyUpdates = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_TRACKING_MONTHLY_UPDATES_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/add-monthly-updates`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.ADD_TRACKING_MONTHLY_UPDATES_SUCCESS,
          payload: res.data,
        })
        onSuccess(res.data)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_TRACKING_MONTHLY_UPDATES_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to edit Monthly Updates
export const editTrackingMonthlyUpdates = (id, data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.EDIT_TRACKING_MONTHLY_UPDATES_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/edit-monthly-update?id=${id}`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.EDIT_TRACKING_MONTHLY_UPDATES_SUCCESS,
          payload: res.data,
        })
        onSuccess(res.data)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.EDIT_TRACKING_MONTHLY_UPDATES_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch key metric values
export const fetchKeyMetricValues = () => {
  return async dispatch => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/get-key-metric-values`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_KEY_METRIC_VALUES,
          payload: res,
        })
        return
      })
      .catch(error => {
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch single Monthly Updates based on users Id.
export const fetchSingleMonthlyUpdateData = (id, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_TRACKING_MONTHLY_UPDATES_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/get-single-monthly-update?id=` + id)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_SINGLE_MONTHLY_UPDATES_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SINGLE_MONTHLY_UPDATES_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch Monthly Updates
export const fetchMonthlyUpdateData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_MONTHLY_UPDATES_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/get-monthly-updates`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_MONTHLY_UPDATES_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_MONTHLY_UPDATES_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

export const fetchMonthlyUpdateDataNew = (onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_MONTHLY_UPDATES_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/get-monthly-updates`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_MONTHLY_UPDATES_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_MONTHLY_UPDATES_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch metric data
export const fetchMetricData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_METRIC_DATA_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/metric-data-plot`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_METRIC_DATA_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_METRIC_DATA_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch users profile data
export const fetchProfileData = (onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_PROFILE_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/profile`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_PROFILE_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_PROFILE_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch contry list
export const getCountryList = name => {
  return async dispatch => {
    await api
      .getWithoutToken('https://restcountries.com/v3.1/all')
      .then(res => {
        dispatch({
          type: actionTypes.SET_FLAG_LINK,
          payload: res?.filter(data => data?.name?.common === name)[0]?.flags?.svg,
        })
      })
      .catch(() => {})
  }
}

// Action to update users profile data
export const updateProfileData = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/startup/update-info`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.UPDATE_PROFILE_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to update users profile pic
export const updateProfilePic = (data, onSuccess, type) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_PIC_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/startup/mime-files`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        const finalRes = { ...res, type: type }
        dispatch({
          type: actionTypes.UPDATE_PROFILE_PIC_SUCCESS,
          payload: finalRes,
        })
        onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_PIC_FAILED,
          payload: error?.response?.data?.error,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to update co investor icon
export const updateCoInvestorIcon = (data, index, onSuccess, type) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_INVESTOR_ICON_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/startup/mime-files`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        const finalRes = { ...res, type: type, index }
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_SUCCESS,
          payload: finalRes,
        })
        onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_FAILED,
          payload: error?.response?.data?.error,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to upload file & get url of the file
export const uploadFileAndGetUrl = (data, onSuccess) => {
  return async dispatch => {
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/startup/mime-files`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        onSuccess(res)
        return
      })
      .catch(error => {
        throw error?.response?.data?.error
      })
  }
}

// Action to change password
export const changePassword = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CHANGE_PASSWORD_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/update-password`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res.result) {
          dispatch({
            type: actionTypes.CHANGE_PASSWORD_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.CHANGE_PASSWORD_FAILED,
          })
          onError && onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CHANGE_PASSWORD_FAILED,
        })
        onError && onError(error?.response?.data?.error)
        throw error?.response?.data?.error
      })
  }
}

// Action to add team members
export const addTeamMember = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_TEAM_MEMBER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/add-team-member`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.ADD_TEAM_MEMBER_SUCCESS,
          payload: res,
        })
        onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_TEAM_MEMBER_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to dispatch a success action indicating the 'DONE' status.
export const isDoneStatus = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.DONE_SUCCESS,
    })
  }
}

// Action to fetch email notifications data
export const getEmailNotificationsData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_EMAIL_NOTIFICATION_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/tracking-email-notification`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_EMAIL_NOTIFICATION_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_EMAIL_NOTIFICATION_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to set email notifications
export const setEmailNotifications = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_EMAIL_NOTIFICATION_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/tracking-email-notification`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.SET_EMAIL_NOTIFICATION_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_EMAIL_NOTIFICATION_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action creator to fetch tour token data.
export const getTourTokenData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_TOUR_TOKEN_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/login`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_TOUR_TOKEN_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_TOUR_TOKEN_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to handle the visibility of the cancel modal.
export const handleCancelModalVisibility = selection => {
  return {
    type: actionTypes.IS_CANCEL_MODAL_VISIBLE,
    payload: selection,
  }
}

// Action to handle the active form state.
export const activeFormHandler = payload => ({
  type: actionTypes.ACTIVE_FORM,
  payload: payload,
})

export const handleEmptyCoInvestorLogos = () => ({
  type: actionTypes.EMPTY_CO_INVESTOR_LOG0S,
})

export const handleRemoveCoInvestor = payload => {
  return {
    type: actionTypes.REMOVE_CO_INVESTOR,
    payload: payload,
  }
}

export const handleNewCoInvestorAddLocally = payload => {
  return {
    type: actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY,
    payload: payload,
  }
}

// Action for sending emails of startup's Monthly Update
export const shareMonthlyUpdates = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SHARE_MONTHLY_UPDATES_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/share-monthly-update-links`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SHARE_MONTHLY_UPDATES_SUCCESS,
          payload: res?.data,
        })
        onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SHARE_MONTHLY_UPDATES_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}
// Action to get startup details
export const getStartupMonthlyUpdateDetails = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/monthly-updates-public-link`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_SUCCESS,
          payload: res.data,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.STARTUP_MONTHLY_UPDATE_DETAIL_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}
// Action to fetch metric data
export const fetchMonthlyUpdateMetricData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/analytics-public-link`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_STARTUP_MONTHLY_UPDATE_METRIC_DATA_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}
// Action to set email notifications
export const setMonthlyUpdateUrlState = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_MONTHLY_UPDATE_URL_STATE_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/startup/update-info`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.SET_MONTHLY_UPDATE_URL_STATE_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_MONTHLY_UPDATE_URL_STATE_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}
