import { lazy } from 'react'
//Recruit portal

export const Hiring = lazy(() => import('../pages/Hiring/Hiring'))

export const LoginHiring = lazy(() => import('../pages/Hiring/HiringPortal/LoginHiring/LoginHiring'))

export const RegisterHiring = lazy(() => import('../pages/Hiring/HiringPortal/RegisterHiring/RegisterHiring'))
export const HiringStepPage = lazy(() => import('../pages/Hiring/HiringPortal/HiringStepPage/HiringStepPage'))
export const Congratulations = lazy(() =>
  import('../pages/Hiring/HiringPortal/Congratulations/Congratulations'),
)
export const AboutYourStartup = lazy(() =>
  import('../pages/Hiring/HiringPortal/AboutYourStartup/AboutYourStartup'),
)
