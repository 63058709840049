import React, { useEffect } from 'react'

import './IdentifyAsAnyOne.scss'

import { Button, Col, Container, Row } from 'react-bootstrap'
import { connect, useDispatch, useSelector } from 'react-redux'

import IdentifyAsAnyOneData from './IdentifyAsAnyOneData'
import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'
import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'
import { nextStepScout, prevStepScout } from '../../redux/scoutFlowControl/scoutFlowControlActions'
import {
  updateLocalStartupUser,
  updateLocalInvestorUser,
  updateLocalScoutUser,
  fetchStartupUserInfoUpdate,
  fetchInvestorUserInfoUpdate,
  fetchScoutUserInfoUpdate,
  fetchStartupUserLogout,
  fetchInvestorUserLogout,
  fetchScoutUserLogout,
} from '../../redux/auth/authActions'
import { SaveAndExitClickedReset } from '../../redux/startupFlowControl/startupFlowControlActions'

import { Steps, Progress } from 'antd'
import 'antd/dist/antd.css'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
// import MileStonesCardList from '../../components/MileStonesCardList/MileStonesCardList'
import IdentifyTagsList from '../../components/IdentifyTagsList/IdentifyTagsList'

const IdentifyAsAnyOne = ({
  onCountinueClick,
  stepStartup,
  fetchInvestorUserInfoUpdate,
  fetchScoutUserInfoUpdate,
  tags,
  updateUserInfoFailed,
  prevStepStartup,
  prevStepInvestor,
  prevStepScout,
  nextStepStartup,
  updateLocalStartupUser,
  updateLocalInvestorUser,
  updateLocalScoutUser,
  fetchStartupUserInfoUpdate,
  startupUser,
  scoutUser,
  startup,
  scout,
  investor,
  investorUser,
}) => {
  const tags1 = startup ? startupUser.tags : investorUser.tags
  const { saveAndExitClicked } = useSelector(state => state.startupflowControl)
  const progressCopy = tags1?.slice()
  const dispatch = useDispatch()

  /**
   * Handle checkbox change event.
   * @param {Object} e - The event object.
   * @description - Add or remove the checkbox value from progressCopy based on its checked status
   *              - Update local user state based on the current user type
   */
  const handleCheckBoxChange = e => {
    if (e.target.checked) {
      if (!tags1.includes(e.target.name)) {
        progressCopy.push(e.target.name)
      }
    } else {
      if (tags1.includes(e.target.name)) {
        const index = progressCopy.findIndex(element => element === e.target.name)
        progressCopy.splice(index, 1)
      }
    }
    if (startupUser) {
      updateLocalStartupUser({ tags1: progressCopy })
    } else if (scoutUser) {
      updateLocalScoutUser({ tags1: progressCopy })
    } else {
      updateLocalInvestorUser({ tags1: progressCopy })
    }
    // updateLocalStartupUser({ tags1: progressCopy })
  }

  /**
   * Handle card change event.
   * @param {Object} e - The event object.
   * @param {Array} tags - The array of tags.
   * @description - Identify the selected tag and add or remove it from progressCopy
   *              - Update local user state based on the current user type
   */
  const handleCardChange = (e, tags) => {
    let x = e.target.id
    if (!tags.includes(x)) {
      progressCopy.push(x)
    } else {
      if (tags.includes(x)) {
        const index = progressCopy.findIndex(element => element === x)
        progressCopy.splice(index, 1)
      }
    }
    if (startup) {
      updateLocalStartupUser({ tags: progressCopy })
    } else if (scout) {
      updateLocalScoutUser({ tags: progressCopy })
    } else {
      updateLocalInvestorUser({ tags: progressCopy })
    }
  }

  /**
   * Handle investor click event for next or back.
   * @param {boolean} isNextOrBack - Indicates whether it's for the next or back action.
   * @description - Update investor user information and handle navigation based on success
   */
  const investorOnClick = isNextOrBack => {
    // onCountinueClick()
    fetchInvestorUserInfoUpdate({
      tags,
      onboarding_step: 10,
    })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            //nextStepInvestor()
            // onCountinueClick()
          } else {
            prevStepInvestor()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/investor/login'
          fetchInvestorUserLogout()
        }
      })
  }

  /**
   * Handle scout click event for next or back.
   * @param {boolean} isNextOrBack - Indicates whether it's for the next or back action.
   * @description - Update scout user information and handle navigation based on success
   */
  const scoutOnClick = isNextOrBack => {
    fetchScoutUserInfoUpdate({ tags, onboarding_step: 7 })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            // nextStepStartup()
            // onCountinueClick()
          } else {
            prevStepScout()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/scout/login'
          dispatch(fetchScoutUserLogout())
        }
      })
  }

  /**
   * Handle startup click event for next or back.
   * @param {boolean} isNextOrBack - Indicates whether it's for the next or back action.
   * @description - Update startup user information and handle navigation based on success
   */
  const startupOnClick = isNextOrBack => {
    fetchStartupUserInfoUpdate({ tags, onboarding_step: 7 })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            // nextStepStartup()
            // onCountinueClick()
          } else {
            prevStepStartup()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }

  /**
   * Handle click event for going back in the startup flow.
   * @param {Object} e - The event object.
   */
  const startupBackOnClick = e => {
    startupOnClick(e, false)
  }

  /**
   * Handle click event for moving to the next step in the startup flow.
   * @param {Object} e - The event object.
   */

  const startupNextOnClick = e => {
    startupOnClick(true)
    onCountinueClick()
  }

  /**
   * Handle click event for moving to the next step in the scout flow.
   * @param {Object} e - The event object.
   */
  const scoutNextOnClick = e => {
    scoutOnClick(true)
    onCountinueClick()
  }

  /**
   * Handle click event for moving to the next step in the investor flow.
   * @param {Object} e - The event object.
   */
  const investorNextOnClick = e => {
    investorOnClick(true)
    onCountinueClick()
  }

  // useEffect hook to handle actions when saveAndExitClicked changes.
  useEffect(() => {
    if (saveAndExitClicked) {
      if (startup) {
        startupOnClick(false)
      } else if (scout) {
        scoutOnClick(false)
      } else {
        investorOnClick(false)
      }
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])
  const isEnabled = tags1 && tags1.length <= 3 && tags1.length > 0

  const maxMilestonesSelected = tags?.length === 3

  /**
   * Functional component representing a checklist.
   * @param {Object} props - The component properties.
   * @returns {JSX.Element} - The rendered component.
   */
  const checkList = IdentifyAsAnyOneData.map((identityAsOne, i) => {
    return (
      <div
        className={tags?.includes(identityAsOne) ? 'selected sector-milestone' : 'sector-milestone'}
        key={i}
      >
        <div className="checkbox">
          <input
            checked={tags1?.includes(identityAsOne)}
            onChange={event => {
              handleCheckBoxChange(event, tags1)
            }}
            type="checkbox"
            id={identityAsOne}
            name={identityAsOne}
            //disabled={maxMilestonesSelected && !tags1.includes(identityAsOne)}
          />
          {identityAsOne === IdentifyAsAnyOneData[0] ||
          identityAsOne === IdentifyAsAnyOneData[1] ||
          identityAsOne === IdentifyAsAnyOneData[2] ||
          identityAsOne === IdentifyAsAnyOneData[3] ? (
            <label
              name={identityAsOne}
              htmlFor={identityAsOne}
              onClick={event => {
                handleCardChange(event, tags1)
              }}
              id={identityAsOne}
            >
              <span
                id={identityAsOne}
                className={tags1?.includes(identityAsOne) ? 'sector-text-color' : ''}
                style={maxMilestonesSelected && !tags1.includes(identityAsOne) ? { color: '#B5B5B5' } : null}
              >
                {identityAsOne}
              </span>
            </label>
          ) : (
            <label name={identityAsOne} htmlFor={identityAsOne}>
              <span className={tags1?.includes(identityAsOne) ? 'sector-text-color' : ''}>
                {identityAsOne}
              </span>
            </label>
          )}
        </div>
      </div>
    )
  })

  const { Step } = Steps

  return (
    <div className="identify-wrapper">
      {/*** Heading is here ***/}
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mx-auto text-center">
            {startup ? (
              <h4 className="heading-question">
                Does {startupUser.company_name} or {startupUser.company_name}'s founding team identify as any
                one of these?
              </h4>
            ) : (
              <h4 className="heading-question">
                Do you <b className="font-weight-bold">identify</b> as any one of these?
              </h4>
            )}
            <h5 className="heading-question-small">If not, please click continue.</h5>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
          <Col md={startup ? 12 : 8} className="identifyTags mx-auto">
            <form>
              <IdentifyTagsList />
            </form>
          </Col>
        </Row>
      </Container>

      {/* New Back & Continue buttons */}
      <section className="bottomBtns mt-5">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                onClick={startup ? startupNextOnClick : scout ? scoutNextOnClick : investorNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  stepScout: state.scoutflowControl.stepScout,
  startupUser: state.auth.startupUser,
  scoutUser: state.auth.scoutUser,
  investorUser: state.auth.user,
  updateUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
  tags: state.auth.startup ? state.auth.startupUser.tags : state.auth.user.tags,
  startup: state.auth.startup,
  scout: state.auth.scout,
  investor: state.auth.investor,
})

const mapDispatchToProps = dispatch => ({
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  prevStepStartup: () => dispatch(prevStepStartup()),
  prevStepScout: () => dispatch(prevStepScout()),
  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },
  updateLocalScoutUser: changedProperties => {
    dispatch(updateLocalScoutUser(changedProperties))
  },
  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },
  fetchScoutUserInfoUpdate: changedProperties => {
    return dispatch(fetchScoutUserInfoUpdate(changedProperties))
  },

  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },
  nextStepStartup: () => dispatch(nextStepStartup()),
  nextStepScout: () => dispatch(nextStepScout()),

  nextStepInvestor: () => dispatch(nextStepInvestor()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentifyAsAnyOne)
