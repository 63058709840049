import _ from "lodash";

import UserType from "./userType";
import { requireNonEmpty } from "../utils/general";

// Naming this uid to stand for universal identifier
const ID_PREFIX = 'uid';
const EXITFUND_NAMESPACE = 'exitfund';
const STARTUP_ENTITY_TYPE = UserType.STARTUP;
const SCOUT_ENTITY_TYPE = UserType.SCOUT;
const INVESTOR_ENTITY_TYPE = UserType.INVESTOR;
const GUEST_ENTITY_TYPE = UserType.GUEST;

export function createInvestorIdentifier(id) {
  requireNonEmpty(id, `Id must be non-empty for creating an investor identifier.`);
  return _.join([ID_PREFIX, EXITFUND_NAMESPACE, INVESTOR_ENTITY_TYPE, id], ':');
}

export function createStartupIdentifier(id) {
  requireNonEmpty(id, `Id must be non-empty for creating an startup identifier.`);
  return _.join([ID_PREFIX, EXITFUND_NAMESPACE, STARTUP_ENTITY_TYPE, id], ':');
}

export function createScoutIdentifier(id) {
  requireNonEmpty(id, `Id must be non-empty for creating an scout identifier.`);
  return _.join([ID_PREFIX, EXITFUND_NAMESPACE, SCOUT_ENTITY_TYPE, id], ':');
}

export function createGuestIdentifier(id) {
  requireNonEmpty(id, `Id must be non-empty for creating an anonymous identifier.`);
  return _.join([ID_PREFIX, EXITFUND_NAMESPACE, GUEST_ENTITY_TYPE, id], ':');
}
