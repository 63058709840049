import React, { Component } from "react";

import { Alert } from "reactstrap";

import "./InvalidUserInputAlert.scss";

export default class InvalidUserInputAlert extends Component {
  render() {
    return (
      <Alert id={this.props.id} className="failure-alert d-flex flex-row" style={{alignItems: "stretch"}}>
        <span className="alert-exclamation">
          !
        </span>
        <span className="alert-description">
          {this.props.description}
        </span>
      </Alert>
    );
  }
}
