// Import action type from an external file
import {
  STARTUP_SIGNUP_FAILED,
  STARTUP_LOGIN_FAILED,
  UPDATE_STARTUP_STEP,
  UPDATE_STARTUP_TAB,
  UPDATE_STARTUP_USER_INFO_FAILED,
  SHOW_SLIDE_DECK_FORM,
} from './startupFlowControlActions'

import { RECEIVE_STARTUP_SIGNIN_BY_EMAIL, LOGOUT_LOCAL_STARTUP_USER } from '../auth/authActions'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  loginFailed: false,
  signupFailed: false,
  updateUserInfoFailed: false,
  confirmedEmail: false,
  searchField: '',
  showSlideDeckForm: false,
  fileUploaded: false,
  stepStartup: 0,
  tabStartup: 0,
  user: {
    isGoogleLogged: false,
    googleProfileObj: {},
  },
  dashboardView: 'discover',
  historySelection: 'all',
  userInfos: [],
  userHistory: [],
  userHistoryConnected: [],
  userHistoryPassed: [],
  invitedInvestor: null,
  loadingDashboard: false,
  viewFeedbackModal: false,
  feedbackInformation: {},
  userHistoryInfo: [],
  referSent: false,
  slideDeckModal: false,
  slideDeckUploadModal: {
    file: null,
    hasUploadFailed: false,
  },
  connectedWithInvestor: false,
  selectedCoFounder: {
    bio: '',
    linkedin: '',
    name: '',
    photo: '',
    position: [],
  },
  saveAndExitClicked: false,
}

// Define a Redux reducer function
const startupFlowControlReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case 'SET_SELECTED_STR_COFOUNDER': {
      return {
        ...state,
        selectedCoFounder: action.payload,
      }
    }
    case 'Save_And_Exit_Clicked': {
      return {
        ...state,
        saveAndExitClicked: true,
      }
    }
    case 'Save_And_Exit_Clicked_Reset': {
      return {
        ...state,
        saveAndExitClicked: false,
      }
    }
    case 'SET_CONNECTED_INVESTOR': {
      return {
        ...state,
        connectedWithInvestor: action.payload,
      }
    }
    case 'SET_SLIDE_DECK_MODAL': {
      return {
        ...state,
        slideDeckModal: action.payload,
      }
    }
    case 'CHANGE_SU_REFER_INFO': {
      return {
        ...state,
        referSent: true,
      }
    }
    case 'CLEAR_STARTUP_FLOW':
      return INITIAL_STATE
    case 'SET_SU_CONFIRMED_EMAIL':
      return {
        ...state,
        confirmedEmail: true,
      }
    case 'RECEIVE_USER_HISTORY_CARD_INFO':
      return {
        ...state,
        userHistoryInfo: payload,
      }
    case 'SET_VIEW_FEEDBACK_MODAL':
      return {
        ...state,
        viewFeedbackModal: payload,
      }
    case 'SET_VIEW_FEEDBACK_INFORMATION':
      return {
        ...state,
        feedbackInformation: payload,
      }
    case 'LOAD_SU_DASHBOARD':
      return {
        ...state,
        loadingDashboard: payload,
      }
    case 'SET_HISTORY_SELECTION':
      return {
        ...state,
        historySelection: payload,
      }
    case 'REMOVE_INVESTOR':
      return {
        ...state,
        userInfos: [...state.userInfos.slice(1)],
      }
    case 'SET_DISCOVER_VIEW':
      return {
        ...state,
        dashboardView: 'discover',
      }
    case 'SET_INVITED_INVESTOR':
      return {
        ...state,
        invitedInvestor: payload,
      }

    case 'SET_HISTORY_VIEW':
      return {
        ...state,
        dashboardView: 'history',
      }
    case 'NEXT_STEP_STARTUP':
      return {
        ...state,
        stepStartup: state.stepStartup + 1,
      }

    case 'PREV_STEP':
      return {
        ...state,
        stepStartup: state.stepStartup - 1,
      }

    case 'RECEIVE_SU_USER_PROFILE':
      return {
        ...state,
        userInfos: payload,
        loading: false,
      }

    case 'RECEIVE_SU_USER_HISTORY':
      return {
        ...state,
        userHistory: payload,
        loading: false,
      }

    case 'RECEIVE_SU_USER_HISTORY_CONNECTED':
      return {
        ...state,
        userHistoryConnected: payload,
        loading: false,
      }

    case 'RECEIVE_SU_USER_HISTORY_PASSED':
      return {
        ...state,
        userHistoryPassed: payload,
        loading: false,
      }

    case 'PREV_STEP_STARTUP':
      return {
        ...state,
        stepStartup: state.stepStartup - 1,
      }

    case 'PITCH_DECK_UPLOAD_SUCCESS':
      return {
        ...state,
        fileUploaded: true,
      }

    case STARTUP_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true,
        signupFailed: false,
        updateUserInfoFailed: false,
        searchField: '',
        showSlideDeckForm: false,
        fileUploaded: false,
        user: {
          isGoogleLogged: false,
          googleProfileObj: {},
        },
        dashboardView: 'discover',
        historySelection: 'all',
        userInfos: [],
        userHistory: [],
        userHistoryConnected: [],
        userHistoryPassed: [],
        invitedInvestor: null,
      }

    case 'GOOGLE_LOGIN_FIELD':
      return {
        ...state,
        user: {
          ...state.user,
          googleProfileObj: action.text,
        },
      }

    case 'RESET_INITIAL_STARTUP_STATE':
      return {
        ...state,
        loginFailed: false,
        signupFailed: false,
        updateUserInfoFailed: false,
        fileUploaded: false,
        showSlideDeckForm: false,
        searchField: '',
        stepStartup: 0,
        tabStartup: 0,
        user: {
          ...state.user,
          isGoogleLogged: false,
          googleProfileObj: {},
        },
      }

    case STARTUP_SIGNUP_FAILED:
      return {
        ...state,
        signupFailed: true,
        loginFailed: false,
        updateUserInfoFailed: false,
        confirmedEmail: false,
        searchField: '',
        showSlideDeckForm: false,
        fileUploaded: false,
        user: {
          isGoogleLogged: false,
          googleProfileObj: {},
        },
        dashboardView: 'discover',
        historySelection: 'all',
        userInfos: [],
        userHistory: [],
        userHistoryConnected: [],
        userHistoryPassed: [],
        invitedInvestor: null,
      }

    case UPDATE_STARTUP_STEP:
      return {
        ...state,
        stepStartup: payload,
      }

    case UPDATE_STARTUP_TAB:
      return {
        ...state,
        tabStartup: payload,
      }

    case RECEIVE_STARTUP_SIGNIN_BY_EMAIL:
      return {
        ...state,
        confirmedEmail: payload.user.email_confirmed,
      }

    case UPDATE_STARTUP_USER_INFO_FAILED:
      return {
        ...state,
        updateUserInfoFailed: true,
      }

    case SHOW_SLIDE_DECK_FORM:
      return {
        ...state,
        showSlideDeckForm: !state.showSlideDeckForm,
      }

    case 'SHOW_SLIDE_DECK_UPLOAD_MODAL':
      return {
        ...state,
        slideDeckUploadModal: {
          file: payload.file,
          hasUploadFailed: false,
        },
      }

    case 'SLIDE_DECK_UPLOAD_FAILED':
      return {
        ...state,
        slideDeckUploadModal: {
          file: state.slideDeckUploadModal.file,
          hasUploadFailed: true,
        },
      }

    case 'HIDE_SLIDE_DECK_UPLOAD_MODAL':
      return {
        ...state,
        slideDeckUploadModal: {
          file: null,
          hasUploadFailed: false,
        },
      }

    case LOGOUT_LOCAL_STARTUP_USER:
      return INITIAL_STATE

    default:
      return state
  }
}

export default startupFlowControlReducer
