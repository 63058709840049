import { SET_PODCAST_VIEW_DETAILS } from './podcastViewDetailsActions'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  podcastViewDetails: {},
}

// Define a Redux reducer function
const podcastViewDetailsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PODCAST_VIEW_DETAILS:
      return {
        ...state,
        podcastViewDetails: payload,
      }

    default:
      return state
  }
}

export default podcastViewDetailsReducer
