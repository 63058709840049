import React, { useState } from "react";
import "./InvestorCard.scss";
import startUpDefaultIcon from "../../assets/images/FillerStartupIcon.png";
import membericon from "../../assets/images/FounderPhoto.png";

import HyperlinkWrapper from "../../components/HyperlinkWrapper/HyperlinkWrapper";
import { getCompanyUrl } from "../../utils/investor/priorInvestment";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";

function PriorInvestmentCard(props) {
  const priorInvestment = props.priorInvestment;

  let companyUrl = getCompanyUrl(priorInvestment);

  return (
    <div
      className="investment-card"
      style={{ position: "relative", top: "-5px" }}
    >
      <div className="prior-invest-img-container">
        <HyperlinkWrapper
          hyperlink={companyUrl}
        >
          <img
            className="prior-invest-img"
            src={
              priorInvestment.logo_link && priorInvestment.logo_link !== "none found"
                ? priorInvestment.logo_link
                : startUpDefaultIcon
            }
            alt=""
          />
        </HyperlinkWrapper>
      </div>
      <HyperlinkWrapper
        hyperlink={companyUrl}
      >
        <h5 className="pt-2 purple-colored font-weight-600">
          {priorInvestment.name}
        </h5>
      </HyperlinkWrapper>
      <div className="preview-team-position">{priorInvestment.year}</div>
    </div>
  );
}

function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  var i = 0;
  var arrayLength = props.pInv ? props.pInv.length : 0;
  var tempArray = [];

  for (i = 0; i < arrayLength; i += 4) {
    tempArray.push(props.pInv.slice(i, i + 4));
  }

  return (
    <div className="prior-invest-carousel">
      {props.pInv && props.pInv.length ? (
        <>
          <Carousel
            interval={null}
            activeIndex={index}
            onSelect={handleSelect}
            className={`mb-0 ${
              props.pInv && props.pInv.length < 5 ? "inv-arrows" : ""
            }`}
          >
            {tempArray.map((item) => (
              <Carousel.Item>
                {item.map((inv, idx) => (
                  <>
                    {idx >= 4 ? null : (
                      <PriorInvestmentCard priorInvestment={inv} />
                    )}
                  </>
                ))}
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="d-lg-none d-block push"></div>
        </>
      ) : (
        <div style={{ color: "#b5b5b5" }}>
          {props.firstName} hasn't filled out their prior investments yet.
        </div>
      )}
    </div>
  );
}

class InvestorCard extends React.Component {
  render() {
    const { user } = this.props;

    const INVEST_DATA = {
      0: "$25,000",
      25: "$50,000",
      50: "$100,000",
      100: "$250,000",
      250: "$500,000",
      500: "$500,000+",
    };

    const sectors = {
      agtech: "Agriculture / Agtech",
      ai: "Artificial Intelligence",
      ar: "Augmented Reality",
      biomed: "Biomedical",
      biotech: "Biotech",
      blockchain: "Blockchain",
      community: "Community",
      crowdfund: "Crowdfunding",
      devtools: "Developer Tools",
      diversity: "Diversity",
      drones: "Drones",
      education: "Education",
      energy: "Energy",
      enterprise: "Enterprise",
      entertain: "Entertainment",
      gaming: "Esports / Online Gaming",
      banking: "Financial / Banking",
      government: "Government",
      hardware: "Hardware",
      health: "Healthcare",
      market: "Marketplace",
      media: "Media / Advertising",
      hardtech: "Moonshots / Hard Tech",
      robotics: "Robotics",
      security: "Security",
      sport: "Sport/Fitness",
      transport: "Transportation",
      travel: "Travel",
      vr: "Virtual Reality",
      other: "Other",
    };

    const mappedSectors =
      user.sectors &&
      user.sectors.map((s) => {
        return sectors[s] ? sectors[s] : s;
      });

    const sortedSectors =
      mappedSectors &&
      mappedSectors.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });

    return (
      <>
        <Row>
          <Col xs={5} className="p-5 preview-left">
            <div className="inner-left">
              <div>
                {user.profile_pic_link && user.profile_pic_link !== "None" ? (
                  <img
                    className="investor-profile-pic"
                    src={user.profile_pic_link}
                    alt=""
                    style={{ marginTop: "0px", objectFit: "cover" }}
                  />
                ) : (
                  <img
                    src={membericon}
                    alt=""
                    style={{ marginTop: "0px" }}
                    className="investor-profile-pic"
                  />
                )}
              </div>
              <h1
                className="purple-colored font-weight-900 pt-4 mb-1"
                style={{ wordWrap: "break-word" }}
              >
                {user.first_name} {user.last_name ? user.last_name : null}
              </h1>
              <h5>{user.location}</h5>

              <h5 className="pt-4">{user.bio}</h5>
              <div>
                <h4 className="font-weight-600 pt-4">Affiliations</h4>
                {user.syndicate && user.syndicate.length ? (
                  user.syndicate.map((syn) => (
                    <div className="affiliation-group investor-card-section-title">
                      {syn}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      color: "#B5B5B5",
                    }}
                  >
                    No Affiliations
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={7} className="py-5 preview-right investor-right">
            <div>
              <h2 style={{ fontWeight: "700" }}>
                Will invest up to{" "}
                <span className="gold-colored">
                  {user.deals && user.deals[0]
                    ? INVEST_DATA[user.deals[0]]
                    : "-"}
                </span>
              </h2>
            </div>
            <h4 className="font-weight-600 pt-4">Invests in</h4>
            <div className="py-2 d-flex" style={{ flexWrap: "wrap" }}>
              {sortedSectors &&
                sortedSectors.map((sector) => (
                  <span
                    className="investor-sector sector-list-size font-weight-bold mb-2"
                    style={{ borderRadius: "30px" }}
                  >
                    {sector}
                  </span>
                ))}
            </div>
            <div className="pt-4 d-flex justify-content-between">
              <h4 className="font-weight-600">Prior Investments</h4>
            </div>
            <div>
              <ControlledCarousel
                pInv={user.prior_investments}
                firstName={user.first_name}
              />
            </div>
          </Col>
        </Row>
        <div style={{ position: "relative", margin: "20px" }} />
      </>
    );
  }
}

export default connect()(InvestorCard);
