import api from '../../utils/api'

export const SET_PODCASTS = 'SET_PODCASTS'
export const SET_PODCASTS_COUNT = 'SET_PODCASTS_COUNT'

// Action to set all podcast data
export const setPodcasts = podcasts => ({
  type: SET_PODCASTS,
  payload: podcasts,
})

// Action to set total count of the podcast data
export const setPodcastCount = podcastst_count => ({
  type: SET_PODCASTS_COUNT,
  payload: podcastst_count,
})

// Action to get all reacord of the podcast
export const fetchPodcastRecords = (page_num, page_size, filter_by) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/marketing/get-all`, {
        page_num,
        page_size,
        filter_by,
      })
      .then(res => {
        if (res.result) {
          dispatch(setPodcasts(res.data))
          dispatch(setPodcastCount(res.cunt))
          return res.req
        } else {
          console.error(res)
          return false
        }
      })
  }
}
